import React, { useEffect, useRef, useState } from "react";
import { Redirect, useParams } from "react-router-dom";
import UserService from "../../services/user.service";
import "../../Print.css";
import { logOut } from "../../helpers/logout";
import { Button, Divider, Grid, Toolbar } from "@material-ui/core";
import ActionButton from "../form/input/ActionButton";
import Tooltip from "@material-ui/core/Tooltip";
import Paper from "@material-ui/core/Paper";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import Popup from "../presentation/Popup";
import TextInput from "../form/input/TextInput";
import DateInput from "../form/input/DateInput";
import altPhoto from "../../assets/trans.png";
import { PDFExport } from "@progress/kendo-react-pdf";
import CheckBoxInput from "../form/input/CheckBoxInput";
import { NotificationManager } from "react-notifications";
import withWidth from "@material-ui/core/withWidth";
import { ReactSketchCanvas } from "react-sketch-canvas";
import { convertSize } from "../../helpers/helper";

const useStyles = makeStyles((theme) => ({
    appBarSpacer: theme.mixins.toolbar,
    root: {
        flexGrow: 1,
        width: "100%",
        backgroundColor: theme.palette.background.paper,
    },
    seeMore: {
        marginTop: theme.spacing(8),
    },
    paper: {
        padding: theme.spacing(2),
        display: "flex",
        overflow: "auto",
        flexDirection: "column",
    },
    toolbarButtons: {
        marginLeft: "auto",
        "& .MuiFormControl-root": {
            margin: theme.spacing(1),
        },
    },
    marginElement: {
        margin: theme.spacing(2),
    },
    marginTop: {
        marginTop: theme.spacing(2),
    },
    icon: {
        marginRight: theme.spacing(2),
    },
    heroContent: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(8, 0, 6),
    },
    heroButtons: {
        marginTop: theme.spacing(4),
    },
    cardGrid: {
        paddingTop: theme.spacing(8),
        paddingBottom: theme.spacing(8),
    },
    card: {
        height: "100%",
        display: "flex",
        flexDirection: "column",
    },
    cardMedia: {
        paddingTop: "56.25%", // 16:9
    },
    cardContent: {
        flexGrow: 1,
    },
}));

const DocumentsSigningGenerator = (props) => {
    const classes = useStyles();
    const fixedHeightPaper = clsx(classes.paper);
    let canvas = useRef(null);
    const [fullScreen, setFullScreen] = useState(false);

    const pdfRef = useRef(null);
    const [funeral, setFuneral] = useState({
        contracting_authority: "",
        ca_address: "",
        ca_zip: "",
        ca_city: "",
        ca_state: "",
        ca_nationality: "",
        ca_email: "",
        ca_phone: "",
        ca_id: "",
        ca_birth: "",
        ca_relationship: "",
        deceased: "",
        d_address: "",
        d_zip: "",
        d_city: "",
        d_state: "",
        d_place_birth: "",
        d_birth: "",
        d_gender: "",
        d_marital_status: "",
        d_death: "",
        d_place_death: "",
        d_id: "",
        d_nationality: "",
        d_education: "",
        d_last_job: "",
        d_wife_husband: "",
        d_wife_husband_id: "",
        date_of_signature_delegation: new Date(),
        planned_pickup_place: "",
        planned_pickup: "",
        town_funeral: "",
        place_funeral: "",
        date_funeral: "",
        coffin_arrival: "",
        coffin_arrival_place: "",
        signatures: {},
        place: {},
        evidence: {},
        other_party_person: "",
        other_party_comp: "",
    });
    const [show, setShow] = useState(false);
    const [date, setDate] = useState(new Date());
    const [success, setSuccess] = useState(false);
    const [otherPartyPerson, setOtherPartyPerson] = useState("");
    const [otherPartyComp, setOtherPartyComp] = useState("");
    const [location, setLocation] = useState("");
    const [checkBoxValues, setCheckBoxValues] = useState({
        items: false,
        urn: false,
        valuables: false,
        documentation: false,
        allow: false,
        remains: false,
    });
    const [nextSign, setNextSign] = useState(null);

    const handleDate = (e) => {
        setDate(e.target.value);
    };

    const handleLocation = (e) => {
        setLocation(e.target.value);
    };

    const [user, setUser] = useState(null);

    const funeral_id = useParams();
    const user_local = JSON.parse(localStorage.getItem("user")).id;

    const handleCheckBoxes = (e) => {
        const { name, value } = e.target;
        if (value) {
            setNextSign(name);
            setShow(true);
        }
        setCheckBoxValues({ ...checkBoxValues, [name]: value });
    };

    useEffect(() => {
        UserService.getUser(user_local).then(
            (response) => {
                setUser(response.data);
                if (response.data.defined_texts) {
                    setLocation(
                        response.data.defined_texts.default_signature_place
                    );
                }
            },
            () => {}
        );
        UserService.getFuneral(funeral_id.funeral_id).then(
            (response) => {
                let data = response.data;
                if (!data.evidence) {
                    data.evidence = {};
                }
                if (!data.place) {
                    data.place = {};
                }
                setFuneral(data);
                data.other_party_person &&
                    setOtherPartyPerson(data.other_party_person);
                data.other_party_comp &&
                    setOtherPartyComp(data.other_party_comp);
                setCheckBoxValues({
                    items: !!data.signatures.items_signature,
                    urn: !!data.signatures.urn_signature,
                    valuables: !!data.signatures.valuables_signature,
                    documentation: !!data.signatures.documentation_signature,
                    allow: !!data.signatures.allow_signature,
                    remains: !!data.signatures.remains_signature,
                });
            },
            (e) => {
                if (e.response.status === 401) {
                    logOut(props);
                    NotificationManager.warning(
                        "Potrebné sa znova prihlásiť!",
                        "Upozornenie",
                        5000
                    );
                }
            }
        );
    }, [funeral_id.funeral_id, user_local, props]);

    const singleFileUploadHandler = (file, tag) => {
        const data = new FormData();
        data.append("someimage", file, `${tag}.png`);
        data.append("signatures_id", funeral.signatures._id);
        data.append("tag", tag);
        data.append(`date_of_${tag}`, new Date(date).toISOString());
        data.append(`location_of_${tag}`, location);
        UserService.signDocument(data)
            .then((response) => {
                if (200 === response.status) {
                    // If file size is larger than expected.
                    if (response.data.error) {
                    } else {
                        let fileName = response.data;
                        setShow(false);
                        if (tag === "remains") {
                            UserService.updateFuneral({
                                ...funeral,
                                other_party_person: otherPartyPerson,
                                other_party_comp: otherPartyComp,
                            });
                            setFuneral({
                                ...funeral,
                                other_party_person: otherPartyPerson,
                                other_party_comp: otherPartyComp,
                                signatures: {
                                    ...funeral.signatures,
                                    [`${tag}_signature`]: fileName.location,
                                    [`location_of_${tag}`]: location,
                                },
                            });
                        } else
                            setFuneral({
                                ...funeral,
                                signatures: {
                                    ...funeral.signatures,
                                    [`${tag}_signature`]: fileName.location,
                                    [`location_of_${tag}`]: location,
                                },
                            });
                    }
                }
            })
            .catch((e) => {
                if (e.response.status === 401) {
                    logOut(props);
                    NotificationManager.warning(
                        "Potrebné sa znova prihlásiť!",
                        "Upozornenie",
                        5000
                    );
                }
                NotificationManager.error(
                    "Nepodarilo sa podpísať dokument!",
                    "Chyba",
                    5000
                );
                setShow(false);
            });
    };

    const handleSignature = async (tag) => {
        const exportImage = canvas.current?.exportImage;

        if (exportImage) {
            const exportedDataURI = await exportImage("png");
            fetch(exportedDataURI)
                .then((response) => {
                    return response.blob();
                })
                .then((blob) => {
                    singleFileUploadHandler(blob, tag);
                })
                .catch(() => {
                    NotificationManager.error(
                        "Nepodarilo sa podpísať dokument!",
                        "Chyba",
                        5000
                    );
                    setShow(false);
                });
        }
    };

    const handleExport = () => {
        pdfRef.current.render();
        pdfRef.current.save();
    };

    const handleClear = () => {
        const resetCanvas = canvas.current?.resetCanvas;

        if (resetCanvas) {
            resetCanvas();
        }
    };

    const showSignDocs = () => {
        if (user) {
            return (
                <div style={{ border: "1px solid" }}>
                    <PDFExport
                        ref={pdfRef}
                        paperSize="A4"
                        scale={0.75}
                        fileName={`dokumenty-${funeral.deceased.replace(
                            " ",
                            ""
                        )}`}
                    >
                        <div className="a4-paper">
                            <div className="wrapper">
                                <div className="column-3 center">
                                    <img
                                        width={150}
                                        src={user.logo}
                                        alt={altPhoto}
                                    />
                                </div>
                                <div className="column-3 center tables">
                                    <div>
                                        <h1>Dokumenty na podpis</h1>
                                    </div>
                                </div>
                                <div className="column-3 center">
                                    <div className="stamp">
                                        <div>{user.name}</div>
                                        <div>{user.address}</div>
                                        <div>IČO: {user.ico}</div>
                                        <div>IČ_DPH: {user.ic_dph}</div>
                                        <div>KONTAKT: {user.phone}</div>
                                    </div>
                                </div>
                            </div>

                            <div className="wrapper-content-no-top">
                                <div>
                                    <CheckBoxInput
                                        name={"items"}
                                        label={"Prevzaté oblečenie a doklady "}
                                        value={checkBoxValues.items}
                                        onChange={handleCheckBoxes}
                                    />
                                    <p>
                                        Obstarávateľ pohrebu{" "}
                                        <span style={{ fontWeight: "bold" }}>
                                            {funeral.contracting_authority}
                                        </span>{" "}
                                        potvrdzuje svojím podpisom, že
                                        odovzdal/a oblečenie, doplnky a doklady
                                        a to:{" "}
                                        <span style={{ fontWeight: "bold" }}>
                                            {funeral.picked_items}
                                        </span>
                                    </p>
                                    <p>
                                        Všetko odovzdané oblečenie a doplnky sa
                                        budú nachádzať na zosnulom alebo v rakve
                                        ak nebolo dohodnuté inak.
                                    </p>
                                    <div className="column-3-s">
                                        V{" "}
                                        {funeral.signatures.location_of_items
                                            ? funeral.signatures
                                                  .location_of_items
                                            : location}{" "}
                                        dňa{" "}
                                        {funeral.signatures.date_of_items
                                            ? new Date(
                                                  funeral.signatures.date_of_items
                                              ).toShortFormat()
                                            : date.toShortFormat()}
                                        .
                                    </div>
                                    <div className="column-3-s-c">Podpis:</div>
                                    <div className="column-3-s-r">
                                        {funeral.signatures.items_signature ? (
                                            <img
                                                src={
                                                    funeral.signatures
                                                        .items_signature
                                                }
                                                crossOrigin="anonymous"
                                                alt={"logo"}
                                                height="50 px"
                                            />
                                        ) : (
                                            "..........................."
                                        )}
                                    </div>
                                </div>
                                <div>
                                    <CheckBoxInput
                                        name={"valuables"}
                                        label={"Prevzatie cenností"}
                                        value={checkBoxValues.valuables}
                                        onChange={handleCheckBoxes}
                                    />
                                    <p>
                                        Obstarávateľ pohrebu{" "}
                                        <span style={{ fontWeight: "bold" }}>
                                            {funeral.contracting_authority}
                                        </span>{" "}
                                        potvrdzuje svojím podpisom prevzatie
                                        cenných predmetov a to:{" "}
                                        <span style={{ fontWeight: "bold" }}>
                                            {funeral.evidence.items}
                                        </span>
                                    </p>

                                    <div className="column-3-s">
                                        V{" "}
                                        {funeral.signatures
                                            .location_of_valuables
                                            ? funeral.signatures
                                                  .location_of_valuables
                                            : location}{" "}
                                        dňa{" "}
                                        {funeral.signatures.date_of_valuables
                                            ? new Date(
                                                  funeral.signatures.date_of_valuables
                                              ).toShortFormat()
                                            : date.toShortFormat()}
                                        .
                                    </div>
                                    <div className="column-3-s-c">Podpis:</div>
                                    <div className="column-3-s-r">
                                        {funeral.signatures
                                            .valuables_signature ? (
                                            <img
                                                src={
                                                    funeral.signatures
                                                        .valuables_signature
                                                }
                                                crossOrigin="anonymous"
                                                alt={"logo"}
                                                height="50 px"
                                            />
                                        ) : (
                                            "..........................."
                                        )}
                                    </div>
                                </div>
                                <div>
                                    <CheckBoxInput
                                        name={"urn"}
                                        label={"Prevzatie urny"}
                                        value={checkBoxValues.urn}
                                        onChange={handleCheckBoxes}
                                    />
                                    <p>
                                        Obstarávateľ pohrebu{" "}
                                        <span style={{ fontWeight: "bold" }}>
                                            {funeral.contracting_authority}
                                        </span>{" "}
                                        potvrdzuje svojím podpisom prevzatie
                                        urny zosnulej osoby:{" "}
                                        <span style={{ fontWeight: "bold" }}>
                                            {funeral.deceased}, r.č -{" "}
                                            {funeral.d_id}
                                        </span>
                                    </p>
                                    <div className="column-3-s">
                                        V{" "}
                                        {funeral.signatures.location_of_urn
                                            ? funeral.signatures.location_of_urn
                                            : location}{" "}
                                        dňa{" "}
                                        {funeral.signatures.date_of_urn
                                            ? new Date(
                                                  funeral.signatures.date_of_urn
                                              ).toShortFormat()
                                            : date.toShortFormat()}
                                        .
                                    </div>
                                    <div className="column-3-s-c">Podpis:</div>
                                    <div className="column-3-s-r">
                                        {funeral.signatures.urn_signature ? (
                                            <img
                                                src={
                                                    funeral.signatures
                                                        .urn_signature
                                                }
                                                crossOrigin="anonymous"
                                                alt={"logo"}
                                                height="50 px"
                                            />
                                        ) : (
                                            "..........................."
                                        )}
                                    </div>
                                </div>
                                <div>
                                    <CheckBoxInput
                                        name={"documentation"}
                                        label={
                                            "Prevzatie kompletnej dokumentácie"
                                        }
                                        value={checkBoxValues.documentation}
                                        onChange={handleCheckBoxes}
                                    />
                                    <p>
                                        Obstarávateľ pohrebu{" "}
                                        <span style={{ fontWeight: "bold" }}>
                                            {funeral.contracting_authority}
                                        </span>{" "}
                                        potvrdzuje svojím podpisom prevzatie
                                        kompletnej dokumentácie a to Úmrtný
                                        list, List o prehliadke mŕtveho,
                                        Faktúru, Bloček, Žiadosť o príspevok na
                                        pohreb.
                                    </p>
                                    <div className="column-3-s">
                                        V{" "}
                                        {funeral.signatures
                                            .location_of_documentation
                                            ? funeral.signatures
                                                  .location_of_documentation
                                            : location}{" "}
                                        dňa{" "}
                                        {funeral.signatures
                                            .date_of_documentation
                                            ? new Date(
                                                  funeral.signatures.date_of_documentation
                                              ).toShortFormat()
                                            : date.toShortFormat()}
                                        .
                                    </div>
                                    <div className="column-3-s-c">Podpis:</div>
                                    <div className="column-3-s-r">
                                        {funeral.signatures
                                            .documentation_signature ? (
                                            <img
                                                src={
                                                    funeral.signatures
                                                        .documentation_signature
                                                }
                                                crossOrigin="anonymous"
                                                alt={"logo"}
                                                height="50 px"
                                            />
                                        ) : (
                                            "..........................."
                                        )}
                                    </div>
                                </div>
                                <div>
                                    <CheckBoxInput
                                        name={"allow"}
                                        label={"Povolenie na pochovanie"}
                                        value={checkBoxValues.allow}
                                        onChange={handleCheckBoxes}
                                    />
                                    <p>
                                        Podpísaný/á{" "}
                                        <span style={{ fontWeight: "bold" }}>
                                            {funeral.place.owner}
                                        </span>
                                        , ktorý má v nájme hrobové miesto číslo{" "}
                                        <span style={{ fontWeight: "bold" }}>
                                            {funeral.place.place_number} v{" "}
                                            {funeral.town_funeral}
                                        </span>{" "}
                                        povoľuje jeho otvorenie/výkop, za účelom
                                        pochovania zosnulého{" "}
                                        <span style={{ fontWeight: "bold" }}>
                                            {funeral.deceased}, r.č -{" "}
                                            {funeral.d_id}
                                        </span>
                                        .
                                    </p>
                                    <div className="column-3-s">
                                        V{" "}
                                        {funeral.signatures.location_of_allow
                                            ? funeral.signatures
                                                  .location_of_allow
                                            : location}{" "}
                                        dňa{" "}
                                        {funeral.signatures.date_of_allow
                                            ? new Date(
                                                  funeral.signatures.date_of_allow
                                              ).toShortFormat()
                                            : date.toShortFormat()}
                                        .
                                    </div>
                                    <div className="column-3-s-c">Podpis:</div>
                                    <div className="column-3-s-r">
                                        {funeral.signatures.allow_signature ? (
                                            <img
                                                src={
                                                    funeral.signatures
                                                        .allow_signature
                                                }
                                                crossOrigin="anonymous"
                                                alt={"logo"}
                                                height="50 px"
                                            />
                                        ) : (
                                            "..........................."
                                        )}
                                    </div>
                                </div>
                                <div>
                                    <CheckBoxInput
                                        name={"remains"}
                                        label={
                                            "Odovzdanie ľudských pozostatkov"
                                        }
                                        value={checkBoxValues.remains}
                                        onChange={handleCheckBoxes}
                                    />
                                    <p>
                                        Dole podpísaný{" "}
                                        <span style={{ fontWeight: "bold" }}>
                                            {funeral.other_party_person
                                                ? funeral.other_party_person
                                                : otherPartyPerson}
                                        </span>{" "}
                                        za pohrebnú službu{" "}
                                        <span style={{ fontWeight: "bold" }}>
                                            {funeral.other_party_comp
                                                ? funeral.other_party_comp
                                                : otherPartyComp}
                                        </span>{" "}
                                        potvrdzuje svojim podpisom, že PREVZAL
                                        dokumentáciu (List o prehliadke mŕtveho
                                        a štatistické hlásenie o úmrtí, Úmrtný
                                        list alebo OP…) a ľudské pozostatky
                                        zosnulého{" "}
                                        <span style={{ fontWeight: "bold" }}>
                                            {funeral.deceased}
                                        </span>
                                        , s rodným číslom{" "}
                                        <span style={{ fontWeight: "bold" }}>
                                            {funeral.d_id}
                                        </span>
                                        , ktorý zomrel dňa{" "}
                                        <span style={{ fontWeight: "bold" }}>
                                            {new Date(
                                                funeral.d_death
                                            ).toShortFormat()}
                                        </span>
                                        .
                                    </p>
                                    <div className="column-3-s">
                                        V{" "}
                                        {funeral.signatures.location_of_remains
                                            ? funeral.signatures
                                                  .location_of_remains
                                            : location}{" "}
                                        dňa{" "}
                                        {funeral.signatures.date_of_remains
                                            ? new Date(
                                                  funeral.signatures.date_of_remains
                                              ).toShortFormat()
                                            : date.toShortFormat()}
                                        .
                                    </div>
                                    <div className="column-3-s-c">Podpis:</div>
                                    <div className="column-3-s-r">
                                        {funeral.signatures
                                            .remains_signature ? (
                                            <img
                                                src={
                                                    funeral.signatures
                                                        .remains_signature
                                                }
                                                crossOrigin="anonymous"
                                                alt={"logo"}
                                                height="50 px"
                                            />
                                        ) : (
                                            "..........................."
                                        )}
                                    </div>
                                </div>
                            </div>
                            <h4 className="mt-top-more center-text">
                                Tento dokument má nadväznosť na splnomocnenie.
                            </h4>
                        </div>
                    </PDFExport>
                </div>
            );
        }
        return <></>;
    };

    return (
        <Container maxWidth="lg" className={classes.seeMore}>
            <div className={classes.root}>
                <Paper className={fixedHeightPaper}>
                    <Grid container>
                        <Grid xs={12} sm={4} md={3} lg={3} item container>
                            <Toolbar>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    hidden={true}
                                    className={classes.button}
                                    onClick={() => {
                                        setSuccess(true);
                                    }}
                                >
                                    Späť na detaily
                                </Button>
                            </Toolbar>
                        </Grid>
                        <Grid
                            xs={12}
                            sm={8}
                            md={9}
                            lg={9}
                            item
                            container
                            justifyContent="flex-end"
                        >
                            <Toolbar className={classes.toolbarButtons}>
                                <TextInput
                                    label="Osoba tretej strany"
                                    name={"otherPartyPerson"}
                                    value={otherPartyPerson}
                                    onChange={(e) => {
                                        setOtherPartyPerson(e.target.value);
                                    }}
                                />
                                <TextInput
                                    label="Služba tretej strany"
                                    name={"otherPartyComp"}
                                    value={otherPartyComp}
                                    onChange={(e) => {
                                        setOtherPartyComp(e.target.value);
                                    }}
                                />
                                <TextInput
                                    label="Miesto podpisu"
                                    name={"location"}
                                    value={location}
                                    onChange={handleLocation}
                                />
                                <DateInput
                                    label="Dátum podpisu"
                                    name={"date"}
                                    value={date}
                                    onChange={handleDate}
                                />
                                <ActionButton color="primary">
                                    <Tooltip title="Pdf export">
                                        <PictureAsPdfIcon
                                            fontSize="small"
                                            onClick={handleExport}
                                        />
                                    </Tooltip>
                                </ActionButton>
                            </Toolbar>
                        </Grid>
                    </Grid>

                    <Divider />
                    <Grid
                        className={classes.marginTop}
                        container
                        spacing={0}
                        direction="column"
                        alignItems="center"
                        justifyContent="center"
                    >
                        {showSignDocs()}
                    </Grid>
                </Paper>
            </div>
            <Popup
                title={"Podpis"}
                openPopup={show}
                setOpenPopup={setShow}
                maxW="xl"
                actions={
                    <>
                        <Button
                            color="primary"
                            variant="outlined"
                            onClick={() => {
                                setFullScreen(!fullScreen);
                            }}
                        >
                            {fullScreen ? "Zmenšiť" : "Zväčšiť"}
                        </Button>
                        <Button
                            color="primary"
                            variant="outlined"
                            onClick={handleClear}
                        >
                            Vyčistiť
                        </Button>
                        <Button
                            color="primary"
                            variant="outlined"
                            onClick={() => {
                                handleSignature(nextSign);
                            }}
                        >
                            Uložiť
                        </Button>
                    </>
                }
            >
                <ReactSketchCanvas
                    ref={canvas}
                    strokeWidth={3}
                    strokeColor="blue"
                    style={{
                        border: "0.0625rem solid #9c9c9c",
                        borderRadius: "0.25rem",
                        width: fullScreen
                            ? convertSize(props.width)
                            : props.width === "xs"
                            ? "300px"
                            : "500px",
                        height: fullScreen
                            ? "600px"
                            : props.width === "xs"
                            ? "125px"
                            : "300px",
                    }}
                />
            </Popup>
            {success ? (
                <Redirect
                    to={{
                        pathname: `/pohreb/${funeral_id.funeral_id}/info`,
                        state: { from: props.location, number: 5 },
                    }}
                />
            ) : (
                <></>
            )}
        </Container>
    );
};
export default withWidth()(DocumentsSigningGenerator);
