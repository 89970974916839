import React, {useState, useEffect} from "react";
import {makeStyles} from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import clsx from "clsx";
import Title from "../dashboard/Title";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import UserService from "../../services/user.service";
import FlowiiService from "../../services/flowii.service";
import { logOut} from "../../helpers/logout";
import {NotificationManager} from 'react-notifications';
import Paper from "@material-ui/core/Paper";
import TextInput from "../form/input/TextInput";
import AuthService from "../../services/auth.service";
import {Divider, IconButton, TextField, Toolbar} from "@material-ui/core";
import {PhotoCamera, VpnKey, Sync, Assignment } from "@material-ui/icons";
import Popup from "../presentation/Popup";
import ActionButton from "../form/input/ActionButton";
import Tooltip from "@material-ui/core/Tooltip";
import EditIcon from "@material-ui/icons/Edit";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        '& .MuiFormControl-root': {
            margin: theme.spacing(1),
            width: '100%'
        }
    },
    appBarSpacer: theme.mixins.toolbar,
    seeMore: {
        marginTop: theme.spacing(8),
    },
    marginTop: {
        marginTop: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    button: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    toolbarButtons: {
        marginLeft: 'auto',
    },
    connected: {
        backgroundColor: 'green',
    },
}));

export default function ProfileDashboard(props) {
    const classes = useStyles();
    const fixedHeightPaper = clsx(classes.paper, classes.root);
    const seeMoreContainer = clsx(classes.seeMore, classes.root);

    const [values, setValues] = useState({
        email: '',
        name: '',
        address: '',
        ico: '',
        ic_dph: '',
        phone: '',
        logo: '',
        replaceLogo: null,
        email_official: '',
        web: '',
        stamp: ''
    });

    const [editValues, setEditValues] = useState({
        email: '',
        name: '',
        address: '',
        ico: '',
        ic_dph: '',
        phone: '',
        logo: '',
        replaceLogo: null,
        email_official: '',
        web: ''
    });

    const [password, setPassword] = useState({
        oldPassword: '',
        password: '',
        repeat: '',
    });

    const [flowii, setFlowii] = useState({
        flowii_username: '',
        flowii_password: '',
        flowii_api_key: '',
        flowii_company_id: ''
    });

    const [stamp, setStamp] = useState({
        stamp: '',
        replaceStamp: null,
    });

    const currentUser = AuthService.getCurrentUser();

    const [show, setShow] = useState(false);
    const [passShow, setPassShow] = useState(false);
    const [flowiiShow, setFlowiiShow] = useState(false);
    const [stampShow, setStampShow] = useState(false);

    const handleChange = (event) => {
        const {name, value} = event.target;
        setEditValues({...editValues, [name]: value});
    }

    const handlePasswordChange = (event) => {
        const {name, value} = event.target;
        setPassword({...password, [name]: value});
    }

    const handleFlowiiChange = (event) => {
        const {name, value} = event.target;
        setFlowii({...flowii, [name]: value});
    }

    useEffect(() => {
        UserService.getUser(currentUser.id).then(
            (response) => {
                setValues(response.data);
                setFlowii(response.data);
            },
            (e) => {
                if (e.response.status === 401) {
                    logOut(props);
                }
            }
        );
    }, [currentUser.id, props]);

    const handleLogo = (e) => {
        setEditValues({...editValues, 'replaceLogo': e.target.files[0]})
    }

    const handleStamp = (e) => {
        setStamp({...stamp, 'replaceStamp': e.target.files[0]})
    }

    const handleCreate = (e) => {
        e.preventDefault();

        if (validate()) {
            const data = new FormData();
            data.append('email', editValues.email);
            data.append('name', editValues.name);
            data.append('address', editValues.address);
            data.append('ico', editValues.ico);
            data.append('ic_dph', editValues.ic_dph);
            data.append('phone', editValues.phone);
            data.append('email_official', editValues.email_official);
            data.append('web', editValues.web);
            if (editValues.replaceLogo) {
                data.append('someimage', editValues.replaceLogo, editValues.replaceLogo.name);
            }

            UserService.updateUser(data).then(
                (user) => {
                    setValues(user.data);
                    setShow(false);
                    NotificationManager.success('Dáta o používateľovi upravené.', 'Upravené', 5000);
                },
                (e) => {
                    if (e.response.status === 401) {
                        logOut(props);
                        NotificationManager.warning('Potrebné sa znova prihlásiť!', 'Upozornenie', 5000);
                    } else {
                        NotificationManager.error('Došlo k neočakávanej chybe. Skúste neskôr alebo kontaktujte podporu.', 'Chyba', 5000);
                    }
                }
            );
        } else {
            NotificationManager.error('Skontrolujte vyplnený formulár!', 'Chyba', 5000);
        }
    };

    const checkOldPass = (code) => {
        return code && code.toString().includes('403');
    }

    const handleChangePassword = (e) => {
        e.preventDefault();

        if (validatePass()) {
            const data = {
                oldPassword: password.oldPassword,
                password: password.password
            }
            UserService.updatePassword(data).then(
                (user) => {
                    setPassword({
                        oldPassword: '',
                        password: '',
                        repeat: '',
                    });
                    setPassShow(false);
                    NotificationManager.success('Heslo bolo zmenené.', 'Upravené', 5000);
                },
                (e) => {
                    if (checkOldPass(e)) {
                        NotificationManager.error('Zadali ste zlé pôvodné heslo!', 'Chyba', 5000);
                    } else {
                        if(e.response.status === 401) {
                            logOut(props);
                            NotificationManager.error('Musíte sa nanovo prihlásiť.', 'Chyba', 5000);
                        } else {
                            NotificationManager.error('Došlo k neočakávanej chybe. Skúste neskôr alebo kontaktujte podporu.', 'Chyba', 5000);
                        }
                    }
                }
            );
        } else {
            NotificationManager.error('Skontrolujte vyplnený formulár!', 'Chyba', 5000);
        }
    };

    const handleChangeStamp= (e) => {
        e.preventDefault();

        const data = new FormData();
        if (stamp.replaceStamp) {
            data.append('someimage', stamp.replaceStamp, stamp.replaceStamp.name);
        }

        UserService.updateStamp(data).then(
            (user) => {
                setValues(user.data);
                setStampShow(false);
                NotificationManager.success('Pečiatka bola zmenená.', 'Upravené', 5000);
            },
            (e) => {
                if(e.response.status === 401) {
                    logOut(props);
                    NotificationManager.error('Musíte sa nanovo prihlásiť.', 'Chyba', 5000);
                } else {
                    NotificationManager.error('Došlo k neočakávanej chybe. Skúste neskôr alebo kontaktujte podporu.', 'Chyba', 5000);
                }
            }
        );

    };

    const handleChangeFlowii = (e) => {
        e.preventDefault();

        const data = {
            flowii_username: flowii.flowii_username,
            flowii_password: flowii.flowii_password,
            flowii_api_key: flowii.flowii_api_key,
        }
        
        UserService.updateFlowii(data).then(
            (response) => {
                setFlowii({
                    flowii_username: '',
                    flowii_password: '',
                    flowii_api_key: '',
                    flowii_company_id: response.data.flowii_company_id,
                });
                setFlowiiShow(false);
                NotificationManager.success('Flowii bolo zmenené.', 'Upravené', 5000);
            },
            (e) => {
                if(e.response.status === 401) {
                    logOut(props);
                    NotificationManager.error('Musíte sa nanovo prihlásiť.', 'Chyba', 5000);
                } else {
                    NotificationManager.error('Došlo k neočakávanej chybe. Skúste neskôr alebo kontaktujte podporu.', 'Chyba', 5000);
                }
            }
        );
    };

    const [errors, setErrors] = useState({});

    const validate = () => {
        let temp = {};
        let ca_email = [];
        let result = [];
        ca_email = values.email.split(',');
        result = ca_email.filter(item => !(/$^|.+@.+..+/).test(item))
        temp.email = result.length === 0 ? "" : "Email ma zlý formát!"
        setErrors({...temp})

        // eslint-disable-next-line eqeqeq
        return Object.values(temp).every(x => x == "")
    }

    const validatePass = () => {
        let temp = {};
        temp.password = (/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]{8,}$/).test(password.password) ? "" : "minimálne 8 znakov, aspoň 1 veľké písmeno, aspoň 1 malé písmeno, aspoň 1 číslo";
        temp.repeat = password.password === password.repeat ? "" : "Heslá sa nezhodujú!"
        setErrors({...temp})

        // eslint-disable-next-line eqeqeq
        return Object.values(temp).every(x => x == "")
    }


    return (

        <Container maxWidth="lg">
            <div className={seeMoreContainer}>
                <div>
                    <div>
                        <Typography className={classes.instructions} component={'span'}>
                            <Paper className={fixedHeightPaper}>
                                <Title>Profil pohrebnej služby</Title>
                                <Toolbar className={classes.toolbarButtons}>
                                    <ActionButton
                                        color={'primary'}>
                                        <Tooltip title="Pečiatka">
                                            <Assignment 
                                                fontSize="small"
                                                onClick={() => {
                                                    setStampShow(true);
                                                }}
                                            />
                                        </Tooltip>
                                    </ActionButton>
                                    <ActionButton
                                        color={flowii.flowii_company_id ? 'primary' : 'secondary'}>
                                        <Tooltip title="Flowii">
                                            <Sync 
                                                fontSize="small"
                                                onClick={() => {
                                                    setFlowiiShow(true);
                                                }}
                                            />
                                        </Tooltip>
                                    </ActionButton>
                                    <ActionButton
                                        color="primary">
                                        <Tooltip title="Zmena hesla">
                                            <VpnKey
                                                fontSize="small"
                                                onClick={() => {
                                                    setPassShow(true);
                                                }}
                                            />
                                        </Tooltip>
                                    </ActionButton>
                                    <ActionButton
                                        color="primary">
                                        <Tooltip title="Upraviť profil">
                                            <EditIcon
                                                fontSize="small"
                                                onClick={() => {
                                                    setShow(true);
                                                    setEditValues(values);
                                                }}
                                            />
                                        </Tooltip>
                                    </ActionButton>
                                </Toolbar>
                                <Divider/>
                                <Box className={classes.marginTop}>
                                    <Grid container className={classes.root} spacing={2}>
                                        <Grid item xs={12} md={6} lg={6}>
                                            <img src={values.logo} alt={'logo'} height='122 px'/>
                                            <TextField
                                                className={classes.marginElement}
                                                id="name"
                                                label="Názov pohrebnej služby"
                                                multiline
                                                value={values.name}
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                fullWidth={true}
                                            />
                                            <TextField
                                                className={classes.marginElement}
                                                id="email_official"
                                                label="Email pre komunikáciu"
                                                multiline
                                                value={values.email_official}
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                fullWidth={true}
                                            />
                                            <TextField
                                                className={classes.marginElement}
                                                id="email"
                                                label="Emaily pre zasielanie notifikácií"
                                                multiline
                                                value={values.email}
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                fullWidth={true}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6} lg={6}>
                                            <TextField
                                                className={classes.marginElement}
                                                id="web"
                                                label="Web stránka pohrebnej služby"
                                                multiline
                                                value={values.web}
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                fullWidth={true}
                                            />
                                            <TextField
                                                className={classes.marginElement}
                                                id="address"
                                                label="Adresa pohrebnej služby"
                                                multiline
                                                value={values.address}
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                fullWidth={true}
                                            />
                                            <TextField
                                                className={classes.marginElement}
                                                id="ico"
                                                label="IČO"
                                                multiline
                                                value={values.ico}
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                fullWidth={true}
                                            />
                                            <TextField
                                                className={classes.marginElement}
                                                id="ic_dph"
                                                label="IČ_DPH"
                                                multiline
                                                value={values.ic_dph}
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                fullWidth={true}
                                            />
                                            <TextField
                                                className={classes.marginElement}
                                                id="phone"
                                                label="Telefón"
                                                multiline
                                                value={values.phone}
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                fullWidth={true}
                                            />
                                        </Grid>
                                    </Grid>
                                </Box>
                                <Popup
                                    title={'Úprava profilu'}
                                    openPopup={show}
                                    setOpenPopup={setShow}
                                >
                                    <div className={classes.root}>
                                        <form autoComplete='off' onSubmit={handleCreate}>
                                            <TextInput
                                                label="Názov pohrebnej služby"
                                                name={'name'}
                                                value={editValues.name}
                                                onChange={handleChange}
                                            />
                                            <TextInput
                                                label="Emailové adresy (oddeliť čiarkou)"
                                                name={'email'}
                                                placeholder={'abc@gmail.com,def@gmail.com,gh...'}
                                                value={editValues.email}
                                                error={errors.email}
                                                onChange={handleChange}
                                            />
                                            <TextInput
                                                label="Email na komunikáciu"
                                                name={'email_official'}
                                                value={editValues.email_official}
                                                onChange={handleChange}
                                            />
                                            <TextInput
                                                label="Webstránka"
                                                name={'web'}
                                                value={editValues.web}
                                                onChange={handleChange}
                                            />
                                            <TextInput
                                                label="Adresa pohrebnej služby"
                                                name={'address'}
                                                value={editValues.address}
                                                onChange={handleChange}
                                            />
                                            <TextInput
                                                label="IČO"
                                                name={'ico'}
                                                value={editValues.ico}
                                                onChange={handleChange}
                                            />
                                            <TextInput
                                                label="IČ_DPH"
                                                name={'ic_dph'}
                                                value={editValues.ic_dph}
                                                onChange={handleChange}
                                            />
                                            <TextInput
                                                label="Telefónne čislo"
                                                name={'phone'}
                                                value={editValues.phone}
                                                onChange={handleChange}
                                            />
                                            <input
                                                accept="image/*"
                                                style={{display: 'none'}}
                                                id="contained-button-logo"
                                                multiple
                                                type="file"
                                                onChange={handleLogo}
                                            />
                                            <label htmlFor="contained-button-logo">
                                                <IconButton color="primary" aria-label="upload picture"
                                                            component="span">
                                                    <PhotoCamera/> Logo
                                                </IconButton>
                                            </label>
                                            <div className="file-name">
                                                {
                                                    (editValues.replaceLogo && editValues.replaceLogo.name)
                                                    && editValues.replaceLogo.name
                                                }
                                            </div>
                                            <div className={classes.marginTop}>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    type='submit'
                                                    hidden={true}
                                                    className={classes.button}
                                                >
                                                    Upraviť
                                                </Button>
                                            </div>
                                        </form>
                                    </div>
                                </Popup>

                                <Popup
                                    title={'Zmena hesla'}
                                    openPopup={passShow}
                                    setOpenPopup={setPassShow}
                                    additionalWork={()=>{
                                        setPassword({
                                            oldPassword: '',
                                            password: '',
                                            repeat: '',
                                        });
                                        setErrors({})
                                    }}
                                >
                                    <Typography variant="body2" component="div" style={{flexGrow: 1}}>
                                        Po zabudnutí hesla je ho možné resetovať len za pomoci administrátora! Dôkladne
                                        si
                                        preto heslo uchovajte.
                                    </Typography>
                                    <div className={classes.root}>
                                        <form autoComplete='off' onSubmit={handleChangePassword}>
                                            <TextInput
                                                label="Pôvodné heslo"
                                                name={'oldPassword'}
                                                type={'password'}
                                                value={password.oldPassword}
                                                onChange={handlePasswordChange}
                                            />
                                            <TextInput
                                                label="Heslo"
                                                name={'password'}
                                                placeholder={'minimálne 8 znakov, aspoň 1 veľké písmeno, aspoň 1 malé písmeno,aspoň 1 číslo'}
                                                type={'password'}
                                                value={password.password}
                                                error={errors.password}
                                                onChange={handlePasswordChange}
                                            />
                                            <TextInput
                                                label="Zopakovať heslo"
                                                name={'repeat'}
                                                type={'password'}
                                                value={password.repeat}
                                                error={errors.repeat}
                                                onChange={handlePasswordChange}
                                            />
                                            <div className={classes.marginTop}>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    type='submit'
                                                    hidden={true}
                                                    className={classes.button}
                                                >
                                                    Zmeniť heslo
                                                </Button>
                                            </div>
                                        </form>
                                    </div>
                                </Popup>

                                <Popup
                                    title={'Zmena pečiatky'}
                                    openPopup={stampShow}
                                    setOpenPopup={setStampShow}
                                    additionalWork={()=>{
                                        setErrors({})
                                    }}
                                >
                                    <div className={classes.root}>
                                        
                                        {
                                                    (values.stamp)
                                                    && <img src={values.stamp} alt={'logo'} height='122 px'/>
                                                }
                                        <form autoComplete='off' onSubmit={handleChangeStamp}>
                                            <input
                                                accept="image/*"
                                                style={{display: 'none'}}
                                                id="contained-button-logo"
                                                multiple
                                                type="file"
                                                onChange={handleStamp}
                                            />
                                            <label htmlFor="contained-button-logo">
                                                <IconButton color="primary" aria-label="upload picture"
                                                            component="span">
                                                    <PhotoCamera/> Nahrať pečiatku
                                                </IconButton>
                                            </label>
                                            <div className="file-name">
                                                {
                                                    (stamp.replaceStamp && stamp.replaceStamp.name)
                                                    && stamp.replaceStamp.name
                                                }
                                            </div>
                                            <div className={classes.marginTop}>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    type='submit'
                                                    hidden={true}
                                                    className={classes.button}
                                                >
                                                    Zmeniť pečiatku
                                                </Button>
                                            </div>
                                        </form>
                                    </div>
                                </Popup>

                                <Popup
                                    title={'Flowii'}
                                    openPopup={flowiiShow}
                                    setOpenPopup={setFlowiiShow}
                                    additionalWork={()=>{
                                       
                                        setErrors({})
                                    }}
                                >
                                    <div className={classes.root}>
                                        <form autoComplete='off' onSubmit={handleChangeFlowii}>
                                            <TextInput
                                                label="Prihlasovacie meno"
                                                name={'flowii_username'}
                                                type={'string'}
                                                value={flowii.flowii_username}
                                                onChange={handleFlowiiChange}
                                            />
                                            <TextInput
                                                label="Heslo"
                                                name={'flowii_password'}
                                                type={'password'}
                                                value={flowii.flowii_password}
                                                onChange={handleFlowiiChange}
                                            />
                                            <TextInput
                                                label="Api key"
                                                name={'flowii_api_key'}
                                                type={'string'}
                                                value={flowii.flowii_api_key}
                                                onChange={handleFlowiiChange}
                                            />
                                            <div className={classes.marginTop}>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    type='submit'
                                                    hidden={true}
                                                    className={classes.button}
                                                >
                                                    Uložiť
                                                </Button>
                                            </div>
                                        </form>
                                    </div>
                                </Popup>
                            </Paper>
                        </Typography>
                    </div>

                </div>
            </div>
        </Container>

    )
}