import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import UserService from "../../services/user.service";
import Container from "@material-ui/core/Container";
import {
    Box,
    Card,
    CardActions,
    CardContent,
    CardMedia,
    Divider,
    IconButton,
    LinearProgress,
    Typography,
    withStyles,
} from "@material-ui/core";
import { PhotoCamera } from "@material-ui/icons";
import Button from "@material-ui/core/Button";
import { NotificationManager } from "react-notifications";
import Grid from "@material-ui/core/Grid";
import altPhoto from "../../assets/trans.png";
import { Lightbox } from "react-modal-image";
import Title from "../dashboard/Title";
import { Redirect } from "react-router-dom";
import { ButtonGroup } from "react-bootstrap";
import { logOut } from "../../helpers/logout";
import watermark from "watermarkjs";
import wmPhoto from "../../assets/watermark.png";

const useStyles = makeStyles((theme) => ({
    appBarSpacer: theme.mixins.toolbar,
    root: {
        flexGrow: 1,
        width: "100%",
        backgroundColor: theme.palette.background.paper,
    },
    seeMore: {
        marginTop: theme.spacing(8),
    },
    paper: {
        padding: theme.spacing(2),
        display: "flex",
        overflow: "auto",
        flexDirection: "column",
    },
    fixedHeight: {
        height: 340,
    },
    buttonPosition: {
        position: "absolute",
        right: theme.spacing(3),
    },
    toolbarButtons: {
        marginLeft: "auto",
    },
    marginElement: {
        margin: theme.spacing(2),
    },
    marginTop: {
        marginTop: theme.spacing(2),
    },
    icon: {
        marginRight: theme.spacing(2),
    },
    heroContent: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(8, 0, 6),
    },
    heroButtons: {
        marginTop: theme.spacing(4),
    },
    cardGrid: {
        paddingTop: theme.spacing(8),
        paddingBottom: theme.spacing(8),
    },
    card: {
        height: "100%",
        display: "flex",
        flexDirection: "column",
    },
    cardMedia: {
        paddingTop: "56.25%", // 16:9
    },
    cardContent: {
        flexGrow: 1,
    },
    buttonsAlign: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        "& > *": {
            margin: theme.spacing(1),
        },
    },
}));

const BorderLinearProgress = withStyles(() => ({
    root: {
        height: 15,
        borderRadius: 5,
    },
    colorPrimary: {
        backgroundColor: "#EEEEEE",
    },
    bar: {
        borderRadius: 5,
        backgroundColor: "#1a90ff",
    },
}))(LinearProgress);

export default function DocumentsDetails(props) {
    const classes = useStyles();

    // const [funeral, setFuneral] = useState(props.funeral);
    const { refetch, funeral, setRefetch } = props;

    const [selectedFile, setSelectedFile] = useState({
        pass_burial: null,
        other_document_1: null,
        other_document_2: null,
        other_document_3: null,
        other_document_4: null,
        hole_photo: null,
        decease_photo: null,
        passport: null,
        examination_photo_back: null,
        death_photo: null,
        examination_photo_front: null,
        take_remains_photo: null,
    });

    const [progress, setProgress] = useState({
        pass_burial: null,
        other_document_1: null,
        other_document_2: null,
        other_document_3: null,
        other_document_4: null,
        hole_photo: null,
        decease_photo: null,
        passport: null,
        examination_photo_front: null,
        examination_photo_back: null,
        death_photo: null,
        take_remains_photo: null,
    });
    const [loaded, setLoaded] = useState({
        pass_burial: null,
        other_document_1: null,
        other_document_2: null,
        other_document_3: null,
        other_document_4: null,
        hole_photo: null,
        decease_photo: null,
        passport: null,
        examination_photo_front: null,
        examination_photo_back: null,
        death_photo: null,
        take_remains_photo: null,
    });

    const [isOpen, setIsOpen] = useState({
        pass_burial: false,
        other_document_1: null,
        other_document_2: null,
        other_document_3: null,
        other_document_4: null,
        hole_photo: false,
        decease_photo: false,
        passport: false,
        examination_photo_front: false,
        examination_photo_back: false,
        death_photo: false,
        take_remains_photo: null,
    });

    const [redirects, setRedirects] = useState({
        delegationRedirect: false,
        gdprRedirect: false,
        infoList: false,
        coffinLabel: false,
        cards: false,
        prices: false,
    });

    const [user, setUser] = useState(null);
    const user_local = JSON.parse(localStorage.getItem("user")).id;

    useEffect(() => {
        UserService.getUser(user_local).then(
            (response) => {
                setUser(response.data);
            },
            (e) => {
                if (e.response.status === 401) {
                    logOut(props);
                    NotificationManager.warning(
                        "Potrebné sa znova prihlásiť!",
                        "Upozornenie",
                        5000
                    );
                }
            }
        );
    }, [user_local, props]);

    const handleRedirects = (name) => {
        setRedirects({ ...redirects, [name]: true });
    };

    const closeLightbox = (name) => () => {
        setIsOpen({ ...isOpen, [name]: !isOpen[name] });
    };

    const singleFileChangedHandler = (name) => (event) => {
        setSelectedFile({ ...selectedFile, [name]: event.target.files[0] });
        setLoaded({ ...loaded, [name]: "custom-file-upload-loaded" });
    };

    const handleProgress = (name) => (data) => {
        setProgress({
            ...progress,
            [name]: Math.round((100 * data.loaded) / data.total),
        });
    };

    const singleFileUploadHandler = (name) => () => {
        const data = new FormData();

        if (selectedFile[name]) {
            data.append(
                "someimage",
                selectedFile[name],
                `${funeral.d_id?.split("/").join("")}_${name}.${selectedFile[
                    name
                ].name
                    .split(".")
                    .pop()}`
            );
            data.append("funeral_id", funeral._id);
            data.append("tag", name);
            UserService.addPhoto(data, handleProgress(name))
                .then((response) => {
                    if (200 === response.status) {
                        // If file size is larger than expected.
                        if (response.data.error) {
                            if (
                                "LIMIT_FILE_SIZE" === response.data.error.code
                            ) {
                                NotificationManager.error(
                                    "Priveľký súbor!",
                                    "Chyba",
                                    5000
                                );
                                setLoaded({ ...loaded, [name]: "" });
                                setSelectedFile({
                                    ...selectedFile,
                                    [name]: null,
                                });
                                setProgress({ ...progress, [name]: null });
                            } else {
                                NotificationManager.error(
                                    "Neočakavaná chyba!",
                                    "Chyba",
                                    5000
                                );
                                setLoaded({ ...loaded, [name]: "" });
                                setSelectedFile({
                                    ...selectedFile,
                                    [name]: null,
                                });
                                setProgress({ ...progress, [name]: null });
                            }
                        } else {
                            let fileName = response.data;
                            setTimeout(function () {
                                setProgress({ ...progress, [name]: null });
                            }, 1500);
                            setRefetch(!refetch);
                            setLoaded({ ...loaded, [name]: "" });
                            setSelectedFile({ ...selectedFile, [name]: null });
                            NotificationManager.success(
                                "Dokument úspešne uložený!",
                                "Uložené",
                                5000
                            );
                        }
                    }
                })
                .catch((e) => {
                    if (e.response.status === 401) {
                        logOut(props);
                        NotificationManager.warning(
                            "Potrebné sa znova prihlásiť!",
                            "Upozornenie",
                            5000
                        );
                    }
                    NotificationManager.error(
                        "Neočakavaná chyba!",
                        "Chyba",
                        5000
                    );
                    setLoaded({ ...loaded, [name]: "" });
                    setProgress({ ...progress, [name]: null });
                    setSelectedFile({ ...selectedFile, [name]: null });
                });
        } else {
            NotificationManager.error(
                "Prv zvoľte súbor a následne nahrajte.",
                "Chyba",
                5000
            );
            setLoaded({ ...loaded, [name]: "" });
            setProgress({ ...progress, [name]: null });
            setSelectedFile({ ...selectedFile, [name]: null });
        }
    };

    let getX = function (photo, logo) {
        if ((photo.width / 4) * 3 + logo.width < photo.width)
            return photo.width - logo.width;
        return (photo.width / 4) * 3;
    };

    let getY = function (photo, logo) {
        return photo.height - logo.height;
    };

    const singleFileUploadHandlerWithWaterMark = (name) => () => {
        const data = new FormData();

        if (selectedFile[name]) {
            watermark([selectedFile[name], wmPhoto])
                .blob(watermark.image.atPos(getX, getY, 0))
                .then((img) => {
                    data.append(
                        "someimage",
                        img,
                        `${funeral.d_id
                            ?.split("/")
                            .join("")}_${name}.${selectedFile[name].name
                            .split(".")
                            .pop()}`
                    );
                    data.append("funeral_id", funeral._id);
                    data.append("tag", name);
                    UserService.addPhoto(data, handleProgress(name))
                        .then((response) => {
                            if (200 === response.status) {
                                // If file size is larger than expected.
                                if (response.data.error) {
                                    if (
                                        "LIMIT_FILE_SIZE" ===
                                        response.data.error.code
                                    ) {
                                        NotificationManager.error(
                                            "Priveľký súbor!",
                                            "Chyba",
                                            5000
                                        );
                                        setLoaded({ ...loaded, [name]: "" });
                                        setSelectedFile({
                                            ...selectedFile,
                                            [name]: null,
                                        });
                                        setProgress({
                                            ...progress,
                                            [name]: null,
                                        });
                                    } else {
                                        NotificationManager.error(
                                            "Neočakavaná chyba!",
                                            "Chyba",
                                            5000
                                        );
                                        setLoaded({ ...loaded, [name]: "" });
                                        setSelectedFile({
                                            ...selectedFile,
                                            [name]: null,
                                        });
                                        setProgress({
                                            ...progress,
                                            [name]: null,
                                        });
                                    }
                                } else {
                                    let fileName = response.data;
                                    setTimeout(function () {
                                        setProgress({
                                            ...progress,
                                            [name]: null,
                                        });
                                    }, 1500);
                                    setRefetch(!refetch);
                                    setLoaded({ ...loaded, [name]: "" });
                                    setSelectedFile({
                                        ...selectedFile,
                                        [name]: null,
                                    });
                                    NotificationManager.success(
                                        "Dokument úspešne uložený!",
                                        "Uložené",
                                        5000
                                    );
                                }
                            }
                        })
                        .catch((e) => {
                            if (e.response.status === 401) {
                                logOut(props);
                                NotificationManager.warning(
                                    "Potrebné sa znova prihlásiť!",
                                    "Upozornenie",
                                    5000
                                );
                            }
                            NotificationManager.error(
                                "Neočakavaná chyba!",
                                "Chyba",
                                5000
                            );
                            setLoaded({ ...loaded, [name]: "" });
                            setProgress({ ...progress, [name]: null });
                            setSelectedFile({ ...selectedFile, [name]: null });
                        });
                });
        } else {
            NotificationManager.error(
                "Prv zvoľte súbor a následne nahrajte.",
                "Chyba",
                5000
            );
            setLoaded({ ...loaded, [name]: "" });
            setProgress({ ...progress, [name]: null });
            setSelectedFile({ ...selectedFile, [name]: null });
        }
    };

    const saveImage = (url) => () => {
        let FileSaver = require("file-saver");
        let fileName = url.substring(url.lastIndexOf("/") + 1);
        FileSaver.saveAs(url, fileName);
    };

    const substrOfName = (str) => {
        if (str.length > 12) {
            return (
                str.substr(0, 4) +
                "..." +
                str.substr(str.length - 8, str.length - 1)
            );
        }
        return str;
    };

    const documentCard = (photo, photoText, selectedFile, title, method) => {
        return (
            <Grid item xs={12} sm={12} md={6} lg={4}>
                <Card className={classes.card}>
                    <CardMedia
                        className={classes.cardMedia}
                        image={photo ? photo : altPhoto}
                        title="Klik pre otvorenie!"
                        onClick={closeLightbox(photoText)}
                    />
                    {isOpen[photoText] && photo && (
                        <Lightbox
                            medium={photo}
                            large={photo}
                            onClose={closeLightbox(photoText)}
                            showRotate={true}
                        />
                    )}
                    <CardContent className={classes.cardContent}>
                        <Typography gutterBottom variant="h5" component="h2">
                            {title}
                        </Typography>
                        <Typography>
                            Nahrajte fotku/sken dokumentu. (max 20MB)
                        </Typography>
                    </CardContent>

                    <CardActions>
                        <input
                            accept="image/*"
                            style={{ display: "none" }}
                            id={`contained-button-${photoText}`}
                            multiple
                            type="file"
                            onChange={singleFileChangedHandler(photoText)}
                        />
                        <label htmlFor={`contained-button-${photoText}`}>
                            <IconButton
                                color="primary"
                                aria-label="upload picture"
                                component="span"
                            >
                                <PhotoCamera />
                            </IconButton>
                        </label>
                        <div className="file-name">
                            {selectedFile &&
                                selectedFile.name &&
                                substrOfName(selectedFile.name)}
                        </div>
                    </CardActions>

                    {progress[photoText] && (
                        <CardActions>
                            <Box
                                className="my20"
                                display="flex"
                                alignItems="center"
                            >
                                <Box width="100%" mr={1}>
                                    <BorderLinearProgress
                                        variant="determinate"
                                        value={progress[photoText]}
                                    />
                                </Box>
                                <Box minWidth={35}>
                                    <Typography
                                        variant="body2"
                                        color="textSecondary"
                                    >{`${progress[photoText]}%`}</Typography>
                                </Box>
                            </Box>
                        </CardActions>
                    )}

                    <CardActions>
                        <Button onClick={method} size="small" color="primary">
                            {photo ? "Upraviť" : "Nahrať"}
                        </Button>
                        {photo && (
                            <Button
                                size="small"
                                color="primary"
                                onClick={saveImage(photo)}
                            >
                                Stiahnúť
                            </Button>
                        )}
                    </CardActions>
                </Card>
            </Grid>
        );
    };

    return (
        <React.Fragment>
            <Title>Dokumenty na generovanie</Title>
            <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justifyContent="center"
            >
                <Grid item xl={12}>
                    <div className={classes.buttonsAlign}>
                        <ButtonGroup color="primary">
                            <Button
                                onClick={() => {
                                    handleRedirects("applicationRedirect");
                                }}
                            >
                                Žiadosť o príspevok na pohreb
                            </Button>
                            <Button
                                onClick={() => {
                                    handleRedirects("urnApplicationRedirect");
                                }}
                            >
                                Žiadosť o vydanie povolenia na uloženie urny
                            </Button>
                            <Button
                                onClick={() => {
                                    handleRedirects("confirmationRedirect");
                                }}
                            >
                                Potvrdenie
                            </Button>
                            <Button
                                onClick={() => {
                                    handleRedirects("delegationRedirect");
                                }}
                            >
                                Splnomocnenie
                            </Button>
                            <Button
                                onClick={() => {
                                    handleRedirects("gdprRedirect");
                                }}
                            >
                                Dokumenty na podpis
                            </Button>
                            <Button
                                onClick={() => {
                                    handleRedirects("infoList");
                                }}
                            >
                                Informačný list k pohrebu
                            </Button>
                            <Button
                                onClick={() => {
                                    handleRedirects("prices");
                                }}
                            >
                                Informačné ceny
                            </Button>
                        </ButtonGroup>
                    </div>
                    <Divider variant="middle" />
                    <div className={classes.buttonsAlign}>
                        <ButtonGroup color="primary">
                            <Button
                                onClick={() => {
                                    handleRedirects("coffinLabel");
                                }}
                            >
                                Štítok na rakvu
                            </Button>
                            {user?.premium && (
                                <Button
                                    onClick={() => {
                                        handleRedirects("cards");
                                    }}
                                >
                                    Kartičky
                                </Button>
                            )}
                        </ButtonGroup>
                    </div>
                </Grid>
            </Grid>
            <Divider />
            <Title>Dokumenty o zosnulom</Title>
            <Container className={classes.cardGrid} maxWidth="md">
                {/* End hero unit */}
                <Grid container spacing={4}>
                    {documentCard(
                        funeral.examination_photo_front,
                        "examination_photo_front",
                        selectedFile.examination_photo_front,
                        "List o prehliadke mŕtveho predná strana",
                        singleFileUploadHandler("examination_photo_front")
                    )}
                    {documentCard(
                        funeral.examination_photo_back,
                        "examination_photo_back",
                        selectedFile.examination_photo_back,
                        "List o prehliadke mŕtveho zadná strana",
                        singleFileUploadHandler("examination_photo_back")
                    )}
                    {documentCard(
                        funeral.death_photo,
                        "death_photo",
                        selectedFile.death_photo,
                        "Úmrtný list",
                        singleFileUploadHandler("death_photo")
                    )}
                    {documentCard(
                        funeral.decease_photo,
                        "decease_photo",
                        selectedFile.decease_photo,
                        "Fotka zosnulého",
                        singleFileUploadHandlerWithWaterMark("decease_photo")
                    )}
                    {documentCard(
                        funeral.hole_photo,
                        "hole_photo",
                        selectedFile.hole_photo,
                        "Fotka výkopu hrobu",
                        singleFileUploadHandler("hole_photo")
                    )}
                    {documentCard(
                        funeral.passport,
                        "passport",
                        selectedFile.passport,
                        "Pas pre mŕtvolu",
                        singleFileUploadHandler("passport")
                    )}
                    {documentCard(
                        funeral.pass_burial,
                        "pass_burial",
                        selectedFile.pass_burial,
                        "Povolenie na pochovanie",
                        singleFileUploadHandler("pass_burial")
                    )}
                    {documentCard(
                        funeral.take_remains_photo,
                        "take_remains_photo",
                        selectedFile.take_remains_photo,
                        "Odovzdanie ľudských pozostatkov",
                        singleFileUploadHandler("take_remains_photo")
                    )}
                    {documentCard(
                        funeral.other_document_1,
                        "other_document_1",
                        selectedFile.other_document_1,
                        "Iné dokumenty 1",
                        singleFileUploadHandler("other_document_1")
                    )}
                    {documentCard(
                        funeral.other_document_2,
                        "other_document_2",
                        selectedFile.other_document_2,
                        "Iné dokumenty 2",
                        singleFileUploadHandler("other_document_2")
                    )}
                    {documentCard(
                        funeral.other_document_3,
                        "other_document_3",
                        selectedFile.other_document_3,
                        "Iné dokumenty 3",
                        singleFileUploadHandler("other_document_3")
                    )}
                    {documentCard(
                        funeral.other_document_4,
                        "other_document_4",
                        selectedFile.other_document_4,
                        "Iné dokumenty 4",
                        singleFileUploadHandler("other_document_4")
                    )}
                </Grid>
            </Container>
            {redirects.applicationRedirect ? (
                <Redirect
                    to={{
                        pathname: `/pohreb/${funeral._id}/ziadost`,
                        state: { from: props.location },
                    }}
                />
            ) : (
                <></>
            )}
            {redirects.urnApplicationRedirect ? (
                <Redirect
                    to={{
                        pathname: `/pohreb/${funeral._id}/ziadost_urna`,
                        state: { from: props.location },
                    }}
                />
            ) : (
                <></>
            )}
             {redirects.confirmationRedirect ? (
                <Redirect
                    to={{
                        pathname: `/pohreb/${funeral._id}/potvrdenie`,
                        state: { from: props.location },
                    }}
                />
            ) : (
                <></>
            )}
            {redirects.delegationRedirect ? (
                <Redirect
                    to={{
                        pathname: `/pohreb/${funeral._id}/splnomocnenie`,
                        state: { from: props.location },
                    }}
                />
            ) : (
                <></>
            )}
            {redirects.gdprRedirect ? (
                <Redirect
                    to={{
                        pathname: `/pohreb/${funeral._id}/gdpr`,
                        state: { from: props.location },
                    }}
                />
            ) : (
                <></>
            )}
            {redirects.infoList ? (
                <Redirect
                    to={{
                        pathname: `/pohreb/${funeral._id}/informacny_list`,
                        state: { from: props.location },
                    }}
                />
            ) : (
                <></>
            )}
            {redirects.coffinLabel ? (
                <Redirect
                    to={{
                        pathname: `/pohreb/${funeral._id}/stitok_rakva`,
                        state: { from: props.location },
                    }}
                />
            ) : (
                <></>
            )}
            {redirects.cards ? (
                <Redirect
                    to={{
                        pathname: `/pohreb/${funeral._id}/karticky`,
                        state: { from: props.location },
                    }}
                />
            ) : (
                <></>
            )}
            {redirects.prices ? (
                <Redirect
                    to={{
                        pathname: `/pohreb/${funeral._id}/cennik`,
                        state: { from: props.location },
                    }}
                />
            ) : (
                <></>
            )}
        </React.Fragment>
    );
}
