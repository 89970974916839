import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Title from '../dashboard/Title';
import UserService from "../../services/user.service";
import { logOut} from "../../helpers/logout";
import useTable from "./useTable";
import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import {InputAdornment, Toolbar} from "@material-ui/core";
import TextInput from "../form/input/TextInput";
import {Search} from "@material-ui/icons";
import Time from "react-time-format";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import { Redirect} from "react-router-dom";
import ActionButton from "../form/input/ActionButton";
import InfoIcon from '@material-ui/icons/Info';
import EuroIcon from '@material-ui/icons/Euro';
import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';
import {NotificationManager} from 'react-notifications';
import ConfirmDialog from "./ConfirmDialog";
import Tooltip from "@material-ui/core/Tooltip";
import JSZip from "jszip";
import { saveAs } from "file-saver";

const useStyles = makeStyles((theme) => ({
    appBarSpacer: theme.mixins.toolbar,
    seeMore: {
        marginTop: theme.spacing(8),
    },
    depositContext: {
        flex: 1,
    },
    titleMargin: {
        marginLeft: theme.spacing(3),
    },
    pageContent: {
        margin: theme.spacing(5),
        padding: theme.spacing(3)
    },
    searchInput: {
        width: '75%'
    },
    buttonPosition: {
        // position: 'static',
        marginLeft: theme.spacing(8)
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
        marginTop: theme.spacing(8),
    },
    floatRight: {
        float: 'right',
    }
}));

const headCells = [
    {id: 'deceased', label: 'Zosnulý'},
    {id: 'd_death', label: 'Dátum úmrtia'},
    {id: 'date_funeral', label: 'Pohreb'},
    {id: 'ca_phone', label: 'Kontakt', disableSorting: true},
    {id: 'paid', label: 'Zaplatený'},
    {id: 'actions', label: 'Akcie', disableSorting: true, align: 'right'}
]

export default function FullList(props) {
    const classes = useStyles();

    const[year, setYear ] = useState('2024');
    const[month, setMonth ] = useState('1');

    const[downloadCount, setDownloadCount ] = useState(0);
    const[downloader, setDownloader ] = useState(0);

    const [funerals, setFunerals] = useState([]);
    const [filterFn, setFilterFn] = useState({
        fn: items => {
            return items;
        }
    })
    const [list, setList] = useState(false);
    const [funeralDetail, setFuneralDetail] = useState(null);
    const [confirmDialog, setConfirmDialog] = useState({isOpen: false, title: '', subTitle: ''})
    const [confirmDialog2, setConfirmDialog2] = useState({isOpen: false, title: '', subTitle: '', color: 'primary'})
    const [confirmDialog3, setConfirmDialog3] = useState({isOpen: false, title: '', subTitle: '', color: 'primary'})
    const [confirmDialog4, setConfirmDialog4] = useState({isOpen: false, title: '', subTitle: '', color: 'primary'})
    const [limit, setLimit] = useState(100);
    const [success, setSuccess] = useState(null);

    const {
        TblContainer,
        TblHead,
        TblPagination,
        recordsAfterPagingAndSorting
    } = useTable(funerals, headCells, filterFn);

    useEffect(() => {
        UserService.getListFunerals(limit).then(
            (response) => {
                setFunerals(response.data);
            },
            (e) => {
                if (e.response.status === 401) {
                    logOut(props);
                    NotificationManager.warning('Potrebné sa znova prihlásiť!', 'Upozornenie', 5000);
                }
            }
        );
    }, [limit, props]);

    const handleSearch = e => {
        let target = e.target;
        const regex = new RegExp(target.value, 'i');
        setFilterFn({
            fn: items => items.filter(x => x.deceased.match(regex)
                || x.contracting_authority.match(regex)
                || x.town_funeral.match(regex)
                || x.d_id.match(regex)
            )
        })
    }

    const handleDelete = (id) => {
        setConfirmDialog({
            ...confirmDialog,
            isOpen: false
        })
        UserService.deleteFuneral(id).then(
            () => {
                NotificationManager.success('Pohreb vymazaný!', 'Vymazané', 5000);
                UserService.getListFunerals(limit).then(
                    (response) => {
                        setFunerals(response.data);
                    },
                    (e) => {
                        if (e.response.status === 401) {
                            logOut(props);
                            NotificationManager.warning('Potrebné sa znova prihlásiť!', 'Upozornenie', 5000);
                        }
                    }
                );
            },
            (e) => {
                if (e.response.status === 401) {
                    logOut(props);
                    NotificationManager.warning('Potrebné sa znova prihlásiť!', 'Upozornenie', 5000);
                }
                NotificationManager.error('Pohreb sa nepodarilo odstrániť!', 'Chyba', 5000);
            }
        );
    }

    const handleRedirect = () => {
        setList(true);
    }

    const handleNoLimit = () => {
        setLimit(0);
    }

    const changePaidStatus = (id) => {
        setConfirmDialog2({
            ...confirmDialog2,
            isOpen: false
        })
        UserService.getFuneral(id).then(
            (response) => {
                let object = response.data;
                object.funeral_paid = !object.funeral_paid;
                UserService.updateFuneral(object).then(
                    (response) => {
                        setSuccess(response.data._id);
                        UserService.getListFunerals(limit).then(
                            (response) => {
                                setFunerals(response.data);
                            },
                            (e) => {
                                if (e.response.status === 401) {
                                    logOut(props);
                                    NotificationManager.warning('Potrebné sa znova prihlásiť!', 'Upozornenie', 5000);
                                }
                            }
                        );
                        NotificationManager.success('Pohreb bol upravený!', 'Vytvorené', 5000);
                    },
                    (e) => {
                        if (e.response.status === 401) {
                            logOut(props);
                            NotificationManager.warning('Potrebné sa znova prihlásiť!', 'Upozornenie', 5000);
                        } else {
                            NotificationManager.error('Došlo k neočakávanej chybe. Skúste neskôr alebo kontaktujte podporu.', 'Chyba', 5000);
                        }
                    }
                );
            },
            (e) => {
                if (e.response.status === 401) {
                    logOut(props);
                    NotificationManager.warning('Potrebné sa znova prihlásiť!', 'Upozornenie', 5000);
                }
            }
        );
    }
    

    function getExtension(filename) {
        return filename.split('.').pop()
    }

    const handleDownload = () => {
        try {

            setConfirmDialog3({
                ...confirmDialog3,
                isOpen: false
            })

            setConfirmDialog4({
                isOpen: true,
                title: 'Čakajte kým sa načítajú všetky údaje.',
            })

            var zip = new JSZip();

            const selected_year = document.getElementsByName("year")[0].value;
            const selected_month = document.getElementsByName("month")[0].value;

            UserService.getListFuneralsDownload(selected_year,selected_month).then(
                 (response) => {

                    setDownloadCount(Object.keys(response.data).length);

                    const promises = response.data.map(async (item, idx) => {

                        var deceased = zip.folder(item.deceased);

                        deceased.file("data.txt", JSON.stringify(item));

                        var photos = deceased.folder("fotky");

                        if(item.grave_hole_photo_key){
                            const response = await fetch(item.grave_hole_photo);
                            if (!response.ok) {throw new Error('Failed to fetch image');}
                            const blob = await response.blob();
                            photos.file('pohrebné miesto.' + getExtension(item.grave_hole_photo).toLowerCase(), blob);
                        }

                        if(item.grave_before_ceremony_key){
                            const response = await fetch(item.grave_before_ceremony);
                            if (!response.ok) {throw new Error('Failed to fetch image');}
                            const blob = await response.blob();
                            photos.file('hrob pred pohrebom.' + getExtension(item.grave_before_ceremony).toLowerCase(), blob);
                        }

                        if(item.grave_after_ceremony_key){
                            const response = await fetch(item.grave_after_ceremony);
                            if (!response.ok) {throw new Error('Failed to fetch image');}
                            const blob = await response.blob();
                            photos.file('hrob po pohrebe.' + getExtension(item.grave_after_ceremony).toLowerCase(), blob);
                        }

                        if(item.grave_ceremony_key){
                            const response = await fetch(item.grave_ceremony);
                            if (!response.ok) {throw new Error('Failed to fetch image');}
                            const blob = await response.blob();
                            photos.file('hrob počas pohrebu.' + getExtension(item.grave_ceremony).toLowerCase(), blob);
                        }

                        if(item.grave_damage_1_key){
                            const response = await fetch(item.grave_damage_1);
                            if (!response.ok) {throw new Error('Failed to fetch image');}
                            const blob = await response.blob();
                            photos.file('poškodenie hrobu 1.' + getExtension(item.grave_damage_1).toLowerCase(), blob);
                        }

                        if(item.grave_damage_2_key){
                            const response = await fetch(item.grave_damage_2);
                            if (!response.ok) {throw new Error('Failed to fetch image');}
                            const blob = await response.blob();
                            photos.file('poškodenie hrobu 2.' + getExtension(item.grave_damage_2).toLowerCase(), blob);
                        }
                        
                        if(item.grave_exterier_1_key){
                            const response = await fetch(item.grave_exterier_1);
                            if (!response.ok) {throw new Error('Failed to fetch image');}
                            const blob = await response.blob();
                            photos.file('okolie hrobu 1.' + getExtension(item.grave_exterier_1).toLowerCase(), blob);
                        }

                        if(item.grave_exterier_2_key){
                            const response = await fetch(item.grave_exterier_2);
                            if (!response.ok) {throw new Error('Failed to fetch image');}
                            const blob = await response.blob();
                            photos.file('okolie hrobu 2.' + getExtension(item.grave_exterier_2).toLowerCase(), blob);
                        }

                        if(item.grave_exterier_3_key){
                            const response = await fetch(item.grave_exterier_3);
                            if (!response.ok) {throw new Error('Failed to fetch image');}
                            const blob = await response.blob();
                            photos.file('okolie hrobu 3.' + getExtension(item.grave_exterier_3).toLowerCase(), blob);
                        }

                        if(item.grave_exterier_4_key){
                            const response = await fetch(item.grave_exterier_4);
                            if (!response.ok) {throw new Error('Failed to fetch image');}
                            const blob = await response.blob();
                            photos.file('okolie hrobu 4.' + getExtension(item.grave_exterier_4).toLowerCase(), blob);
                        }

                        if(item.grave_exterier_5_key){
                            const response = await fetch(item.grave_exterier_5);
                            if (!response.ok) {throw new Error('Failed to fetch image');}
                            const blob = await response.blob();
                            photos.file('okolie hrobu 5.' + getExtension(item.grave_exterier_5).toLowerCase(), blob);
                        }

                        if(item.grave_exterier_6_key){
                            const response = await fetch(item.grave_exterier_6);
                            if (!response.ok) {throw new Error('Failed to fetch image');}
                            const blob = await response.blob();
                            photos.file('okolie hrobu 6.' + getExtension(item.grave_exterier_6).toLowerCase(), blob);
                        }

                        if(item.grave_exterier_7_key){
                            const response = await fetch(item.grave_exterier_7);
                            if (!response.ok) {throw new Error('Failed to fetch image');}
                            const blob = await response.blob();
                            photos.file('okolie hrobu 7.' + getExtension(item.grave_exterier_7).toLowerCase(), blob);
                        }

                        if(item.grave_exterier_8_key){
                            const response = await fetch(item.grave_exterier_8);
                            if (!response.ok) {throw new Error('Failed to fetch image');}
                            const blob = await response.blob();
                            photos.file('okolie hrobu 8.' + getExtension(item.grave_exterier_8).toLowerCase(), blob);
                        }

                        if(item.grave_exterier_9_key){
                            const response = await fetch(item.grave_exterier_9);
                            if (!response.ok) {throw new Error('Failed to fetch image');}
                            const blob = await response.blob();
                            photos.file('okolie hrobu 9.' + getExtension(item.grave_exterier_9).toLowerCase(), blob);
                        }

                        if(item.grave_exterier_10_key){
                            const response = await fetch(item.grave_exterier_10);
                            if (!response.ok) {throw new Error('Failed to fetch image');}
                            const blob = await response.blob();
                            photos.file('okolie hrobu 10.' + getExtension(item.grave_exterier_10).toLowerCase(), blob);
                        }

                        var documents = deceased.folder("dokumenty");

                        if(item.examination_photo_front_key){
                            const response = await fetch(item.examination_photo_front);
                            if (!response.ok) {throw new Error('Failed to fetch image');}
                            const blob = await response.blob();
                            documents.file('list o prehliadke mŕtveho predná strana.' + getExtension(item.examination_photo_front).toLowerCase(), blob);
                        }

                        if(item.examination_photo_back_key){
                            const response = await fetch(item.examination_photo_back);
                            if (!response.ok) {throw new Error('Failed to fetch image');}
                            const blob = await response.blob();
                            documents.file('list o prehliadke mŕtveho zadná strana.' + getExtension(item.examination_photo_back).toLowerCase(), blob);
                        }

                        if(item.death_photo_key){
                            const response = await fetch(item.death_photo);
                            if (!response.ok) {throw new Error('Failed to fetch image');}
                            const blob = await response.blob();
                            documents.file('úmrtný list.' + getExtension(item.death_photo).toLowerCase(), blob);
                        }

                        if(item.hole_photo_key){
                            const response = await fetch(item.hole_photo);
                            if (!response.ok) {throw new Error('Failed to fetch image');}
                            const blob = await response.blob();
                            documents.file('fotka výkopu hrobu.' + getExtension(item.hole_photo).toLowerCase(), blob);
                        }

                        if(item.passport_key){
                            const response = await fetch(item.passport);
                            if (!response.ok) {throw new Error('Failed to fetch image');}
                            const blob = await response.blob();
                            documents.file('pas pre mŕtvolu.' + getExtension(item.passport).toLowerCase(), blob);
                        }

                        if(item.pass_burial_key){
                            const response = await fetch(item.pass_burial);
                            if (!response.ok) {throw new Error('Failed to fetch image');}
                            const blob = await response.blob();
                            documents.file('povolenie na pochovanie.' + getExtension(item.pass_burial).toLowerCase(), blob);
                        }

                        if(item.take_remains_photo_key){
                            const response = await fetch(item.take_remains_photo);
                            if (!response.ok) {throw new Error('Failed to fetch image');}
                            const blob = await response.blob();
                            documents.file('odovzdanie ľudských pozostatkov.' + getExtension(item.take_remains_photo).toLowerCase(), blob);
                        }

                        if(item.other_document_1_key){
                            const response = await fetch(item.other_document_1);
                            if (!response.ok) {throw new Error('Failed to fetch image');}
                            const blob = await response.blob();
                            documents.file('Iné dokumenty 1.' + getExtension(item.other_document_1).toLowerCase(), blob);
                        }

                        if(item.other_document_2_key){
                            const response = await fetch(item.other_document_2);
                            if (!response.ok) {throw new Error('Failed to fetch image');}
                            const blob = await response.blob();
                            documents.file('Iné dokumenty 2.' + getExtension(item.other_document_2).toLowerCase(), blob);
                        }

                        if(item.other_document_3_key){
                            const response = await fetch(item.other_document_3);
                            if (!response.ok) {throw new Error('Failed to fetch image');}
                            const blob = await response.blob();
                            documents.file('Iné dokumenty 3.' + getExtension(item.other_document_3).toLowerCase(), blob);
                        }

                        if(item.other_document_4_key){
                            const response = await fetch(item.other_document_4);
                            if (!response.ok) {throw new Error('Failed to fetch image');}
                            const blob = await response.blob();
                            documents.file('Iné dokumenty 4.' + getExtension(item.other_document_4).toLowerCase(), blob);
                        }

                        setDownloader(prevState => {
                            return prevState + 1
                        })

                    })

                    return Promise.all(promises);

                },
                (e) => {

                    setDownloader(0);

                    if (e.response.status === 401) {
                        logOut(props);
                        NotificationManager.warning('Potrebné sa znova prihlásiť!', 'Upozornenie', 5000);
                    }else{
                        setConfirmDialog4({
                            ...setConfirmDialog4,
                            isOpen: false
                        })
                        NotificationManager.warning('Pri sťahovaní nastala chyba', 'Upozornenie', 5000);
                    }
                }
            ).then(
                (response) => {

                    setDownloader(0);

                    setConfirmDialog4({
                        ...setConfirmDialog4,
                        isOpen: false
                    })

                    zip.generateAsync({type:"blob"}).then(function (content) {
                        saveAs(content, "MP-" + selected_year + "-" + selected_month + ".zip");
                    });
                }
            );
        } catch (error) {

            setDownloader(0);
            
            setConfirmDialog4({
                ...setConfirmDialog4,
                isOpen: false
            })
            NotificationManager.warning('Pri sťahovaní nastala chyba', 'Upozornenie', 5000);
        }
    };

    return (
        <React.Fragment>
            <div className={classes.appBarSpacer}>
                <Container maxWidth="lg">
                    <Paper className={classes.paper}>
                        <div className={classes.titleMargin}>
                            <Title>Zoznam pohrebov</Title>
                        </div>
                        <Toolbar>
                            <TextInput
                                label="Vyhľadať podľa mena"
                                className={classes.searchInput}
                                InputProps={{
                                    startAdornment: (<InputAdornment position="start">
                                        <Search/>
                                    </InputAdornment>)
                                }}
                                onChange={handleSearch}
                            />
                            <Button
                                className={classes.buttonPosition}
                                variant="outlined"
                                startIcon={<AddIcon/>}
                                onClick={handleRedirect}
                            >
                                Vytvoriť pohreb
                            </Button>
                        </Toolbar>
                        <TblContainer>
                            <TblHead/>
                            <TableBody>
                                {
                                    recordsAfterPagingAndSorting().map((item, index) =>
                                        (<TableRow key={index}>
                                            <TableCell>{item.deceased}</TableCell>
                                            <TableCell><Time value={item.d_death}
                                                             format="DD.MM.YYYY HH:mm"/></TableCell>
                                            <TableCell><Time value={item.date_funeral}
                                                             format="DD.MM.YYYY HH:mm"/></TableCell>
                                            <TableCell>{item.ca_phone}</TableCell>
                                            <TableCell>{item.funeral_paid == true ? 'Ano' : 'Nie'}</TableCell>
                                            <TableCell align="right">
                                                <ActionButton
                                                    color={item.funeral_paid ? 'primary' : 'secondary'}>
                                                    <Tooltip title={item.funeral_paid ? "Označiť ako nezaplatený" : "Označiť ako zaplatený"}>
                                                        <EuroIcon
                                                            fontSize="small"
                                                            onClick={() => {
                                                                setConfirmDialog2({
                                                                    isOpen: true,
                                                                    title: item.funeral_paid ? 'Naozaj chcete označiť pohreb ako nezaplatený' : 'Naozaj chcete označiť pohreb ako zaplatený',
                                                                    onConfirm: () => {
                                                                        changePaidStatus(item._id);
                                                                    }
                                                                })
                                                                
                                                            }}
                                                        />
                                                    </Tooltip>
                                                </ActionButton>
                                                <ActionButton
                                                    color="primary">
                                                    <Tooltip title="Detaily pohrebu">
                                                        <InfoIcon
                                                            fontSize="small"
                                                            onClick={() => {
                                                                setFuneralDetail(item._id);
                                                            }}
                                                        />
                                                    </Tooltip>
                                                </ActionButton>
                                                <ActionButton
                                                    color="secondary">
                                                    <Tooltip title="Vymazať pohreb">
                                                        <DeleteIcon
                                                            fontSize="small"
                                                            onClick={() => {
                                                                setConfirmDialog({
                                                                    isOpen: true,
                                                                    title: 'Naozaj chceš vymazať tento pohreb?',
                                                                    subTitle: "Túto operáciu nevieš vrátiť.",
                                                                    onConfirm: () => {
                                                                        handleDelete(item._id)
                                                                    }
                                                                })
                                                            }}
                                                        />
                                                    </Tooltip>
                                                </ActionButton>
                                            </TableCell>
                                        </TableRow>)
                                    )
                                }
                            </TableBody>
                        </TblContainer>
                        <TblPagination/>
                        <div style={{ display: 'flex', justifyContent: 'space-between'  }}> 
                            <Button size="small" color="primary"
                                    onClick={handleNoLimit}>Načítať viac ako max. 100 záznamov</Button>

                                <Button size="small" color="primary"
                                    onClick={() => {
                                        setConfirmDialog3({
                                            isOpen: true,
                                            title: 'Naozaj chcete stiahnuť všetky pohreby?',
                                            onConfirm: () => {
                                                handleDownload();
                                            }
                                        })
                                        
                                    }}>Stiahnuť zálohu</Button>
                        </div>
                    </Paper>
                </Container>
                <ConfirmDialog
                    confirmDialog={confirmDialog}
                    setConfirmDialog={setConfirmDialog}
                    icon={<DeleteIcon/>}
                />
                <ConfirmDialog
                    confirmDialog={confirmDialog2}
                    setConfirmDialog={setConfirmDialog2}
                    color="primary"
                    icon={<EuroIcon/>}
                />
                <ConfirmDialog
                    confirmDialog={confirmDialog3}
                    setConfirmDialog={setConfirmDialog3}
                    color="primary"
                    year={year}
                    month={month}
                    updateYear={setYear}
                    updateMonth={setMonth}
                    dates={true}
                    icon={<SaveIcon/>}
                />
                <ConfirmDialog
                    confirmDialog={confirmDialog4}
                    setConfirmDialog={setConfirmDialog4}
                    color="primary"
                    counter={downloadCount}
                    downlaoder={downloader}
                    buttons={false}
                    icon={<SaveIcon/>}
                />
                {list && <Redirect to={{
                    pathname: `/novy-pohreb`,
                    state: {from: props.location}
                }}/>}
                {funeralDetail ? <Redirect to={{
                    pathname: `/pohreb/${funeralDetail}/info`,
                    state: { from: props.location }
                }} /> : <></>}
            </div>
        </React.Fragment>
    );
}