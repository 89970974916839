import React, {useEffect, useRef, useState} from "react";
import {Redirect, useParams} from "react-router-dom";
import UserService from "../../services/user.service";
import '../../Print.css';
import { logOut} from "../../helpers/logout";
import {Button, Divider, Grid, Toolbar} from "@material-ui/core";
import ActionButton from "../form/input/ActionButton";
import Tooltip from "@material-ui/core/Tooltip";
import Paper from "@material-ui/core/Paper";
import Container from "@material-ui/core/Container";
import {makeStyles} from "@material-ui/core/styles";
import clsx from "clsx";
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import Popup from "../presentation/Popup";
import TextInput from "../form/input/TextInput";
import DateInput from "../form/input/DateInput";
import altPhoto from "../../assets/trans.png";
import {PDFExport} from '@progress/kendo-react-pdf';
import Time from "react-time-format";
import {NotificationManager} from "react-notifications";
import withWidth from '@material-ui/core/withWidth'
import {ReactSketchCanvas} from "react-sketch-canvas";
import {convertSize} from "../../helpers/helper";
import application1 from '../../assets/application1.jpg';
import application2 from '../../assets/application2.jpg';
import application3 from '../../assets/application3.jpg';
import funus from '../../assets/funus.png';
import mp from '../../assets/mp.png';
import { red } from "@material-ui/core/colors";

const useStyles = makeStyles((theme) => ({
    appBarSpacer: theme.mixins.toolbar,
    root: {
        flexGrow: 1,
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
    seeMore: {
        marginTop: theme.spacing(8),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    toolbarButtons: {
        '& .MuiFormControl-root': {
            margin: theme.spacing(1)
        }
    },
    marginElement: {
        margin: theme.spacing(2)
    },
    marginTop: {
        marginTop: theme.spacing(2)
    },
    icon: {
        marginRight: theme.spacing(2),
    },
    heroContent: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(8, 0, 6),
    },
    heroButtons: {
        marginTop: theme.spacing(4),
    },
    cardGrid: {
        paddingTop: theme.spacing(8),
        paddingBottom: theme.spacing(8),
    },
    card: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    cardMedia: {
        paddingTop: '56.25%', // 16:9
    },
    cardContent: {
        flexGrow: 1,
    },
}));


const ApplicationGenerator = (props) => {
    const classes = useStyles();
    const fixedHeightPaper = clsx(classes.paper);
    let canvas = useRef(null);

    const pdfRef = useRef(null);
    const [funeral, setFuneral] = useState({
        contracting_authority: '',
        ca_name: '',
        ca_last_name: '',
        ca_address: '',
        ca_street: '',
        ca_street_number: '',
        ca_zip: '',
        ca_city: '',
        ca_state: '',
        ca_nationality: '',
        ca_email: '',
        ca_phone: '',
        ca_id: '',
        ca_birth: '',
        ca_relationship: '',
        ca_sr_1: '',
        ca_sr_2: '',
        deceased: '',
        d_name: '',
        d_last_name: '',
        d_address: '',
        d_street: '',
        d_street_number: '',
        d_zip: '',
        d_city: '',
        d_state: '',
        d_place_birth: '',
        d_birth: '',
        d_gender: '',
        d_marital_status: '',
        d_death: '',
        d_place_death: '',
        d_id: '',
        d_nationality: '',
        d_education: '',
        d_last_job: '',
        d_wife_husband: '',
        d_wife_husband_id: '',
        d_sr_1: '',
        d_sr_2: '',
        date_of_signature_delegation: new Date(),
        planned_pickup_place: '',
        planned_pickup: '',
        town_funeral: '',
        place_funeral: '',
        date_funeral: '',
        coffin_arrival: '',
        coffin_arrival_place: '',
        signatures: {},
        application_date: '',
        employee: ''
    });
    const [show, setShow] = useState(false);
    const [date, setDate] = useState(new Date());
    const [location, setLocation] = useState('');
    const [success, setSuccess] = useState(false);
    const [fullScreen, setFullScreen] = useState(false);

    const handleShow = () => setShow(true);

    const handleDate = (e) => {
        setDate(e.target.value)
    }

    const handleLocation = (e) => {
        setLocation(e.target.value)
    }

    const [user, setUser] = useState(null);

    const funeral_id = useParams();
    const user_local = JSON.parse(localStorage.getItem('user')).id;

    useEffect(() => {
        UserService.getUser(user_local).then(
            (response) => {
                setUser(response.data);
                if (response.data.defined_texts) {
                    setLocation(response.data.defined_texts.default_signature_place);
                }
            },
            (e) => {
                if (e.response.status === 401) {
                    logOut(props);
                    NotificationManager.warning('Potrebné sa znova prihlásiť!', 'Upozornenie', 5000);
                }
            }
        );
        UserService.getFuneral(funeral_id.funeral_id).then(
            (response) => {
                setFuneral({...response.data,
                    ca_name: response.data.contracting_authority ? response.data.contracting_authority.split(" ")[0] : '',
                    ca_last_name: response.data.contracting_authority ? response.data.contracting_authority.split(" ").slice(1,response.data.contracting_authority.length - 1).join(' ') : '',
                    d_name: response.data.deceased ? response.data.deceased.split(" ")[0] : '',
                    d_last_name: response.data.deceased ? response.data.deceased.split(" ").slice(1,response.data.deceased.length - 1).join(' ') : '',
                    ca_street: response.data.ca_address ? response.data.ca_address.split(" ").slice(0, -1).join(' ') : '',
                    ca_street_number: response.data.ca_address ? response.data.ca_address.split(" ").slice(-1)[0] : '',
                    d_street: response.data.d_address ? response.data.d_address.split(" ").slice(0, -1).join(' ') : '',
                    d_street_number: response.data.d_address ? response.data.d_address.split(" ").slice(-1)[0] : '',
                    ca_birth: new Date(response.data.ca_birth).toLocaleDateString(),
                    d_birth: new Date(response.data.d_birth).toLocaleDateString(),
                    d_death: new Date(response.data.d_death).toLocaleDateString(),
                    date_funeral: new Date(response.data.date_funeral).toLocaleDateString(),
                    ca_sr_1: 'X',
                    ca_sr_2: '',
                    d_sr_1: 'X',
                    d_sr_2: '',
                    application_date: new Date(response.data.date_funeral).toLocaleDateString(),
                    employee: response.data.evidence ? response.data.evidence.employee : ''
                });
            },
            (e) => {
                if (e.response.status === 401) {
                    logOut(props);
                    NotificationManager.warning('Potrebné sa znova prihlásiť!', 'Upozornenie', 5000);
                }
            }
        );
    }, [funeral_id.funeral_id, user_local, props]);

    const singleFileUploadHandler = (file) => {
        const data = new FormData();
        data.append('someimage', file, 'delegation_signature.png');
        data.append('signatures_id', funeral.signatures._id);
        data.append('tag', 'delegation');
        data.append('date_of_delegation', new Date(date).toISOString());
        data.append('location_of_delegation', location);
        UserService.signDocument(data)
            .then((response) => {
                if (200 === response.status) {
                    // If file size is larger than expected.
                    if (response.data.error) {
                    } else {
                        let fileName = response.data;
                        setFuneral({
                            ...funeral,
                            'signatures': {...funeral.signatures, 'delegation_signature': fileName.location}
                        });
                        setShow(false);
                    }
                }
            }).catch((e) => {
            if (e.response.status === 401) {
                logOut(props);
                NotificationManager.warning('Potrebné sa znova prihlásiť!', 'Upozornenie', 5000);
            }
            NotificationManager.error('Nepodarilo sa podpísať dokument!', 'Chyba', 5000);
            setShow(false);
        });
    };

    const handleSignature = async () => {
        const exportImage = canvas.current?.exportImage;

        if (exportImage) {
            const exportedDataURI = await exportImage('png');
            fetch(exportedDataURI)
                .then((response) => {
                    return response.blob();
                })
                .then((blob) => {
                    singleFileUploadHandler(blob);
                })
                .catch(() => {
                    NotificationManager.error('Nepodarilo sa podpísať dokument!', 'Chyba', 5000);
                    setShow(false);
                });
        }
    }

    const handleExport = () => {
        pdfRef.current.save();
    }

    const handleClear = () => {
        const resetCanvas = canvas.current?.resetCanvas;

        if (resetCanvas) {
            resetCanvas();
        }
    }

    const handleChange = (event) => {
        const {name, value} = event.target;
        setFuneral({...funeral, [name]: value});
    }

    const showDelegation = () => {
        if (user) {
            return <div style={{border: '1px solid'}}>
                <PDFExport ref={pdfRef} paperSize="A4" scale={0.75}
                           fileName={`ziadost-o-prispevok-${funeral.deceased.replace(' ', '')}`}>
                    <div className="a4-paper" style={{backgroundSize: 'cover', backgroundImage: `url(${application1})`}}>
                        <div className="wrapper" style={{position: 'relative', height: '100%'}}>
                            <input name="ca_last_name" onChange={handleChange} type="text" style={{marginTop: '162px', marginLeft: '88px', width: '266px', position: 'absolute', border: 'none', background: 'none'}} value={funeral.ca_last_name} />
                            <input name="ca_name" onChange={handleChange} type="text" style={{marginTop: '162px', marginLeft: '369px', width: '198px', position: 'absolute', border: 'none', background: 'none'}} value={funeral.ca_name} />
                            
                            <input name="ca_birth" onChange={handleChange} type="text" style={{marginTop: '206px', marginLeft: '88px', width: '200px', position: 'absolute', border: 'none',background: 'none'}} value={funeral.ca_birth} />
                            <input name="ca_id" onChange={handleChange} type="text" style={{marginTop: '206px', marginLeft: '304px', width: '198px', position: 'absolute', border: 'none', background: 'none'}} value={funeral.ca_id} />
                            <input name="ca_nationality" onChange={handleChange} type="text" style={{marginTop: '206px', marginLeft: '517px', width: '212px', position: 'absolute', border: 'none', background: 'none'}} value={funeral.ca_nationality} />

                            <input name="ca_sr_1" onChange={handleChange} type="text" style={{marginTop: '229px', marginLeft: '107px', width: '15px', position: 'absolute', border: 'none', background: 'none'}} value={funeral.ca_sr_1} />
                            <input name="ca_sr_2" onChange={handleChange} type="text" style={{marginTop: '229px', marginLeft: '329px', width: '15px', position: 'absolute', border: 'none', background: 'none'}} value={funeral.ca_sr_2} />
                            
                            <input name="ca_street" onChange={handleChange} type="text" style={{marginTop: '250px', marginLeft: '160px', width: '390px', position: 'absolute', border: 'none', background: 'none'}} value={funeral.ca_street} />
                            <input name="ca_street_number" onChange={handleChange} type="text" style={{marginTop: '250px', marginLeft: '615px', width: '113px', position: 'absolute', border: 'none', background: 'none'}} value={funeral.ca_street_number} />

                            <input name="ca_zip" onChange={handleChange} type="text" style={{marginTop: '274px', marginLeft: '160px', width: '80px', position: 'absolute', border: 'none', background: 'none'}} value={funeral.ca_zip} />
                            <input name="ca_city" onChange={handleChange} type="text" style={{marginTop: '274px', marginLeft: '287px', width: '263px', position: 'absolute', border: 'none', background: 'none'}} value={funeral.ca_city} />
                            <input name="ca_phone" onChange={handleChange} type="text" style={{marginTop: '274px', marginLeft: '615px', width: '113px', position: 'absolute', border: 'none', background: 'none'}} value={funeral.ca_phone} />

                            {/* <div style={{marginTop: '322px', marginLeft: '162px', position: 'absolute'}}>{funeral.ca_street}</div>
                            <div style={{marginTop: '322px', marginLeft: '618px', position: 'absolute'}}>{funeral.ca_street_number}</div>

                            <div style={{marginTop: '345px', marginLeft: '162px', position: 'absolute'}}>{funeral.ca_zip}</div>
                            <div style={{marginTop: '345px', marginLeft: '290px', position: 'absolute'}}>{funeral.ca_city}</div>
                            <div style={{marginTop: '345px', marginLeft: '618px', position: 'absolute'}}>{funeral.ca_phone}</div>

                            <div style={{marginTop: '370px', marginLeft: '162px', position: 'absolute'}}>{funeral.ca_state}</div> */}

                            <input name="d_last_name" onChange={handleChange} type="text" style={{marginTop: '455px', marginLeft: '88px', width: '266px', position: 'absolute', border: 'none', background: 'none'}} value={funeral.d_last_name} />
                            <input name="d_name" onChange={handleChange} type="text" style={{marginTop: '455px', marginLeft: '369px', width: '198px', position: 'absolute', border: 'none', background: 'none'}} value={funeral.d_name} />
                            
                            <input name="d_birth" onChange={handleChange} type="text" style={{marginTop: '502px', marginLeft: '88px', width: '200px', position: 'absolute', border: 'none',background: 'none'}} value={funeral.d_birth} />
                            <input name="d_id" onChange={handleChange} type="text" style={{marginTop: '502px', marginLeft: '304px', width: '198px', position: 'absolute', border: 'none', background: 'none'}} value={funeral.d_id} />
                            <input name="d_nationality" onChange={handleChange} type="text" style={{marginTop: '502px', marginLeft: '517px', width: '212px', position: 'absolute', border: 'none', background: 'none'}} value={funeral.d_nationality} />

                            <input name="d_sr_1" onChange={handleChange} type="text" style={{marginTop: '525px', marginLeft: '107px', width: '15px', position: 'absolute', border: 'none', background: 'none'}} value={funeral.d_sr_1} />
                            <input name="d_sr_2" onChange={handleChange} type="text" style={{marginTop: '525px', marginLeft: '329px', width: '15px', position: 'absolute', border: 'none', background: 'none'}} value={funeral.d_sr_2} />
                            
                            <input name="d_street" onChange={handleChange} type="text" style={{marginTop: '545px', marginLeft: '160px', width: '390px', position: 'absolute', border: 'none', background: 'none'}} value={funeral.d_street} />
                            <input name="d_street_number" onChange={handleChange} type="text" style={{marginTop: '545px', marginLeft: '615px', width: '113px', position: 'absolute', border: 'none', background: 'none'}} value={funeral.d_street_number} />

                            <input name="d_zip" onChange={handleChange} type="text" style={{marginTop: '569px', marginLeft: '160px', width: '80px', position: 'absolute', border: 'none', background: 'none'}} value={funeral.d_zip} />
                            <input name="d_city" onChange={handleChange} type="text" style={{marginTop: '569px', marginLeft: '287px', width: '263px', position: 'absolute', border: 'none', background: 'none'}} value={funeral.d_city} />

                            <input name="d_death" onChange={handleChange} type="text" style={{marginTop: '693px', marginLeft: '195px', width: '95px', position: 'absolute', border: 'none', background: 'none'}} value={funeral.d_death} />
                            <input name="d_place_death" onChange={handleChange} type="text" style={{marginTop: '693px', marginLeft: '437px', width: '292px', position: 'absolute', border: 'none', background: 'none'}} value={funeral.d_place_death} />

                        </div>
                    </div>
                    <div className="a4-paper"  style={{backgroundSize: 'cover', backgroundImage: `url(${application2})`}}>
                        <div className="wrapper" style={{position: 'relative', height: '100%'}}>

                            <input name="ca_name" onChange={handleChange} type="text" style={{marginTop: '316px', marginLeft: '292px', width: '146px', position: 'absolute', border: 'none', background: 'none'}} value={funeral.ca_name} />
                            <input name="ca_last_name" onChange={handleChange} type="text" style={{marginTop: '316px', marginLeft: '517px', width: '212px', position: 'absolute', border: 'none', background: 'none'}} value={funeral.ca_last_name} />
                            
                            <input name="ca_birth" onChange={handleChange} type="text" style={{marginTop: '359px', marginLeft: '88px', width: '202px', position: 'absolute', border: 'none',background: 'none'}} value={funeral.ca_birth} />
                            <input name="ca_id" onChange={handleChange} type="text" style={{marginTop: '359px', marginLeft: '305px', width: '198px', position: 'absolute', border: 'none', background: 'none'}} value={funeral.ca_id} />
                            <input name="ca_nationality" onChange={handleChange} type="text" style={{marginTop: '359px', marginLeft: '517px', width: '212px', position: 'absolute', border: 'none', background: 'none'}} value={funeral.ca_nationality} />

                            <input name="ca_sr_1" onChange={handleChange} type="text" style={{marginTop: '382px', marginLeft: '97px', width: '15px', position: 'absolute', border: 'none', background: 'none'}} value={funeral.ca_sr_1} />
                            <input name="ca_sr_2" onChange={handleChange} type="text" style={{marginTop: '382px', marginLeft: '317px', width: '15px', position: 'absolute', border: 'none', background: 'none'}} value={funeral.ca_sr_2} />
                            
                            <input name="ca_street" onChange={handleChange} type="text" style={{marginTop: '418px', marginLeft: '161px', width: '390px', position: 'absolute', border: 'none', background: 'none'}} value={funeral.ca_street} />
                            <input name="ca_street_number" onChange={handleChange} type="text" style={{marginTop: '418px', marginLeft: '615px', width: '113px', position: 'absolute', border: 'none', background: 'none'}} value={funeral.ca_street_number} />

                            <input name="ca_zip" onChange={handleChange} type="text" style={{marginTop: '442px', marginLeft: '161px', width: '80px', position: 'absolute', border: 'none', background: 'none'}} value={funeral.ca_zip} />
                            <input name="ca_city" onChange={handleChange} type="text" style={{marginTop: '442px', marginLeft: '288px', width: '263px', position: 'absolute', border: 'none', background: 'none'}} value={funeral.ca_city} />
                            <input name="ca_phone" onChange={handleChange} type="text" style={{marginTop: '442px', marginLeft: '615px', width: '113px', position: 'absolute', border: 'none', background: 'none'}} value={funeral.ca_phone} />

                            {/* <div style={{marginTop: '725px', marginLeft: '162px', position: 'absolute'}}>{funeral.ca_address.split(" ").slice(0, -1).join(' ')}</div>
                            <div style={{marginTop: '725px', marginLeft: '618px', position: 'absolute'}}>{funeral.ca_address.split(" ").slice(-1)[0]}</div>

                            <div style={{marginTop: '750px', marginLeft: '162px', position: 'absolute'}}>{funeral.ca_zip}</div>
                            <div style={{marginTop: '750px', marginLeft: '290px', position: 'absolute'}}>{funeral.ca_city}</div>
                            <div style={{marginTop: '750px', marginLeft: '618px', position: 'absolute'}}>{funeral.ca_phone}</div>

                            <div style={{marginTop: '775px', marginLeft: '162px', position: 'absolute'}}>{funeral.ca_state}</div> */}

                            <input name="d_death" onChange={handleChange} type="text" style={{marginTop: '558px', marginLeft: '598px', width: '130px', position: 'absolute', border: 'none', background: 'none'}} value={funeral.d_death} />

                            <input name="date_funeral" onChange={handleChange} type="text" style={{marginTop: '583px', marginLeft: '227px', width: '130px', position: 'absolute', border: 'none', background: 'none'}} value={funeral.date_funeral} />
                            <input name="town_funeral" onChange={handleChange} type="text" style={{marginTop: '583px', marginLeft: '486px', width: '243px', position: 'absolute', border: 'none', background: 'none'}} value={funeral.town_funeral} />

                            <input name="application_date" onChange={handleChange} type="text" style={{marginTop: '664px', marginLeft: '150px', width: '113px', position: 'absolute', border: 'none', background: 'none'}} value={funeral.application_date} />
                            <input name="employee" onChange={handleChange} type="text" style={{marginTop: '664px', marginLeft: '420px', width: '178px', position: 'absolute', border: 'none', background: 'none'}} value={funeral.employee} />


                            {/* <div style={{marginTop: '842px', marginLeft: '620px', position: 'absolute'}}>{funeral.signatures.delegation_signature && <img src={funeral.signatures.delegation_signature} crossOrigin="anonymous" alt={'logo'} height='60 px'/>}</div> */}
                        </div>        
                    </div>
                    <div className="a4-paper"  style={{backgroundSize: 'cover', backgroundImage: `url(${application3})`}}>
                        <div className="wrapper" style={{position: 'relative', height: '100%'}}>
                            <div style={{bottom: '32px', position: 'absolute', display: 'flex', justifyContent: 'center', width: '100%', alignItems: 'center', gap: '20px'}}>
                                <img height={64} src={funus} alt={altPhoto}/>
                                <img height={64} src={mp} alt={altPhoto}/>
                            </div>
                        </div>
                    </div>
                </PDFExport>
            </div>
        }
        return <></>
    }

    return (
        <Container maxWidth="lg" className={classes.seeMore}>
            <div className={classes.root}>
                <Paper className={fixedHeightPaper}>
                    <Grid container>
                        <Grid xs={12} sm={4} md={3} lg={3} item container>
                            <Toolbar>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    hidden={true}
                                    className={classes.button}
                                    onClick={() => {
                                        setSuccess(true)
                                    }}
                                >
                                    Späť na detaily
                                </Button>
                            </Toolbar>
                        </Grid>
                        <Grid xs={12} sm={8} md={9} lg={9} item container justifyContent='flex-end'>
                            <Toolbar className={classes.toolbarButtons}>
                                <TextInput
                                    label="Miesto podpisu"
                                    name={'location'}
                                    value={location}
                                    onChange={handleLocation}
                                />
                                <DateInput
                                    label="Dátum podpisu"
                                    name={'date'}
                                    value={date}
                                    onChange={handleDate}
                                />
                                <ActionButton
                                    color="primary">
                                    <Tooltip title="Podpísať splnomocnenie">
                                        <BorderColorIcon
                                            fontSize="small"
                                            onClick={() => {
                                                handleShow();
                                            }}
                                        />
                                    </Tooltip>
                                </ActionButton>
                                <ActionButton
                                    color="primary">
                                    <Tooltip title="Pdf export">
                                        <PictureAsPdfIcon
                                            fontSize="small"
                                            onClick={handleExport}
                                        />
                                    </Tooltip>
                                </ActionButton>
                            </Toolbar>
                        </Grid>
                    </Grid>

                    <Divider/>
                    <Grid className={classes.marginTop}
                          container
                          spacing={0}
                          direction="column"
                          alignItems="center"
                          justifyContent="center"
                    >
                        {showDelegation()}
                    </Grid>
                </Paper>
            </div>
            <Popup
                title={'Podpis'}
                openPopup={show}
                setOpenPopup={setShow}
                maxW = 'xl'
                actions={<>
                    <Button color="primary" variant="outlined" onClick={() => {
                        setFullScreen(!fullScreen);
                    }
                    }>
                        {fullScreen ? 'Zmenšiť' : 'Zväčšiť'}
                    </Button>
                    <Button color="primary" variant="outlined" onClick={handleClear}>
                        Vyčistiť
                    </Button>
                    <Button color="primary" variant="outlined" onClick={handleSignature}>
                        Uložiť
                    </Button>
                </>}
            >
                <ReactSketchCanvas
                    ref={canvas}
                    strokeWidth={3}
                    strokeColor="blue"
                    style={{
                        border: "0.0625rem solid #9c9c9c",
                        borderRadius: "0.25rem",
                        width: fullScreen ? convertSize(props.width) : props.width === 'xs' ? "300px" : "500px",
                        height: fullScreen ? '600px' : props.width === 'xs' ? "125px" : "300px"
                    }}
                />
            </Popup>
            {success ? <Redirect to={{
                pathname: `/pohreb/${funeral_id.funeral_id}/info`,
                state: {from: props.location, number: 5}
            }}/> : <></>}
        </Container>
    )
}
export default withWidth()(ApplicationGenerator);

