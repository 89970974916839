import React, {useState}  from 'react'
import SelectInput from "../form/input/SelectInput";
import { Dialog, DialogTitle, DialogContent, DialogActions, Typography, makeStyles, IconButton } from '@material-ui/core'
import RegularButton from "../form/input/RegularButton";

const useStyles = makeStyles(theme => ({
    dialog: {
        padding: theme.spacing(2),
        position: 'absolute',
        top: theme.spacing(5)
    },
    dialogTitle: {
        textAlign: 'center'
    },
    dialogContent: {
        textAlign: 'center'
    },
    dialogAction: {
        justifyContent: 'center'
    },
    titleIcon: {
        backgroundColor: theme.palette.secondary.light,
        color: theme.palette.secondary.main,
        '&:hover': {
            backgroundColor: theme.palette.secondary.light,
            cursor: 'default'
        },
        '& .MuiSvgIcon-root': {
            fontSize: '8rem',
        }
    },
    titleIconPirmary: {
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.primary.main,
        '&:hover': {
            backgroundColor: theme.palette.primary.light,
            cursor: 'default'
        },
        '& .MuiSvgIcon-root': {
            fontSize: '8rem',
        }
    }
}))

export default function ConfirmDialog(props) {

    const { confirmDialog, setConfirmDialog, updateYear, updateMonth, year, month, color = null, buttons = true, dates = false, counter = null, downlaoder = null} = props;
    const classes = useStyles()

    const handleYear = (event) => {
        updateYear(event.target.value)
    }

    const handleMonth = (event) => {
        updateMonth(event.target.value)
    }

    return (
        <Dialog open={confirmDialog.isOpen} onClose={() => setConfirmDialog({ ...confirmDialog, isOpen: false })} classes={{ paper: classes.dialog }}>
            <DialogTitle className={classes.dialogTitle}>
                <IconButton disableRipple className={color ? classes.titleIconPirmary :classes.titleIcon}>
                    {props.icon}
                </IconButton>
            </DialogTitle>
            <DialogContent className={classes.dialogContent}>
                <Typography variant="h6">
                    {confirmDialog.title}
                </Typography>
                <Typography variant="subtitle2">
                    {confirmDialog.subTitle}
                </Typography>
            </DialogContent>
            {dates ? 
                <DialogContent className={classes.dialogContent}>
                    <SelectInput
                        label="Rok"
                        name={'year'}
                        value={year}
                        options={[
                            {value: '2021', title: '2021'},
                            {value: '2022', title: '2022'},
                            {value: '2023', title: '2023'},
                            {value: '2024', title: '2024'},
                        ]}
                        onChange={handleYear}
                    />
                    <SelectInput
                        label="Mesiac"
                        name={'month'}
                        value={month}
                        options={[
                            {value: '1', title: '1'},
                            {value: '2', title: '2'},
                            {value: '3', title: '3'},
                            {value: '4', title: '4'},
                            {value: '5', title: '5'},
                            {value: '6', title: '6'},
                            {value: '7', title: '7'},
                            {value: '8', title: '8'},
                            {value: '9', title: '9'},
                            {value: '10', title: '10'},
                            {value: '11', title: '11'},
                            {value: '12', title: '12'},
                        ]}
                        onChange={handleMonth}
                    />
                </DialogContent>
            : ''}
            {buttons ? 
                <DialogActions className={classes.dialogAction}>
                <RegularButton
                    text="Nie"
                    color="default"
                    onClick={() => setConfirmDialog({ ...confirmDialog, isOpen: false })} />
                <RegularButton
                    text="Áno"
                    color= {color? color: 'secondary'}
                    onClick={confirmDialog.onConfirm} />
                </DialogActions>
            : <DialogContent>
                {"Počet načítaných " + downlaoder + " z " + counter}
             </DialogContent>}
        </Dialog>
    )
}