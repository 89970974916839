import axios from "axios";

const API_URL = process.env.REACT_APP_FUNUS_URL;

const createFunerioFuneral = (data, token) => {
    return axios.post(API_URL + "wp-json/api/decedent", data, {
            headers: { 'Authorization': `Bearer ${token}`}
        },
    )
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    createFunerioFuneral
};