import React, {useEffect, useState} from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';
import AuthService from "../../services/auth.service";
import TextInput from "../form/input/TextInput";
import {NotificationManager} from "react-notifications";
import Cloud from '../../assets/cloud.jpg'
import {Link} from "react-router-dom";

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center" component={"div"}>
            {'Copyright © Moderné pohrebníctvo '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100vh',
    },
    image: {
        backgroundImage: `url(${Cloud})`,
        backgroundRepeat: 'no-repeat',
        backgroundColor:
            theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },
    paper: {
        margin: theme.spacing(8, 4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%',
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    margin: theme.spacing(1),
    marginTopMiddle: {
        marginTop: theme.spacing(35),
        [theme.breakpoints.between('sm', 'md')]: {
            marginTop: theme.spacing(10)
        },
        [theme.breakpoints.between('md', 'lg')]: {
            marginTop: theme.spacing(15)
        },
        [theme.breakpoints.between('xs', 'sm')]: {
            marginTop: theme.spacing(5)
        },
        [theme.breakpoints.down('xs')]: {
            marginTop: theme.spacing(5)
        },
    },
    marginTopLittle: {
        marginTop: theme.spacing(3)
    },
    marginTopLittleMore: {
        marginTop: theme.spacing(5),
        [theme.breakpoints.between('xs', 'md')]: {
            marginBottom: theme.spacing(5)
        },
        [theme.breakpoints.down('xs')]: {
            marginBottom: theme.spacing(5)
        },

    }
}));

export default function SignInSide(props) {
    const classes = useStyles();

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");

    const user = AuthService.getCurrentUser();

    const onChangeUsername = (e) => {
        const username = e.target.value;
        setUsername(username);
    };

    const onChangePassword = (e) => {
        const password = e.target.value;
        setPassword(password);
    };

    const handleLogin = (e) => {
        e.preventDefault();

        AuthService.login(username, password).then(
            () => {
                props.history.push("/");
                window.location.reload();
            },
            () => {
                setPassword('');
                NotificationManager.error('Nesprávne údaje!', 'Chyba', 5000);
            }
        );
    };

    useEffect(() => {
    }, []);

    return (
        <Grid container component="div" className={classes.root}>
            <CssBaseline/>
            {user ?
                <Grid container item xs={12} sm={12} md={12} className={classes.image}>

                    <Grid item xs={1} sm={2} md={2}>
                    </Grid>
                    <Grid item xs={10} sm={8} md={8} className={classes.marginTopMiddle}>
                        <Typography variant="h4" component="div">
                            Vitajte na stránke MODERN
                            POHREBNÍCTVO, prvá aplikácia pre
                            pohrebné služby
                        </Typography>
                        <Typography className={classes.marginTopLittle} variant="body1" component="div">
                            MODERNÉ POHREBNÍCTVO je webová aplikácia, ktorá pomáha modernizovať
                            pohrebné služby. Táto webová aplikácia slúži pohrebníctvam na kompletne
                            zaznamenanie a vybavenie pohrebu.
                            Tak ako každé odvetvie prechádza modernizáciou, rozhodli
                            sme sa s našou firmou MODERNÉ POHREBNÍCTVO priniesť túto modernizáciu
                            aj do sveta pohrebných služieb. Sme jediní na Slovensku, kto takúto aplikáciu ponúka.
                            Preto nás neváhajte kontaktovať a zmodernizujte aj Vy Vaše pohrebníctvo.
                            Chcete vedieť <Link to="/informacie" target="_blank"
                                                rel="noopener noreferrer">viac</Link>?
                        </Typography>
                    </Grid>
                    <Grid item xs={1} sm={2} md={2}>

                    </Grid>
                    <Grid item xs={1} sm={2} md={2}>
                    </Grid>
                    <Grid item xs={10} sm={8} md={8}>
                        <Typography variant="h4" component="div">
                            Kontakt
                        </Typography>
                        <Typography className={classes.marginTopLittle} variant="body1" component="div">
                            V prípade záujmu alebo akýchkoľvek otázok nás neváhajte kontaktovať: Marek Kandráč
                            - <span style={{fontWeight: "bold"}}>0907292958</span>
                            <br/>
                            V prípade technických otázok a pomoc pri riešení problémov kontaktujte: Samuel Elsner
                            - <span style={{fontWeight: "bold"}}>0908997258</span>
                            <br/>
                            Mailovou formou na: <span
                            style={{fontWeight: "bold"}}>modernepohrebnictvo@gmail.com</span>
                        </Typography>
                    </Grid>
                    <Grid item xs={1} sm={2} md={2}>
                    </Grid>
                </Grid>

                :
                //s prihlasenim
                <>
                    <Grid item xs={12} sm={12} md={7} className={classes.image}>
                        <Grid container>
                            <Grid item xs={1} sm={2} md={2}>
                            </Grid>
                            <Grid item xs={10} sm={8} md={8} className={classes.marginTopMiddle}>
                                <Typography variant="h4" component="h2">
                                    Vitajte na stránke MODERNÉ
                                    POHREBNÍCTVO, prvá aplikácia pre
                                    pohrebné služby
                                </Typography>
                                <Typography className={classes.marginTopLittle} variant="body1" component="h2">
                                    MODERNÉ POHREBNÍCTVO je webová aplikácia, ktorá pomáha modernizovať
                                    pohrebné služby. Táto webová aplikácia slúži pohrebníctvam na kompletne zaznamenanie
                                    a vybavenie pohrebu.
                                    Tak, ako každé odvetvie prechádza modernizáciou rozhodli sme sa s našou firmou
                                    MODERNÉ POHREBNÍCTVO priniesť túto modernizáciu aj do sveta pohrebných služieb.
                                    Sme jediný na Slovensku kto takúto aplikáciu ponúka. Preto nás neváhajte kontaktovať
                                    a
                                    zmodernizujte aj Vy Vašeš pohrebníctvo. Chcete vedieť <Link to="/informacie"
                                                                                               target="_blank"
                                                                                               rel="noopener noreferrer"> viac? </Link>
                                </Typography>
                            </Grid>
                            <Grid item xs={1} sm={2} md={2}>
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item xs={1} sm={2} md={2}>
                            </Grid>
                            <Grid item xs={10} sm={8} md={8} className={classes.marginTopLittleMore}>
                                <Typography variant="h4" component="h2">
                                    Kontakt
                                </Typography>
                                <Typography className={classes.marginTopLittle} variant="body1" component="h2">
                                    V prípade záujmu alebo akýchkoľvek otázok nás neváhajte kontaktovať na tel. č.: <span style={{fontWeight: "bold"}}>0907292958</span>
                                    <br/>
                                    V prípade technických otázok a pomoc pri riešení problémov nás kontaktujte na tel. č.: <span style={{fontWeight: "bold"}}>0908997258</span>
                                    <br/>
                                    Mailovou formou na: <span
                                    style={{fontWeight: "bold"}}>modernepohrebnictvo@gmail.com</span>
                                </Typography>
                            </Grid>
                            <Grid item xs={1} sm={2} md={2}>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={5} component={Paper} elevation={6} square>
                        <div className={classes.paper}>
                            <Avatar className={classes.avatar}>
                                <LockOutlinedIcon/>
                            </Avatar>
                            <Typography component="h1" variant="h5">
                                Prihláste sa
                            </Typography>
                            <form className={classes.form} onSubmit={handleLogin}>
                                <TextInput
                                    label="Prihlasovacie meno"
                                    name={'username'}
                                    id="username"
                                    value={username}
                                    onChange={onChangeUsername}
                                    autoComplete="username"
                                    autoFocus
                                    fullWidth
                                    margin="normal"
                                    required
                                />
                                <TextInput
                                    margin="normal"
                                    label="Heslo"
                                    type="password"
                                    name={'password'}
                                    value={password}
                                    fullWidth
                                    required
                                    onChange={onChangePassword}
                                />
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    className={classes.submit}
                                >
                                    Prihlásiť sa
                                </Button>
                            </form>
                            {Copyright()}
                        </div>
                    </Grid>
                </>
            }
        </Grid>
    );
}