import React, {useEffect, useState} from 'react';
import clsx from 'clsx';
import {createTheme, makeStyles} from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import {helperListItems, docListItems, mainListItems, secondaryListItems} from './listItems';
import MainPage from "../presentation/MainPage";
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import Box from "@material-ui/core/Box";
import {Link, Redirect, Route, Switch} from "react-router-dom";
import SignIn from "../auth/SignIn";
import Register from "../auth/Register";
import DelegationPDF from "../generators/DelegationGenerator";
import DelegationGenerator from "../generators/DelegationGenerator";
import ConfirmationGenerator from "../generators/ConfirmationGenerator";
import ApplicationGenerator from "../generators/ApplicationGenerator";
import UrnApplication from "../generators/UrnApplication";
import DocumentsSigningGenerator from "../generators/DocumentsSigningGenerator";
import AuthService from "../../services/auth.service";
import FuneralForm from "../form/FuneralForm";
import {NotificationContainer, NotificationManager} from "react-notifications";
import 'react-notifications/lib/notifications.css';
import FullList from "../presentation/FullList";
import TaskList from "../presentation/TaskList";
import TasksDashboard from "../settings/TasksDashboard";
import {ThemeProvider} from '@material-ui/core';
import FuneralDetails from "../presentation/FuneralDetails";
import PlaceForm from "../form/PlaceForm";
import EvidenceForm from "../form/EvidenceForm";
import ProfileDashboard from "../settings/ProfileDashboard";
import ServiceDashboard from "../settings/ServicesDashboard";
import ServicesForm from "../form/ServicesForm";
import ProductsDashboard from "../settings/ProductsDashboard";
import ProductsForm from "../form/ProductsForm";
import AdminLogs from "../presentation/AdminLogs";
import ExportEvidence from "../settings/ExportEvidence";
import Information from "../presentation/Information";
import DefinedTexts from "../settings/DefinedTexts";
import InformationPaperGenerator from "../generators/InformationPaperGenerator";
import CoffinLabelGenerator from "../generators/CoffinLabelGenerator";
import CardsGenerator from "../generators/CardsGenerator";
import GuideExcel from "../presentation/GuideExcel";
import GDPRpolicy from "../presentation/GDPRpolicy";
import Policy from "../presentation/Policy";
import PricesGenerator from "../generators/PricesGenerator";

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center" component={"div"}>
            {'Copyright © '}
            <br/>
            {'Moderné pohrebníctvo '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
    },
    toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    menuButtonHidden: {
        display: 'none',
    },
    title: {
        flexGrow: 1,
    },
    drawerPaper: {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9),
        },
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        // height: '100vh',
        overflow: 'auto',
        paddingTop: theme.spacing(4),
    },
    contentNoTop: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    mtTop: {
      marginTop:  theme.spacing(4)
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    fixedHeight: {
        height: 340,
    },
    hidde: {
        [theme.breakpoints.between('xs', 'sm')]: {
            display : 'none'
        },
        [theme.breakpoints.down('xs')]: {
            display : 'none'
        },
    }
}));


const theme = createTheme({
    palette: {
        primary: {
            main: "#333996",
            light: '#3c44b126'
        },
        secondary: {
            main: "#f83245",
            light: '#f8324526'
        },
        background: {
            default: "#f4f5fd"
        },
    },
    overrides: {
        MuiAppBar: {
            root: {
                transform: 'translateZ(0)'
            }
        }
    },
    props: {
        MuiIconButton: {
            disableRipple: true
        }
    }
})

export default function Dashboard(props) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(true);
    const handleDrawerOpen = () => {
        setOpen(true);
    };
    const handleDrawerClose = () => {
        setOpen(false);
    };
    const [showModeratorBoard, setShowModeratorBoard] = useState(false);
    const [showAdminBoard, setShowAdminBoard] = useState(false);
    const [currentUser, setCurrentUser] = useState(undefined);

    useEffect(() => {
        const user = AuthService.getCurrentUser();

        if (user) {
            setCurrentUser(user);
            if (user.roles.includes("ROLE_USER")) {
                logOut();
                NotificationManager.warning('Potrebné sa znova prihlásiť!', 'Upozornenie', 5000);

            }
            setShowModeratorBoard(user.roles.includes("ROLE_MODERATOR"));
            setShowAdminBoard(user.roles.includes("ROLE_ADMIN"));
        }
    }, []);

    const logOut = () => {
        AuthService.logout();
        setCurrentUser(undefined);
    };

    const PrivateModeratorRoute = ({component: Component, ...rest}) => (
        <Route {...rest} render={(props) => (
            showModeratorBoard === true
                ? <Component {...props} />
                : <Redirect to={{
                    pathname: '/',
                    state: {from: props.location}
                }}/>
        )}/>
    )

    const PrivateAdminRoute = ({component: Component, ...rest}) => (
        <Route {...rest} render={(props) => (
            showAdminBoard === true
                ? <Component {...props} />
                : <Redirect to={{
                    pathname: '/',
                    state: {from: props.location}
                }}/>
        )}/>
    )

    return (
        <ThemeProvider theme={theme}>
            <div className={classes.root}>
                <CssBaseline/>
                {currentUser &&
                <>
                    <AppBar position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)}>
                        <Toolbar className={classes.toolbar}>
                            <IconButton
                                edge="start"
                                color="inherit"
                                aria-label="open drawer"
                                onClick={handleDrawerOpen}
                                className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
                            >
                                <MenuIcon/>
                            </IconButton>
                            <Typography component="h1" variant="h5" color="inherit" noWrap className={classes.title}>
                                Moderné pohrebníctvo
                            </Typography>
                            {showAdminBoard && <Link to="/logs" style={{textDecoration: 'none', color: 'white'}}>
                                <IconButton color="inherit">
                                    <Typography component="h1" variant="h6" color="inherit" noWrap
                                                className={classes.title}>
                                        Logy
                                    </Typography>
                                </IconButton>
                            </Link>}
                            {showAdminBoard && <Link to="/register" style={{textDecoration: 'none', color: 'white'}}>
                                <IconButton color="inherit">
                                    <Typography component="h1" variant="h6" color="inherit" noWrap
                                                className={classes.title}>
                                        Registracia
                                    </Typography>
                                </IconButton>
                            </Link>}
                            <Link to="/" style={{textDecoration: 'none', color: 'white'}}>
                                <IconButton color="inherit" onClick={logOut}>
                                    <ExitToAppIcon/>
                                    <Typography component="h1" variant="h6" color="inherit" noWrap
                                                className={classes.title}>
                                        Odhlásiť sa
                                    </Typography>
                                </IconButton>
                            </Link>
                        </Toolbar>
                    </AppBar>
                    <Drawer
                        variant="permanent"
                        classes={{
                            paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
                        }}
                        open={open}
                    >
                        <div className={classes.toolbarIcon}>
                            <IconButton onClick={handleDrawerClose}>
                                <ChevronLeftIcon/>
                            </IconButton>
                        </div>
                        <Divider/>
                        <List>{mainListItems}</List>
                        <Divider/>
                        <List>{helperListItems}</List>
                        <Divider/>
                        <List>{secondaryListItems}</List>
                        <Divider/>
                        <List>{docListItems}</List>
                        <Divider/>

                        { open && <Box pt={4} className={classes.hidde}>
                            <Copyright/>
                        </Box>}

                    </Drawer>
                </>
                }
                <main
                    {...(currentUser ? {
                        className: classes.content
                    } : {className: classes.contentNoTop})}>
                    <div>
                        <Switch>
                            <Route exact path="/" component={SignIn}/>
                            <Route exact path="/informacie" component={Information}/>
                            <Route exact path="/gdpr" component={GDPRpolicy}/>
                            <Route exact path="/podmienky" component={Policy}/>
                            <Route exact path="/navod_excel" component={GuideExcel}/>
                            <PrivateAdminRoute exact path="/register" component={Register}/>
                            <PrivateAdminRoute exact path="/logs" component={AdminLogs}/>
                            <PrivateModeratorRoute path="/domov" component={MainPage}/>
                            <PrivateModeratorRoute path="/profil" component={ProfileDashboard}/>
                            <PrivateModeratorRoute path="/definovane_texty" component={DefinedTexts}/>
                            <PrivateModeratorRoute path="/splnomocnenie" component={DelegationPDF}/>
                            <PrivateModeratorRoute path="/sluzby" component={ServiceDashboard}/>
                            <PrivateModeratorRoute path="/produkty" component={ProductsDashboard}/>
                            <PrivateModeratorRoute path="/generovanie_evidencie" component={ExportEvidence}/>
                            <PrivateModeratorRoute path="/novy-pohreb" component={(props) => <FuneralForm {...props}
                                                                                                          key={window.location.pathname}/>}/>
                            <PrivateModeratorRoute path="/pohreb/:funeral_id/upravit"
                                                   component={(props) => <FuneralForm {...props}
                                                                                      key={window.location.pathname}/>}/>
                            <PrivateModeratorRoute path="/zoznam" component={FullList} {...props}
                                                   key={window.location.pathname}/>
                            <PrivateModeratorRoute path="/pohreb/:funeral_id/miesto"
                                                   component={(props) => <PlaceForm {...props}
                                                                                    key={window.location.pathname}/>}/>
                            <PrivateModeratorRoute path="/pohreb/miesto/:funeral_id/:place_id"
                                                   component={(props) => <PlaceForm {...props}
                                                                                    key={window.location.pathname}/>}/>
                            <PrivateModeratorRoute path="/pohreb/:funeral_id/info" component={FuneralDetails} {...props}
                                                   key={window.location.pathname}/>
                            <PrivateModeratorRoute path="/pohreb/:funeral_id/splnomocnenie"
                                                   component={DelegationGenerator}/>
                            <PrivateModeratorRoute path="/pohreb/:funeral_id/potvrdenie"
                                                   component={ConfirmationGenerator}/>
                            <PrivateModeratorRoute path="/pohreb/:funeral_id/ziadost"
                                                   component={ApplicationGenerator}/>
                            <PrivateModeratorRoute path="/pohreb/:funeral_id/ziadost_urna"
                                                   component={UrnApplication}/>
                            <PrivateModeratorRoute path="/pohreb/:funeral_id/gdpr" component={DocumentsSigningGenerator}/>
                            <PrivateModeratorRoute path="/pohreb/:funeral_id/informacny_list" component={InformationPaperGenerator}/>
                            <PrivateModeratorRoute path="/pohreb/:funeral_id/stitok_rakva" component={CoffinLabelGenerator}/>
                            <PrivateModeratorRoute path="/pohreb/:funeral_id/cennik" component={PricesGenerator}/>
                            <PrivateModeratorRoute path="/pohreb/:funeral_id/karticky" component={CardsGenerator}/>

                            <PrivateModeratorRoute path="/pohreb/produkty/:funeral_id"
                                                   component={(props) => <ProductsForm {...props}
                                                                                       key={window.location.pathname}/>}/>
                            <PrivateModeratorRoute path="/pohreb/:funeral_id/evidencia"
                                                   component={(props) => <EvidenceForm {...props}
                                                                                       key={window.location.pathname}/>}/>
                            <PrivateModeratorRoute path="/pohreb/evidencia/:funeral_id/:evidence_id"
                                                   component={(props) => <EvidenceForm {...props}
                                                                                       key={window.location.pathname}/>}/>
                            <PrivateModeratorRoute path="/pohreb/sluzby/:funeral_id"
                                                   component={(props) => <ServicesForm {...props}
                                                                                       key={window.location.pathname}/>}/>
                            <PrivateModeratorRoute path="/ulohy" component={TaskList} {...props}
                                                   key={window.location.pathname}/>

                            <PrivateModeratorRoute path="/definovane_ulohy" component={TasksDashboard} {...props}
                                                   key={window.location.pathname}/>
                        </Switch>
                    </div>
                    <div className={classes.mtTop}/>
                    <NotificationContainer/>
                </main>
            </div>
        </ThemeProvider>
    );
}