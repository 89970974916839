import React from 'react';
import {FormControl, FormHelperText, InputLabel, MenuItem, TextField} from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';

export default function AutocompleteInput(props) {

    const {name, label, options, value, onChange, error = null, renderInput} = props;

    return (
        <FormControl
            variant='outlined'
            {...(error && {
                error: true
            })}
        >
            <Autocomplete
                name={name}
                label={label}
                onChange={onChange}
                options={options}
                defaultValue={options.find(element => element.title == value)}
                getOptionLabel={(option) => option.title}
                renderInput={(params) => <TextField {...params} style={{ margin: "0px" }} label={label} variant="outlined" {...(error && {
                    error: true
                })}/>}
            />
            {error && <FormHelperText>{error}</FormHelperText>}
        </FormControl>
    )

}