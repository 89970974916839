import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import clsx from "clsx";
import UserService from "../../services/user.service";
import FunerioService from "../../services/funerio.service";
import FlowiiService from "../../services/flowii.service";
import { logOut} from "../../helpers/logout";
import PropTypes from 'prop-types';
import Box from "@material-ui/core/Box";
import {
    Button,
    Divider, InputAdornment,
    TextField,
    Toolbar,
    Typography,
    withWidth,
    CardMedia,
    Card,
    CardContent,
    CardActions,
    IconButton,
    LinearProgress,
    withStyles
} from "@material-ui/core";
import { PhotoCamera } from "@material-ui/icons";
import altPhoto from "../../assets/trans.png";
import { Lightbox } from "react-modal-image";

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import AppBar from '@material-ui/core/AppBar';
import {Redirect} from "react-router-dom";

import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import PlaceIcon from '@material-ui/icons/Place';
import SettingsApplicationsIcon from '@material-ui/icons/SettingsApplications';
import AllInboxIcon from '@material-ui/icons/AllInbox';
import StorageIcon from '@material-ui/icons/Storage';
import DescriptionIcon from '@material-ui/icons/Description';
import Tooltip from "@material-ui/core/Tooltip";
import DeleteIcon from "@material-ui/icons/Delete";
import ActionButton from "../form/input/ActionButton";
import ConfirmDialog from "./ConfirmDialog";
import {NotificationManager} from "react-notifications";
import EditIcon from '@material-ui/icons/Edit';
import Title from "../dashboard/Title";
import DocumentsDetails from "./DocumentsDetails";
import TaskDetails from "./TaskDetails";
import ShareIcon from '@material-ui/icons/Share';
import Popup from "./Popup";
import TextInput from "../form/input/TextInput";
import AssignmentIcon from '@material-ui/icons/Assignment';

const useStyles = makeStyles((theme) => ({
    appBarSpacer: theme.mixins.toolbar,
    root: {
        flexGrow: 1,
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
    seeMore: {
        marginTop: theme.spacing(8),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    fixedHeight: {
        height: 340,
    },
    buttonPosition: {
        position: 'absolute',
        right: theme.spacing(3)
    },
    toolbarButtons: {
        marginLeft: 'auto',
    },
    marginElement: {
        margin: theme.spacing(2)
    },
    marginTop: {
        marginTop: theme.spacing(2)
    },
    icon: {
        marginRight: theme.spacing(2),
    },
    heroContent: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(8, 0, 6),
    },
    heroButtons: {
        marginTop: theme.spacing(4),
    },
    cardGrid: {
        paddingTop: theme.spacing(8),
        paddingBottom: theme.spacing(8),
    },
    card: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    cardMedia: {
        paddingTop: '56.25%', // 16:9
    },
    cardContent: {
        flexGrow: 1,
    },
}));

function TabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-force-tabpanel-${index}`}
            aria-labelledby={`scrollable-force-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography component={'span'}>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `scrollable-force-tab-${index}`,
        'aria-controls': `scrollable-force-tabpanel-${index}`,
    };
}

// eslint-disable-next-line no-extend-native
Date.prototype.toShortFormat = function () {

    let monthNames = ["01", "02", "03", "04",
        "05", "06", "07", "08",
        "09", "10", "11", "12"];

    let day = this.getDate() < 10 ? `0${this.getDate()}` : this.getDate();

    let monthIndex = this.getMonth();
    let monthName = monthNames[monthIndex];

    let year = this.getFullYear();

    if (isNaN(this.getDate())) {
        return ''
    }

    return `${day}.${monthName}.${year}`;
}

// eslint-disable-next-line no-extend-native
Date.prototype.yyyyMMddFormat = function () {

    let monthNames = ["01", "02", "03", "04",
        "05", "06", "07", "08",
        "09", "10", "11", "12"];

    let day = this.getDate() < 10 ? `0${this.getDate()}` : this.getDate();

    let monthIndex = this.getMonth();
    let monthName = monthNames[monthIndex];

    let year = this.getFullYear();

    if (isNaN(this.getDate())) {
        return ''
    }

    return `${year}-${monthName}-${day}.`;
}

// eslint-disable-next-line no-extend-native
Date.prototype.timeFormat= function () {

    let minutes = this.getMinutes() < 10 ? `0${this.getMinutes()}` : this.getMinutes();
    let hours = this.getHours() < 10 ? `0${this.getHours()}` : this.getHours();

    if (isNaN(this.getDate())) {
        return ''
    }

    return `${hours}:${minutes}`;
}

// eslint-disable-next-line no-extend-native
Date.prototype.toShortFormatWithTimeExcel = function () {

    let monthNames = ["01", "02", "03", "04",
        "05", "06", "07", "08",
        "09", "10", "11", "12"];

    let dayNames = ["01", "02", "03", "04",
        "05", "06", "07", "08",
        "09", "10", "11", "12",
        "13", "14", "15", "16",
        "17", "18", "19", "20",
        "21", "22", "23", "24",
        "25", "26", "27", "28",
        "29", "30", "31"];

    let day = this.getDate();
    day = dayNames[day - 1];

    let monthIndex = this.getMonth();
    let monthName = monthNames[monthIndex];

    let year = this.getFullYear();

    let minutes = this.getMinutes() < 10 ? `0${this.getMinutes()}` : this.getMinutes();
    let hours = this.getHours() < 10 ? `0${this.getHours()}` : this.getHours();

    if (isNaN(this.getDate())) {
        return ''
    }

    return `${day}.${monthName}.${year} ${hours}:${minutes}`;
}

function FuneralDetails(props) {
    const classes = useStyles();
    const fixedHeightPaper = clsx(classes.paper);
    const [value, setValue] = useState(0);
    const [editNotes, setEditNotes] = useState(false);
    const [user, setuser] = useState(null);
    const [refetch, setRefetch] = useState(false);
    const [funeral, setFuneral] = useState({
        contracting_authority: ' ',
        funeral_type: ' ',
        ca_address: ' ',
        ca_zip: '',
        ca_city: '',
        ca_state: '',
        ca_nationality: '',
        ca_email: ' ',
        ca_phone: ' ',
        ca_id: ' ',
        ca_birth: ' ',
        ca_relationship: ' ',
        deceased: ' ',
        d_address: ' ',
        d_zip: ' ',
        d_city: ' ',
        d_state: ' ',
        d_place_birth: ' ',
        d_birth: null,
        d_death: null,
        d_place_death: ' ',
        d_id: ' ',
        d_gender: ' ',
        d_marital_status: ' ',
        d_nationality: ' ',
        d_education: ' ',
        d_last_job: ' ',
        d_wife_husband: ' ',
        d_wife_husband_id: ' ',
        planned_pickup_place: ' ',
        planned_pickup: null,
        town_funeral: ' ',
        place_funeral: ' ',
        date_funeral: null,
        coffin_arrival: null,
        coffin_arrival_place: ' ',
        picked_items: ' ',
        decease_photo: '',
        notes: ' ',
        service_entities: [],
        product_entities: []
    });
    const [place, setPlace] = useState({
        cremation: ' ',
        new_monument: ' ',
        depth_hole: ' ',
        uncover_grave: ' ',
        cemetery: ' ',
        place_number: ' ',
        grave: ' ',
        who_is_buried: ' ',
        period_buried: ' ',
        covering_cement: ' ',
        exhumation: ' ',
        owner: ' '
    });
    const [evidence, setEvidence] = useState({
        items: ' ',
        placement_fridge: null,
        autopsy: ' ',
        d_dangerous_disease: ' ',
        car_id: ' ',
        temperature: ' ',
        disinfection: ' ',
        disinfection_date: null,
        employee: ' ',
        employee_money: ' ',
        placement_of_decedent: ' '
    });
    const [confirmDialog, setConfirmDialog] = useState({isOpen: false, title: '', subTitle: ''})
    const [confirmDialogFun, setConfirmDialogFun] = useState({isOpen: false, title: '', subTitle: ''})
    const [redirects, setRedirects] = useState({
        list: false,
        editFuneral: false,
        editPlace: false,
        editServices: false,
        editProducts: false,
        editEvidence: false,
        createPlace: false,
        createEvidence: false

    });

    const [selectedFile, setSelectedFile] = useState({
        grave_hole_photo: null,
        grave_before_ceremony: null,
        grave_after_ceremony: null,
        grave_ceremony: null,
        grave_damage_1: null,
        grave_damage_2: null,
        grave_exterier_1: null,
        grave_exterier_2: null,
        grave_exterier_3: null,
        grave_exterier_4: null,
        grave_exterier_5: null,
        grave_exterier_6: null,
        grave_exterier_7: null,
        grave_exterier_8: null,
        grave_exterier_9: null,
        grave_exterier_10: null,
    });

    const [progress, setProgress] = useState({
        grave_hole_photo: null,
        grave_before_ceremony: null,
        grave_after_ceremony: null,
        grave_ceremony: null,
        grave_damage_1: null,
        grave_damage_2: null,
        grave_exterier_1: null,
        grave_exterier_2: null,
        grave_exterier_3: null,
        grave_exterier_4: null,
        grave_exterier_5: null,
        grave_exterier_6: null,
        grave_exterier_7: null,
        grave_exterier_8: null,
        grave_exterier_9: null,
        grave_exterier_10: null,
    });

    const [loaded, setLoaded] = useState({
        grave_hole_photo: null,
        grave_before_ceremony: null,
        grave_after_ceremony: null,
        grave_ceremony: null,
        grave_damage_1: null,
        grave_damage_2: null,
        grave_exterier_1: null,
        grave_exterier_2: null,
        grave_exterier_3: null,
        grave_exterier_4: null,
        grave_exterier_5: null,
        grave_exterier_6: null,
        grave_exterier_7: null,
        grave_exterier_8: null,
        grave_exterier_9: null,
        grave_exterier_10: null,
    });

    const [isOpen, setIsOpen] = useState({
        grave_hole_photo: null,
        grave_before_ceremony: null,
        grave_after_ceremony: null,
        grave_ceremony: null,
        grave_damage_1: null,
        grave_damage_2: null,
        grave_exterier_1: null,
        grave_exterier_2: null,
        grave_exterier_3: null,
        grave_exterier_4: null,
        grave_exterier_5: null,
        grave_exterier_6: null,
        grave_exterier_7: null,
        grave_exterier_8: null,
        grave_exterier_9: null,
        grave_exterier_10: null,
    });

    const [notes, setNotes] = useState('');

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleNotes = (e) => {
        setNotes(e.target.value);
    };

    const handleRedirects = (name) => {
        setRedirects({...redirects, [name]: true});
    }
    let user_local = JSON.parse(localStorage.getItem('user')).id;

    useEffect(() => {
        UserService.getFuneral(props.match.params.funeral_id).then(
            (response) => {
                setFuneral(response.data);
                if (response.data.place) setPlace(response.data.place);
                if (response.data.evidence) setEvidence(response.data.evidence);
                if (props.location.state.number) {
                    setValue(props.location.state.number);
                }
            },
            (e) => {
                if (e.response.status === 401) {
                    logOut(props);
                }
            }
        );
        UserService.getUser(user_local).then(
            (response) => {
                setuser(response.data)
            },
            (e) => {
                if (e.response.status === 401) {
                    logOut(props);
                    NotificationManager.warning('Potrebné sa znova prihlásiť!', 'Upozornenie', 5000);
                }
            }
        );

    }, [props, user_local, refetch]);

    const handleEditNotes = () => {
        UserService.editNotes({_id: funeral._id, notes: notes}).then(
            () => {
                NotificationManager.success('Poznámky upravené!', 'Upravené', 5000);
                setFuneral({...funeral, notes: notes});
                setNotes('');
                setEditNotes(false);
            },
            (e) => {
                if (e.response.status === 401) {
                    logOut(props);
                    NotificationManager.warning('Potrebné sa znova prihlásiť!', 'Upozornenie', 5000);
                }
                NotificationManager.error('Poznámky sa nepodarilo upraviť!', 'Chyba', 5000);
            }
        );
    }

    const handleDelete = (id) => {
        setConfirmDialog({
            ...confirmDialog,
            isOpen: false
        })
        UserService.deleteFuneral(id).then(
            () => {
                NotificationManager.success('Pohreb vymazaný!', 'Vymazané', 5000);
                handleRedirects('list');
            },
            (e) => {
                if (e.response.status === 401) {
                    logOut(props);
                    NotificationManager.warning('Potrebné sa znova prihlásiť!', 'Upozornenie', 5000);
                }
                NotificationManager.error('Pohreb sa nepodarilo odstrániť!', 'Chyba', 5000);
            }
        );
    }

    const createFunerioPost = (data) => {
        setConfirmDialogFun({
            ...confirmDialogFun,
            isOpen: false
        })
        FunerioService.createFunerioFuneral(data, user.funerio_token).then(
            (response) => {
                window.open(response.data.url, '_blank');
                UserService.updateFuneral({...funeral, funerio_id: response.data.id}).then(
                    (r) => {
                        setFuneral({...funeral, funerio_id: response.data.id});
                    },
                    (e) => {
                        if (e.response.status === 401) {
                            logOut(props);
                            NotificationManager.warning('Potrebné sa znova prihlásiť!', 'Upozornenie', 5000);
                        }
                    }
                );
            },
            (e) => {
                if (e.response.status === 401) {
                    logOut(props);
                    NotificationManager.warning('Potrebné sa znova prihlásiť!', 'Upozornenie', 5000);
                }
                if(e.response.status === 400){
                    NotificationManager.warning('Ak sa toto hlásenie opakuje kontaktujte podporu.', 'Chyba', 5000);
                } else {
                    NotificationManager.error('Pohreb sa nepodarilo odoslať na www.funus.sk!', 'Chyba', 5000);
                }
            }
        );
    }

    const createFlowiiOrder = (funeral_id) => {
        setConfirmDialogFun({
            ...confirmDialogFun,
            isOpen: false
        })
        FlowiiService.createFlowiiOrder(funeral_id).then(
            (response) => {
                window.open(response.data.url, '_blank');
            },
            (e) => {
                if (e.response.status === 401) {
                    logOut(props);
                    NotificationManager.warning('Potrebné sa znova prihlásiť!', 'Upozornenie', 5000);
                }
                if(e.response.status === 400){
                    NotificationManager.warning('Ak sa toto hlásenie opakuje kontaktujte podporu.', 'Chyba', 5000);
                } else {
                    NotificationManager.error('Pohreb sa nepodarilo odoslať na flowii!', 'Chyba', 5000);
                }
            }
        );
    }

    const showValue = (item) => {
        if (item.text) {
            return item.text;
        }
        if (item.text_cost) {
            return `${item.text_cost.text} - ${item.text_cost.cost}`;
        }
        if (item.number) {
            return item.number;
        }
        if (item.yes_no !== undefined) {
            return item.yes_no ? 'Áno' : 'Nie';
        }
        return '';
    }

    const calculateCost = (entities) => {
        let sum = 0;
        entities.forEach(entity => {
            if (entity.count_cost) {
                if (entity.number) {
                    sum += entity.number;
                }
                if(entity.text_cost) {
                    sum += entity.text_cost.cost;
                }
            }
        })
        return sum;
    }

    const closeLightbox = (name) => () => {
        setIsOpen({ ...isOpen, [name]: !isOpen[name] });
    };

    const singleFileChangedHandler = (name) => (event) => {
        setSelectedFile({ ...selectedFile, [name]: event.target.files[0] });
        setLoaded({ ...loaded, [name]: "custom-file-upload-loaded" });
    };

    const handleProgress = (name) => (data) => {
        setProgress({
            ...progress,
            [name]: Math.round((100 * data.loaded) / data.total),
        });
    };

    const saveImage = (url) => () => {
        let FileSaver = require("file-saver");
        let fileName = url.substring(url.lastIndexOf("/") + 1);
        FileSaver.saveAs(url, fileName);
    };

    const substrOfName = (str) => {
        if (str.length > 12) {
            return (
                str.substr(0, 4) +
                "..." +
                str.substr(str.length - 8, str.length - 1)
            );
        }
        return str;
    };

    const BorderLinearProgress = withStyles(() => ({
        root: {
            height: 15,
            borderRadius: 5,
        },
        colorPrimary: {
            backgroundColor: "#EEEEEE",
        },
        bar: {
            borderRadius: 5,
            backgroundColor: "#1a90ff",
        },
    }))(LinearProgress);

    const documentCard = (photo, photoText, selectedFile, title, method) => {
        return (
            <Grid item xs={12} sm={12} md={6} lg={4}>
                <Card className={classes.card}>
                    <CardMedia
                        className={classes.cardMedia}
                        image={photo ? photo : altPhoto}
                        title="Klik pre otvorenie!"
                        onClick={closeLightbox(photoText)}
                    />
                    {isOpen[photoText] && photo && (
                        <Lightbox
                            medium={photo}
                            large={photo}
                            onClose={closeLightbox(photoText)}
                            showRotate={true}
                        />
                    )}
                    <CardContent className={classes.cardContent}>
                        <Typography gutterBottom variant="h5" component="h2">
                            {title}
                        </Typography>
                        <Typography>
                            Nahrajte fotku (max 20MB)
                        </Typography>
                    </CardContent>

                    <CardActions>
                        <input
                            accept="image/*"
                            style={{ display: "none" }}
                            id={`contained-button-${photoText}`}
                            multiple
                            type="file"
                            onChange={singleFileChangedHandler(photoText)}
                        />
                        <label htmlFor={`contained-button-${photoText}`}>
                            <IconButton
                                color="primary"
                                aria-label="upload picture"
                                component="span"
                            >
                                <PhotoCamera />
                            </IconButton>
                        </label>
                        <div className="file-name">
                            {selectedFile &&
                                selectedFile.name &&
                                substrOfName(selectedFile.name)}
                        </div>
                    </CardActions>

                    {progress[photoText] && (
                        <CardActions>
                            <Box
                                className="my20"
                                display="flex"
                                alignItems="center"
                            >
                                <Box width="100%" mr={1}>
                                    <BorderLinearProgress
                                        variant="determinate"
                                        value={progress[photoText]}
                                    />
                                </Box>
                                <Box minWidth={35}>
                                    <Typography
                                        variant="body2"
                                        color="textSecondary"
                                    >{`${progress[photoText]}%`}</Typography>
                                </Box>
                            </Box>
                        </CardActions>
                    )}

                    <CardActions>
                        <Button onClick={method} size="small" color="primary">
                            {photo ? "Upraviť" : "Nahrať"}
                        </Button>
                        {photo && (
                            <Button
                                size="small"
                                color="primary"
                                onClick={saveImage(photo)}
                            >
                                Stiahnúť
                            </Button>
                        )}
                    </CardActions>
                </Card>
            </Grid>
        );
    };

    const singleFileUploadHandler = (name) => () => {
        const data = new FormData();

        if (selectedFile[name]) {
            data.append(
                "someimage",
                selectedFile[name],
                `${funeral.d_id?.split("/").join("")}_${name}.${selectedFile[
                    name
                ].name
                    .split(".")
                    .pop()}`
            );
            data.append("funeral_id", funeral._id);
            data.append("tag", name);
            UserService.addPhoto(data, handleProgress(name))
                .then((response) => {
                    if (200 === response.status) {
                        // If file size is larger than expected.
                        if (response.data.error) {
                            if (
                                "LIMIT_FILE_SIZE" === response.data.error.code
                            ) {
                                NotificationManager.error(
                                    "Priveľký súbor!",
                                    "Chyba",
                                    5000
                                );
                                setLoaded({ ...loaded, [name]: "" });
                                setSelectedFile({
                                    ...selectedFile,
                                    [name]: null,
                                });
                                setProgress({ ...progress, [name]: null });
                            } else {
                                NotificationManager.error(
                                    "Neočakavaná chyba!",
                                    "Chyba",
                                    5000
                                );
                                setLoaded({ ...loaded, [name]: "" });
                                setSelectedFile({
                                    ...selectedFile,
                                    [name]: null,
                                });
                                setProgress({ ...progress, [name]: null });
                            }
                        } else {
                            let fileName = response.data;
                            setTimeout(function () {
                                setProgress({ ...progress, [name]: null });
                            }, 1500);
                            setRefetch(!refetch);
                            setLoaded({ ...loaded, [name]: "" });
                            setSelectedFile({ ...selectedFile, [name]: null });
                            NotificationManager.success(
                                "Dokument úspešne uložený!",
                                "Uložené",
                                5000
                            );
                        }
                    }
                })
                .catch((e) => {
                    if (e.response.status === 401) {
                        logOut(props);
                        NotificationManager.warning(
                            "Potrebné sa znova prihlásiť!",
                            "Upozornenie",
                            5000
                        );
                    }
                    NotificationManager.error(
                        "Neočakavaná chyba!",
                        "Chyba",
                        5000
                    );
                    setLoaded({ ...loaded, [name]: "" });
                    setProgress({ ...progress, [name]: null });
                    setSelectedFile({ ...selectedFile, [name]: null });
                });
        } else {
            NotificationManager.error(
                "Prv zvoľte súbor a následne nahrajte.",
                "Chyba",
                5000
            );
            setLoaded({ ...loaded, [name]: "" });
            setProgress({ ...progress, [name]: null });
            setSelectedFile({ ...selectedFile, [name]: null });
        }
    };


    const basicInfoView = () => {
        return (
            <>
                <Paper className={fixedHeightPaper}>
                    <Grid container>
                        {
                            user?.funerio_service_id &&
                            <Grid item>
                                <Toolbar>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        hidden={true}
                                        onClick={() => {
                                            setConfirmDialogFun({
                                                isOpen: true,
                                                title: 'Zdieľať pohreb na funus.sk?',
                                                subTitle: "Po súhlase sa otvorí nové okno www.funus.sk (ak sa nové okno neotvorilo," +
                                                    " skontrolujte si nastavenia prehliadača ohľadom vyskakovacích okien pre www.modernepohrebnictvo.sk)",
                                                onConfirm: () => {

                                                    UserService.getFuneral(props.match.params.funeral_id).then(
                                                        (response) => {
                                                            let data = {
                                                                id: response.data.funerio_id,
                                                                name: response.data.deceased,
                                                                place_of_funeral: `${response.data.town_funeral}, ${response.data.place_funeral}`,
                                                                date_of_funeral: new Date(response.data.date_funeral).yyyyMMddFormat(),
                                                                time_of_funeral: new Date(response.data.date_funeral).timeFormat(),
                                                                image: response.data.decease_photo,
                                                                place_of_birth: response.data.d_place_birth,
                                                                funeral_service_id: parseInt(user.funerio_service_id),
                                                                date_of_death: new Date(response.data.d_death).yyyyMMddFormat(),
                                                                date_of_birth: new Date(response.data.d_birth).yyyyMMddFormat(),
                                                            };
                                                            createFunerioPost(data);
                                                        },
                                                        (e) => {
                                                            if (e.response.status === 401) {
                                                                logOut(props);
                                                            }
                                                        }
                                                    )

                                                }
                                            })
                                        }}
                                    >
                                        Zdieľať pohreb na Funus.sk
                                    </Button>
                                </Toolbar>
                            </Grid>
                        }
                        {
                            user?.flowii_company_id &&
                            <Grid item>
                                <Toolbar>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        hidden={true}
                                        onClick={() => {
                                            setConfirmDialogFun({
                                                isOpen: true,
                                                title: 'Vytvoriť objednávku vo Flowii?',
                                                subTitle: "",
                                                onConfirm: () => {
                                                    createFlowiiOrder(props.match.params.funeral_id);
                                                }
                                            })
                                        }}
                                    >
                                        Vytvoriť objednávku vo Flowii
                                    </Button>
                                </Toolbar>
                            </Grid>
                        }
                        <Grid item className={classes.toolbarButtons}>
                            <Toolbar>
                                <ActionButton
                                    color="primary">
                                    <Tooltip title="Upraviť pohreb">
                                        <EditIcon
                                            fontSize="small"
                                            onClick={() => {
                                                handleRedirects('editFuneral');
                                            }}
                                        />
                                    </Tooltip>
                                </ActionButton>
                                <ActionButton

                                    color="secondary">
                                    <Tooltip title="Vymazať pohreb">
                                        <DeleteIcon
                                            fontSize="small"
                                            onClick={() => {
                                                setConfirmDialog({
                                                    isOpen: true,
                                                    title: 'Naozaj chceš vymazať tento pohreb?',
                                                    subTitle: "Túto operáciu nevieš vrátiť.",
                                                    onConfirm: () => {
                                                        handleDelete(funeral._id)
                                                    }
                                                })
                                            }}
                                        />
                                    </Tooltip>
                                </ActionButton>

                            </Toolbar>
                        </Grid>
                    </Grid>

                    <Divider/>
                    <Box className={classes.marginTop}>
                        <Grid container className={classes.root} spacing={2}>
                            <Grid item xs={12} md={12} lg={12}>

                                <Tooltip title="Kliknúť pre úpravu">
                                    <TextField
                                        className={classes.marginElement}
                                        id="notes"
                                        label="Poznámky k pohrebu"
                                        multiline
                                        value={funeral.notes}
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        fullWidth={true}
                                        onClick={ () => {
                                            setEditNotes(true);
                                            setNotes(funeral.notes)
                                        }}
                                    />
                                </Tooltip>
                            </Grid>
                        </Grid>
                        <Popup
                            title={'Úprava poznámok'}
                            openPopup={editNotes}
                            setOpenPopup={setEditNotes}
                            actions={<>
                                <Button color="primary" variant="outlined" onClick={handleEditNotes}>
                                    Uložiť
                                </Button>
                            </>}
                        >
                            <TextInput
                                label="Poznámky"
                                name={'notes'}
                                multiline
                                value={notes}
                                onChange={handleNotes}
                            />
                        </Popup>
                    </Box>
                    <Box className={classes.marginTop}>
                        <Title>Informácie o zosnulom</Title>
                        <Grid container className={classes.root} spacing={2}>
                            <Grid item xs={12} md={6} lg={4}>
                                <TextField
                                    className={classes.marginElement}
                                    id="deceased"
                                    label="Meno"

                                    multiline
                                    value={funeral.deceased}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    fullWidth={true}
                                />
                                <TextField
                                    className={classes.marginElement}
                                    id="d_gender"
                                    label="Pohlavie"

                                    multiline
                                    value={funeral.d_gender}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    fullWidth={true}
                                />
                                <TextField
                                    className={classes.marginElement}
                                    id="d_nationality"
                                    label="Národnosť"

                                    multiline
                                    value={funeral.d_nationality}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    fullWidth={true}
                                />
                                <TextField
                                    className={classes.marginElement}
                                    id="d_id"
                                    label="Rodné číslo"

                                    multiline
                                    value={funeral.d_id}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    fullWidth={true}
                                />
                                <TextField
                                    className={classes.marginElement}
                                    id="d_place_b"
                                    label="Miesto narodenia"

                                    multiline
                                    value={funeral.d_place_birth}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    fullWidth={true}
                                />
                                <TextField
                                    className={classes.marginElement}
                                    id="d_birth"
                                    label="Dátum narodenia"

                                    multiline
                                    {...(funeral.d_birth ? {
                                            value: new Date(funeral.d_birth).toShortFormat()
                                        } : {
                                            value: ' '
                                        }
                                    )}
                                    InputProps={{
                                        readOnly: true,
                                        startAdornment: <InputAdornment position="start"> </InputAdornment>,
                                    }}
                                    fullWidth={true}
                                />
                            </Grid>

                            <Grid item xs={12} md={6} lg={4}>
                            <TextField
                                    className={classes.marginElement}
                                    id="d_address"
                                    label="Adresa"

                                    multiline
                                    value={funeral.d_address}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    fullWidth={true}
                                />
                                <TextField
                                    className={classes.marginElement}
                                    id="d_zip"
                                    label="PSČ"

                                    multiline
                                    value={funeral.d_zip}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    fullWidth={true}
                                />
                                <TextField
                                    className={classes.marginElement}
                                    id="d_city"
                                    label="Mesto"

                                    multiline
                                    value={funeral.d_city}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    fullWidth={true}
                                />
                                <TextField
                                    className={classes.marginElement}
                                    id="d_state"
                                    label="Štát"

                                    multiline
                                    value={funeral.d_state}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    fullWidth={true}
                                />
                                 <TextField
                                    className={classes.marginElement}
                                    id="d_last_job"
                                    label="Posledné zamestnanie"

                                    multiline
                                    value={funeral.d_last_job}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    fullWidth={true}
                                />
                            </Grid>

                            <Grid item xs={12} md={6} lg={4}>
                                <TextField
                                    className={classes.marginElement}
                                    id="d_place_death"
                                    label="Miesto úmrtia"

                                    multiline
                                    value={funeral.d_place_death}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    fullWidth={true}
                                />
                                <TextField
                                    className={classes.marginElement}
                                    id="d_place_death"
                                    label="Dátum úmrtia"

                                    multiline
                                    {...(funeral.d_death ? {
                                            value: new Date(funeral.d_death).toShortFormat()
                                        } : {
                                            value: ' '
                                        }
                                    )}
                                    InputProps={{
                                        readOnly: true,
                                        startAdornment: <InputAdornment position="start"> </InputAdornment>
                                    }}
                                    fullWidth={true}
                                />
                                <TextField
                                    className={classes.marginElement}
                                    id="d_marital_status"
                                    label="Rodinný stav"

                                    multiline
                                    value={funeral.d_marital_status}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    fullWidth={true}
                                />
                                <TextField
                                    className={classes.marginElement}
                                    id="d_education"
                                    label="Vzdelanie"

                                    multiline
                                    value={funeral.d_education}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    fullWidth={true}
                                />
                                <TextField
                                    className={classes.marginElement}
                                    id="d_wife_husband"
                                    label="Partner"

                                    multiline
                                    value={funeral.d_wife_husband}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    fullWidth={true}
                                />
                                <TextField
                                    className={classes.marginElement}
                                    id="d_wife_husband_id"
                                    label="Rodné číslo partnera"

                                    multiline
                                    value={funeral.d_wife_husband_id}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    fullWidth={true}
                                />
                            </Grid>
                        </Grid>
                    </Box>

                    <Box className={classes.marginTop}>
                        <Title>Informácie o obstarávateľovi pohrebu</Title>
                        <Grid container className={classes.root} spacing={2}>
                            <Grid item xs={12} md={6} lg={4}>
                                <TextField
                                    className={classes.marginElement}
                                    id="contracting_authority"
                                    label="Meno"

                                    multiline
                                    value={funeral.contracting_authority}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    fullWidth={true}
                                />
                                <TextField
                                    className={classes.marginElement}
                                    id="ca_id"
                                    label="Rodné číslo"

                                    multiline
                                    value={funeral.ca_id}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    fullWidth={true}
                                />
                            </Grid>

                            <Grid item xs={12} md={6} lg={4}>
                                <TextField
                                    className={classes.marginElement}
                                    id="ca_address"
                                    label="Adresa"

                                    multiline
                                    value={funeral.ca_address}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    fullWidth={true}
                                />
                                <TextField
                                    className={classes.marginElement}
                                    id="ca_email"
                                    label="Email"

                                    multiline
                                    value={funeral.ca_email}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    fullWidth={true}
                                />
                            </Grid>

                            <Grid item xs={12} md={6} lg={4}>
                                <TextField
                                    className={classes.marginElement}
                                    id="ca_zip"
                                    label="PSČ"

                                    multiline
                                    value={funeral.ca_zip}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    fullWidth={true}
                                />
                                <TextField
                                    className={classes.marginElement}
                                    id="ca_city"
                                    label="Mesto"

                                    multiline
                                    value={funeral.ca_city}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    fullWidth={true}
                                />
                            </Grid>

                            <Grid item xs={12} md={6} lg={4}>
                                <TextField
                                    className={classes.marginElement}
                                    id="ca_state"
                                    label="Štát"

                                    multiline
                                    value={funeral.ca_state}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    fullWidth={true}
                                />
                                <TextField
                                    className={classes.marginElement}
                                    id="ca_nationality"
                                    label="Národnosť"

                                    multiline
                                    value={funeral.ca_nationality}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    fullWidth={true}
                                />
                            </Grid>

                            <Grid item xs={12} md={6} lg={4}>
                                <TextField
                                    className={classes.marginElement}
                                    id="ca_phone"
                                    label="Telefónne číslo"

                                    multiline
                                    value={funeral.ca_phone}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    fullWidth={true}
                                />
                                <TextField
                                    className={classes.marginElement}
                                    id="ca_relationship"
                                    label="Príbuzenský vzťah"

                                    multiline
                                    value={funeral.ca_relationship}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    fullWidth={true}
                                />
                            </Grid>

                            <Grid item xs={12} md={6} lg={4}>
                            <TextField
                                    className={classes.marginElement}
                                    id="ca_birth"
                                    label="Dátum narodenia"

                                    multiline
                                    {...(funeral.ca_birth ? {
                                            value: new Date(funeral.ca_birth).toShortFormat()
                                        } : {
                                            value: ' '
                                        }
                                    )}
                                    InputProps={{
                                        readOnly: true,
                                        startAdornment: <InputAdornment position="start"> </InputAdornment>,
                                    }}
                                    fullWidth={true}
                                />
                              
                            </Grid>
                        </Grid>
                    </Box>
                    <Box className={classes.marginTop}>
                        <Title>Informácie o pohrebe</Title>
                        <Grid container className={classes.root} spacing={2}>
                            <Grid item xs={12} md={6} lg={4}>
                                <Box>
                                    <TextField
                                        className={classes.marginElement}
                                        id="planned_pickup"
                                        label="Plánované vyzdvihnutie"

                                        multiline
                                        {...(funeral.planned_pickup ? {
                                                value: new Date(funeral.planned_pickup).toShortFormatWithTimeExcel()
                                            } : {
                                                value: ' '
                                            }
                                        )}
                                        InputProps={{
                                            readOnly: true,
                                            startAdornment: <InputAdornment position="start"> </InputAdornment>,
                                        }}
                                        fullWidth={true}
                                    />
                                    <TextField
                                        className={classes.marginElement}
                                        id="coffin_arrival"
                                        label="Dátum príjazdu rakvy"

                                        multiline
                                        {...(funeral.coffin_arrival ? {
                                                value: new Date(funeral.coffin_arrival).toShortFormatWithTimeExcel()
                                            } : {
                                                value: ' '
                                            }
                                        )}
                                        InputProps={{
                                            readOnly: true,
                                            startAdornment: <InputAdornment position="start"> </InputAdornment>,
                                        }}
                                        fullWidth={true}
                                    />
                                    <TextField
                                        className={classes.marginElement}
                                        id="date_funeral"
                                        label="Dátum pohrebu"

                                        multiline
                                        {...(funeral.date_funeral ? {
                                                value: new Date(funeral.date_funeral).toShortFormatWithTimeExcel()
                                            } : {
                                                value: ' '
                                            }
                                        )}
                                        InputProps={{
                                            readOnly: true,
                                            startAdornment: <InputAdornment position="start"> </InputAdornment>,
                                        }}
                                        fullWidth={true}
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={6} lg={4}>
                                <TextField
                                    className={classes.marginElement}
                                    id="planned_pickup_place"
                                    label="Adresa plánovaného vyzdvihnutia"

                                    multiline
                                    value={funeral.planned_pickup_place}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    fullWidth={true}
                                />
                                <TextField
                                    className={classes.marginElement}
                                    id="town_funeral"
                                    label="Mesto pohrebu"

                                    multiline
                                    value={funeral.town_funeral}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    fullWidth={true}
                                />
                                <TextField
                                    className={classes.marginElement}
                                    id="place_funeral"
                                    label="Miesto pohrebu"

                                    multiline
                                    value={funeral.place_funeral}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    fullWidth={true}
                                />
                            </Grid>
                            <Grid item xs={12} md={6} lg={4}>
                                <TextField
                                    className={classes.marginElement}
                                    id="coffin_arrival_place"
                                    label="Adresa príjazdu rakvy"

                                    multiline
                                    value={funeral.coffin_arrival_place}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    fullWidth={true}
                                />
                                <TextField
                                    className={classes.marginElement}
                                    id="funeral_type"
                                    label="Typ pohrebu"

                                    multiline
                                    value={funeral.funeral_type}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    fullWidth={true}
                                />
                                <TextField
                                    className={classes.marginElement}
                                    id="picked_items"
                                    label="Prevzaté veci"

                                    multiline
                                    value={funeral.picked_items}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    fullWidth={true}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                </Paper>
                <ConfirmDialog
                    confirmDialog={confirmDialog}
                    setConfirmDialog={setConfirmDialog}
                    icon={<DeleteIcon/>}
                />
                <ConfirmDialog
                    confirmDialog={confirmDialogFun}
                    setConfirmDialog={setConfirmDialogFun}
                    color = {'primary'}
                    icon={<ShareIcon/>}
                />

            </>
        )
    }
    const placeView = () => {
        return (
            <>
                <Paper className={fixedHeightPaper}>
                    <Toolbar className={classes.toolbarButtons}>
                        <ActionButton
                            color="primary">
                            <Tooltip title="Upraviť miesto pohrebu">
                                <EditIcon
                                    fontSize="small"
                                    onClick={() => {
                                        funeral.place ? handleRedirects('editPlace') : handleRedirects('createPlace');
                                    }}
                                />
                            </Tooltip>
                        </ActionButton>
                    </Toolbar>
                    <Divider/>
                    <Box className={classes.marginTop}>
                        <Title>Informácie o pohrebnom mieste</Title>
                        <Grid container className={classes.root} spacing={2}>
                            <Grid item xs={12} md={6} lg={4}>
                                <TextField
                                    className={classes.marginElement}
                                    id="planned_pickup"
                                    label="Spopolnenie"

                                    multiline
                                    value={place.cremation}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    fullWidth={true}
                                />
                                <TextField
                                    className={classes.marginElement}
                                    id="new_monument"
                                    label="Nový hrob"

                                    multiline
                                    value={place.new_monument}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    fullWidth={true}
                                />
                                <TextField
                                    className={classes.marginElement}
                                    id="depth_hole"
                                    label="Hĺbka jamy"

                                    multiline
                                    value={place.depth_hole}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    fullWidth={true}
                                />
                                <TextField
                                    className={classes.marginElement}
                                    id="grave"
                                    label="Hrobka"

                                    multiline
                                    value={place.grave}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    fullWidth={true}
                                />
                            </Grid>
                            <Grid item xs={12} md={6} lg={4}>
                                <TextField
                                    className={classes.marginElement}
                                    id="uncover_grave"
                                    label="Odkryť pomník s krycou doskou"

                                    multiline
                                    value={place.uncover_grave}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    fullWidth={true}
                                />

                                <TextField
                                    className={classes.marginElement}
                                    id="covering_cement"
                                    label="Zaliatie panelov cementovou maltou"

                                    multiline
                                    value={place.covering_cement}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    fullWidth={true}
                                />

                                <TextField
                                    className={classes.marginElement}
                                    id="cemetery"
                                    label="Cintorín"

                                    multiline
                                    value={place.cemetery}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    fullWidth={true}
                                />

                                <TextField
                                    className={classes.marginElement}
                                    id="place_number"
                                    label="Číslo hrobového miesta"

                                    multiline
                                    value={place.place_number}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    fullWidth={true}
                                />

                            </Grid>
                            <Grid item xs={12} md={6} lg={4}>
                                <TextField
                                    className={classes.marginElement}
                                    id="who_is_buried"
                                    label="Pochovaní v hrobke"

                                    multiline
                                    value={place.who_is_buried}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    fullWidth={true}
                                /><TextField
                                className={classes.marginElement}
                                id="period_buried"
                                label="Dĺžka pochovaného"

                                multiline
                                value={place.period_buried}
                                InputProps={{
                                    readOnly: true,
                                }}
                                fullWidth={true}
                            /><TextField
                                className={classes.marginElement}
                                id="exhumation"
                                label="Exhumácia"

                                multiline
                                value={place.exhumation}
                                InputProps={{
                                    readOnly: true,
                                }}
                                fullWidth={true}
                            />
                            <TextField
                                    className={classes.marginElement}
                                    id="owner"
                                    label="Majiteľ miesta"

                                    multiline
                                    value={place.owner}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    fullWidth={true}
                                />
                            </Grid>
                        </Grid>
                        <Divider />
                        <Title>Fotky</Title>
                        <Container className={classes.cardGrid} maxWidth="md">
                        <Grid container spacing={4}>
                            {documentCard(
                                funeral.grave_hole_photo,
                                "grave_hole_photo",
                                selectedFile.grave_hole_photo,
                                "Výkop hrobu",
                                singleFileUploadHandler("grave_hole_photo")
                            )}
                            {documentCard(
                                funeral.grave_before_ceremony,
                                "grave_before_ceremony",
                                selectedFile.grave_before_ceremony,
                                "Hrob pred pohrebom",
                                singleFileUploadHandler("grave_before_ceremony")
                            )}
                            {documentCard(
                                funeral.grave_after_ceremony,
                                "grave_after_ceremony",
                                selectedFile.grave_after_ceremony,
                                "Hrob po pohrebe",
                                singleFileUploadHandler("grave_after_ceremony")
                            )}
                            {documentCard(
                                funeral.grave_ceremony,
                                "grave_ceremony",
                                selectedFile.grave_ceremony,
                                "Hrob počas pohrebu",
                                singleFileUploadHandler("grave_ceremony")
                            )}
                            {documentCard(
                                funeral.grave_damage_1,
                                "grave_damage_1",
                                selectedFile.grave_damage_1,
                                "Poškodenie hrobu 1",
                                singleFileUploadHandler("grave_damage_1")
                            )}
                            {documentCard(
                                funeral.grave_damage_2,
                                "grave_damage_2",
                                selectedFile.grave_damage_2,
                                "Poškodenie hrobu 2",
                                singleFileUploadHandler("grave_damage_2")
                            )}
                             {documentCard(
                                funeral.grave_exterier_1,
                                "grave_exterier_1",
                                selectedFile.grave_exterier_1,
                                "Okolie hrobu 1",
                                singleFileUploadHandler("grave_exterier_1")
                            )}
                             {documentCard(
                                funeral.grave_exterier_2,
                                "grave_exterier_2",
                                selectedFile.grave_exterier_2,
                                "Okolie hrobu 2",
                                singleFileUploadHandler("grave_exterier_2")
                            )}
                             {documentCard(
                                funeral.grave_exterier_3,
                                "grave_exterier_3",
                                selectedFile.grave_exterier_3,
                                "Okolie hrobu 3",
                                singleFileUploadHandler("grave_exterier_3")
                            )}
                             {documentCard(
                                funeral.grave_exterier_4,
                                "grave_exterier_4",
                                selectedFile.grave_exterier_4,
                                "Okolie hrobu 4",
                                singleFileUploadHandler("grave_exterier_4")
                            )}
                             {documentCard(
                                funeral.grave_exterier_5,
                                "grave_exterier_5",
                                selectedFile.grave_exterier_5,
                                "Okolie hrobu 5",
                                singleFileUploadHandler("grave_exterier_5")
                            )}
                             {documentCard(
                                funeral.grave_exterier_6,
                                "grave_exterier_6",
                                selectedFile.grave_exterier_6,
                                "Okolie hrobu 6",
                                singleFileUploadHandler("grave_exterier_6")
                            )}
                             {documentCard(
                                funeral.grave_exterier_7,
                                "grave_exterier_7",
                                selectedFile.grave_exterier_7,
                                "Okolie hrobu 7",
                                singleFileUploadHandler("grave_exterier_7")
                            )}
                             {documentCard(
                                funeral.grave_exterier_8,
                                "grave_exterier_8",
                                selectedFile.grave_exterier_8,
                                "Okolie hrobu 8",
                                singleFileUploadHandler("grave_exterier_8")
                            )}
                             {documentCard(
                                funeral.grave_exterier_9,
                                "grave_exterier_9",
                                selectedFile.grave_exterier_9,
                                "Okolie hrobu 9",
                                singleFileUploadHandler("grave_exterier_9")
                            )}
                             {documentCard(
                                funeral.grave_exterier_10,
                                "grave_exterier_10",
                                selectedFile.grave_exterier_10,
                                "Okolie hrobu 10",
                                singleFileUploadHandler("grave_exterier_10")
                            )}

                        </Grid>
            </Container>
                    </Box>
                </Paper>
            </>
        )
    }
    const productsView = () => {
        return (
            <>
                <Paper className={fixedHeightPaper}>
                    <Toolbar className={classes.toolbarButtons}>
                        <ActionButton
                            color="primary">
                            <Tooltip title="Upraviť produkty pohrebu">
                                <EditIcon
                                    fontSize="small"
                                    onClick={() => {
                                        handleRedirects('editProducts');
                                    }}
                                />
                            </Tooltip>
                        </ActionButton>
                    </Toolbar>

                    <Divider/>
                    <Box className={classes.marginTop}>
                        <Title>Informácie o produktoch</Title>
                        <Typography component="div" variant="body1" color="primary" gutterBottom>
                            Informačná výsledná suma za zvolené produkty: <Typography component="span" variant="h5"
                                                                                      color="primary" gutterBottom>
                            {calculateCost(funeral.product_entities)}€
                        </Typography>
                        </Typography>


                        <Grid container className={classes.root} spacing={2}>
                            {funeral.product_entities.map((item, index) =>
                                <Grid key={index} item xs={12} md={6} lg={6}>
                                    <TextField
                                        className={classes.marginElement}
                                        id={item.name}
                                        label={item.name}
                                        multiline
                                        fullWidth={true}
                                        value={showValue(item)}
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                    />
                                </Grid>)}
                        </Grid>
                    </Box>
                </Paper>
            </>
        )
    }
    const servicesView = () => {
        return (
            <>
                <Paper className={fixedHeightPaper}>
                    <Toolbar className={classes.toolbarButtons}>
                        <ActionButton
                            color="primary">
                            <Tooltip title="Upraviť služby pohrebu">
                                <EditIcon
                                    fontSize="small"
                                    onClick={() => {
                                        handleRedirects('editServices');
                                    }}
                                />
                            </Tooltip>
                        </ActionButton>
                    </Toolbar>

                    <Divider/>
                    <Box className={classes.marginTop}>
                        <Title>Informácie o službách</Title>
                        <Typography component="div" variant="body1" color="primary" gutterBottom>
                            Informačná výsledná suma za zvolené služby: <Typography component="span" variant="h5"
                                                                                      color="primary" gutterBottom>
                            {calculateCost(funeral.service_entities)}€
                        </Typography>
                        </Typography>
                        <Grid container className={classes.root} spacing={2}>
                            {funeral.service_entities.map((item, index) =>
                                <Grid key={index} item xs={12} md={6} lg={6}>
                                    <TextField
                                        className={classes.marginElement}
                                        id={item.name}
                                        label={item.name}
                                        multiline
                                        fullWidth={true}
                                        value={showValue(item)}
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                    />
                                </Grid>)}
                        </Grid>
                    </Box>
                </Paper>
            </>
        )
    }
    const evidenceView = () => {
        return (
            <>
                <Paper className={fixedHeightPaper}>
                    <Toolbar className={classes.toolbarButtons}>
                        <ActionButton
                            color="primary">
                            <Tooltip title="Upraviť evidenciu pohrebu">
                                <EditIcon
                                    fontSize="small"
                                    onClick={() => {
                                        funeral.evidence ? handleRedirects('editEvidence') : handleRedirects('createEvidence');
                                    }}
                                />
                            </Tooltip>
                        </ActionButton>
                    </Toolbar>

                    <Divider/>
                    <Box className={classes.marginTop}>
                        <Title>Informácie o dátach pre evidenciu</Title>
                        <Grid container className={classes.root} spacing={2}>
                            <Grid item xs={12} md={6} lg={6}>
                                <TextField
                                    className={classes.marginElement}
                                    id="items_evidence"
                                    label="Odovzdané doklady a osobné veci"

                                    multiline
                                    fullWidth={true}
                                    value={evidence.items}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                />
                                <TextField
                                    className={classes.marginElement}
                                    id="autopsy"
                                    label="Pitva"

                                    multiline
                                    fullWidth={true}
                                    value={evidence.autopsy}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                />
                                <TextField
                                    className={classes.marginElement}
                                    id="d_dangerous_disease"
                                    label="Úmrtie na nebezpečnú chorobu"

                                    multiline
                                    fullWidth={true}
                                    value={evidence.d_dangerous_disease}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                />
                                <TextField
                                    className={classes.marginElement}
                                    id="placement_fridge"
                                    label="Dátum a čas uloženia do chladiaceho zariadenia"

                                    multiline
                                    fullWidth={true}
                                    {...(evidence.placement_fridge ? {
                                            value: new Date(evidence.placement_fridge).toShortFormatWithTimeExcel()
                                        } : {
                                            value: ' '
                                        }
                                    )}
                                    InputProps={{
                                        readOnly: true,
                                        startAdornment: <InputAdornment position="start"> </InputAdornment>,
                                    }}
                                />
                                <TextField
                                    className={classes.marginElement}
                                    id="employee"
                                    label="Kto vybavuje pohreb (zamestnanec)"

                                    multiline
                                    fullWidth={true}
                                    value={evidence.employee}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                />
                                <TextField
                                    className={classes.marginElement}
                                    id="placement_of_decedent"
                                    label="Miesto, kde sú uložené ľudské ostatky pred pohrebom"

                                    multiline
                                    fullWidth={true}
                                    value={evidence.placement_of_decedent}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} md={6} lg={6}>

                                <TextField
                                    className={classes.marginElement}
                                    id="car_id"
                                    label="ŠPZ prepravujúceho vozidla"
                                    multiline
                                    fullWidth={true}
                                    value={evidence.car_id}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                />

                                <TextField
                                    className={classes.marginElement}
                                    id="temperature"
                                    label="Teplota v chladiarenskom zariadení"

                                    multiline
                                    fullWidth={true}
                                    value={evidence.temperature}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                />
                                <TextField
                                    className={classes.marginElement}
                                    id="disinfection"
                                    label="Čistenie a dezinfekcia chladiaceho zariadenia a vozidla"

                                    multiline
                                    fullWidth={true}
                                    value={evidence.disinfection}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                />
                                <TextField
                                    className={classes.marginElement}
                                    id="disinfection_date"
                                    label="Čistenie a dezinfekcia chladiaceho zariadenia a vozidla dátum a čas"
                                    multiline
                                    fullWidth={true}
                                    {...(evidence.disinfection_date ? {
                                            value: new Date(evidence.disinfection_date).toShortFormatWithTimeExcel()
                                        } : {
                                            value: ' '
                                        }
                                    )}
                                    InputProps={{
                                        readOnly: true,
                                        startAdornment: <InputAdornment position="start"> </InputAdornment>,
                                    }}
                                />
                                 <TextField
                                    className={classes.marginElement}
                                    id="employee_money"
                                    label="Kto prevzal peniaze za pohreb (zamestnanec)"

                                    multiline
                                    fullWidth={true}
                                    value={evidence.employee_money}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                </Paper>
            </>
        )
    }
    const documentsView = () => {
        return (
            <>
                <Paper className={fixedHeightPaper}>
                    <DocumentsDetails {...props} funeral={funeral} setRefetch={setRefetch} refetch={refetch}/>
                </Paper>
            </>
        )
    }

    const tasksView = () => {
        return (
            <>
                <TaskDetails {...props} funeral={funeral} setRefetch={setRefetch} refetch={refetch}/>
            </>
        )
    }

    return (
        <>
            <Container maxWidth="lg" className={classes.seeMore}>
                <div className={classes.root}>
                    <AppBar position="static" color="default">
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            indicatorColor="primary"
                            textColor="primary"
                            {...((props.width !== 'xl' && props.width !== 'lg') ? {
                                variant: 'scrollable'
                            } : {centered: true})}
                        >
                            <Tab label="Základné informácie" icon={<PermIdentityIcon/>} {...a11yProps(0)} />
                            <Tab label="Pohrebné miesto" icon={<PlaceIcon/>} {...a11yProps(1)} />
                            <Tab label="Služby pohrebu" icon={<SettingsApplicationsIcon/>} {...a11yProps(2)} />
                            <Tab label="Produkty pohrebu" icon={<AllInboxIcon/>} {...a11yProps(3)} />
                            <Tab label="Evidencia" icon={<StorageIcon/>} {...a11yProps(4)} />
                            <Tab label="Dokumenty" icon={<DescriptionIcon/>} {...a11yProps(5)} />
                            <Tab label="Úlohy" icon={<AssignmentIcon/>} {...a11yProps(6)} />
                        </Tabs>
                    </AppBar>
                    <TabPanel value={value} index={0}>
                        {basicInfoView()}
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        {placeView()}
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                        {servicesView()}
                    </TabPanel>
                    <TabPanel value={value} index={3}>
                        {productsView()}
                    </TabPanel>
                    <TabPanel value={value} index={4}>
                        {evidenceView()}
                    </TabPanel>
                    <TabPanel value={value} index={5}>
                        {documentsView()}
                    </TabPanel>
                    <TabPanel value={value} index={6}>
                        {tasksView()}
                    </TabPanel>
                </div>
            </Container>
            {redirects.editFuneral ? <Redirect to={{
                pathname: `/pohreb/${funeral._id}/upravit`,
                state: {from: props.location}
            }}/> : <></>}
            {redirects.list ? <Redirect to={{
                pathname: `/zoznam`,
                state: {from: props.location}
            }}/> : <></>}
            {redirects.editPlace ? <Redirect to={{
                pathname: `/pohreb/miesto/${funeral._id}/${funeral.place._id}`,
                state: {from: props.location}
            }}/> : <></>}
            {redirects.editProducts ? <Redirect to={{
                pathname: `/pohreb/produkty/${funeral._id}`,
                state: {from: props.location}
            }}/> : <></>}
            {redirects.editServices ? <Redirect to={{
                pathname: `/pohreb/sluzby/${funeral._id}`,
                state: {from: props.location}
            }}/> : <></>}
            {redirects.editEvidence ? <Redirect to={{
                pathname: `/pohreb/evidencia/${funeral._id}/${funeral.evidence._id}`,
                state: {from: props.location}
            }}/> : <></>}
            {redirects.createPlace ? <Redirect to={{
                pathname: `/pohreb/${funeral._id}/miesto`,
                state: {from: props.location}
            }}/> : <></>}
            {redirects.createEvidence ? <Redirect to={{
                pathname: `/pohreb/${funeral._id}/evidencia`,
                state: {from: props.location}
            }}/> : <></>}
        </>
    );
}

export default withWidth()(FuneralDetails)