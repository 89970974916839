import axios from "axios";
import {authHeader, authHeaderForPhotos} from "./auth-header";

const API_URL = process.env.REACT_APP_API_URL;

const createFlowiiOrder = (funeral_id) => {

    return axios.post(API_URL + "user/flowii_order", {funeral_id: funeral_id}, {headers: authHeader()});

}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    createFlowiiOrder,
};