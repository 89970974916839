import React from 'react';
import {FormControl, FormHelperText, InputLabel, MenuItem, Select} from "@material-ui/core";

export default function SelectInputAdjusted(props) {

    const {name, label, options, value, onChange, error = null} = props;

    let valueFromOptions;

    const tryLa = () => {
        options.forEach( item => {
            if(JSON.stringify(item) === JSON.stringify(value)){
                valueFromOptions = item;
            }
        })
    }

    return (
        <FormControl
            variant='outlined'
            {...(error && {
                error: true
            })}
        >
            {tryLa()}
            <InputLabel>{label}</InputLabel>
            <Select
                name={name}
                label={label}
                value={valueFromOptions}
                onChange={onChange}
            >
                {
                    options.map( (opt, index) => (<MenuItem key={index} value={opt}>{`${opt.text} - ${opt.cost}€`}</MenuItem> ))
                }

            </Select>
            {error && <FormHelperText>{error}</FormHelperText>}
        </FormControl>
    )

}