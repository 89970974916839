import React from 'react';
import {TextField} from "@material-ui/core";

export default function TextInput(props) {

    const {name, label, placeholder, value, onChange, error = null, ...other} = props;

    return (
        <TextField
            variant="outlined"
            label={label}
            placeholder={placeholder}
            name={name}
            value={value}
            onChange={onChange}
            {...(error && {
                error: true,
                helperText: error
            })}
            {...other}
        />
    )

}