import React, {useEffect, useRef, useState} from "react";
import {Redirect, useParams} from "react-router-dom";
import UserService from "../../services/user.service";
import '../../Print.css';
import { logOut} from "../../helpers/logout";
import {Button, Divider, Grid, Toolbar} from "@material-ui/core";
import ActionButton from "../form/input/ActionButton";
import Tooltip from "@material-ui/core/Tooltip";
import Paper from "@material-ui/core/Paper";
import Container from "@material-ui/core/Container";
import {makeStyles} from "@material-ui/core/styles";
import clsx from "clsx";
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import Popup from "../presentation/Popup";
import TextInput from "../form/input/TextInput";
import DateInput from "../form/input/DateInput";
import altPhoto from "../../assets/trans.png";
import {PDFExport} from '@progress/kendo-react-pdf';
import Time from "react-time-format";
import {NotificationManager} from "react-notifications";
import withWidth from '@material-ui/core/withWidth'
import {ReactSketchCanvas} from "react-sketch-canvas";
import {convertSize} from "../../helpers/helper";


const useStyles = makeStyles((theme) => ({
    appBarSpacer: theme.mixins.toolbar,
    root: {
        flexGrow: 1,
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
    seeMore: {
        marginTop: theme.spacing(8),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    toolbarButtons: {
        '& .MuiFormControl-root': {
            margin: theme.spacing(1)
        }
    },
    marginElement: {
        margin: theme.spacing(2)
    },
    marginTop: {
        marginTop: theme.spacing(2)
    },
    icon: {
        marginRight: theme.spacing(2),
    },
    heroContent: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(8, 0, 6),
    },
    heroButtons: {
        marginTop: theme.spacing(4),
    },
    cardGrid: {
        paddingTop: theme.spacing(8),
        paddingBottom: theme.spacing(8),
    },
    card: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    cardMedia: {
        paddingTop: '56.25%', // 16:9
    },
    cardContent: {
        flexGrow: 1,
    },
}));


const DelegationGenerator = (props) => {
    const classes = useStyles();
    const fixedHeightPaper = clsx(classes.paper);
    let canvas = useRef(null);

    const pdfRef = useRef(null);
    const [funeral, setFuneral] = useState({
        contracting_authority: '',
        ca_address: '',
        ca_zip: '',
        ca_city: '',
        ca_state: '',
        ca_nationality: '',
        ca_email: '',
        ca_phone: '',
        ca_id: '',
        ca_birth: '',
        ca_relationship: '',
        deceased: '',
        d_address: '',
        d_zip: '',
        d_city: '',
        d_state: '',
        d_place_birth: '',
        d_birth: '',
        d_gender: '',
        d_marital_status: '',
        d_death: '',
        d_place_death: '',
        d_id: '',
        d_nationality: '',
        d_education: '',
        d_last_job: '',
        d_wife_husband: '',
        d_wife_husband_id: '',
        date_of_signature_delegation: new Date(),
        planned_pickup_place: '',
        planned_pickup: '',
        town_funeral: '',
        place_funeral: '',
        date_funeral: '',
        coffin_arrival: '',
        coffin_arrival_place: '',
        signatures: {}
    });
    const [show, setShow] = useState(false);
    const [date, setDate] = useState(new Date());
    const [location, setLocation] = useState('');
    const [success, setSuccess] = useState(false);
    const [fullScreen, setFullScreen] = useState(false);

    const handleShow = () => setShow(true);

    const handleDate = (e) => {
        setDate(e.target.value)
    }

    const handleLocation = (e) => {
        setLocation(e.target.value)
    }

    const [user, setUser] = useState(null);

    const funeral_id = useParams();
    const user_local = JSON.parse(localStorage.getItem('user')).id;

    useEffect(() => {
        UserService.getUser(user_local).then(
            (response) => {
                setUser(response.data);
                if (response.data.defined_texts) {
                    setLocation(response.data.defined_texts.default_signature_place);
                }
            },
            (e) => {
                if (e.response.status === 401) {
                    logOut(props);
                    NotificationManager.warning('Potrebné sa znova prihlásiť!', 'Upozornenie', 5000);
                }
            }
        );
        UserService.getFuneral(funeral_id.funeral_id).then(
            (response) => {
                setFuneral(response.data);
            },
            (e) => {
                if (e.response.status === 401) {
                    logOut(props);
                    NotificationManager.warning('Potrebné sa znova prihlásiť!', 'Upozornenie', 5000);
                }
            }
        );
    }, [funeral_id.funeral_id, user_local, props]);

    const singleFileUploadHandler = (file) => {
        const data = new FormData();
        data.append('someimage', file, 'delegation_signature.png');
        data.append('signatures_id', funeral.signatures._id);
        data.append('tag', 'delegation');
        data.append('date_of_delegation', new Date(date).toISOString());
        data.append('location_of_delegation', location);
        UserService.signDocument(data)
            .then((response) => {
                if (200 === response.status) {
                    // If file size is larger than expected.
                    if (response.data.error) {
                    } else {
                        let fileName = response.data;
                        setFuneral({
                            ...funeral,
                            'signatures': {...funeral.signatures, 'delegation_signature': fileName.location}
                        });
                        setShow(false);
                    }
                }
            }).catch((e) => {
            if (e.response.status === 401) {
                logOut(props);
                NotificationManager.warning('Potrebné sa znova prihlásiť!', 'Upozornenie', 5000);
            }
            NotificationManager.error('Nepodarilo sa podpísať dokument!', 'Chyba', 5000);
            setShow(false);
        });
    };

    const handleSignature = async () => {
        const exportImage = canvas.current?.exportImage;

        if (exportImage) {
            const exportedDataURI = await exportImage('png');
            fetch(exportedDataURI)
                .then((response) => {
                    return response.blob();
                })
                .then((blob) => {
                    singleFileUploadHandler(blob);
                })
                .catch(() => {
                    NotificationManager.error('Nepodarilo sa podpísať dokument!', 'Chyba', 5000);
                    setShow(false);
                });
        }
    }

    const handleExport = () => {
        pdfRef.current.save();
    }

    const handleClear = () => {
        const resetCanvas = canvas.current?.resetCanvas;

        if (resetCanvas) {
            resetCanvas();
        }
    }

    const showDelegation = () => {
        if (user) {
            return <div style={{border: '1px solid'}}>
                <PDFExport ref={pdfRef} paperSize="A4" scale={0.75}
                           fileName={`splnomocnenie-${funeral.deceased.replace(' ', '')}`}>
                    <div className="a4-paper">
                        <div className="wrapper">
                            <div className="column-3 center">
                                <img width={150} src={user.logo} alt={altPhoto}/>
                            </div>
                            <div className="column-3 center">
                                <div>
                                    <h2>
                                        Splnomocnenie
                                        Objednávka pohrebu
                                        Správa pre matriku
                                    </h2>
                                </div>
                            </div>
                            <div className="column-3 center">
                                <div className="stamp">
                                    <div>{user.name}</div>
                                    <div>{user.address}</div>
                                    <div>IČO: {user.ico}</div>
                                    <div>IČ_DPH: {user.ic_dph}</div>
                                    <div>KONTAKT: {user.phone}</div>
                                </div>
                            </div>
                        </div>
                        <div className="wrapper-tbl">
                            <div className="column-2">
                                <div className="tables">
                                    <div><span
                                        style={{fontWeight: 'bold'}}>Vybavuje:</span> {funeral.contracting_authority}
                                    </div>
                                    <div><span style={{fontWeight: 'bold'}}> Adresa:</span> {funeral.ca_address} {funeral.ca_city} {funeral.ca_zip}</div>
                                    <div><span style={{fontWeight: 'bold'}}>Tel. č.:</span> {funeral.ca_phone}</div>
                                </div>
                            </div>
                            <div className="column-2">
                                <div className="tables">
                                    <div><span style={{fontWeight: 'bold'}}>Rodné číslo:</span> {funeral.ca_id}</div>
                                    <div><span style={{fontWeight: 'bold'}}>Dátum narodenia:</span> <Time
                                        value={funeral.ca_birth} format="DD.MM.YYYY"/></div>
                                    <div><span
                                        style={{fontWeight: 'bold'}}>Príbuzenský vzťah:</span> {funeral.ca_relationship}
                                    </div>
                                    <div><span style={{fontWeight: 'bold'}}>E-mail:</span> {funeral.ca_email}</div>
                                </div>
                            </div>
                        </div>
                        <div className="wrapper-tbl">
                            <div className="column-2">
                                <div className="tables">
                                    <div><span style={{fontWeight: 'bold'}}>Zosnulý:</span> {funeral.deceased}</div>
                                    <div><span style={{fontWeight: 'bold'}}>Pohlavie:</span> {funeral.d_gender}</div>
                                    <div><span style={{fontWeight: 'bold'}}>Národnosť:</span> {funeral.d_nationality}</div>
                                    <div><span style={{fontWeight: 'bold'}}>Dátum narodenia:</span> <Time
                                        value={funeral.d_birth} format="DD.MM.YYYY"/></div>
                                    <div><span style={{fontWeight: 'bold'}}>Dátum úmrtia:</span> <Time
                                        value={funeral.d_death} format="DD.MM.YYYY"/></div>
                                    <div><span
                                        style={{fontWeight: 'bold'}}>Rodinný stav:</span> {funeral.d_marital_status}
                                    </div>
                                    <div><span
                                        style={{fontWeight: 'bold'}}>Posledné zamestnanie:</span> {funeral.d_last_job}
                                    </div>
                                </div>
                            </div>
                            <div className="column-2">
                                <div className="tables">
                                    <div><span style={{fontWeight: 'bold'}}>Rodné číslo:</span> {funeral.d_id}</div>
                                    <div><span
                                        style={{fontWeight: 'bold'}}>Miesto narodenia:</span> {funeral.d_place_birth}
                                    </div>
                                    <div><span
                                        style={{fontWeight: 'bold'}}>Miesto úmrtia:</span> {funeral.d_place_death}</div>
                                    <div><span style={{fontWeight: 'bold'}}>Vzdelanie:</span> {funeral.d_education}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="wrapper-tbl">
                            <div className="column-2">
                                <div className="tables">
                                    <div><span
                                        style={{fontWeight: 'bold'}}>Manžel/ka:</span> {funeral.d_wife_husband ? funeral.d_wife_husband : '-'}
                                    </div>
                                </div>
                            </div>
                            <div className="column-2">
                                <div className="tables">
                                    <div><span
                                        style={{fontWeight: 'bold'}}>Rodné číslo:</span> {funeral.d_wife_husband_id ? funeral.d_wife_husband_id : '-'}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="wrapper-content">
                            Týmto splnomocňujem pracovníkov pohrebnej služby <span
                            style={{fontWeight: 'bold'}}>{user.name} - {user.address}</span> k
                            nasledujúcim úkonom podľa zákona č. 131/2010 Z. z. o pohrebníctve
                            v znení neskorších predpisov: <br/>
                            <span style={{fontWeight: 'bold'}}>Prevzatie:</span> zosnulej osoby, urny, Listov o
                            prehliadke
                            mŕtveho (aj uchovanie kópie), OP a všetkých ostatných osobných dokumentov
                            a dokladov, osobných vecí a cenností.<br/>
                            <span style={{fontWeight: 'bold'}}>Vybavenie:</span> matričnej dokumentácie (§ 18 zákona NR
                            SR č. 154/1994 Z.z. o matrikách v znení
                            neskorších predpisov), faktúry a všetkých náležitostí spojených s pohrebom.<br/>
                            <span style={{fontWeight: 'bold'}}>Ďalšie:</span> Odovzdanie kópií Listov o prehliadke
                            mŕtveho zainteresovaným osobám (kňaz, správca
                            domu smút...), marketingové účely, živé vysielanie z pohrebu, evidencia pohrebnej služby
                            o zaobchádzaní s ľudskými pozostatkami a o pochovaní potratu/ľudského plodu, zabezpečenie
                            pohrebu ľudských pozostatkov alebo ostatkov, zabezpečovanie kremácie ľudských pozostatkov,
                            ostatkov alebo ľudského plodu, vybavovanie objednávok na viazanie vencov a kvetinovú
                            smútočnú výzdobu na poslednú rozlúčku, poskytovanie služby v oblasti spracovania a prednesu
                            nekrológu, povolenie na pochovanie.
                            Prevádzkovateľ informoval dotknutú osobu o spôsobe, rozsahu a účelu spracúvania jej osobných
                            údajov podľa Zákona č. 18/2018 Z.z., o ochrane osobných údajov v platnom znení a Nariadenia
                            európskeho parlamentu a rady (EÚ) 2016/679 o ochrane fyzických osôb pri spracúvaní osobných
                            údajov a o voľnom pohybe takýchto údajov. Prevádzkovateľ informoval dotknutú osobu aj o jej
                            právach. Všeobecné nariadenie o ochrane osobných údajov priznáva dotknutej osobe niekoľko
                            práv, ktoré si môže uplatniť priamo u nás (ako u subjektu, ktorý jej osobné údaje spracúva).
                            Viac informácií o tom, aké sú Vaše práva a ako ich môžete uplatniť nájdete na webovom sídle
                            prevádzkovateľa <span style={{fontWeight: 'bold'}}>{user.web}</span> v sekcii <span
                            style={{fontWeight: 'bold'}}> ochrana osobných údajov / GDPR</span>. Vaša
                            žiadosť na uplatnenie práv môže byť ústna, písomná, elektronická, prípadne podaná inými
                            prostriedkami (odporúčame využiť najmä písomnú alebo elektronickú formu, aby bolo možné v
                            prípadnom konaní o ochrane osobných údajov preukázať, že ste si práva uplatnili). <span
                            style={{fontWeight: 'bold'}}>Sme povinný vybaviť Vašu žiadosť bezodkladne najneskôr do jedného
                            mesiaca od jej doručenia.</span> V prípade
                            akýchkoľvek otázok alebo nejasností nás môžete kontaktovať na telefónnom
                            čísle <span style={{fontWeight: 'bold'}}>{user.phone}</span> alebo elektronicky
                            prostredníctvom
                            emailu <span style={{fontWeight: 'bold'}}>{user.email_official}</span>
                        </div>
                        <div className="wrapper">
                            <div className="column-3-s">
                                V {funeral.signatures.location_of_delegation ? funeral.signatures.location_of_delegation
                                : location} dňa {funeral.signatures.date_of_delegation ? new Date(funeral.signatures.date_of_delegation).toShortFormat()
                                : date.toShortFormat()}.
                                <br></br>
                                {user.stamp &&
                                 <img height={100} src={user.stamp} style={{marginTop: '5px'}}/>}
                            </div>
                            <div className="column-3-s-c">
                                Podpis:
                            </div>
                            <div className="column-3-s-r" style={{position: 'relative'}}>
                                {funeral.signatures.delegation_signature &&
                                <img src={funeral.signatures.delegation_signature} crossOrigin="anonymous" alt={'logo'}
                                     height='102 px' />}
                            </div>
                        </div>
                    </div>
                </PDFExport>
            </div>
        }
        return <></>
    }



    return (
        <Container maxWidth="lg" className={classes.seeMore}>
            <div className={classes.root}>
                <Paper className={fixedHeightPaper}>
                    <Grid container>
                        <Grid xs={12} sm={4} md={3} lg={3} item container>
                            <Toolbar>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    hidden={true}
                                    className={classes.button}
                                    onClick={() => {
                                        setSuccess(true)
                                    }}
                                >
                                    Späť na detaily
                                </Button>
                            </Toolbar>
                        </Grid>
                        <Grid xs={12} sm={8} md={9} lg={9} item container justifyContent='flex-end'>
                            <Toolbar className={classes.toolbarButtons}>
                                <TextInput
                                    label="Miesto podpisu"
                                    name={'location'}
                                    value={location}
                                    onChange={handleLocation}
                                />
                                <DateInput
                                    label="Dátum podpisu"
                                    name={'date'}
                                    value={date}
                                    onChange={handleDate}
                                />
                                <ActionButton
                                    color="primary">
                                    <Tooltip title="Podpísať splnomocnenie">
                                        <BorderColorIcon
                                            fontSize="small"
                                            onClick={() => {
                                                handleShow();
                                            }}
                                        />
                                    </Tooltip>
                                </ActionButton>
                                <ActionButton
                                    color="primary">
                                    <Tooltip title="Pdf export">
                                        <PictureAsPdfIcon
                                            fontSize="small"
                                            onClick={handleExport}
                                        />
                                    </Tooltip>
                                </ActionButton>
                            </Toolbar>
                        </Grid>
                    </Grid>

                    <Divider/>
                    <Grid className={classes.marginTop}
                          container
                          spacing={0}
                          direction="column"
                          alignItems="center"
                          justifyContent="center"
                    >
                        {showDelegation()}
                    </Grid>
                </Paper>
            </div>
            <Popup
                title={'Podpis'}
                openPopup={show}
                setOpenPopup={setShow}
                maxW = 'xl'
                actions={<>
                    <Button color="primary" variant="outlined" onClick={() => {
                        setFullScreen(!fullScreen);
                    }
                    }>
                        {fullScreen ? 'Zmenšiť' : 'Zväčšiť'}
                    </Button>
                    <Button color="primary" variant="outlined" onClick={handleClear}>
                        Vyčistiť
                    </Button>
                    <Button color="primary" variant="outlined" onClick={handleSignature}>
                        Uložiť
                    </Button>
                </>}
            >
                <ReactSketchCanvas
                    ref={canvas}
                    strokeWidth={3}
                    strokeColor="blue"
                    style={{
                        border: "0.0625rem solid #9c9c9c",
                        borderRadius: "0.25rem",
                        width: fullScreen ? convertSize(props.width) : props.width === 'xs' ? "300px" : "500px",
                        height: fullScreen ? '600px' : props.width === 'xs' ? "125px" : "300px"
                    }}
                />
            </Popup>
            {success ? <Redirect to={{
                pathname: `/pohreb/${funeral_id.funeral_id}/info`,
                state: {from: props.location, number: 5}
            }}/> : <></>}
        </Container>
    )
}
export default withWidth()(DelegationGenerator);

