import React from 'react'
import { MuiPickersUtilsProvider, DateTimePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import skLocale from "date-fns/locale/sk";
import DateRangeIcon from '@material-ui/icons/DateRange';

export default function DateTimeInput(props) {

    const { name, label, value, onChange, error = null } = props


    const convertToDefEventPara = (name, value) => ({
        target: {
            name, value
        }
    })

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={skLocale}>
            <DateTimePicker     variant="inline" inputVariant="outlined"
                                label={label}
                                ampm={false}
                                {...(error && {
                                    error: true,
                                    helperText: error
                                })}
                                format="dd.MM.yyyy-p"
                                name={name}
                                value={value}
                                onChange={date =>onChange(convertToDefEventPara(name,date))}
                                InputProps={{
                                    endAdornment: (
                                        <DateRangeIcon/>
                                    ),
                                }}

            />
        </MuiPickersUtilsProvider>
    )
}