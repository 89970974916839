import React, {useEffect, useRef, useState} from "react";
import {Redirect, useParams} from "react-router-dom";
import UserService from "../../services/user.service";
import '../../Print.css';
import { logOut} from "../../helpers/logout";
import {Button, Divider, Grid, Toolbar} from "@material-ui/core";
import ActionButton from "../form/input/ActionButton";
import Tooltip from "@material-ui/core/Tooltip";
import Paper from "@material-ui/core/Paper";
import Container from "@material-ui/core/Container";
import {makeStyles} from "@material-ui/core/styles";
import clsx from "clsx";
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import altPhoto from "../../assets/candle.jpg";
import {PDFExport} from '@progress/kendo-react-pdf';
import {NotificationManager} from "react-notifications";


const useStyles = makeStyles((theme) => ({
    appBarSpacer: theme.mixins.toolbar,
    root: {
        flexGrow: 1,
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
    seeMore: {
        marginTop: theme.spacing(8),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    toolbarButtons: {
        '& .MuiFormControl-root': {
            margin: theme.spacing(1)
        }
    },
    marginElement: {
        margin: theme.spacing(2)
    },
    marginTop: {
        marginTop: theme.spacing(2)
    },
    icon: {
        marginRight: theme.spacing(2),
    },
    heroContent: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(8, 0, 6),
    },
    heroButtons: {
        marginTop: theme.spacing(4),
    },
    cardGrid: {
        paddingTop: theme.spacing(8),
        paddingBottom: theme.spacing(8),
    },
    cardMedia: {
        height: 200,
        width: 150,
        borderRadius: 10
    },
    cardContent: {
        flexGrow: 1,
    },
}));


const InformationPaperGenerator = (props) => {
    const classes = useStyles();
    const fixedHeightPaper = clsx(classes.paper);

    const pdfRef = useRef(null);
    const [funeral, setFuneral] = useState({
        contracting_authority: '',
        ca_address: '',
        ca_zip: '',
        ca_city: '',
        ca_state: '',
        ca_nationality: '',
        ca_email: '',
        ca_phone: '',
        ca_id: '',
        ca_birth: '',
        ca_relationship: '',
        deceased: '',
        d_address: '',
        d_zip: '',
        d_city: '',
        d_state: '',
        d_place_birth: '',
        d_birth: '',
        d_gender: '',
        d_marital_status: '',
        d_death: '',
        d_place_death: '',
        d_id: '',
        d_nationality: '',
        d_education: '',
        d_last_job: '',
        d_wife_husband: '',
        d_wife_husband_id: '',
        planned_pickup_place: '',
        planned_pickup: '',
        town_funeral: '',
        place_funeral: '',
        date_funeral: '',
        coffin_arrival: '',
        coffin_arrival_place: '',
        signatures: {},
        product_entities: [],
        service_entities: []
    });
    const [success, setSuccess] = useState(false);

    const [user, setUser] = useState(null);

    const funeral_id = useParams();
    const user_local = JSON.parse(localStorage.getItem('user')).id;

    useEffect(() => {
        UserService.getUser(user_local).then(
            (response) => {
                setUser(response.data);
            },
            (e) => {
                if (e.response.status === 401) {
                    logOut(props);
                    NotificationManager.warning('Potrebné sa znova prihlásiť!', 'Upozornenie', 5000);
                }
            }
        );
        UserService.getFuneral(funeral_id.funeral_id).then(
            (response) => {
                setFuneral(response.data);
            },
            (e) => {
                if (e.response.status === 401) {
                    logOut(props);
                    NotificationManager.warning('Potrebné sa znova prihlásiť!', 'Upozornenie', 5000);
                }
            }
        );
    }, [funeral_id.funeral_id, user_local, props]);

    const handleExport = () => {
        pdfRef.current.save();
    }

    const contacts = () => {
        return <>
            <span style={{textDecoration: 'underline'}}>Užitočné kontakty:</span> <span style={{fontWeight: "normal"}}>
                {user.defined_texts.important_contacts}
            </span>
        </>
    }

    const steps = () => {
        return <>
            <span style={{textDecoration: 'underline'}}>Dalšie kroky:</span> <span style={{fontWeight: "normal"}}>
                {user.defined_texts.what_next}
            </span>
        </>
    }

    const listItems = (entities) => {
        return funeral[entities].map((item, index) => {
            if (item.show_list) {
                if (item.text) {
                    return <span key={index}> <span
                        style={{fontWeight: 'bold'}}>{item.name}</span> - {item.text}, </span>
                }
                if (item.number) {
                    return <span key={index}> <span
                        style={{fontWeight: 'bold'}}>{item.name}</span> - {item.number}, </span>
                }
                if (item.yes_no !== undefined) {
                    return <span key={index}> <span
                        style={{fontWeight: 'bold'}}>{item.name}</span> - {item.yes_no ? 'Áno' : 'Nie'}, </span>
                }
                if (item.text_cost) {
                    return <span key={index}> <span
                        style={{fontWeight: 'bold'}}>{item.name}</span> - {item.text_cost.text}, </span>
                }
            }
            return ''
        })
    }

    const showDelegation = () => {
        if (user) {
            return <div style={{border: '1px solid'}}>
                <PDFExport ref={pdfRef} paperSize="A4" scale={0.75}
                           fileName={`informacnylist-${funeral.deceased.replace(' ', '')}`}>
                    <div className="a4-paper">
                        <div className="wrapper">
                            <div className="column-3 center">
                                <img width={150} src={user.logo} alt={altPhoto}/>
                            </div>
                            <div className="column-3 center">
                                <div>
                                    <h1>
                                        Informačný list k pohrebu
                                    </h1>
                                </div>
                            </div>
                            <div className="column-3 center">
                                <div className="stamp">
                                    <div>{user.name}</div>
                                    <div>{user.address}</div>
                                    <div>IČO: {user.ico}</div>
                                    <div>IČ_DPH: {user.ic_dph}</div>
                                    <div>KONTAKT: {user.phone}</div>
                                </div>
                            </div>
                        </div>
                        <div className="wrapper-tbl">
                            <div className="column-2-1">
                                <div className="tables">
                                    <h3><span style={{fontWeight: 'normal'}}>Zosnulý/á:</span> {funeral.deceased}</h3>
                                    <h3><span
                                        style={{fontWeight: 'normal'}}>Dátum narodenia:</span> {funeral.d_birth && new Date(funeral.d_birth).toShortFormat()}
                                    </h3>
                                    <h3><span
                                        style={{fontWeight: 'normal'}}>Dátum úmrtia:</span> {funeral.d_death && new Date(funeral.d_death).toShortFormat()}
                                    </h3>
                                </div>
                            </div>
                            <div className="column-2-2">
                                <div className="tables">
                                    <h3><span
                                        style={{fontWeight: 'normal'}}>Kedy bude pohreb:</span> {funeral.date_funeral && new Date(funeral.date_funeral).toShortFormatWithTimeExcel()}
                                    </h3>
                                    <h3><span style={{fontWeight: 'normal'}}>Mesto/Obec pohrebu:</span> {funeral.town_funeral}</h3>
                                    <h3><span
                                        style={{fontWeight: 'normal'}}>Miesto pohrebu:</span> {funeral.place_funeral}
                                    </h3>
                                </div>
                            </div>
                        </div>

                        <div className="wrapper-content">
                            <h4><span style={{textDecoration: 'underline'}}>Aké produkty ste si objednali:</span> <span
                                style={{fontWeight: 'normal'}}>
                                {listItems('product_entities')}
                            </span></h4>
                            <h4><span style={{textDecoration: 'underline'}}>Aké služby ste si objednali:</span> <span
                                style={{fontWeight: 'normal'}}>
                                {listItems('service_entities')}
                            </span></h4>
                            <h4 className="mt-top">
                                {user.defined_texts && user.defined_texts.important_contacts ?
                                    contacts()
                                    : ''}
                            </h4>

                            <h4 className="mt-top">
                                {user.defined_texts && user.defined_texts.what_next ?
                                    steps()
                                    : ''}
                            </h4>
                            <h4 className="mt-top-more center-text">
                                V prípade akýchkoľvek otázok nás neváhajte kontaktovať. Tento dokument slúži len na
                                informačné účely.
                            </h4>
                            <h4 className="center-text">
                                {user.email_official}, {user.phone}
                            </h4>
                        </div>
                    </div>
                </PDFExport>
            </div>
        }
        return <></>
    }

    return (
        <Container maxWidth="lg" className={classes.seeMore}>
            <div className={classes.root}>
                <Paper className={fixedHeightPaper}>
                    <Grid container>
                        <Grid xs={12} sm={4} md={3} lg={3} item container>
                            <Toolbar>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    hidden={true}
                                    className={classes.button}
                                    onClick={() => {
                                        setSuccess(true)
                                    }}
                                >
                                    Späť na detaily
                                </Button>
                            </Toolbar>
                        </Grid>
                        <Grid xs={12} sm={8} md={9} lg={9} item container justifyContent='flex-end'>
                            <Toolbar className={classes.toolbarButtons}>
                                <ActionButton
                                    color="primary">
                                    <Tooltip title="Pdf export">
                                        <PictureAsPdfIcon
                                            fontSize="small"
                                            onClick={handleExport}
                                        />
                                    </Tooltip>
                                </ActionButton>
                            </Toolbar>
                        </Grid>
                    </Grid>

                    <Divider/>
                    <Grid className={classes.marginTop}
                          container
                          spacing={0}
                          direction="column"
                          alignItems="center"
                          justifyContent="center"
                    >
                        {showDelegation()}
                    </Grid>
                </Paper>
            </div>
            {success ? <Redirect to={{
                pathname: `/pohreb/${funeral_id.funeral_id}/info`,
                state: {from: props.location, number: 5}
            }}/> : <></>}
        </Container>
    )
}
export default InformationPaperGenerator;

