import React, {useEffect, useRef, useState} from "react";
import {Redirect, useParams} from "react-router-dom";
import UserService from "../../services/user.service";
import '../../Print.css';
import { logOut} from "../../helpers/logout";
import {Button, Divider, Grid, Toolbar} from "@material-ui/core";
import ActionButton from "../form/input/ActionButton";
import Tooltip from "@material-ui/core/Tooltip";
import Paper from "@material-ui/core/Paper";
import Container from "@material-ui/core/Container";
import {makeStyles} from "@material-ui/core/styles";
import clsx from "clsx";
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import altPhoto from "../../assets/candle.jpg";
import {PDFExport} from '@progress/kendo-react-pdf';
import {NotificationManager} from "react-notifications";
import TextInput from "../form/input/TextInput";
import DateInput from "../form/input/DateInput";
import Time from "react-time-format";

const useStyles = makeStyles((theme) => ({
    appBarSpacer: theme.mixins.toolbar,
    root: {
        flexGrow: 1,
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
    seeMore: {
        marginTop: theme.spacing(8),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    toolbarButtons: {
        '& .MuiFormControl-root': {
            margin: theme.spacing(1)
        }
    },
    marginElement: {
        margin: theme.spacing(2)
    },
    marginTop: {
        marginTop: theme.spacing(2)
    },
    icon: {
        marginRight: theme.spacing(2),
    },
    heroContent: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(8, 0, 6),
    },
    heroButtons: {
        marginTop: theme.spacing(4),
    },
    cardGrid: {
        paddingTop: theme.spacing(8),
        paddingBottom: theme.spacing(8),
    },
    cardMedia: {
        height: 200,
        width: 150,
        borderRadius: 10
    },
    cardContent: {
        flexGrow: 1,
    },
}));


const ConfirmationGenerator = (props) => {
    const classes = useStyles();
    const fixedHeightPaper = clsx(classes.paper);

    const pdfRef = useRef(null);
    const [funeral, setFuneral] = useState({
        contracting_authority: '',
        ca_address: '',
        ca_zip: '',
        ca_city: '',
        ca_state: '',
        ca_nationality: '',
        ca_email: '',
        ca_phone: '',
        ca_id: '',
        ca_birth: '',
        ca_relationship: '',
        deceased: '',
        d_address: '',
        d_zip: '',
        d_city: '',
        d_state: '',
        d_place_birth: '',
        d_birth: '',
        d_gender: '',
        d_marital_status: '',
        d_death: '',
        d_place_death: '',
        d_id: '',
        d_nationality: '',
        d_education: '',
        d_last_job: '',
        d_wife_husband: '',
        d_wife_husband_id: '',
        planned_pickup_place: '',
        planned_pickup: '',
        town_funeral: '',
        place_funeral: '',
        date_funeral: '',
        coffin_arrival: '',
        coffin_arrival_place: '',
        signatures: {}
    });
    const [success, setSuccess] = useState(false);
    const [date, setDate] = useState(new Date());
    const [location, setLocation] = useState('');

    const [user, setUser] = useState(null);

    const funeral_id = useParams();
    const user_local = JSON.parse(localStorage.getItem('user')).id;

    const handleDate = (e) => {
        setDate(e.target.value)
    }

    const handleLocation = (e) => {
        setLocation(e.target.value)
    }

    useEffect(() => {
        UserService.getUser(user_local).then(
            (response) => {
                setUser(response.data);
                if (response.data.defined_texts) {
                    setLocation(response.data.defined_texts.default_signature_place);
                }
            },
            (e) => {
                if (e.response.status === 401) {
                    logOut(props);
                    NotificationManager.warning('Potrebné sa znova prihlásiť!', 'Upozornenie', 5000);
                }
            }
        );
        UserService.getFuneral(funeral_id.funeral_id).then(
            (response) => {
                setFuneral(response.data);
            },
            (e) => {
                if (e.response.status === 401) {
                    logOut(props);
                    NotificationManager.warning('Potrebné sa znova prihlásiť!', 'Upozornenie', 5000);
                }
            }
        );
    }, [funeral_id.funeral_id, user_local, props]);

    const handleExport = () => {
        pdfRef.current.save();
    }

    const showDelegation = () => {
        if (user) {
            return <div style={{border: '1px solid'}}>
                <PDFExport ref={pdfRef} paperSize="A4" scale={0.75}
                           fileName={`potvrdenie-${funeral.contracting_authority.replace(' ', '')}`}>
                    <div className="a4-paper">
                    <div className="wrapper">
                            <div className="column-3 center">
                                <img width={150} src={user.logo} alt={altPhoto}/>
                            </div>
                            <div className="column-3">
                                <div className="stamp">
                                    <div>{user.name}</div>
                                    <div>{user.address}</div>
                                    <div>IČO: {user.ico}</div>
                                    <div>IČ_DPH: {user.ic_dph}</div>
                                    <div>KONTAKT: {user.phone}</div>
                                </div>
                            </div>
                        </div>
                        <div className="wrapper-content">
                            <h1 className="center">
                                Potvrdenie
                            </h1>
                            <p>
                                Pohrebná služba <b>{user.name}</b> potvrdzuje, že menovaný <b>{funeral.contracting_authority}</b> bytom {funeral.ca_address} {funeral.ca_city} {funeral.ca_zip} dňa {funeral.signatures.date_of_remains ? new Date(funeral.signatures.date_of_remains).toShortFormat() : date.toShortFormat()} <b>obstarával</b> pohreb nebohého <b>{funeral.deceased}</b>, ktorý zomrel dňa <Time value={funeral.d_death} format="DD.MM.YYYY"/>.                            </p>
                            <p>
                                Potvrdenie sa vydáva pre potreby zamastnávateľa.
                            </p>
         
                        </div>
                        <div className="wrapper-content" style={{marginTop: '0mm'}}>
                            <div className="column-2" style={{marginLeft: '0mm'}}>
                                <p style={{marginTop: '5mm'}}>
                                    V{" "}
                                        {funeral.signatures.location_of_remains
                                            ? funeral.signatures
                                                  .location_of_remains
                                            : location}{" "}
                                        dňa{" "}
                                        {funeral.signatures.date_of_remains
                                            ? new Date(
                                                  funeral.signatures.date_of_remains
                                              ).toShortFormat()
                                            : date.toShortFormat()}
                                        .
                                </p>
                            </div>
                            <div className="column">
                                <p style={{marginTop: '5mm'}}>
                                    Pohrebná služba: 
                                </p>
                            </div>
                        </div>

                        <div className="wrapper" style={{marginTop: '100mm'}}>
                            <div className="column-3 center">
                                <img width={150} src={user.logo} alt={altPhoto}/>
                            </div>
                            <div className="column-3">
                                <div className="stamp">
                                    <div>{user.name}</div>
                                    <div>{user.address}</div>
                                    <div>IČO: {user.ico}</div>
                                    <div>IČ_DPH: {user.ic_dph}</div>
                                    <div>KONTAKT: {user.phone}</div>
                                </div>
                            </div>
                        </div>
                        <div className="wrapper-content">
                            <h1 className="center">
                                Potvrdenie
                            </h1>
                            <p>
                                Pohrebná služba <b>{user.name}</b> potvrdzuje, že menovaný <b>{funeral.contracting_authority}</b> bytom {funeral.ca_address} {funeral.ca_city} {funeral.ca_zip} dňa {funeral.signatures.date_of_remains ? new Date(funeral.signatures.date_of_remains).toShortFormat() : date.toShortFormat()} <b>obstarával</b> pohreb nebohého <b>{funeral.deceased}</b>, ktorý zomrel dňa <Time value={funeral.d_death} format="DD.MM.YYYY"/>.
                            </p>
                            <p>
                                Potvrdenie sa vydáva pre potreby zamastnávateľa.
                            </p>
         
                        </div>
                        <div className="wrapper-content" style={{marginTop: '0mm'}}>
                            <div className="column-2" style={{marginLeft: '0mm'}}>
                                <p style={{marginTop: '5mm'}}>
                                    V{" "}
                                        {funeral.signatures.location_of_remains
                                            ? funeral.signatures
                                                  .location_of_remains
                                            : location}{" "}
                                        dňa{" "}
                                        {funeral.signatures.date_of_remains
                                            ? new Date(
                                                  funeral.signatures.date_of_remains
                                              ).toShortFormat()
                                            : date.toShortFormat()}
                                        .
                                </p>
                            </div>
                            <div className="column">
                                <p style={{marginTop: '5mm'}}>
                                    Pohrebná služba: 
                                </p>
                            </div>
                        </div>
                    </div>
                </PDFExport>
            </div>
        }
        return <></>
    }

    return (
        <Container maxWidth="lg" className={classes.seeMore}>
            <div className={classes.root}>
                <Paper className={fixedHeightPaper}>
                    <Grid container>
                        <Grid xs={12} sm={4} md={3} lg={3} item container>
                            <Toolbar>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    hidden={true}
                                    className={classes.button}
                                    onClick={() => {
                                        setSuccess(true)
                                    }}
                                >
                                    Späť na detaily
                                </Button>
                            </Toolbar>
                        </Grid>
                        <Grid xs={12} sm={8} md={9} lg={9} item container justifyContent='flex-end'>
                            <Toolbar className={classes.toolbarButtons}>
                                <TextInput
                                    label="Miesto podpisu"
                                    name={'location'}
                                    value={location}
                                    onChange={handleLocation}
                                />
                                <DateInput
                                    label="Dátum podpisu"
                                    name={'date'}
                                    value={date}
                                    onChange={handleDate}
                                />
                                <ActionButton
                                    color="primary">
                                    <Tooltip title="Pdf export">
                                        <PictureAsPdfIcon
                                            fontSize="small"
                                            onClick={handleExport}
                                        />
                                    </Tooltip>
                                </ActionButton>
                            </Toolbar>
                        </Grid>
                    </Grid>

                    <Divider/>
                    <Grid className={classes.marginTop}
                          container
                          spacing={0}
                          direction="column"
                          alignItems="center"
                          justifyContent="center"
                    >
                        {showDelegation()}
                    </Grid>
                </Paper>
            </div>
            {success ? <Redirect to={{
                pathname: `/pohreb/${funeral_id.funeral_id}/info`,
                state: {from: props.location, number: 5}
            }}/> : <></>}
        </Container>
    )
}
export default ConfirmationGenerator;

