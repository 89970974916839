import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';
import Cloud from '../../assets/cloud.jpg'
import {Paper} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100vh',
    },
    image: {
        backgroundImage: `url(${Cloud})`,
        backgroundRepeat: 'no-repeat',
        backgroundColor:
            theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },
    paper: {
        margin: theme.spacing(8, 4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%',
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    margin: theme.spacing(1),
    marginTopMiddle: {
        marginTop: theme.spacing(15)
    },
    marginTopLittle: {
        marginTop: theme.spacing(3)
    },
    marginTopLittleMore: {
        marginTop: theme.spacing(5)
    }
}));

export default function GDPRpolicy(props) {
    const classes = useStyles();


    return (
        <Grid container component="main" className={classes.root}>
            <CssBaseline/>
            <Grid item xs={12} sm={12} md={12} className={classes.image}>
                <Paper>
                    <Grid container>
                        <Grid item xs={1} sm={2} md={2} className={classes.marginTopMiddle}>
                        </Grid>

                        <Grid item xs={10} sm={8} md={8} className={classes.marginTopMiddle}>

                            <Typography justify variant="h4" component="h2">
                                PRAVIDLÁ OCHRANY OSOBNÝCH ÚDAJOV WEBOVEJ APLIKÁCIE MODERNÉ
                                POHREBNÍCTVO (Účinné od 1.10.2021)
                            </Typography>
                            <div className={classes.marginTopLittle}/>
                            <Typography variant="h4" component="h2">
                                1. ÚVODNÉ USTANOVENIA
                            </Typography>
                            <Typography variant="body1" component="h2">
                                &emsp;1.1. <span style={{fontWeight: 'bold'}}>MODERNÉ POHREBNÍCTVO s. r. o.</span>, organizačná zložka so sídlom
                                <span style={{fontWeight: 'bold'}}> Pod
                                kopaninou 1060/19, 082 71 Lipany, IČO: 53 959 191</span>, zapísaná v obchodnom registri
                                Okresného
                                súdu Prešov, oddiel: Sro, vložka č. 42705/P (ďalej len „poskytovateľ“) okrem iného
                                prevádzkuje
                                webovú aplikáciu MODERNÉ POHREBNÍCTVO (ďalej len „webová aplikácia“), ktorá pozostáva
                                z jednotlivých poskytovateľom vytvorených databáz a slúži pohrebným službám na
                                zaznamenanie a
                                uchovanie pohrebu zosnulého/ej, ktorý je prístupný prostredníctvom siete
                                internet
                                (online) na internetovej adrese www.modernepohrebnictvo.sk.
                            </Typography>
                            <Typography variant="body1" component="h2">
                                &emsp;1.2. Tieto pravidlá ochrany osobných údajov popisujú akým spôsobom spracúvame osobné
                                údaje v súvislosti s poskytovaním webovej aplikácie, slúžiacej na elektronické
                                vytvorenie,
                                podpísanie a evidenciu pohrebov (ďalej len „pravidlá OOÚ“).
                            </Typography>
                            <Typography variant="body1" component="h2">
                                &emsp;1.3. Osobné údaje dotknutých osôb sú spracúvané v súlade s platnými právnymi predpismi
                                účinnými na území Slovenskej republiky, a to v súlade so zákonom č. 18/2018 Z. z.
                                oochrane
                                osobných údajov a o zmene a doplnení niektorých zákonov (ďalej ako „Zákon“) a v súlade s
                                Nariadením Európskeho parlamentu a Rady (EÚ) 2016/679 z 27. apríla 2016 o ochrane
                                fyzických
                                osôb pri spracúvaní osobných údajov a o voľnom pohybe takýchto údajov, ktorým sa zrušuje
                                smernica 95/46/ES (ďalej ako „GDPR“).
                            </Typography>
                            <Typography variant="body1" component="h2">
                                &emsp;1.4. Právny vzťah používateľmi služby webovej aplikácie sa riadi Všeobecnými
                                obchodnými podmienkami, platnými a účinnými pri používaní webovej aplikácie, dostupnými
                                na
                                webovej stránke https://modernepohrebnictvo.sk/. (ďalej len „VOP“).
                            </Typography>
                            <Typography variant="body1" component="h2">
                                &emsp;1.5. Tieto pravidlá OOÚ sú záväzné:
                                &emsp;a) pre všetkých používateľov služby webovej aplikácie v zmysle VOP využívajúcich službu
                                webovej aplikácie definovanú vo VOP;
                                &emsp;b) pre všetkých návštevníkov webovej stránky https://modernepohrebnictvo.sk/;
                                &emsp;c) pre všetkých, ktorí nás kontaktovali prostredníctvom webovej stránky https://
                                modernepohrebnictvo.sk/, či už prostredníctvom formuláru alebo inými kanálmi
                                (telefonicky,
                                emailom...);
                                Všetci spolu ďalej a nerozdielne ako „dotknuté osoby.
                            </Typography>
                            <Typography variant="body1" component="h2">
                                &emsp;1.6. Osobné údaje dotknutých osôb získavame a následne spracúvame z rôznych dôvodov a
                                teda aj pre viacero účelov, a to vždy na základe legislatívou stanovených právnych
                                základov.
                            </Typography>
                            <Typography variant="body1" component="h2">
                                &emsp;1.7. Spoločnosť MODERNÉ POHREBNÍCTVO môže byť v súvislosti s poskytovaním
                                webovej aplikácie a prevádzkovaním webovej stránky pri spracúvaní osobných údajov v
                                pozícii
                                Prevádzkovateľa ako aj v pozícii Sprostredkovateľa osobných údajov.
                            </Typography>
                            <Typography variant="h4" component="h2">
                                2.  TYP A ROZSAH SPRACOVANIA OSOBNÝCH ÚDAJOV
                            </Typography>
                            <Typography variant="body1" component="h2">
                                &emsp;2.1.  Osobné údaje dotknutých osôb, ktoré spracúvame, patria primárne do kategórie
                                bežných osobných údajov v rozsahu:
                                <br/>
                                &emsp;a) meno a priezvisko, prípadne titul
                                <br/>
                                &emsp;b) adresa trvalého pobytu a/alebo korešpondenčná adresa<br/>
                                &emsp;c) telefónne číslo<br/>
                                &emsp;d) emailová adresa<br/>
                                &emsp;e) údaje o bankovom účte a iné (v závislosti od toho, aké ďalšie údaje v používateľskom účte
                                uvediete)<br/>
                                &emsp;f) online identifikátory (resp. metadáta): IP adresa zariadenia, operačný systém zariadenia, GPS
                                dáta, typ a verzia prehliadača, z ktorého pristupujete k využívaniu webovej aplikácie.
                            </Typography>
                            <Typography variant="body1" component="h2">
                                &emsp;2.2. Vo väčšine prípadov spracúvame Vaše osobné údaje na základe toho, že spracúvanie je
                                nevyhnutné na účely oprávneného záujmu, ktorý sledujeme, na zmluvnom základe alebo na základe
                                súhlasu Vás ako dotknutej osoby. V mnohých prípadoch budeme tiež musieť spracúvať Vaše osobné
                                údaje na základe právnej povinnosti. V prípade spracúvania na základe súhlasu máte vždy možnosť
                                svoj súhlas stiahnuť.
                            </Typography>
                            <Typography variant="h4" component="h2">
                                3.  PRÁVNY ZÁKLAD SPRACÚVANIA
                            </Typography>
                            <Typography variant="body1" component="h2">
                                &emsp;3.1. Osobné údaje spracúvame na základe nasledovných právnych základov:<br/>
                                &emsp;3.1.1. na základe súhlasu dotknutej osoby so spracúvaním svojich osobných súhlasov na
                                konkrétne vymedzený účel:<br/>
                                &emsp;a) bežné osobné údaje používateľov webovej aplikácie za účelom zasielania marketingových
                                informácií (newsletter, informácie súvisiace so službami a produktom spoločnosti, informácie o
                                rôznych podujatiach a pod.)<br/>
                                &emsp;b) bežné osobné údaje dotknutých osôb v rozsahu kontaktného formuláru za účelom kontaktovania
                                Vás vybraným komunikačným kanálom (emailom, telefonicky) v súvislosti s informáciami,
                                ktoré ste si vyžiadali alebo o ktoré máte záujem (informácie o produktoch, službách,
                                riešeniach..)<br/>
                                &emsp;c) bežné osobné údaje používateľov webovej aplikácie za účelom propagácie spoločnosti (vrátane
                                zverejnenia referencie klientov a názoru na spoluprácu s nami),<br/>
                                &emsp;d)  GPS dáta používateľov webovej aplikácie, ktoré sú zbierané pri podpisovaní dokumentu za
                                účelom dokazovania pravosti podpisu. Zbieranie GPS je možné kedykoľvek vypnúť priamo v
                                nastaveniach webového prehliadača<br/>
                                &emsp;3.1.2. na základe zmluvy, t.j. spracúvanie osobných údajov sa vykonáva na právnom základe
                                podľa § 13 ods. 1 písm. b) zákona č. 18/2018 Z.z. o ochrane osobných údajov, t.j. spracúvanie
                                osobných údajov je nevyhnutné na plnenie zmluvy:<br/>
                                &emsp;a) bežné osobné údaje používateľov webovej aplikácie v rozsahu registračného formulára
                                (minimálne v rozsahu meno, priezvisko, emailová adresa, telefónne číslo), dostupného na webovej
                                stránke, za účelom plnenia našich povinností (poskytovanie webovej aplikácie) vyplývajúcich z
                                nášho zmluvného vzťahu v zmysle Všeobecných obchodných podmienok.<br/>
                                &emsp;3.1.3. na základe osobitných predpisov, t.j. spracúvanie osobných údajov je nevyhnutné
                                podľa osobitného predpisu alebo medzinárodnej zmluvy, ktorou je Slovenská republika viazaná:<br/>
                                &emsp;a) fakturačné údaje používateľov webovej aplikácie za účelom vedenia účtovnej agendy<br/>
                                &emsp;b) korešpondenčné údaje dotknutých osôb za účelom evidencie došlej a odoslanej pošty<br/>
                                &emsp;3.1.4. na základe oprávnených záujmov, t.j. spracúvanie osobných údajov je nevyhnutné na
                                účel oprávnených záujmov Prevádzkovateľa alebo tretej strany okrem prípadov, keď nad týmito
                                záujmami prevažujú záujmy alebo práva dotknutej osoby vyžadujúce si ochranu osobných údajov,
                                najmä ak je dotknutou osobou dieťa:<br/>
                                &emsp;a) bežné osobné údaje používateľov webovej aplikácie za účelom riešenia nahlásených problémov,<br/>
                                &emsp;b) obchodné údaje používateľov webovej aplikácie za účelom nadviazania zmluvnej spolupráce a
                                starostlivosti o existujúcich používateľov, rozvoja vzájomnej spolupráce, organizovania
                                workshopov, konferencií a podobných aktivít súvisiacich s našimi produktmi, službami alebo
                                značkou firmy,<br/>
                                &emsp;c) údaje zbierané automatizovane na webových stránkach - cookies za účelom zlepšovania služby
                                webovej aplikácie,<br/>
                                &emsp;d) online identifikátory (resp. metadáta) používateľov služby: IP adresa zariadenia, operačný systém
                                zariadenia, typ a verziu prehliadača, z ktorého pristupujete k využívaniu funkcionalít webovej
                                aplikácie účelom dokazovania pravosti podpisu.
                            </Typography>
                            <Typography variant="h4" component="h2">
                                4. KOHO OSOBNÉ ÚDAJE SPRACÚVAME?
                            </Typography>
                            <Typography variant="body1" component="h2">
                                &emsp;4.1 Dotknutými osobami, ktorých osobné údaje spracúvame v postavení Prevádzkovateľa
                                osobných údajov vo webovej aplikácií sú:<br/>
                                &emsp;• Používatelia webovej aplikácie,<br/>
                                &emsp;• Zákazníci používateľa webovej aplikácie,<br/>
                                &emsp;• Návštevníci webovej stránky https://modernepohrebnictvo.sk,<br/>
                                &emsp;• Žiadatelia o predloženie cenovej ponuky a iných obchodných informácií,<br/>
                                &emsp;• Iné osoby, ktoré sa s nami spoja na základe vlastnej iniciatívy telefonicky, emailom alebo
                                prostredníctvom kontaktného formulára dostupného na webovej stránke https://
                                modernepohrebnictvo.sk z dôvodov ako napr. podanie podnetu alebo sťažnosti, vyžiadania spätnej
                                väzby a poskytnutia informácií a inej komunikácie.
                            </Typography>
                            <Typography variant="body1" component="h2">
                                &emsp;4.2. Dotknutými osobami, ktorých osobné údaje spracúvame v postavení Sprostredkovateľa
                                osobných údajov sú:<br/>
                                &emsp;4.2.1.Obstarávetelia pohrebu, t.j. osoby, ktoré nemajú zriadený vo webovej aplikácii
                                používateľský účet a s ktorými používatelia webovej aplikácie zabezpečujú pohreb, elektronické
                                podpísanie dokumentu medzi Podpisujúcim a používateľom webovej aplikácie zahŕňa vytvorenie
                                dokumentu<br/>
                                &emsp;4.2.2.Účel spracúvania, právny základ, rozsah spracúvaných osobných údajov a dĺžku
                                uchovávania spracúvaných osobných údajov v tomto prípade definuje Prevádzkovateľ – používateľ
                                webovej aplikácie.<br/>
                                &emsp;4.2.3. MODERNÉ POHREBNÍCTVO  nevykonáva s osobnými údajmi, ktorých
                                prevádzkovateľom je používateľ, žiadne operácie (s výnimkou ich uloženia na serveroch
                                MODERNÉ POHREBNÍCTVO, prípadne v hostingovom centre), najmä do nich nezasahuje,
                                nemení ich, nesprístupňuje, ani ich neodovzdáva tretím osobám (výnimkou je ich sprístupnenie
                                štátnym orgánom v súlade s právnymi predpismi).<br/>
                                &emsp;4.2.4. Účelom spracovania osobných údajov je poskytovať služby prevádzkovateľovi údajov
                                za účelom zmluvných záväzkov, ako je popísané objednávke alebo v zmluve o poskytovaní služieb
                                alebo zmluve o spolupráci so sprostredkovateľom údajov.<br/>
                                &emsp;4.2.5. Poskytovanie služieb môže zahŕňať prijatie objednávok na pohreb, na kvety,
                                vystavenie faktúr, spracovanie darov, spomienkové stránky, vystavenie parte a zverejnenie
                                relevantných informácií o úmrtných oznámeniach a parte v rôznych médiách, vrátane novín a on-
                                line služieb. Na to je potrebné získať prístup k osobným informáciám súvisiacim s objednávkou
                                prostredníctvom on-line riešenia sprostredkovateľa údajov.<br/>
                                &emsp;4.2.6. Sprostredkovateľ údajov nebude spracovávať osobné údaje iným spôsobom, než je
                                dohodnuté v tomto článku. Prevádzkovateľ údajov zodpovedá za presnosť a zákonnosť osobných
                                údajov, na ktoré sa vzťahuje tento článok. Za účelom vykonania dohodnutého poskytovania služieb
                                bude mať prevádzkovateľ údajov prístup ku všetkým dôležitým informáciám zhromaždeným
                                prevádzkovateľom údajov a spracuje tieto informácie podľa účelu vrátane ich ukladania a
                                prípadného ďalšieho uchovávania. Osobné údaje prevedené do databáz nesmú byť použité na iné
                                účely ako sú uvedené v tomto článku.<br/>
                                &emsp;4.2.7. Podľa Nariadenia Európskeho parlamentu a Rady (EÚ) 2016/679 z 27. apríla 2016 o
                                ochrane fyzických osôb pri spracúvaní osobných údajov a o voľnom pohybe takýchto údajov v
                                znení neskorších predpisov (ďalej len ako „GDPR“), informácie o zosnulej osobe nie sú osobnými
                                údajmi, a preto sa na ne tento článok nevzťahuje.<br/>
                                &emsp;4.2.8. Prostredníctvom rôznych služieb sa získavajú rôzne typy osobných údajov:<br/>
                                &emsp;a) Pri objednávaní pohrebu sa zaznamenajú informácie o obstarávateľovi pohrebu a to meno,
                                priezvisko, adresa, rodné číslo, dátum narodenia, kontakt.<br/>
                                &emsp;b) Je bežné, že sa v úmrtnom oznámení alebo parte uvádzajú bezprostrední príbuzní pod krstným
                                menom. Predpokladá sa, že prevádzkovateľ údajov dostal povolenie na zverejnenie úmrtného
                                oznámenia alebo parte s uvedenými osobnými informáciami. Zverejnené informácie môže
                                sprostredkovateľ údajov na žiadosť ktorejkoľvek z uvedených osôb vymazať, ak však boli
                                informácie zverejnené v inom médiu mimo kontroly sprostredkovateľa údajov, je to mimo
                                zodpovednosti sprostredkovateľa údajov.<br/>
                                &emsp;c) Pri vytváraní spomienkových stránok je potrebné zaregistrovať meno a e-mailovú adresu správcu
                                rodiny. Správca rodiny sa môže rozhodnúť vymazať spomienkovú stránku a súvisiace osobné
                                informácie a môže tiež zmeniť informácie o správcovi. Ak spomienková stránka existuje, tieto
                                informácie sa uchovávajú.<br/>
                                &emsp;4.2.9. Je možné pridať ďalšie informácie, pretože služby sa neustále vyvíjajú. V tomto
                                prípade sú zahrnuté do týchto podmienok. VOP budú aktualizované, ak sa služby zmenia takým
                                spôsobom, ktorý ovplyvňuje spracovanie osobných údajov.
                            </Typography>
                            <Typography variant="h4" component="h2">
                                5. SPRACÚVANIE ELEKTRONICKÉHO PODPISU
                            </Typography>
                            <Typography variant="body1" component="h2">
                                &emsp;5.1. Z technického hľadiska je možné vo webovej aplikácií vykonať elektornický podpis
                                dokumentov. Samotný rozsah spracúvaných osobných údajov, právny základ, účel a dĺžku
                                uchovávania určuje Prevádzkovateľ – Používateľ webovej aplikácie.
                            </Typography>
                            <Typography variant="body1" component="h2">
                                &emsp;5.2. Elektronický podpis je po podpísaní dokumentu neoddeliteľne spojený s obsahom
                                podpísaného dokumentu. Ak dôjde k zmene údajov nachádzajúcich sa v podpisanom dokumente,
                                elektornický podpis sa automaticky vymaže.
                            </Typography>
                            <Typography variant="body1" component="h2">
                                &emsp;5.3. MODERNÉ POHREBNÍCTVO  nemá prístup k charakteristikám elektronického
                                podpisu. Elektornický podpis vytvorený vo webovej aplikácií MODERNÉ POHREBNÍCTVO
                                NEMÁ a ani NESPĹŇA štandardy elektronického podpisu podľa “Nariadenia Európskeho
                                parlamentu a Rady č. 910/2014 z 23. Júla 2014 (eIDAS)”, z ktorého vychádza slovenský “Zákon č.
                                272/2016 Z.z. O dôverných službách.”
                            </Typography>
                            <Typography variant="h4" component="h2">
                                6.  AKO DLHO UCHOVÁVAME OSOBNÉ ÚDAJE?
                            </Typography>
                            <Typography variant="body1" component="h2">
                                &emsp;6.1. Vaše osobné údaje uchovávame len obmedzený čas, kým je to potrebné pre naplnenie
                                účelu spracúvania podľa osobitných predpisov, a účelu, na ktorý boli získané, ak máme oprávnený
                                záujem ich uchovať napr. až do ukončenia zmluvného vzťahu a/alebo vypršania premlčacej lehoty,
                                v rámci ktorej by sme mali byť schopný sa brániť proti právnym nárokom. Základným časovým
                                medzníkom spracúvania a uchovávania Vašich osobných údajov je trvanie Vášho zmluvného
                                vzťahu s našou spoločnosťou v zmysle Všeobecných obchodných podmienok, a po jeho zániku do
                                uplynutia lehôt stanovených na uplatnenie práv z tohto vzťahu. Vaše osobné údaje budú vymazané,
                                keď pominú ich účely spracúvania. Doba uchovávania Vašich osobných údajov sa líši v závislosti
                                od legislatívnych požiadaviek, ktorými sa konkrétny účel spracúvania riadi. V každom prípade, aj
                                keď nie je možné určiť konkrétnu dobu spracúvania vopred, vždy sú stanovené presné kritériá jej
                                určenia tak, aby sa osobné údaje neuchovávali dlhšie, než je to nevyhnutné. Vaše osobné údaje
                                môžeme spracúvať po dlhšiu dobu po ukončení zmluvného vzťahu v prípade pretrvávajúceho
                                právneho sporu alebo ak ste nám udelili súhlas na uchovávanie Vašich osobných údajov na dlhší
                                čas.
                            </Typography>
                            <Typography variant="body1" component="h2">
                                &emsp;6.2. V prípade spracúvania osobných údajov, ku ktorému bol udelený súhlas, budú Vaše
                                osobné údaje všeobecne spracúvané po dobu 5 rokov alebo do odvolania takého súhlasu.
                            </Typography>
                            <Typography variant="h4" component="h2">
                                7. OCHRANA OSOBNÝCH ÚDAJOV
                            </Typography>
                            <Typography variant="body1" component="h2">
                                &emsp;7.1. Bezpečnosť osobných údajov ako aj ďalších dôverných informácií, ktoré potrebujeme
                                má u nás vysokú prioritu. Dodržiavame medzinárodné bezpečnostné štandardy. Máme
                                implementovaný rámec smerníc, postupov a školení, ktoré sa vzťahujú na ochranu osobných
                                údajov, zachovanie ich dôvernosti, integrity a dostupnosti. Pravidelne prehodnocujeme primeranosť
                                našich organizačných ako aj technických bezpečnostných opatrení, investujeme do zvyšovania ich
                                účinnosti alebo zavedenia nových nástrojov a opatrení.
                            </Typography>
                            <Typography variant="body1" component="h2">
                                &emsp;7.2. MODERNÉ POHREBNÍCTVO má na zaistenie vysokej úrovne bezpečnosti zavedené
                                technické a organizačné opatrenia v súlade s Nariadením GDPR, zákonom č. 18/2018 Z.z. o
                                ochrane osobných údajov, zákonom č. 69/2018 Z.z. o kybernetickej bezpečnosti a ISO 27001, ktoré
                                sú pravidelne preskúmavané a prehodnocované. Tieto opatrenia zabezpečujú dôvernosť, integritu,
                                dostupnosť a odolnosť systémov spracúvajúcich osobné údaje.
                            </Typography>
                            <Typography variant="body1" component="h2">
                                &emsp;7.3. Integrita, t.j. celistvosť a nemeniteľnosť obsahu podpísaného dokumentu zaručuje, že
                                informácie obsiahnuté v dokumente (jeho obsah) nie je možné zmeniť po podpise dokumentu.
                                Integrita každého dokumentu následne, po tom ako bol podpísaný pomocou digitálneho
                                vlastnoručného podpisu, je zaručená spôsobom vkladania unikátneho digitálneho podpisu pri
                                každom vlastnoručnom podpise. Pri zmene údajov o obstarávateľovi pohrebu a  zosnulom dôjde
                                automaticky k vymazaniu podpisu. Preto sa musí tento dokument opätovne podpísať.
                            </Typography>
                            <Typography variant="body1" component="h2">
                                &emsp;7.4. Osobné ako aj iné citlivé údaje nachádzajúce sa vo webovej aplikácií sú bezpečne
                                uložené v Datacentre spoločnosti Amazon Web Service a samotný server je chránený na viacerých
                                úrovniach. Prvou je aplikačný firewall s IPS (Intrusion Prevention System), ktorý kontroluje všetku
                                komunikáciu za účelom ochrany servera a dát na sieťovej úrovni. Druhou je predradený dedikovaný
                                server, na ktorom je ukončená všetka komunikácia, ktorého úlohou je ochrana dát. Treťou úrovňou
                                je pravidelné zálohovanie dát.
                            </Typography>
                            <Typography variant="body1" component="h2">
                                &emsp;7.5. Všetky dáta, ktoré prechádzajú v rámci webovej aplikácie sú zabezpečené SSL
                                certifikátom s 256- bitovým šifrovaním. Podpis v dokumente je zašifrovaný asynchrónnou šifrou.
                                Každá manipulácia s PDF dokumentami sa zaznamenáva v separátnych perzistentných logoch.
                            </Typography>
                            <Typography variant="body1" component="h2">
                                &emsp;7.6. Pravidelne vykonávame interné bezpečnostné audity a aktualizácie softvéru, aby sme
                                zaistili bezpečnosť a spoľahlivosť našich systémov.
                            </Typography>
                            <Typography variant="body1" component="h2">
                                &emsp;7.7. Sprostredkovateľ údajov ohlási všetky nezrovnalosti prevádzkovateľovi údajov. V
                                prípade porušenia osobných údajov musí sprostredkovateľ údajov bezodkladne informovať
                                prevádzkovateľa údajov hneď ako zistí porušenie osobných údajov v súlade s GDPR.
                            </Typography>
                            <Typography variant="body1" component="h2">
                                &emsp;7.8. Ak prevádzkovateľ údajov zistí, že sprostredkovateľ údajov nespracováva osobné údaje
                                podľa tejto zmluvy alebo v rozpore s požiadavkami v GDPR, môže mu nariadiť, aby okamžite
                                zastavil ďalšie spracovávanie informácií (do 24 hodín).
                            </Typography>
                            <Typography variant="body1" component="h2">
                                &emsp;7.9. V rámci našich spracovateľských činností s osobnými údajmi nevykonávame
                                automatizované profilovanie dotknutých osôb.
                            </Typography>
                            <Typography variant="body1" component="h2">
                                &emsp;7.10. Osobné údaje sa nebudú prenášať mimo Európsky hospodársky priestor (EEA). Vaše
                                osobné údaje nikdy neprenesieme mimo EEA bez toho, aby sme zaistili ich bezpečnosť a ochranu.
                                Preto sa ubezpečíme, že všetci príjemcovia majú podpísané štandardné zmluvné doložky EÚ na
                                odôvodnenie prevodu alebo, že daná krajina garantuje primeranú ochranu podľa aktuálne platnej
                                legislatívy o ochrane osobných údajov.
                            </Typography>
                            <Typography variant="body1" component="h2">
                                &emsp;7.11. Pri spracúvaní osobných údajov v mene prevádzkovateľa údajov dodržiava
                                sprostredkovateľ údajov všetky primerané zvyklosti a pokyny uzavreté písomne medzi
                                prevádzkovateľom údajov a sprostredkovateľom údajov. Ak sprostredkovateľ údajov pokladá
                                akékoľvek pokyny od prevádzkovateľa údajov za nelegálne, musí ho okamžite informovať.
                            </Typography>
                            <Typography variant="body1" component="h2">
                                &emsp;7.12. Sprostredkovateľ údajov je povinný poskytnúť prevádzkovateľovi údajov prístup k
                                svojim písomným technickým a organizačným bezpečnostným opatreniam a poskytnúť pomoc tak,
                                aby mohol prevádzkovateľ údajov plniť svoje povinnosti podľa predpisov.
                            </Typography>
                            <Typography variant="body1" component="h2">
                                &emsp;7.13.Prevádzkovateľ údajov má právo prístupu ku všetkým osobným údajom
                                spracovávaným menom prevádzkovateľa údajov a k systémom používaným na tento účel.
                                Sprostredkovateľ údajov na tento účel poskytne potrebnú pomoc.
                            </Typography>
                            <Typography variant="body1" component="h2">
                                &emsp;7.14. Sprostredkovateľ údajov uchováva kópie osobných údajov, ak je to potrebné na
                                plnenie povinností vyplývajúcich zo zmluvy s prevádzkovateľom údajov a na splnenie účelu
                                spracovania. Osobné údaje budú zmazané, ak bude účel spracovania ukončený. Sprostredkovateľ
                                údajov musí dodržiavať profesijné tajomstvo, pokiaľ ide o dokumentáciu a osobné údaje, ku ktorým
                                má prístup v súlade s týmito VOP. Zabezpečí, že každý, kto pracuje v mene sprostredkovateľa
                                údajov a má prístup k osobným údajom, ktoré sú súčasťou týchto VOP, podlieha povinnosti
                                mlčanlivosti. Toto ustanovenie platí aj po ukončení platnosti zmluvy. Sprostredkovateľ údajov
                                uchováva záznam o spracovateľských činnostiach, ako je stanovené v článku 30 GDPR. Záznam
                                musí byť k dispozícii na požiadanie prevádzkovateľa údajov.
                            </Typography>
                            <Typography variant="h4" component="h2">
                                8. AKÉ SÚ VAŠE PRÁVA?
                            </Typography>
                            <Typography variant="body1" component="h2">
                                &emsp;8.1. V zmysle aktuálne platnej legislatívy máte právo na prístup k svojim osobným údajom,
                                na ich opravu, vymazanie alebo obmedzenie ich spracúvania, ako aj právo vzniesť námietky voči
                                nášmu spracúvaniu. Keď spracúvame Vaše osobné údaje na základe súhlasu, máte taktiež právo
                                kedykoľvek odvolať poskytnutý súhlas.
                            </Typography>
                            <Typography variant="body1" component="h2">
                                &emsp;8.2. Na odvolanie súhlasu so spracúvaním Vašich osobných údajov nám pošlite e-mail na
                                adresu modernepohrebnictvo@gmail.com.
                            </Typography>
                            <Typography variant="body1" component="h2">
                                &emsp;8.3. Všetky svoje práva si môžete uplatniť podaním žiadosti u zodpovednej osoby emailom
                                na modernepohrebnictvo@gmail.com. Pri vybavení Vašej žiadosti sme oprávnení od Vás požadovať
                                predloženie dodatočných identifikačných údajov za účelom jednorazového overenia identity
                                žiadateľa. Pri vybavovaní Vašej žiadosti v zmysle práv dotknutých osôb budeme zároveň
                                zohľadňovať naše preukázateľné legitímne dôvody na spracúvanie osobných údajov alebo na
                                preukázanie, uplatnenie alebo obranu právnych nárokov.
                            </Typography>
                            <Typography variant="body1" component="h2">
                                &emsp;8.4. V prípade, že Vaše osobné údaje spracúvame v úlohe Sprostredkovateľa, odporúčame
                                Vám smerovať svoje podnety a žiadosti prednostne na Prevádzkovateľa osobných údajov, nakoľko
                                nie sme vždy oprávnení vybavovať takéto žiadosti dotknutých osôb bez jeho vedomia.
                            </Typography>
                            <Typography variant="h4" component="h2">
                                9. KOMU POSKYTUJEME OSOBNÉ ÚDAJE?
                            </Typography>
                            <Typography variant="body1" component="h2">
                                &emsp;9.1. K Vašim údajom budú mať prístup naši poverení zamestnanci. V takom prípade sa
                                prístup udelí iba vtedy, ak je to potrebné na vyššie uvedené účely, a len vtedy, ak je zamestnanec
                                viazaný povinnosťou mlčanlivosti.
                            </Typography>
                            <Typography variant="body1" component="h2">
                                &emsp;9.2. Vaše osobné údaje poskytneme iným stranám výlučne iba vtedy, ak nám to priamo
                                vyplýva zo zákona alebo ste k tomu vyjadrili výslovný súhlas. Osobné údaje môžeme poskytovať
                                ešte našim dodávateľom na základe vzájomného zmluvného vzťahu a obchodných podmienok. Tie
                                zaväzujú našich dodávateľov dodržiavať bezpečnostné mechanizmy na ochranu osobných údajov a
                                dodržiavať minimálne rovnakú úroveň bezpečnostných štandardov týkajúcich sa dôvernosti,
                                integrity a dostupnosti údajov tak, aby nedošlo k zníženiu úrovne ochrany nami garantovaných
                                bezpečnostných záruk. Prenos osobných údajov do tretích krajín nevykonávame.
                            </Typography>
                            <Typography variant="body1" component="h2">
                                &emsp;9.3. Ak to vyžaduje zákon alebo súdny príkaz, Vaše osobné údaje môžeme zdieľať napríklad
                                s našimi dodávateľmi alebo klientmi, daňovými úradmi, úradmi sociálneho zabezpečenia, orgánmi
                                činnými v trestnom konaní alebo inými orgánmi verejnej moci.
                            </Typography>
                            <Typography variant="h4" component="h2">
                                10. AKO NÁS KONTAKTOVAŤ?
                            </Typography>
                            <Typography variant="body1" component="h2">
                                &emsp;10.1. V prípade akýchkoľvek otázok týkajúcich sa spracúvania Vašich osobných údajov
                                alebo v prípade, že chcete vedieť viac informácií o tom ako a prečo spracúvame Vaše osobné údaje,
                                alebo máte akékoľvek otázky týkajúce sa uplatnenia Vašich práv, kontaktujte naše zodpovedné
                                osoby za ochranu osobných údajov niektorým z týchto spôsobov: <br/>
                                a)Fyzicky formou listu na adresu sídla spoločnosti: Pod kopaninou 1060/19, 08271 Lipany<br/>
                                b)Elektronicky e-mailom: modernepohrebnictvo@gmail.com
                                c)Telefonicky: +4219 07 292 958<br/>
                                &emsp;10.2. Budeme sa zaoberať akýmikoľvek sťažnosťami a žiadosťami, ktoré dostaneme a
                                odpovieme na ne v lehote 30 dní.
                            </Typography>
                            <Typography variant="h4" component="h2">
                                11. ZÁVEREČNÉ USTANOVENIA
                            </Typography>
                            <Typography variant="body1" component="h2">
                                &emsp;11.1. Prevádzkovateľ informoval dotknutú osobu o spôsobe, rozsahu a účelu spracúvania jej
                                osobných údajov podľa Zákona č. 18/2018 Z.z., o ochrane osobných údajov v platnom znení a
                                Nariadenia európskeho parlamentu a rady (EÚ) 2016/679 o ochrane fyzických osôb pri spracúvaní
                                osobných údajov a o voľnom pohybe takýchto údajov.Prevádzkovateľ informoval dotknutú osobu aj
                                o jej právach. Všeobecné nariadenie o ochrane osobných údajov priznáva dotknutej osobe niekoľko
                                práv ,ktoré si môže uplatniť priamo u nás (ako u subjektu,ktorý jej osobné údaje spracúva) Viac
                                informácii o tom,aké sú Vaše práva a ako ich môžete uplatniť nájdete na webovom sídle
                                prevádzkovateľa www.kandrac.sk v sekcii ochrana osobných údajov / GDPR. Vaša žiadosť na
                                uplatnenie práv môže byť ústna, písomná, elektronická prípadne podaná inými prostriedkami
                                (odporúčame využiť najmä písomnú, alebo elektronickú formu, aby bolo možné v prípadnom
                                konaní o ochrane osobných údajov preukázať, že ste si práva uplatnili). Sme povinný vybaviť Vašu
                                žiadosť bezodkladne, najneskôr do jedného mesiaca od jej doručenia. V prípade akýchkoľvek
                                otázok alebo nejasností nás môžete kontaktovať na telefónnom čísle: 0907 292 958 alebo
                                elektronicky prostredníctvom emailu: modernepohrebnictvo@gmail.com
                            </Typography>
                            <Typography variant="body1" component="h2">
                                &emsp;11.2. Tieto Zásady ochrany osobných údajov sú účinné od 1.10.2021.
                            </Typography>
                            <div className={classes.marginTopLittle}/>


                        </Grid>

                        <Grid item xs={1} sm={2} md={2} className={classes.marginTopLittleMore}>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        </Grid>
    );
}