import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Upcomming from "./Upcomming";
import clsx from "clsx";
import ShortList from "./ShortList";
import UserService from "../../services/user.service";
import { logOut} from "../../helpers/logout";
import {NotificationManager} from "react-notifications";


const useStyles = makeStyles((theme) => ({
    appBarSpacer: theme.mixins.toolbar,
    seeMore: {
        marginTop: theme.spacing(3),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    fixedHeight: {
        height: 340,
    },
}));

export default function MainPage(props) {
    const classes = useStyles();
    const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);

    const [closestFunerals, setClosestFunerals] = useState([]);
    const [closestPickUp, setClosestPickUp] = useState([]);
    const [closestCoffin, setClosestCoffin] = useState([]);


    useEffect(() => {
        UserService.getUpcomingFunerals().then(
            (response) => {
                setClosestFunerals([]);
                response.data.map((item) =>
                    setClosestFunerals(closestFunerals => [...closestFunerals, {
                        funeral_id: item._id,
                        deceased: item.deceased,
                        date: item.date_funeral,
                        place: item.place_funeral
                    }])
                )
            },
            (e) => {
                if (e.response.status === 401) {
                    logOut(props);
                    NotificationManager.warning('Potrebné sa znova prihlásiť!', 'Upozornenie', 5000);
                }
                setClosestFunerals([]);
            }
        );
        UserService.getUpcomingPickUp().then(
            (response) => {
                setClosestPickUp([]);
                response.data.map((item) =>
                    setClosestPickUp(closestPickUps => [...closestPickUps, {
                        funeral_id: item._id,
                        deceased: item.deceased,
                        date: item.planned_pickup,
                        place: item.planned_pickup_place
                    }])
                )
            },
            () => {
                setClosestPickUp([]);
            }
        );
        UserService.getUpcomingCoffin().then(
            (response) => {
                setClosestCoffin([]);
                response.data.map((item) =>
                    setClosestCoffin(closestCoffin => [...closestCoffin, {
                        funeral_id: item._id,
                        deceased: item.deceased,
                        date: item.coffin_arrival,
                        place: item.coffin_arrival_place
                    }])
                )
            },
            () => {
                setClosestCoffin([]);
            }
        );
    }, [props]);

    return (
        <React.Fragment>
            <div className={classes.appBarSpacer}/>
            <Container maxWidth="lg" className={classes.container}>
                <Grid container spacing={3}>
                    {/* Recent Deposits */}
                    <Grid item xs={12} md={12} lg={4}>
                        <Paper className={fixedHeightPaper}>
                            <Upcomming title='Najbližšie vyzdvihnutia nebohých' data={closestPickUp}/>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={12} lg={4}>
                        <Paper className={fixedHeightPaper}>
                            <Upcomming title='Najbližšie príjazdy rakvy' data={closestCoffin}/>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={12} lg={4}>
                        <Paper className={fixedHeightPaper}>
                            <Upcomming title='Najbližšie pohreby' data={closestFunerals}/>
                        </Paper>
                    </Grid>
                    <Grid item xs={12}>
                        <Paper className={classes.paper}>
                            <ShortList/>
                        </Paper>
                    </Grid>
                </Grid>
            </Container>
        </React.Fragment>
    );
}