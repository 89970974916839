import AuthService from "../services/auth.service";
import jwt_decode from "jwt-decode";

export const logOut = (props) => {
    AuthService.logout();
    props.history.push("/");
    window.location.reload();
}

export const checkUnauthorized = (code) => {
    return code && code.toString().includes('401');
}

export const checkExpiration = (props) => {
    const token =
        JSON.parse(localStorage.getItem("user")) &&
        JSON.parse(localStorage.getItem("user"))["accessToken"];
    console.log('expiration', jwt_decode(token).exp)
    console.log('now',  Date.now() / 1000)
    if (jwt_decode(token).exp < Date.now() / 1000) {
        logOut(props);
        localStorage.clear();
        return false;
    }
    return true;
};