import React, {useState, useEffect} from "react";
import {makeStyles} from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import clsx from "clsx";
import Title from "../dashboard/Title";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import SelectInput from "./input/SelectInput";
import UserService from "../../services/user.service";
import {logOut} from "../../helpers/logout";
import {Redirect, useParams} from "react-router-dom";
import {NotificationManager} from 'react-notifications';
import Paper from "@material-ui/core/Paper";
import TextInput from "./input/TextInput";
import DateTimeInput from "./input/DateTimeInput";
import {Grid} from "@material-ui/core";
import {checkEqualityOfObjects} from "../../helpers/helper";
import Popup from "../presentation/Popup";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        '& .MuiFormControl-root': {
            margin: theme.spacing(1)
        }
    },
    appBarSpacer: theme.mixins.toolbar,
    seeMore: {
        marginTop: theme.spacing(8),
    },
    marginTop: {
        marginTop: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    button: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
}));

const propsValuablesCheck = [
    "items"
]

export default function EvidenceForm(props) {
    const classes = useStyles();
    const fixedHeightPaper = clsx(classes.paper, classes.root);
    const seeMoreContainer = clsx(classes.seeMore, classes.root);

    const [values, setValues] = useState({
        items: '',
        placement_fridge: null,
        car_id: '',
        temperature: '',
        disinfection: '',
        disinfection_date: null,
        autopsy: '',
        d_dangerous_disease: '',
        funeral_id: useParams().funeral_id,
        employee: '',
        employee_money: '',
        placement_of_decedent: ''
    });

    const [oldValues, setOldValues] = useState({
        items: '',
        placement_fridge: null,
        car_id: '',
        temperature: '',
        disinfection: '',
        disinfection_date: null,
        autopsy: '',
        d_dangerous_disease: '',
        funeral_id: useParams().funeral_id,
        employee: '',
        employee_money: '',
        placement_of_decedent: ''
    });

    const [show, setShow] = useState(false);
    const [signatures, setSignatures] = useState({});
    const [success, setSuccess] = useState(false);

    const handleChange = (event) => {
        const {name, value} = event.target;
        setValues({...values, [name]: value});
    }
    const params = useParams();
    const hasProperty = params.hasOwnProperty('evidence_id');

    useEffect(() => {
        UserService.getSignatures(params.funeral_id).then(
            (response) => {
                // if (response.data.valuables_signature) {
                //     setShow(true);
                // }
                setSignatures(response.data);
            }
        )
        if (hasProperty) {
            UserService.getEvidence(params.evidence_id).then(
                (response) => {
                    setValues(response.data);
                    setOldValues(response.data);
                    console.log(response.data);
                },
                (e) => {
                    if (e.response.status === 401) {
                        logOut(props);
                        NotificationManager.warning('Potrebné sa znova prihlásiť!', 'Upozornenie', 5000);
                    }
                }
            );
        }
    }, [hasProperty, params.funeral_id, params.evidence_id, props]);

    const handleCreate = (e) => {
        e.preventDefault();
        // if (signatures.valuables_signature) {
        //     if (!checkEqualityOfObjects(values, oldValues, propsValuablesCheck)) {
        //         let data = {
        //             tag: 'valuables',
        //             signatures_id: signatures._id
        //         };
        //         UserService.unSignDocument(data)
        //             .then(() => {
        //             }, (e => {
        //                 console.log(e)
        //             }))
        //     }
        // }
        UserService.createEvidence(values).then(
            () => {
                setSuccess(true);
                NotificationManager.success('Dáta o evidencií upravené.', 'Upravené', 5000);
            },
            (e) => {
                if (e.response.status === 401) {
                    logOut(props);
                    NotificationManager.warning('Potrebné sa znova prihlásiť!', 'Upozornenie', 5000);
                } else {
                    NotificationManager.error('Došlo k neočakávanej chybe. Skúste neskôr alebo kontaktujte podporu.', 'Chyba', 5000);
                }
            }
        );

    };

    return (
        <form onSubmit={handleCreate}>
            <Container maxWidth="lg">
                <div className={seeMoreContainer}>
                    <div>
                        <div>

                            <Paper className={fixedHeightPaper}>
                                <Typography className={classes.instructions} component={'span'}>
                                    <Title>Úprava dát pre evidenciu</Title>
                                </Typography>
                                <TextInput
                                    label="Odovzdané doklady a osobné veci"
                                    name={'items'}
                                    value={values.items}
                                    onChange={handleChange}
                                />
                                <SelectInput
                                    label="Pitva"
                                    name={'autopsy'}
                                    value={values.autopsy}
                                    options={[
                                        {value: 'Áno', title: 'Áno'},
                                        {value: 'Nie', title: 'Nie'},
                                    ]}
                                    onChange={handleChange}
                                />
                                <SelectInput
                                    label="Úmrtie na nebezpečnú chorobu"
                                    name={'d_dangerous_disease'}
                                    value={values.d_dangerous_disease}
                                    options={[
                                        {value: 'Áno', title: 'Áno'},
                                        {value: 'Nie', title: 'Nie'},
                                    ]}
                                    onChange={handleChange}
                                />
                                <DateTimeInput
                                    label="Dátum a čas uloženia do chladiaceho zariadenia"
                                    name={'placement_fridge'}
                                    value={values.placement_fridge}
                                    onChange={handleChange}
                                />
                                <TextInput
                                    label="ŠPZ prepravujúceho vozidla"
                                    name={'car_id'}
                                    value={values.car_id}
                                    onChange={handleChange}
                                />
                                <TextInput
                                    label="Teplota v chladiarenskom zariadení"
                                    name={'temperature'}
                                    value={values.temperature}
                                    onChange={handleChange}
                                />
                                <TextInput
                                    label="Čistenie a dezinfekcia chladiaceho zariadenia a vozidla"
                                    name={'disinfection'}
                                    value={values.disinfection}
                                    onChange={handleChange}
                                />
                                <DateTimeInput
                                    label="Dátum a čas - čistenie a dezinfekcia chladiaceho zariadenia a vozidla"
                                    name={'disinfection_date'}
                                    value={values.disinfection_date}
                                    onChange={handleChange}
                                />
                                 <TextInput
                                    label="Kto vybavuje pohreb (zamestnanec)"
                                    name={'employee'}
                                    value={values.employee}
                                    onChange={handleChange}
                                />
                                 <TextInput
                                    label="Kto prevzal peniaze za pohreb (zamestnanec)"
                                    name={'employee_money'}
                                    value={values.employee_money}
                                    onChange={handleChange}
                                />
                                 <TextInput
                                    label="Miesto, kde sú uložené ľudské ostatky pred pohrebom"
                                    name={'placement_of_decedent'}
                                    value={values.placement_of_decedent}
                                    onChange={handleChange}
                                />
                            </Paper>

                            <Grid container>
                                <Grid item xs={6} sm={6} md={6} lg={6}>
                                    <div className={classes.marginTop}>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            type='submit'
                                            hidden={true}
                                            className={classes.button}
                                        >
                                            Upraviť
                                        </Button>
                                    </div>
                                </Grid>
                                <Grid item container xs={6} sm={6} md={6} lg={6} justifyContent='flex-end'>
                                    <div className={classes.marginTop}>
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            hidden={true}
                                            className={classes.button}
                                            onClick={() => {
                                                setSuccess(true);
                                            }}
                                        >
                                            Späť na detaily
                                        </Button>
                                    </div>
                                </Grid>
                            </Grid>
                        </div>

                    </div>
                </div>
            </Container>
            <Popup
                title={'Upozornenie'}
                openPopup={show}
                setOpenPopup={setShow}
            >
                <Typography component="h2" variant="h6" color="secondary">
                    Pri úprave dát, ktoré sú uvedené pri podpísaných dokumentoch, sa podpis pri dokumente vymaže!
                </Typography>
                <Typography component="div" variant="subtitle1">
                    Dáta pre Dokumenty na podpis
                </Typography>
                <Typography component="div" variant="body2">
                    &emsp;odovzdané doklady a osobné veci
                </Typography>
            </Popup>
            {success ? <Redirect to={{
                pathname: `/pohreb/${params.funeral_id}/info`,
                state: {from: props.location, number: 4}
            }}/> : <></>}
        </form>
    )
}