import React, {useState, useEffect} from "react";
import {makeStyles} from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import clsx from "clsx";
import Title from "../dashboard/Title";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import UserService from "../../services/user.service";
import { logOut} from "../../helpers/logout";
import {NotificationManager} from 'react-notifications';
import Paper from "@material-ui/core/Paper";
import TextInput from "../form/input/TextInput";
import AuthService from "../../services/auth.service";
import {Divider, TextField, Toolbar} from "@material-ui/core";
import Popup from "../presentation/Popup";
import ActionButton from "../form/input/ActionButton";
import Tooltip from "@material-ui/core/Tooltip";
import EditIcon from "@material-ui/icons/Edit";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        '& .MuiFormControl-root': {
            margin: theme.spacing(1),
            width: '100%'
        }
    },
    appBarSpacer: theme.mixins.toolbar,
    seeMore: {
        marginTop: theme.spacing(8),
    },
    marginTop: {
        marginTop: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    button: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    toolbarButtons: {
        marginLeft: 'auto',
    },
}));

export default function DefinedTexts(props) {
    const classes = useStyles();
    const fixedHeightPaper = clsx(classes.paper, classes.root);
    const seeMoreContainer = clsx(classes.seeMore, classes.root);

    const [values, setValues] = useState({
        default_signature_place: '',
        important_contacts: '',
        what_next: ''
    });

    const [editValues, setEditValues] = useState({
        default_signature_place: '',
        important_contacts: '',
        what_next: ''
    });

    const currentUser = AuthService.getCurrentUser();

    const [show, setShow] = useState(false);

    const handleChange = (event) => {
        const {name, value} = event.target;
        setEditValues({...editValues, [name]: value});
    }

    useEffect(() => {
        UserService.getUser(currentUser.id).then(
            (response) => {
                if(response.data.defined_texts) {
                    setValues(response.data.defined_texts);
                    setEditValues(response.data.defined_texts);
                }
            },
            (e) => {
                if (e.response.status === 401) {
                    logOut(props);
                    NotificationManager.warning('Potrebné sa znova prihlásiť!', 'Upozornenie', 5000);
                }
            }
        );
    }, [currentUser.id, props]);

    const handleCreate = (e) => {
        e.preventDefault();

        UserService.createDefinedTexts(editValues).then(
            (defTexts) => {
                setValues(defTexts.data);
                setShow(false);
                NotificationManager.success('Preddefinované texty boli upravené.', 'Upravené', 5000);
            },
            (e) => {
                if (e.response.status === 401) {
                    logOut(props);
                    NotificationManager.warning('Potrebné sa znova prihlásiť!', 'Upozornenie', 5000);
                } else {
                    NotificationManager.error('Došlo k neočakávanej chybe. Skúste neskôr alebo kontaktujte podporu.', 'Chyba', 5000);
                }
            }
        );

    };

    return (

        <Container maxWidth="lg">
            <div className={seeMoreContainer}>
                <div>
                    <div>
                        <Typography className={classes.instructions} component={'span'}>
                            <Paper className={fixedHeightPaper}>
                                <Title>Preddefinované texty</Title>
                                <Toolbar className={classes.toolbarButtons}>
                                    <ActionButton
                                        color="primary">
                                        <Tooltip title="Upraviť preddefinované texty">
                                            <EditIcon
                                                fontSize="small"
                                                onClick={() => {
                                                    setShow(true);
                                                    setEditValues(values);
                                                }}
                                            />
                                        </Tooltip>
                                    </ActionButton>
                                </Toolbar>
                                <Divider/>
                                <Box className={classes.marginTop}>
                                    <Grid container className={classes.root} spacing={2}>
                                        <Grid item xs={12} md={6} lg={6}>
                                            <TextField
                                                id="default_signature_place"
                                                label="Prednastavené miesto podpisu"
                                                multiline
                                                value={values.default_signature_place}
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                fullWidth={true}
                                            />
                                            <TextField
                                                id="important_contacts"
                                                label="Doležité kontakty (informačný list pohrebu)"
                                                multiline
                                                value={values.important_contacts}
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                fullWidth={true}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6} lg={6}>
                                            <TextField
                                                id="what_next"
                                                label="Nasledujúce informačné kroky (informačný list pohrebu)"
                                                multiline
                                                value={values.what_next}
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                fullWidth={true}
                                            />
                                        </Grid>
                                    </Grid>
                                </Box>
                                <Popup
                                    title={'Úprava preddefinovaných textov'}
                                    openPopup={show}
                                    setOpenPopup={setShow}
                                >
                                    <div className={classes.root}>
                                        <form autoComplete='off' onSubmit={handleCreate}>
                                            <TextInput
                                                label="Prednastavené miesto podpisu"
                                                name={'default_signature_place'}
                                                value={editValues.default_signature_place}
                                                onChange={handleChange}
                                            />
                                            <TextInput
                                                label="Doležité kontakty (informačný list pohrebu)"
                                                name={'important_contacts'}
                                                value={editValues.important_contacts}
                                                onChange={handleChange}
                                                multiline
                                            />
                                            <TextInput
                                                label="Nasledujúce informačné kroky (informačný list pohrebu)"
                                                name={'what_next'}
                                                value={editValues.what_next}
                                                onChange={handleChange}
                                                multiline
                                            />
                                            <div className={classes.marginTop}>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    type='submit'
                                                    hidden={true}
                                                    className={classes.button}
                                                >
                                                    Upraviť
                                                </Button>
                                            </div>
                                        </form>
                                    </div>
                                </Popup>
                            </Paper>
                        </Typography>
                    </div>

                </div>
            </div>
        </Container>

    )
}