import React, {useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Title from '../dashboard/Title';
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Table from "@material-ui/core/Table";
import Tooltip from '@material-ui/core/Tooltip';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import PersonIcon from '@material-ui/icons/Person';
import PlaceIcon from '@material-ui/icons/Place';
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";
import Time from "react-time-format";
import {Redirect} from "react-router-dom";
import InfoIcon from "@material-ui/icons/Info";
import ActionButton from "../form/input/ActionButton";

const useStyles = makeStyles({
    depositContext: {
        flex: 1,
    },
    mt: {
        marginTop: 4,
    },
    row: {
        cursor: 'pointer',
        "&:hover, &:focus": {
            backgroundColor: '#fffbf2'
          },
    }
});

export default function Upcomming(props) {
    const classes = useStyles();
    const [funeralDetail, setFuneralDetail] = useState(null);

    const handleClick = (id) => {
        setFuneralDetail(id);
    }


    return (
        <React.Fragment>
            <Title>{props.title}</Title>
            {props.data.length === 0 ? <Typography
                    color="textSecondary"
                    className={clsx(classes.depositContext, classes.mt)}
                    variant="h6">
                    Žiadne udalosti.
                </Typography> :
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell align="center"><PersonIcon/></TableCell>
                            <TableCell align="center"><AccessTimeIcon/></TableCell>
                            <TableCell align="center"><PlaceIcon/></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.data.map((row) => (
                            <TableRow className={clsx(classes.row)} key={row.funeral_id} onClick={() => {
                                handleClick(row.funeral_id)
                            }}>
                                <TableCell>{row.deceased}</TableCell>
                                <TableCell><Time value={row.date} format="DD.MM.YYYY HH:mm"/></TableCell>
                                <TableCell>{row.place}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>}
            {funeralDetail ? <Redirect to={{
                pathname: `/pohreb/${funeralDetail}/info`,
                state: {from: props.location}
            }}/> : <></>}
        </React.Fragment>
    );
}