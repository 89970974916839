import React, { useState, useRef } from "react";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import { isEmail } from "validator";

import AuthService from "../../services/auth.service";

const required = (value) => {
    if (!value) {
        return (
            <div className="alert alert-danger" role="alert">
                This field is required!
            </div>
        );
    }
};

const validEmail = (value) => {
    if (!isEmail(value)) {
        return (
            <div className="alert alert-danger" role="alert">
                This is not a valid email.
            </div>
        );
    }
};

const vusername = (value) => {
    if (value.length < 3 || value.length > 20) {
        return (
            <div className="alert alert-danger" role="alert">
                The username must be between 3 and 20 characters.
            </div>
        );
    }
};

const vpassword = (value) => {
    if (value.length < 6 || value.length > 40) {
        return (
            <div className="alert alert-danger" role="alert">
                The password must be between 6 and 40 characters.
            </div>
        );
    }
};

const Register = () => {
    const form = useRef();
    const checkBtn = useRef();


    const [user, setUser] = useState({
        username: '',
        email: '',
        password: '',
        name: '',
        address: '',
        ico: '',
        ic_dph: '',
        phone: '',
        logo: null,
        email_official: '',
        web: '',
        default_signature_place: ''
    });
    const [successful, setSuccessful] = useState(false);
    const [message, setMessage] = useState("");

    const handleUser = (name) => (e) => {
        setUser({...user, [name]: e.target.value});
    };

    const handleLogo = (e) => {
        setUser({...user, 'logo': e.target.files[0]})
    }

    const handleRegister = (e) => {
        e.preventDefault();

        setMessage("");
        setSuccessful(false);

        form.current.validateAll();
        const data = new FormData();
        if (checkBtn.current.context._errors.length === 0) {
            data.append('username', user.username);
            data.append('email', user.email);
            data.append('password', user.password);
            data.append('name', user.name);
            data.append('address', user.address);
            data.append('ico', user.ico);
            data.append('ic_dph', user.ic_dph);
            data.append('phone', user.phone);
            data.append('email_official', user.email_official);
            data.append('web', user.web);
            data.append('default_signature_place', user.default_signature_place);
            data.append('someimage', user.logo, user.logo.name);
            AuthService.register(data).then(
                (response) => {
                    setMessage(response.data.message);
                    setSuccessful(true);
                },
                (error) => {
                    const resMessage =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();

                    setMessage(resMessage);
                    setSuccessful(false);
                }
            );
        }
    };

    return (
        <div className="col-md-12">
            <div className="card card-container">
                <img
                    src="//ssl.gstatic.com/accounts/ui/avatar_2x.png"
                    alt="profile-img"
                    className="profile-img-card"
                />

                <Form onSubmit={handleRegister} ref={form}>
                    {!successful && (
                        <div>
                            <div className="form-group">
                                <label htmlFor="username">Username</label>
                                <Input
                                    type="text"
                                    className="form-control"
                                    name="username"
                                    value={user.username}
                                    onChange={handleUser('username')}
                                    validations={[required, vusername]}
                                />
                            </div>

                            <div className="form-group">
                                <label htmlFor="email">Email</label>
                                <Input
                                    type="text"
                                    className="form-control"
                                    name="email"
                                    value={user.email}
                                    onChange={handleUser('email')}
                                    validations={[required, validEmail]}
                                />
                            </div>

                            <div className="form-group">
                                <label htmlFor="password">Password</label>
                                <Input
                                    type="password"
                                    className="form-control"
                                    name="password"
                                    value={user.password}
                                    onChange={handleUser('password')}
                                    validations={[required, vpassword]}
                                />
                            </div>

                            <div className="form-group">
                                <label htmlFor="username">Názov spoločnosti</label>
                                <Input
                                    type="text"
                                    className="form-control"
                                    name="name"
                                    value={user.name}
                                    onChange={handleUser('name')}
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="username">Adresa</label>
                                <Input
                                    type="text"
                                    className="form-control"
                                    name="address"
                                    value={user.address}
                                    onChange={handleUser('address')}
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="username">Email na komunikaciu</label>
                                <Input
                                    type="text"
                                    className="form-control"
                                    name="email_official"
                                    value={user.email_official}
                                    onChange={handleUser('email_official')}
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="username">web</label>
                                <Input
                                    type="text"
                                    className="form-control"
                                    name="web"
                                    value={user.web}
                                    onChange={handleUser('web')}
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="username">default_signature_place</label>
                                <Input
                                    type="text"
                                    className="form-control"
                                    name="default_signature_place"
                                    value={user.default_signature_place}
                                    onChange={handleUser('default_signature_place')}
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="username">IČO</label>
                                <Input
                                    type="text"
                                    className="form-control"
                                    name="ico"
                                    value={user.ico}
                                    onChange={handleUser('ico')}
                                    validations={[required, vusername]}
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="username">IČ DPH</label>
                                <Input
                                    type="text"
                                    className="form-control"
                                    name="ic_dph"
                                    value={user.ic_dph}
                                    onChange={handleUser('ic_dph')}
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="username">Kontakt</label>
                                <Input
                                    type="text"
                                    className="form-control"
                                    name="phone"
                                    value={user.phone}
                                    onChange={handleUser('phone')}
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="">Logo</label>
                                <input type="file" onChange={handleLogo}/>
                            </div>

                            <div className="form-group">
                                <button className="btn btn-primary btn-block">Sign Up</button>
                            </div>
                        </div>
                    )}

                    {message && (
                        <div className="form-group">
                            <div
                                className={ successful ? "alert alert-success" : "alert alert-danger" }
                                role="alert"
                            >
                                {message}
                            </div>
                        </div>
                    )}
                    <CheckButton style={{ display: "none" }} ref={checkBtn} />
                </Form>
            </div>
        </div>
    );
};

export default Register;