export const checkEqualityOfObjects = (object1, object2, propsToCheck) => {
    let check = true;
    propsToCheck.map( prop => {
        if(object1[prop] !== object2[prop]){
            check = false
        }
        return prop;
    })
    return check;
}

export const convertSize = (width) => {
    switch (width) {
        case 'xs':
            return '300px'
        case 'sm':
            return '500px'
        case 'md':
            return '800px'
        case 'lg':
            return '1100px'
        default:
            return '1536px'
    }
}
