import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import clsx from "clsx";
import Title from "../dashboard/Title";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import SelectInput from "./input/SelectInput";
import UserService from "../../services/user.service";
import { logOut} from "../../helpers/logout";
import {Redirect, useParams} from "react-router-dom";
import {NotificationManager} from 'react-notifications';
import Paper from "@material-ui/core/Paper";
import TextInput from "./input/TextInput";
import {Grid, InputAdornment, TextField} from "@material-ui/core";
import SelectInputAdjusted from "./input/SelectInputAdjusted";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        '& .MuiFormControl-root': {
            margin: theme.spacing(1)
        }
    },
    appBarSpacer: theme.mixins.toolbar,
    seeMore: {
        marginTop: theme.spacing(8),
    },
    marginTop: {
        marginTop: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    button: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
}));

export default function ProductsForm(props) {
    const classes = useStyles();
    const fixedHeightPaper = clsx(classes.paper, classes.root);
    const seeMoreContainer = clsx(classes.seeMore, classes.root);

    const [success, setSuccess] = useState(false);
    const [entities, setEntities] = useState([]);

    const handleTextInput = index => e => {
        let newArr = [...entities];
        newArr[index].text = e.target.value;
        setEntities(newArr);
    }

    const handleNumberInput = index => e => {
        let newArr = [...entities];
        newArr[index].number = e.target.value;
        setEntities(newArr);
    }

    const handleYesNoInput = index => e => {
        let newArr = [...entities];
        newArr[index].yes_no = e.target.value;
        setEntities(newArr);
    }

    const handleTextCostInput = index => e => {
        let newArr = [...entities];
        newArr[index].text_cost = e.target.value;
        setEntities(newArr);
    }

    const params = useParams();

    useEffect(() => {
        UserService.getFuneral(props.match.params.funeral_id).then(
            (response) => {
                let ents = response.data.product_entities.map( entity => {
                    if(!entity.product_template){
                       return entity;
                    }
                    if(entity.product_template.type === 4){
                        entity.product_template.text_cost_option = entity.product_template.text_cost_option.map(val => {
                            return {text: val.text, cost: val.cost}
                        });
                    }
                    return entity;
                })
                setEntities(ents);
            },
            (e) => {
                if (e.response.status === 401) {
                    logOut(props);
                    NotificationManager.warning('Potrebné sa znova prihlásiť!', 'Upozornenie', 5000);
                }
            }
        );
    }, [props]);

    const handleUpdate = (e) => {
        e.preventDefault();

        UserService.updateProductEntities(entities).then(
            () => {
                setSuccess(true);
                NotificationManager.success('Dáta o produktoch upravené.', 'Upravené', 5000);
            },
            (e) => {
                if (e.response.status === 401) {
                    logOut(props);
                    NotificationManager.warning('Potrebné sa znova prihlásiť!', 'Upozornenie', 5000);
                } else {
                    NotificationManager.error('Došlo k neočakávanej chybe. Skúste neskôr alebo kontaktujte podporu.', 'Chyba', 5000);
                }
            }
        );

    };


    return (
        <form onSubmit={handleUpdate}>
            <Container maxWidth="lg">
                <div className={seeMoreContainer}>
                    <div>
                        <div>
                            <Paper className={fixedHeightPaper}>
                                <Typography className={classes.instructions} component={'span'}>
                                    <Title>Úprava hodnôt produktov</Title>
                                </Typography>
                                {entities.map((item, index) => {
                                    if(!item.product_template){
                                        return <TextField
                                            disabled
                                            key={index}
                                            label={item.name}
                                            variant="outlined"
                                            defaultValue="Hodnota nemenná. Šablóna bola vymazaná." />
                                    }
                                    switch (item.product_template.type) {
                                        case 0:
                                            return <TextInput
                                                label={item.name}
                                                key={index}
                                                type={'text'}
                                                value={entities[index].text ? entities[index].text : ''}
                                                onChange={handleTextInput(index)}
                                                InputProps={{
                                                    startAdornment: <InputAdornment
                                                        position="start"> </InputAdornment>,
                                                }}
                                            />
                                        case 1:
                                            return <SelectInput
                                                key={index}
                                                label={item.name}
                                                value={entities[index].text ? entities[index].text : ''}
                                                options={item.product_template.text_option.map((opt, idx) => {
                                                        return {value: opt, title: opt};
                                                    }
                                                )}
                                                onChange={handleTextInput(index)}
                                            />
                                        case 2:
                                            return <SelectInput
                                                key={index}
                                                label={item.name}
                                                value={entities[index].yes_no !== undefined ? entities[index].yes_no : ''}
                                                options={[{value: true, title: 'Áno'}, {value: false, title: 'Nie'}]}
                                                onChange={handleYesNoInput(index)}
                                            />
                                        case 3:
                                            return <TextInput
                                                label={item.name}
                                                key={index}
                                                type={"number"}
                                                value={entities[index].number ? entities[index].number : ''}
                                                onChange={handleNumberInput(index)}
                                                InputProps={{
                                                    startAdornment: <InputAdornment
                                                        position="start"> </InputAdornment>,
                                                }}
                                            />
                                        default:
                                            return <SelectInputAdjusted
                                                key={index}
                                                label={item.name}
                                                name={'select'}
                                                value={entities[index].text_cost ? entities[index].text_cost : ''}
                                                options={item.product_template.text_cost_option}
                                                onChange={handleTextCostInput(index)}
                                            />
                                    }
                                })}
                            </Paper>

                            <Grid container>
                                <Grid item xs={6} sm={6} md={6} lg={6}>
                                    <div className={classes.marginTop}>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            type='submit'
                                            hidden={true}
                                            className={classes.button}
                                        >
                                            Upraviť
                                        </Button>
                                    </div>
                                </Grid>
                                <Grid item container xs={6} sm={6} md={6} lg={6} justifyContent='flex-end'>
                                    <div className={classes.marginTop}>
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            hidden={true}
                                            className={classes.button}
                                            onClick={() => {
                                                setSuccess(true);
                                            }}
                                        >
                                            Späť na detaily
                                        </Button>
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                </div>
            </Container>
            {success ? <Redirect to={{
                pathname: `/pohreb/${params.funeral_id}/info`,
                state: {from: props.location, number: 3}
            }}/> : <></>}
        </form>
    )
}