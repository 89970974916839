import React from 'react';
import {FormControl, FormHelperText, InputLabel, MenuItem, Select} from "@material-ui/core";

export default function SelectInput(props) {

    const {name, label, options, value, onChange, error = null} = props;

    return (
        <FormControl
            variant='outlined'
            {...(error && {
                error: true
            })}
        >
            <InputLabel>{label}</InputLabel>
            <Select
                name={name}
                label={label}
                value={value}
                onChange={onChange}
            >
                {
                    options.map( (opt, index) => (<MenuItem key={index} value={opt.value}>{opt.title}</MenuItem> ))
                }

            </Select>
            {error && <FormHelperText>{error}</FormHelperText>}
        </FormControl>
    )

}