import axios from "axios";
import {authHeaderForPhotos} from "./auth-header";

const API_URL = process.env.REACT_APP_API_URL_AUTH;

const register = (data) => {
    return axios.post(API_URL + "signup", data, {
     headers: authHeaderForPhotos(data)});
};

const login = (username, password) => {
    return axios
        .post(API_URL + "signin", {
            username,
            password,
        })
        .then((response) => {
            if (response.data.accessToken) {
                localStorage.setItem("user", JSON.stringify(response.data));
            }

            return response.data;
        });
};

const logout = () => {
    localStorage.removeItem("user");
};

const getCurrentUser = () => {
    return JSON.parse(localStorage.getItem("user"));
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    register,
    login,
    logout,
    getCurrentUser,
};