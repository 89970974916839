import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Title from '../dashboard/Title';
import UserService from "../../services/user.service";
import { logOut} from "../../helpers/logout";
import Time from "react-time-format";
import Tooltip from "@material-ui/core/Tooltip";
import {Link, Redirect} from "react-router-dom";
import useTable from "./useTable";
import ActionButton from "../form/input/ActionButton";
import InfoIcon from "@material-ui/icons/Info";
import {NotificationManager} from "react-notifications";

const useStyles = makeStyles((theme) => ({
    seeMore: {
        marginTop: theme.spacing(3),
    },
    depositContext: {
        flex: 1,
    },
}));

const headCells = [
    {id: 'deceased', label: 'Zosnulý', disableSorting: true},
    {id: 'd_death', label: 'Dátum úmrtia', disableSorting: true},
    {id: 'date_funeral', label: 'Pohreb', disableSorting: true},
    {id: 'ca_phone', label: 'Kontakt', disableSorting: true},
    {id: 'actions', label: 'Akcie', disableSorting: true, align: 'right'}
]

export default function ShortList(props) {
    const classes = useStyles();

    const [content, setContent] = useState([]);
    const [funeralDetail, setFuneralDetail] = useState(null);

    const {
        TblContainer,
        TblHead,
    } = useTable(content, headCells);


    useEffect(() => {
        UserService.getListFunerals(5).then(
            (response) => {
                setContent(response.data);
            },
            (e) => {
                if (e.response.status === 401) {
                    logOut(props);
                    NotificationManager.warning('Potrebné sa znova prihlásiť!', 'Upozornenie', 5000);
                }
            }
        );
    }, [props]);

    return (
        <React.Fragment>
            <Title>Krátky zoznam posledných pohrebov</Title>
            <TblContainer>
                <TblHead/>
                <TableBody>
                    {
                        content.map((item, index) =>
                            (<TableRow key={index}>
                                <TableCell>{item.deceased}</TableCell>
                                <TableCell><Time value={item.d_death} format="DD.MM.YYYY HH:mm"/></TableCell>
                                <TableCell><Time value={item.date_funeral} format="DD.MM.YYYY HH:mm"/></TableCell>
                                <TableCell>{item.ca_phone}</TableCell>
                                <TableCell align="right">
                                    <ActionButton
                                        color="primary">
                                        <Tooltip title="Detaily pohrebu">
                                            <InfoIcon
                                                onClick={() => {
                                                    setFuneralDetail(item._id);
                                                }}
                                                fontSize="small"
                                            />
                                        </Tooltip>
                                    </ActionButton>
                                </TableCell>
                            </TableRow>)
                        )
                    }
                </TableBody>
            </TblContainer>
            <div className={classes.seeMore}>
                <Link to="/zoznam" >
                    Celkový zoznam pohrebov
                </Link>
            </div>
            {funeralDetail ? <Redirect to={{
                pathname: `/pohreb/${funeralDetail}/info`,
                state: { from: props.location }
            }} /> : <></>}
        </React.Fragment>
    );
}