import React, {useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import clsx from "clsx";
import Title from "../dashboard/Title";
import Typography from "@material-ui/core/Typography";
import UserService from "../../services/user.service";
import { logOut} from "../../helpers/logout";
import Paper from "@material-ui/core/Paper";
import {Divider, Toolbar} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import {NotificationManager} from "react-notifications";
import {
    DataGrid,
    GridToolbarContainer,
    GridToolbarExport,
} from '@material-ui/data-grid';
import TextInput from "../form/input/TextInput";
import {Link} from "react-router-dom";

function CustomToolbar() {
    return (
        <GridToolbarContainer>
            <GridToolbarExport
                csvOptions={{ utf8WithBom: true }}
            />
        </GridToolbarContainer>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        '& .MuiFormControl-root': {
            margin: theme.spacing(1),
            width: '100%'
        }
    },
    seeMore: {
        marginTop: theme.spacing(8),
    },
    mtlittle: {
        marginTop: theme.spacing(2),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    toolbarButtons: {
        marginLeft: 'auto',
    },
}));

const columns = [
    {
        field: 'deceased',
        headerName: 'Meno a priezvisko zosnuleého',
        width: 150,
        editable: false,
    },
    {
        field: 'd_birth',
        headerName: 'Dátum narodenia',
        width: 150,
        editable: false,
    },
    {
        field: 'd_place_birth',
        headerName: 'Miesto narodenia',

        width: 150,
        editable: false,
    },
    {
        field: 'd_death',
        headerName: 'Dátum úmrtia',
        width: 150,
        editable: false,
    },
    {
        field: 'd_place_death',
        headerName: 'Miesto úmrtia',
        width: 150,
        editable: false,
    },
    {
        field: 'date_funeral',
        headerName: 'Dátum a čas pohrebu',
        width: 200,
        editable: false,
    },
    {
        field: 'd_id',
        headerName: 'Rodné číslo',

        width: 150,
        editable: false,
    }, {
        field: 'autopsy',
        headerName: 'Pitva',
        width: 150,
        editable: false,
    },
    {
        field: 'd_dangerous_disease',
        headerName: 'Úmrtie na nebezpečnú chorobu',
        width: 150,
        editable: false,
    },
    {
        field: 'planned_pickup',
        headerName: 'Dátum a čas prevzatia',
        width: 150,
        editable: false,
    }, {
        field: 'items',
        headerName: 'Odovzdane doklady a osobné veci',
        width: 150,
        editable: false,
    },
    {
        field: 'placement_fridge',
        headerName: 'Dátum a čas uloženia do chlad. zaria.',
        width: 150,
        editable: false,
    },
    {
        field: 'car_id',
        headerName: 'ŠPZ prepravujúceho vozidla',

        width: 150,
        editable: false,
    }, {
        field: 'temperature',
        headerName: 'Teplota (C)',
        width: 150,
        editable: false,
    },
    {
        field: 'disinfection',
        headerName: 'Čistenie a dezinfekcia chladiaceho zariadenia a vozidla',
        width: 150,
        editable: false,
    },
    {
        field: 'disinfection_date',
        headerName: 'Čistenie a dezinfekcia chladiaceho zariadenia a vozidla, dátum a čas',
        width: 150,
        editable: false,
    }, {
        field: 'employee',
        headerName: 'Kto vybavuje pohreb (zamestnanec)',
        width: 150,
        editable: false,
    },, {
        field: 'employee_money',
        headerName: 'Kto prevzal peniaze za pohreb (zamestnanec)',
        width: 150,
        editable: false,
    },, {
        field: 'placement_of_decedent',
        headerName: 'Miesto, kde sú uložené ľudské ostatky pred pohrebom',
        width: 150,
        editable: false,
    },
];

export default function ExportEvidence(props) {
    const classes = useStyles();
    const fixedHeightPaper = clsx(classes.paper, classes.root);
    const seeMoreContainer = clsx(classes.seeMore, classes.root);

    const [values, setValues] = useState([]);
    const [year, setYear] = useState('');

    const handleFetchYear = () => {
        if (!year) {
            NotificationManager.error('Zvoľte rok!', 'Chyba', 5000);
            return;
        }
        UserService.getGeneratedEvidence(year).then(
            (response) => {
                let rows = response.data.map( (fun, index) => {
                    let row = {};
                    row.id = index;
                    row.deceased = fun.deceased;
                    row.d_birth = fun.d_birth ? new Date(fun.d_birth).toShortFormat() : '';
                    row.d_place_birth = fun.d_place_birth;
                    row.d_death = fun.d_death ? new Date(fun.d_death).toShortFormat() : '';
                    row.d_place_death = fun.d_place_death;
                    row.date_funeral = fun.date_funeral ?  new Date(fun.date_funeral).toShortFormatWithTimeExcel() : '';
                    row.d_id = fun.d_id;
                    row.autopsy = fun.evidence ? fun.evidence.autopsy : '';
                    row.d_dangerous_disease = fun.evidence ? fun.evidence.d_dangerous_disease : '';
                    row.planned_pickup = fun.planned_pickup ? new Date(fun.planned_pickup).toShortFormatWithTimeExcel() : '';
                    row.items = fun.evidence ? fun.evidence.items : '';
                    row.placement_fridge = fun.evidence ? (fun.evidence.placement_fridge ? new Date(fun.evidence.placement_fridge).toShortFormatWithTimeExcel() : '') : '';
                    row.car_id = fun.evidence ? fun.evidence.car_id : '';
                    row.temperature = fun.evidence ? fun.evidence.temperature : '';
                    row.disinfection = fun.evidence ? fun.evidence.disinfection : '';
                    row.disinfection_date = fun.evidence ? (fun.evidence.disinfection_date ? new Date(fun.evidence.disinfection_date).toShortFormatWithTimeExcel() : '') : '';
                    row.employee = fun.evidence ? fun.evidence.employee : '';
                    row.employee_money = fun.evidence ? fun.evidence.employee_money : '';
                    row.placement_of_decedent = fun.evidence ? fun.evidence.placement_of_decedent : '';
                    return row;
                })
                setValues(rows);
            },
            (e) => {
                if (e.response.status === 401) {
                    logOut(props);
                    NotificationManager.warning('Potrebné sa znova prihlásiť!', 'Upozornenie', 5000);
                }
            }
        );
    }

    const handleChange = (e) => {
        if(isNaN(e.target.value)){
            NotificationManager.error('Zadajte rok! (napr.: 2021)', 'Chyba', 5000);
        }
        setYear(e.target.value);
    }

    return (

        <Container maxWidth="lg">
            <div className={seeMoreContainer}>
                <div>
                    <div>
                        <div className={classes.instructions}>
                            <Paper className={fixedHeightPaper}>
                                <Title>Export dát evidencie</Title>
                                <Toolbar className={classes.toolbarButtons}>
                                    <TextInput
                                        label="Rok pre generovanie"
                                        name={'cost'}
                                        type="number"
                                        value={year}
                                        onChange={handleChange}
                                    />
                                    <Button size="small" color="primary"
                                            onClick={handleFetchYear}>Načítať dáta</Button>
                                </Toolbar>
                                <Divider/>
                                <div style={{height: 300, width: '100%'}}>
                                    <DataGrid
                                        rows={values}
                                        columns={columns}
                                        disableSelectionOnClick
                                        disableColumnSelector
                                        disableColumnFilter
                                        disableColumnMenu
                                        disableMultipleColumnsSorting
                                        pageSize={5}
                                        components={{
                                            Toolbar: CustomToolbar,
                                        }}
                                    />
                                </div>
                                <Typography component="h2" variant="body2" color="primary" gutterBottom className={classes.mtlittle}>
                                    <Link to="/navod_excel">Ak sú vaše dáta nečitateľné, tu nájdete návod na otvorenie vygenerovaného súboru v programe Google sheets.</Link>
                                </Typography>
                            </Paper>
                        </div>
                    </div>

                </div>
            </div>
        </Container>

    )
}