import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';
import Cloud from '../../assets/cloud.jpg'
import {Paper} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100vh',
    },
    image: {
        backgroundImage: `url(${Cloud})`,
        backgroundRepeat: 'no-repeat',
        backgroundColor:
            theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },
    paper: {
        margin: theme.spacing(8, 4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%',
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    margin: theme.spacing(1),
    marginTopMiddle: {
        marginTop: theme.spacing(15)
    },
    marginTopLittle: {
        marginTop: theme.spacing(3)
    },
    marginTopLittleMore: {
        marginTop: theme.spacing(5)
    }
}));

export default function Policy(props) {
    const classes = useStyles();


    return (
        <Grid container component="main" className={classes.root}>
            <CssBaseline/>
            <Grid item xs={12} sm={12} md={12} className={classes.image}>
                <Paper>
                    <Grid container>
                        <Grid item xs={1} sm={2} md={2} className={classes.marginTopMiddle}>
                        </Grid>

                        <Grid item xs={10} sm={8} md={8} className={classes.marginTopMiddle}>

                            <Typography justify variant="h4" component="h2">
                                VŠEOBECNÉ OBCHODNÉ PODMIENKY UŽÍVANIA WEBOVEJ APLIKÁCIE
                                MODERNÉ POHREBNÍCTVO
                                (účinné od 1.10. 2021)
                            </Typography>
                            <div className={classes.marginTopLittle}/>
                            <Typography variant="h4" component="h2">
                                1. ÚVODNÉ USTANOVENIA
                            </Typography>
                            <Typography variant="body1" component="h2">
                                &emsp;1.1. <span style={{fontWeight: 'bold'}}>MODERNÉ POHREBNÍCTVO s. r. o.</span>, organizačná zložka so sídlom
                                <span style={{fontWeight: 'bold'}}> Pod
                                kopaninou 1060/19, 082 71 Lipany, IČO: 53 959 191</span>, zapísaná v obchodnom registri Okresného
                                súdu  Prešov, oddiel: Sro, vložka č. 42705/P (ďalej len „poskytovateľ“) okrem iného prevádzkuje
                                webovú aplikáciu MODERNÉ POHREBNÍCTVO (ďalej len „webová aplikácia“), ktorá pozostáva
                                z jednotlivých poskytovateľom vytvorených databáz a slúži pohrebným službám na zaznamenanie a
                                uchovanie pohrebu zosnulého/ej, ktorý je prístupný prostredníctvom siete internet na internetovej
                                adrese www.modernepohrebnictvo.sk.<br/>
                                &emsp;1.2. Webovú aplikáciu je možné užívať len na základe písomnej zmluvy, uzavretej medzi
                                poskytovateľom a používateľom (ďalej len „zmluva“; „poskytovateľ“ a „používateľ“).
                                Poskytovateľ a používateľ sú ďalej tiež označovaní ako „zmluvné strany“.<br/>
                                &emsp;1.3. Práva a povinnosti medzi poskytovateľom a užívateľom v súvislosti s užívaním
                                webovej aplikácie sa riadia podľa zmluvy a týmito obchodnými podmienkami (ďalej len „VOP“).
                            </Typography>
                            <Typography variant="h4" component="h2">
                                2. PREDMET ZMLUVY
                            </Typography>
                            <Typography variant="body1" component="h2">
                                &emsp;2.1. Poskytovateľ na základe zmluvy poskytuje používateľovi právo na prístup do webovej
                                aplikácie a právo užívať webovú aplikáciu a používateľ sa zaväzuje uhradiť poskytovateľovi
                                odmenu, a to všetko v rozsahu a za podmienok stanovených zmluvou a týmito VOP.<br/>
                                &emsp;2.2. Používateľ je oprávnený k webovej aplikácií pristupovať výlučne prostredníctvom
                                používateľského účtu zriaďovaného poskytovateľom (ďalej len „používateľský účet“).<br/>
                                &emsp;2.3. Zmluva medzi poskytovateľom a používateľom a všetky jej zmeny musia mať písomnú
                                formu. Vylučuje sa, že by k zmene zmluvy mohlo dôjsť iným než písomným spôsobom. To platí aj
                                pre vzdanie sa požiadavky písomnej formy.<br/>
                                &emsp;2.4. Vzťahy a prípadné spory, ktoré vzniknú na základe zmluvy, budú riešené výlučne podľa
                                platného práva Slovenskej republiky a budú riešené súdmi podľa príslušných právnych predpisov.<br/>
                                &emsp;2.5. Zmluva je uzatváraná v slovenskom jazyku.<br/>
                                &emsp;2.6. Uzavretá zmluva je predávajúcim archivovaná po dobu najmenej piatich rokov od jej
                                uzavretia, najdlhšie však na dobu podľa príslušných právnych predpisov, za účelom jej úspešného
                                splnenia a nie je prístupná tretím nezúčastneným stranám. Informácie o jednotlivých technických
                                krokoch vedúcich k uzatvoreniu zmluvy sú zrejmé z týchto obchodných podmienok, kde je tento
                                proces zrozumiteľne popísaný. Kupujúci má možnosť pred podpísaním zmluvy ju skontrolovať a
                                prípadne opraviť.
                            </Typography>
                            <Typography variant="h4" component="h2">
                                3. ROZSAH A PODMIENKY LICENCIE
                            </Typography>
                            <Typography variant="body1" component="h2">
                                &emsp;3.1. Licencia je udelená ako nevýhradná, územne neobmedzená, neprenosná a časovo
                                obmedzená na dobu trvania zmluvy uzavretej medzi používateľom a poskytovateľom.
                                Používateľské práva udelené poskytovateľom sú obmedzené výhradne na používateľa.<br/>
                                &emsp;3.2. Prevádzkovateľ poskytne používateľovi prístupové údaje do webovej aplikácie. Tieto
                                prístupové údaje môže používateľ poskytnúť ďalším osobám (ďalej len „osoby v organizácií“) v jej
                                organizácií a za ich činnosť vo webovej aplikácií berie používateľ plnú zodpovednosť. Používateľ
                                prestáva byť používateľom zrušením prístupu do webovej aplikácie, a to po oznámení tejto
                                skutočnosti poskytovateľovi používateľom. Pre vylúčenie pochybností používateľ zodpovedá za
                                akékoľvek konanie osôb v organizácií.<br/>
                                &emsp;3.3. Používateľ nie je oprávnený webovú aplikáciu či akúkoľvek jej časť (vrátane
                                akejkoľvek databázy) rozmnožovať, rozširovať alebo inak prenechať či umožniť jeho aj dočasné
                                užitie tretím stranám (organizáciam).<br/>
                                &emsp;3.4. Pre prípad zdieľania prístupu s tretou spolupracujúcou stranou je používateľ povinný
                                vyžiadať si predchádzajúci písomný súhlas poskytovateľa a uviesť mu identifikačné údaje
                                spoluužívateľa (meno, IČO, email, telefón). V tomto prípade si poskytovateľ vyhradzuje právo
                                udelenia súhlasu alebo nesúhlasu so zdieľaním prístupu.<br/>
                                &emsp;3.5. Používateľ je oprávnený používať webovú aplikáciu v rámci udelenej licencie výlučne
                                pre vlastnú potrebu. Používateľ je oprávnený pristupovať a môže využívať všetky služby webovej
                                aplikácie.<br/>
                                &emsp;3.6. Licencia sa rovnako vzťahuje na aktualizácie webovej aplikácie vykonané
                                poskytovateľom počas doby trvania zmluvy.
                            </Typography>
                            <Typography variant="h4" component="h2">
                                4. OBJEDNÁVKOVÝ PROCES
                            </Typography>
                            <Typography variant="body1" component="h2">
                                &emsp;4.1. Objednávať je možné nasledujúcimi spôsobmi:<br/>
                                &emsp;•  emailom: modernepohrebnictvo@gmail.com<br/>
                                &emsp;•  telefonicky na tel. +421907292958<br/>
                                &emsp;4.2. Objednávky cez e-mail a telefón medzi 8:00 a 20:00 hodinou v pracovné dni.
                            </Typography>
                            <Typography variant="h4" component="h2">
                                5. TECHNICKÉ PODMIENKY PRE PRÍSTUP DO WEBOVEJ APLIKÁCIE
                            </Typography>
                            <Typography variant="body1" component="h2">
                                &emsp;5.1. Technické podmienky pre využívanie online prístupu do webovej aplikácie sú
                                nasledovné:<br/>
                                &emsp;• Internetové pripojenie<br/>
                                &emsp;• Internetový prehliadač Internet Mozilla, Safari, Firefox , Google Chrome, Microsoft Edge<br/>
                                &emsp;• Poskytovateľ tieto technické podmienky nezabezpečuje a ani nenesie náklady za ich
                                zabezpečenie.
                            </Typography>
                            <Typography variant="h4" component="h2">
                                6. UŽÍVATEĽSKÝ ÚČET
                            </Typography>
                            <Typography variant="body1" component="h2">
                                &emsp;6.1. Používateľský účet zakladá poskytovateľ webovej aplikácie. Poskytovateľ vytvorí
                                podľa dohody používateľský účet s vopred dohodnutým prihlasovacím menom a heslom. Heslo je
                                následne možné zmeniť v nastaveniach profilu používateľa.<br/>
                                &emsp;6.2. Používateľský účet ďalej obsahuje tieto informácie: logo, obchodný názov, email,
                                email-y pre notifikácie, web stránku, adresu, IČO, IČ DPH, telefón používateľa.<br/>
                                &emsp;6.3. Prístup k používateľskému účtu je zabezpečený používateľským menom a heslom.
                                Používateľ je povinný zachovať mlčanlivosť ohľadom týchto informácií, ktoré umožňujú prístup k
                                používateľskému účtu (výnimka sú osoby v organizácií). Používateľ nie je oprávnený umožniť
                                využívanie používateľského účtu tretím stranám.<br/>
                                &emsp;6.4. Poskytovateľ môže zrušiť používateľský účet, a to najmä v prípade, kedy kupujúci svoj
                                používateľský účet dlhšie ako 2 roky nevyužíva či v prípade, kedy kupujúci poruší svoje povinnosti
                                z kúpnej zmluvy (vrátane obchodných podmienok).<br/>
                                &emsp;6.5. Kupujúci berie na vedomie, že používateľský účet nemusí byť dostupný nepretržite, a to
                                najmä s ohľadom na údržbu hardvérového a softvérového vybavenia poskytovateľa, alebo jeho
                                partnerov.
                            </Typography>
                            <Typography variant="h4" component="h2">
                                7. BEZPEČNOSŤ
                            </Typography>
                            <Typography variant="body1" component="h2">
                                &emsp;7.1. K používateľskému účtu dostane oprávnená osoba unikátne prihlasovacie údaje, ktoré je
                                povinná zabezpečiť proti strate, odcudzeniu a zneužitiu tretími osobami tak, aby nemohlo dôjsť k
                                používaniu webovej aplikácie treťou osobou. Používateľ, ako ani osoby v organizácií nie sú
                                oprávnení zdieľať prihlasovacie údaje do webovej aplikácie so žiadnymi osobami. Ak hrozí
                                zneužitie alebo použitie používateľského účtu nepovolanou osobou, sú používateľ a osoby v
                                organizácií povinní na to poskytovateľa bez zbytočného odkladu písomne alebo telefonicky
                                upozorniť. Poskytovateľ je v takom prípade oprávnený prihlasovacie údaje k používateľskému účtu
                                zablokovať a vystaviť používateľovi nové prihlasovacie údaje. O týchto povinnostiach je používateľ
                                povinný vopred informovať osoby v organizácií a za porušenie akejkoľvek povinnosti podľa tohto
                                ustanovenia zodpovedá používateľ.<br/>
                                &emsp;7.2. Poskytovateľ si vyhradzuje právo zmeniť heslo k používateľskému prístupu spravidla k
                                výročnému dňu predĺženia predplatného alebo aj skôr na základe vyžiadania používateľa.
                                Poskytovateľ je povinný používateľa o takejto zmene písomne informovať.<br/>
                                &emsp;7.3. V prípade dôvodného podozrenia porušenia akejkoľvek zmluvnej alebo zákonnej
                                povinnosti používateľa alebo oprávnenej osoby je poskytovateľ oprávnený obmedziť alebo
                                zablokovať používateľovi alebo oprávnenej osoby prístup do webovej aplikácie, a to do
                                uspokojivého odstránenia týchto pochybností.<br/>
                                &emsp;7.4. Dáta sú uchované a zálohované na serveroch Amazon Inc., ktorý vystupujú ako
                                poskytovateľ hardvéru pre funkčnosť aplikácie. Dáta sú zálohované každy deň.<br/>
                                &emsp;7.5. Portál je zabezpečený SSL certifikátom, ktorý zabezpečuje bezpečnú komunikáciu
                                medzi webovými servermi prostredníctvom HTTPS protokolu. SSL spojenie funguje na princípe
                                asymetrickej šifry, kedy každá z komunikujúcich strán má dvojicu šifrovacích kľúčov - verejný a
                                súkromný. Verejný kľúč je možné zverejniť a ak týmto kľúčom ktokoľvek zašifruje nejakú správu,
                                je zabezpečené, že ju bude môcť rozšifrovať len majiteľ použitého verejného kľúča svojím
                                súkromným kľúčom. Údaje o zabezpečení osobných údajov je možné nájsť v nasledovnej sekcii:
                                Ochrana osobných údajov.
                            </Typography>
                            <Typography variant="h4" component="h2">
                                8. CENY
                            </Typography>
                            <Typography variant="body1" component="h2">
                                &emsp;8.1. Všetky ceny sú zmluvné. Ceny sú konečné, tzn. vrátane DPH.<br/>
                                &emsp;8.2. Používateľ dostane softvér za cenu platnú v čase objednania. Používateľ má možnosť sa
                                pred vykonaním objednávky oboznámiť s celkovou cenou vrátane DPH. Táto cena bude uvedená v
                                zmluve. Používateľ spotrebiteľ má možnosť sa pred podpísaním zmluvy oboznámiť so
                                skutočnosťou, po akú dobu zostáva ponuka alebo cena v platnosti.<br/>
                                &emsp;8.3.V celkovej cene nie sú zahrnuté žiadne ďalšie služby posyktovateľa. Práce navyše sú
                                poskytovateľom účtované na základe individuálnej dohody, spravidla hodinovou sadzbou
                                dohodnutou so záujemcom o tieto služby.<br/>
                                &emsp;8.4. Prehľadový cenník vydávaný poskytovateľom, ako aj ústne a telefonické informácie či
                                informácie poskytované prostredníctvom internetových www serverov o cenách webovej aplikácie
                                sú informatívne, zo strany poskytovateľa nezáväzné a používateľom nevymáhateľné. Poskytovateľ
                                si vyhradzuje právo zmeniť technické parametre, popr. aj ceny webovej aplikácie bez
                                predchádzajúceho oznámenia. Poskytovateľ nezodpovedá za chyby vzniknuté pri tlači obchodných
                                a technických materiálov.<br/>
                                &emsp;8.5. Pre upresnenie ceny a špecifikácie individuálne zákazky je používateľ oprávnený si
                                vyžiadať záväznú cenovú ponuku (ďalej len "záväzná cenová ponuka"), ktorej platnosť je 30
                                kalendárnych dní odo dňa vystavenia, ak nie je uvedené inak.<br/>
                                &emsp;8.6. Pre cenovú kalkuláciu webovej aplikácie platia ceny, uvedené v platnej záväznej
                                cenovej ponuke poskytovateľa, alebo ceny platné v okamihu prijatia objednávky. Ceny webovej
                                aplikácie uvádzané v cenovej ponuke nezahŕňajú žiadne súvisiace služby, pokiaľ nie je výslovne
                                uvedené inak. Požiadavku na poskytnutie súvisiacich služieb je potrebné uviesť v objednávke.<br/>
                                &emsp;8.7. Ak je dohodnutá úhrada podľa hodinovej sadzby, vykonáva sa vyúčtovanie na
                                základe záznamu o vykonaní práce, ktorý vedie každý pracovník poskytovateľa. Fakturácia sa
                                vykonáva mesačne dodatočne.<br/>
                                &emsp;8.8. Ak dosiahne index rastu cien v porovnaní so stavom ku dňu uzavretia zmluvy 20%, je
                                poskytovateľ oprávnený zvýšiť používateľovi výšku pravidelných mesačných platieb za webovú
                                aplikáciu počnúc kalendárnym mesiacom nasledujúcim po uvedenom náraste indexu o 20%.<br/>
                                &emsp;8.9. K ďalšiemu zvýšeniu o 20% môže poskytovateľ pristúpiť a zvýšiť pravidelné mesačné
                                platby za webovú aplikáciu obdobne vždy, keď index rastu cien dosiahne 20% v porovnaní s 1.
                                dňom kalendárneho mesiaca, v ktorom došlo k poslednému zvýšeniu pravidelných platieb za
                                webovú aplikáciu.
                            </Typography>
                            <Typography variant="h4" component="h2">
                                9. PLATOBNÉ PODMIENKY
                            </Typography>
                            <Typography variant="body1" component="h2">
                                &emsp;9.1. Predávajúci akceptuje nasledujúce platobné podmienky:<br/>
                                &emsp;- platba vopred bankovým prevodom<br/>
                                &emsp;- platba cez internetové rozhranie banky<br/>
                                &emsp;9.2.Podľa zákona o evidencii tržieb je predávajúci povinný vystaviť kupujúcemu faktúru.<br/>
                                &emsp;9.3.V prípade, že faktúra vystavená poskytovateľom nieje zaplatená v lehote uvedenej v
                                odseku 1 tohto článku, bude účtovaný úrok vo výške 10% za kalendárny mesiac z celkovej dlžnej
                                sumy.<br/>
                                &emsp;9.4.Kópia faktúry zaslaná poskytovateľom slúži ako dostatočný dôkaz dlhu.
                            </Typography>
                            <Typography variant="h4" component="h2">
                                10. ROZŠÍRENIE BALÍČKU
                            </Typography>
                            <Typography variant="body1" component="h2">
                                &emsp;10.1. Poskytovateľ je oprávnený rozšíriť obsah jednotlivých modulov ako aj cenu
                                vzťahujúcu sa na tieto moduly. Poskytovateľ je povinný zvýšenie ceny oznámiť používateľovi
                                najneskôr 14 dní pred jej účinnosťou elektronicky na emailovú adresu používateľa.<br/>
                                &emsp;10.2. Používateľ je oprávnený vypovedať zmluvu do 14 dní od doručenia oznámenia o
                                zvýšení ceny z dôvodu nesúhlasu so zvýšením ceny. Zmluva zaniká uplynutím posledného dňa
                                prebiehajúceho obdobia.
                            </Typography>
                            <Typography variant="h4" component="h2">
                                11. POVINNOSTI STRÁN A ZÁRUKA
                            </Typography>
                            <Typography variant="body1" component="h2">
                                &emsp;11.1. Používateľ zodpovedá za obsah a dáta zverejnené a umiestnené na virtuálnom serveri a
                                zaväzuje sa všetky služby užívať len v súlade s platnými právnymi predpismi Slovenskej republiky,
                                Európskej únie, medzinárodnými zmluvami, ktorými je Slovenská republika viazaná, a v súlade s
                                dobrými mravmi.<br/>
                                &emsp;11.2. Používateľ nie je oprávnený používať produkt a služby k šíreniu SPAMu, ktoré by
                                mohli príjemcu obťažovať alebo mravne a psychicky poškodiť. Používateľ sa zaväzuje, že nebude
                                poškodzovať meno poskytovateľa ani ostatných používateľov Internetu, bude jednať v súlade s
                                VOP a najmä sa nebude akýmkoľvek spôsobom podieľať na činnostiach, ktoré sú považované za
                                hrubé porušenie VOP:<br/>
                                &emsp;a) využívať webovú aplikáciu pre rozosielanie nevyžiadaných e-mailov (spamming), reťazových
                                e-mailov, prípadne ich duplikácii,<br/>
                                &emsp;b) nahrávať do webovej aplikácie nelegálny softvér a audiovizuálne súbory. V opačnom prípade je
                                poskytovateľ oprávnený tento nelegálny softvér a audiovizuálne súbory odstrániť,<br/>
                                &emsp;c) odosielať správy alebo ponúkať na sťahovanie (download) súbory obsahujúce vírusy, trojské
                                kone a iné nebezpečné programy,<br/>
                                &emsp;d) snažiť sa preniknúť na účty (stránky, diskové priestory apod.) iných používateľov Serverov
                                poskytovateľa,
                                e) e) zverejňovať informácie, ktoré sú v rozpore s platnými zákonmi SR.
                            </Typography>
                            <Typography variant="h4" component="h2">
                                12. POVINNOSTI A PRÁVA POSKYTOVATEĽA (ZODPOVEDNOSŤ ZA DOSTUPNOSŤ A
                                OBSAH)
                            </Typography>
                            <Typography variant="body1" component="h2">
                                &emsp;12.1. Poskytovateľ nepreberá nijakú zodpovednosť voči používateľovi alebo tretím osobám
                                za spôsob využitia obsahu databáz webovej aplikácie používateľom.<br/>
                                &emsp;12.2. Poskytovateľ zodpovedá za to, že dostupnosť webovej aplikácie nebude nižšia než 90
                                %, a to merané vždy počas všetkých pracovných dní v kalendárnom mesiaci od 08.00 do 20.00 hod
                                v mieste pripojenia servera poskytovateľa k sieti. Poskytovateľ tak hlavne nezodpovedá za
                                akékoľvek prerušenie či výpadky pripojenia k sieti Internet poskytovaného používateľovi tretími
                                osobami.<br/>
                                &emsp;12.3. Poskytovateľ je oprávnený prerušiť prevádzku servera, a to v čase nevyhnutne
                                potrebnom, najmä z dôvodu údržby a úprav zariadení, ktoré priamo ovplyvňujú beh služieb
                                objednaných používateľom. Poskytovateľ v prípade, že je to možné, informuje o výpadku
                                používateľa dopredu, pomocou e-mailu alebo telefónu. Ak je výpadok spôsobený poskytovateľom
                                dlhší ako 24 hodín, znižuje sa suma za služby o pomernú čiastku počtu dní v mesiaci, kedy nebol
                                server v prevádzke. Pri platbe na dlhšie obdobie ako mesiac sa ponižuje suma z pomernej časti,
                                kedy bol server mimo prevádzky.<br/>
                                &emsp;12.4. Poskytovateľ má právo viesť elektronickú databázu svojich používateľov, monitorovať
                                a archivovať všetky vykonávané operácie na svojich zariadeniach za účelom vyhodnocovania a
                                zlepšovania poskytovaných služieb, a to v súlade so zák. č 101/2000 Zb., o ochrane osobných
                                údajov.<br/>
                                &emsp;12.5. Poskytovateľ má  právo okamžite pozastaviť službu v prípade ak sa dozvie, že
                                používateľ publikuje na stránke obsah, ktorý je v rozpore so zákonmi slovenskej republiky či
                                platnej legislatívy Európskej únie.<br/>
                                &emsp;12.6. Poskytovateľ nezodpovedá za chyby ani škody spôsobené vadami webovej aplikácie
                                alebo jej chybnými výstupmi, ak boli zapríčinené používateľom, tretími osobami alebo okolnosťami
                                vylučujúcimi zodpovednosť. Poskytovateľ nezodpovedá za chyby ani za škodu spôsobenú najmä:<br/>
                                &emsp;• vložením nesprávnych údajov do webovej aplikácie používateľom, chybným postupom
                                používateľa pri vkladaní informácií alebo súborov do webovej aplikácie alebo nesprávnou
                                interpretáciou údajov prezentovaných webovou aplikáciou,<br/>
                                &emsp;• zavírovaním lokálnej siete používateľa alebo jeho počítačov počítačovými vírusmi (spyware,
                                malware a iné) popr. útokom hackerov alebo iným obdobným vonkajším útokom,<br/>
                                &emsp;• v dôsledku poškodenia spôsobeného neodborným zásahom do webovej aplikácie do
                                systémového programového vybavenia a prostredia,<br/>
                                &emsp;• poškodením spôsobeného nesprávnou funkciou technického vybavenia, operačného systému
                                alebo siete, v dôsledku poškodenia spôsobeného nesprávnou funkciou programov iných
                                výrobcov, ktoré bežia súčasne s dodaným programovým vybavením.<br/>
                                &emsp;• poskytovateľ nezodpovedá za prerušenie poskytovania služieb, za závady, škody a stratu či
                                poškodenie dát spôsobenú zásahom vyššej moci, v prípade poruchy na zariadení, zlyhania
                                dodávky el. energie, výpadku internetového pripojenia spôsobené dodávateľom pripojenia
                                alebo napadnutie siete treťou osobou,<br/>
                                &emsp;• poskytovateľ nezodpovedá za závady, škody a stratu či poškodenie dát vzniknutú v dôsledku
                                nesprávnej obsluhy správcom systému používateľa alebo v dôsledku napadnutia serveru
                                treťou osobou z dôvodu nedodržania bezpečnostných štandardov obvyklých pri prevádzke
                                hostingu na internete.<br/>
                                &emsp;• poskytovateľ nezodpovedá za porušenie autorských práv používateľom, práv k ochranným
                                známkam, práv k obchodnému menu a iných práv chránených slovenskými zákonmi.<br/>
                                &emsp;12.7. Účastníci tohto záväzkového vzťahu stanovujú, že výška predvídateľnej škody, ktorá
                                prípadne môže vzniknúť porušením povinností poskytovateľa, predstavuje čiastku maximálne do
                                výšky 30% z ceny webovej aplikácie, ohľadom ktorej došlo ku škodovej udalosti, ak nie je
                                dohodnutá iná výška.<br/>
                                &emsp;12.8. Poskytovateľ nenesie zodpovednosť za stratu alebo poškodenie dát používateľa,
                                prípadná rekonštrukcia stratených alebo znehodnotených dát ide na vrub používateľa.<br/>
                                &emsp;12.9. Pri napadnutí webovej aplikácie, si poskytovateľ vyhradzuje právo na stratu dát za
                                posledných 7 dní.<br/>
                                &emsp;12.10. Účastníci tohto záväzkového vzťahu nezodpovedajú, okrem prípadov stanovených
                                príslušným zákonom, za porušenie záväzkov spôsobené vyššou mocou, tj okolnosťami, ktoré
                                nastanú nezávisle na vôli účastníkov, ktoré nebolo možné ani s vynaložením všetkého možného
                                úsilia odvrátiť, resp. sú objektívne neodvrátiteľnou náhodou.<br/>
                                &emsp;12.11. Vyššia moc:<br/>
                                Spomínanými skutočnosťami, ktoré nemôžu účastníci ovplyvniť a ktoré vylučujú zodpovednosť, sú
                                mienené:<br/>
                                &emsp;• vojnový stav,<br/>
                                &emsp;• zákonné obmedzenia vývozu, dovozu, výroby, štrajku, sabotáže,<br/>
                                &emsp;• živelná pohroma<br/>
                                &emsp;• ďalšie skutočnosti, ktoré poskytovateľ nebol schopný dôvodne predpokladať, alebo pri
                                primeranom úsilí ovplyvniť a ktoré bránia naplneniu uzavretej zmluvy.<br/>
                                &emsp;12.12. O vzniku okolností vylučujúcich zodpovednosť je dotknutá strana povinná
                                informovať druhú stranu bez zbytočného odkladu, inak stráca právo na uplatnenie dôsledkov týchto
                                okolností. Po skončení trvania týchto okolností je dotknutá strana povinná ihneď informovať druhú
                                stranu o náhradnom termíne plnenia. Poskytovateľ splní povinnosť podľa tohto odstavca
                                uverejnením informácie o vzniku / skončení týchto okolností na svojej oficiálnej produktovej
                                stránke.<br/>
                                &emsp;12.13. Pokiaľ nie je výslovne stanovené inak, strany nebudú hradiť jedna druhej náhradu:<br/>
                                &emsp;• ušlého zisku, ktorý vznikne v súvislosti s plnením zmluvy, to neplatí, ak škoda vznikne v
                                dôsledku konania založeného na podvodnom konaní, úmysle alebo hrubej nedbanlivosti
                                zodpovednej strany<br/>
                                &emsp;• za škody, ktoré vzniknú v súvislosti s objednávkou vystavenou ústne.
                            </Typography>
                            <Typography variant="h4" component="h2">
                                13. PODPORA, AKTUALIZÁCIA,  RIEŠENIE INCIDENTOV A ZÁVAD
                            </Typography>
                            <Typography variant="body1" component="h2">
                                &emsp;13.1. Poskytovateľ prostredníctvom telefónneho poradenského servisu poskytuje podporu
                                pri riešení problémov alebo chýb týkajúcich sa práce s webovou aplikáciou v pracovných dňoch od
                                9.00 do 16.00 hod. na tel. čísle: +421 908 997 258. Oznámenie používateľovi je možné poskytnúť
                                aj elektronickou poštou na emailovú adresu modernepohrebnictvo@gmail.com. V oznámení je
                                potrebné stručne uviesť údaje používateľa (prihlasovacie meno a meno organizácie) a v čom je
                                pozorovateľný príslušný problém či chyba. Telefónne číslo či e-mailovú adresu je poskytovateľ
                                oprávnený jednostranne meniť oznámením na internetových stránkach webovej aplikácie.
                                &emsp;13.2. Poskytovateľ zabezpečí používateľovi nasledujúce servisné služby:
                                &emsp;• vyhradenie kontaktnej osoby pre riešenie požiadaviek,
                                &emsp;• telefonická / e-mailová technická podpora na riešenie príp. incidentov a závad.
                                Poskytovateľ v žiadnom prípade neposkytuje zdrojové kódy aplikácií.
                                &emsp;13.3. Poskytovateľ vykonáva aktualizáciu webovej aplikácie spravidla raz mesačne.
                                Aktualizácie sú automatické bez nutnosti ich potvrdenia.
                                &emsp;13.4. Aktualizácie sú používateľmi vykonávané na serveroch poskytovateľa automaticky a
                                bez poplatku. Poskytovateľ však môže po predchádzajúcom oznámení túto službu spoplatniť.
                                &emsp;13.5. O periodicite a rozsahu aktualizácií rozhoduje poskytovateľ. Primárnym účelom
                                aktualizácií je prispôsobovanie webovej aplikácie novým verziám programovacích jazykov, v
                                ktorých je webová aplikácia napísaná. Sekundárnym účelom je uľahčenie obsluhy webovej
                                aplikácie. Nové funkcie a moduly administrácie webovej aplikácie nie sú automaticky súčasťou
                                aktualizácie, ich zaradenie alebo nezaradenie do aktualizačných balíčkov závisí na rozhodnutí
                                poskytovateľa.
                                &emsp;13.6. Poskytovateľ je oprávnený obmedziť alebo pozastaviť prevádzku webovej aplikácie na
                                dobu nevyhnutne potrebnú na vykonanie aktualizácie. Poskytovateľ neručí za prípadné finančné
                                straty používateľa spôsobených zastavením prevádzky webovej aplikácie z dôvodu aktualizácie.
                                &emsp;13.7. Poskytovateľ priebežne informuje používateľa o aktualizáciách a updatoch.
                                &emsp;13.8. Poskytovateľ nemôže ručiť za bezproblémovú funkčnosť napojenia produktu na
                                programy tretích strán.
                                &emsp;13.9. Poskytovateľ je povinný riešiť incidenty a odstraňovať závady, a to v lehotách
                                uvedených v čl. 13.12.
                                Skutočnosť o nájdenej závade je používateľ povinný bezodkladne oznámiť poskytovateľ.
                                Používateľ je povinný oznámiť závadu iba elektronicky prostredníctvom e-mailu na adresu
                                modernepohrebnictvo@gmail.com . V prípade kritickej závady je pre dodržanie reakčnej doby a
                                doby pre vyriešenie používateľ je vždy povinný závadu oznámiť telefonicky na 0907292958.
                                &emsp;13.10. Pre účely stanovenia spôsobu a doby odstránenia závad a vyriešenia incidentu sa
                                rozlišujú tieto kategórie závad a incidentov takto:
                                &emsp;• „kritické závady“ – vady takého charakteru, ktoré sú zásadnou prekážkou vo fungovaní
                                Programu a zásadným spôsobom znemožňujú jeho využitie pre primárne stanovený účel.
                                Jedná sa napríklad o nefungujúce vytvorenie pohrebu vrátane všetkých modulov v ňom
                                nutných k vytvoreniu, nefungujúce dokončenie vytvorenia pohrebu, zle sa zobrazujúce dáta
                                atd...
                                &emsp;• „vážne závady“ – vady, ktoré zásadným spôsobom neznemožňujú chod webovej aplikácie a
                                jeho využitie pre primárne stanovený účel. Jedná sa teda o závady, ktoré obmedzujú
                                závažným spôsobom funkčnosť niektorých modulov webovej aplikácie, napr. nefungujúce
                                zobrazenie dát, nemožnosť vytvorenia elektronického podpisu, nefungujúce prihlásenie
                                zákazníka.
                                &emsp;• „drobné závady“ - chyby, ktoré zásadným spôsobom neznemožňujú chod webovej aplikácie a
                                jej využitie pre primárne stanovený účel. Jedná sa teda o závady, ktoré obmedzujú
                                nežiadúcim spôsobom funkčnosť modulov webovej aplikácie, napr. drobný nesúlad v
                                implementácii grafiky, formulárových polí, nezarovnávanie textov podľa grafiky, a
                                podobných drobných chýb vzniknutých napríklad napĺňaním textov v administrácii, chyby
                                grafického či kozmetického charakteru.
                                &emsp;• „incident“ (chyba na strane uživateľa alebo tretej strany) – chovanie webovej aplikácie, ktoré
                                môže mať charakter kritickej, vážnej či drobnej závady, avšak bolo zapríčinené používateľom
                                alebo treťou osobou; za incident nenesie poskytovateľ žiadnu zodpovednosť a nie je viazaný
                                lehotami uvedenými nižšie v čl. 13.12. týchto VOP (reakčná doba, doba pre vyriešenie) a nie
                                je povinný podieľať sa na odstraňovaní následkov takto zapríčinených incidentov.
                                13.11. Za závady sa ďalej nepovažujú ďalej uvedené prejavy webovej aplikácie, pričom v takýchto
                                prípadoch nevznikajú používateľovi žiadne nároky zo zodpovednosti za vady ani nárok na
                                odstúpenie od Zmluvy:
                                &emsp;• prípadné chybné hlásenia alebo iné obdobné prejavy, ktoré nemajú žiadny vplyv na
                                funkčnosť webovej aplikácie a nespôsobujú žiadne odchýlky od jeho zjednaných vlastností;
                                &emsp;• absencia vlastností či funkcií, ktoré nie sú výslovne uvedené v zozname Základných
                                funkčných vlastností webovej aplikácie.
                                &emsp;13.12. Poskytovateľ garantuje tieto lehoty:<br/>
                                &emsp;•Kritická závada - Okamžite v pracovnej dobe - Do 10 pracovných hodín<br/>
                                &emsp;•Vážna závada - Do 16 pracovných hodín - Do 3 pracovných dní<br/>
                                &emsp;•Drobná závada - Do 32 pracovných hodín - Do 10 pracovných dní<br/>
                                *) v prípade, že sa jedná o závadu mimoriadneho charakteru vyžadujúcu dlhšiu dobu na vyriešenie,
                                či incident spôsobený skutočnosťami, ktoré nemôže poskytovateľ ovplyvniť (výpadok
                                internetových spojení apod.), je poskytovateľ oprávnený predĺžiť dobu vyriešenia o dobu
                                nevyhnutnú na vyriešenie. O tomto je poskytovateľ povinný používateľa okamžite informovať
                                emailom.
                            </Typography>
                            <Typography variant="h4" component="h2">
                                14. AUTORSKÉ UJEDNANIE
                            </Typography>
                            <Typography variant="body1" component="h2">
                                &emsp;14.1. Používateľ berie na vedomie, že webová aplikácia, ako obsah jednotlivých databáz, tak
                                aj softvér je predmetom autorských práv poskytovateľa a prípadne autorov jednotlivých obsahových
                                častí databáz a softvéru. Autorské právo je predmetom ochrany podľa autorského práva zákona o
                                verejnoprávnej ochrane podľa trestného zákona a jeho porušenie môže byť stíhané ako
                                prostriedkami civilnoprávnymi, tak aj trestnoprávnymi.<br/>
                                &emsp;14.2. Autorské práva, ako aj ostatné iné práva duševného vlastníctva, vzťahujúce sa k
                                softvérovým produktom, vrátane príručiek, manuálov a ďalších dokumentov distribuovaných spolu
                                so softvérovými produktmi, naďalej prislúchajú príslušným subjektom ako ich nositeľom a nie sú
                                týmto záväzkovým vzťahom dotknuté.<br/>
                                &emsp;14.3. Za podmienky riadnej úhrady dohodnutej ceny za prevádzku udeľuje poskytovateľ
                                používateľovi oprávnenie k výkonu práva užívať webovú aplikáciu špecifikovanú v zmluve k účelu
                                zo zmluvy vyplývajúcemu, a to za ďalej uvedených podmienok:<br/>
                                &emsp;• doba trvania: používateľovi je povolené službu využívať po uhradení na vybraté predplatené
                                obdobie<br/>
                                &emsp;• územný rozsah služby: neobmedzene na celom území Slovenskej republiky<br/>
                                &emsp;14.4. Používateľ nie je oprávnený službu predávať ďalej. Postúpiť práva k webovej aplikácií,
                                a to aj osobe, ktorá s ním tvorí koncern v zmysle ustanovenia § 71 a n zák. č 90/2012 Zb., o
                                obchodných spoločnostiach a družstvách, je oprávnený iba s výslovným súhlasom
                                posyktovateľa.<br/>
                                &emsp;14.5. Požívateľ nie je oprávnený webovú aplikáciu rozmnožovať za účelom jej rozširovanie,
                                rozširovať či akýmkoľvek spôsobom oznamovať tretím osobám, prenajímať ani požičiavať, iba ak
                                by mu k tomu dal poskytovateľ predchádzajúci výslovný súhlas. Používateľ rovnako nie je
                                oprávnený prekročiť množstevné rozsah licencie dohodnutý v zmluve alebo stanovený týmito VOP.<br/>
                                &emsp;14.6. Posyktovateľ nesmie vykonávať úpravu, spätnú analýzu, rekompiláciu, prevod zo
                                zdrojového kódu aplikácie, pristupovať k zdrojovému kódu a nesmie sprístupniť zdrojový kód
                                aplikácie tretej osobe.<br/>
                                &emsp;14.7. Používateľ je ďalej povinný dodržiavať všetky obmedzenia v použití softvér
                                ustanovených zákonom, zmluvou a týmito VOP.<br/>
                                &emsp;14.8. Používateľ nie je oprávnený odstraňovať, meniť, zakrývať alebo akýmkoľvek iným
                                spôsobom zasahovať do akýchkoľvek autorskoprávnych, či iných označení príslušných subjektov
                                umiestnených alebo uložených na softvérových produktoch, alebo akejkoľvek ich časti, či
                                dokumentáciu distribuovanej spolu so softvérovými produktmi.<br/>
                                &emsp;14.9. Autorské práva k dodanému produktu, príslušenstvo a dokumentácia náleží
                                príslušnému výrobcovi. Všetky logá, registrované ochranné známky, ochranné známky, ďalšie
                                značky a názvy výrobkov patria ich vlastníkom. V prípade poskytnutia práva na užívanie softvéru
                                Kritická závada Okamžite v pracovnej dobe Do 10 pracovných hodín
                                Vážna závada   Do 16 pracovných hodín Do 3 pracovných dní
                                Drobná závada Do 32 pracovných hodín Do 10 pracovných dní
                                prináležia všetky autorské práva posyktovateľovi a tvorcovi softvéru. Používateľovi nevznikajú
                                uzavretím zmluvy žiadne práva k ochranným známkam posyktovateľa alebo tretích osôb.<br/>
                                &emsp;14.10. Používateľ zabezpečí, že tretie osoby nebudú bez predchádzajúceho písomného
                                súhlasu poskytovateľa akýmkoľvek spôsobom oboznámené s rozsahom a postupom plnenia zmluvy
                                ak nej patriacimi podkladmi. Používateľ berie na vedomie, že sa jedná o informácie dôverné v
                                zmysle § 1730 Občianskeho zákonníka.<br/>
                                &emsp;14.11. Poskytovateľ je povinný zachovávať mlčanlivosť o všetkých podstatných
                                skutočnostiach získaných pri svojej činnosti vyplývajúce zo zmluvy, a to najmä o skutočnostiach,
                                ktoré tvoria obchodné tajomstvo a dôverné informácie používateľa.<br/>
                                &emsp;14.12. Bez výslovného povolenia poskytovateľa je zakázané čiastočné alebo úplné
                                kopírovanie cenníkov, brožúr, fotografií, katalógov, technických dát atď. používateľom nevyplýva
                                žiadne právo z prípadných chýb či nepresných dát uvedených v týchto materiáloch.<br/>
                                &emsp;14.13. Autorské práva sa riadia právnymi predpismi Slovenskej republiky.<br/>
                                &emsp;14.14. Používateľ súhlasí s tým, že v spodnej časti (päte) webu budú uvedené informácie o
                                poskytovateľovi, ako majiteľovi príslušných licenčných oprávnení.<br/>
                                &emsp;14.15 Ak používateľ nesplní vyššie uvedené podmienky, môže poskytovateľ odstúpiť od
                                tejto zmluvy a požadovať finančnú náhradu v prípade ušlého zisku. V tomto prípade musí
                                používateľ rešpektovať vyrovnanie medzi používateľom a poskytovateľom, eventuálne rozhodnutie
                                súdu.<br/>
                                &emsp;14.16. Predmetný softvér ako autorské dielo a databázy v ňom zahrnuté požívajú ochranu
                                najmä zák. č 121/2000 Zb., autorského zákona a zák. č 40/2009 Zb., trestného zákonníka.
                                Používateľ je oprávnený ho použiť len v rozsahu a spôsobom stanoveným poskytovateľom.<br/>
                                &emsp;14.17. V prípade zásahu používateľa do autorských práv poskytovateľa vzniká
                                poskytovateľovi nárok na zmluvnú pokutu vo výške 5000, - EUR (slovom päťtisíc  euro). Zmluvná
                                pokuta je splatná na základe výzvy k úhrade zmluvnej pokuty v lehote 15 dní od doručenia výzvy.
                                Nárok poskytovateľa na náhradu škody spôsobenej zásahom používateľa do autorských práv
                                poskytovateľa, ktorý má voči používateľovi, zaplatením zmluvnej pokuty používateľom nie je
                                dotknutý.<br/>
                                &emsp;14.18 Popri nároku na zmluvnú pokutu má poskytovateľ v prípade zásahu používateľa do
                                jeho autorských práv nároky vyplývajúce z autorského zákona, najmä nárok na zdržanie sa ďalších
                                zásahov do autorských práv, nárok na oznámenie údajov o spôsobe a rozsahu neoprávneného
                                použitia softvér a nárok na odstránenie následkov zásahu do autorských práv, vrátane primeraného
                                zadosťučinenia a vydania prípadného bezdôvodného obohatenia.
                            </Typography>
                            <Typography variant="h4" component="h2">
                                15. POVINNOSŤ MLČANLIVOSTI
                            </Typography>
                            <Typography variant="body1" component="h2">
                                &emsp;15.1. Používateľ sa zaväzuje zachovávať mlčanlivosť o všetkých skutočnostiach týkajúcich
                                sa Zmluvy, Produktu, Spoločnosti MODERNÉ POHREBNÍCTVO s. r. o. a jej činnosti, o ktorých sa
                                dozvie v rámci spolupráce s MODERNÉ POHREBNÍCTVO s. r. o. alebo v súvislosti s ňou, najmä
                                však neposkytnúť informácie tretej osobe o obchodnej a marketingovej stratégii, o organizácii
                                práce, o spôsobe a metódach riadenia, ako aj akékoľvek ďalšie informácie, ktoré nie sú bežne
                                prístupné verejnosti alebo ktoré môžu spôsobiť Spoločnosti MODERNÉ POHREBNÍCTVO s. r. o.
                                ujmu. V opačnom prípade sa vystavuje všetkým dôsledkom, ktoré s takýmto konaním spájajú
                                príslušné právne predpisy.
                            </Typography>
                            <Typography variant="h4" component="h2">
                                16. DÔVERNÉ INFORMÁCIE
                            </Typography>
                            <Typography variant="body1" component="h2">
                                &emsp;16.1.Zmluvné strany sa zaväzujú uchovať v tajnosti uzatvorenie Zmluvy, všetky skutočnosti,
                                ktoré sa dozvedia v súvislosti so Zmluvou, nezneužiť informácie nadobudnuté v súvislosti so
                                Zmluvou vo svoj prospech alebo v prospech tretej osoby, ktorých zneužitie by mohlo spôsobiť
                                akúkoľvek ujmu, či už majetkovú alebo nemajetkovú ktorejkoľvek zo zmluvných strán a zdržať sa
                                akéhokoľvek konania, ktoré by k takémuto zneužívaniu mohlo viesť.<br/>
                                &emsp;16.2. Žiadna zo zmluvných strán nebude poskytovať tretím osobám žiadne informácie, ktoré
                                sa jej stanú známymi pri rokovaniach o uzatvorení zmluvy a taktiež akékoľvek informácie o know-
                                how druhej zmluvnej strany s výnimkou informácií verejne známych.<br/>
                                &emsp;16.3.Všetky informácie, ktoré sa zo zmluvy alebo zo vzájomných rokovaní zmluvné strany
                                dozvedia sú dôvernými informáciami, ktoré sú predmetom ochrany podľa príslušných ustanovení
                                Obchodného zákonníka.<br/>
                                &emsp;16.4. Osobitne sa používateľ ohľadne každého produktu zaväzuje zachovať mlčanlivosť
                                o kúpnej cene za produkt.
                            </Typography>
                            <Typography variant="h4" component="h2">
                                17. ODSTÚPENIE OD ZMLUVY
                            </Typography>
                            <Typography variant="body1" component="h2">
                                &emsp;17.1. Používateľ je oprávnený od zmluvy písomne odstúpiť, ak dostupnosť webovej
                                aplikácie v zmysle čl. 12.2. VOP bude v každom z troch po sebe idúcich kalendárnych mesiacoch
                                nižšia ako 90 % alebo poskytovateľ bude aj napriek písomnému upozorneniu používateľa v
                                minimálne 14-dennom omeškaní s plnením niektorej z podstatných povinností vyplývajúcich zo
                                zmluvy.<br/>
                                &emsp;17.2. V prípade, keď používateľ poruší svoju povinnosť vyplývajúcu zo zmluvy, je
                                poskytovateľ oprávnený vykonať blokovanie prihlasovacích údajov k používateľskému účtu, a to
                                do doby, dokedy bude používateľ porušovať svoju povinnosť alebo dokedy neodstráni dôsledky
                                porušenia svojej povinnosti. Takýmto porušením sa predovšetkým rozumie omeškanie s úhradou
                                faktúry dlhšie než 15 dní.<br/>
                                &emsp;17.3. Poskytovateľ je oprávnený od zmluvy s používateľom odstúpiť, ak (1.) používateľ
                                poruší svoju povinnosť podľa čl. 3 alebo 5 týchto VOP alebo (2.) používateľ napriek písomnej
                                výzve poskytovateľa opakovane poruší svoju inú povinnosť vyplývajúcu zo zmluvy alebo (3.)
                                používateľ napriek písomnému upozorneniu poskytovateľa neodstráni dôsledky porušenia
                                povinnosti používateľa vyplývajúcich zo zmluvy.<br/>
                                &emsp;17.4. Odstúpenie od zmluvy musí byť písomné. Zmluva zaniká k okamihu doručenia
                                oznámenia o odstúpení druhej zmluvnej strany. Ak používateľ uhradil poskytovateľovi odmenu za
                                užívanie webovej aplikácie, ktorá sa časovo vzťahuje aj na obdobie po zániku zmluvy, potom mu
                                bude táto časť odmeny vrátená, ak dôjde k odstúpeniu od zmluvy podľa VOP.<br/>
                                &emsp;17.5. Ak používateľ uhradil poskytovateľovi odmenu za užívanie webovej aplikácie, ktorá
                                sa časovo vzťahuje aj na obdobie po zániku zmluvy a dôjde k odstúpeniu od zmluvy podľa VOP,
                                táto časť odmeny mu vrátená nebude a bude definovaná a od používateľa vyžadovaná náhrada
                                škody.<br/>
                                &emsp;17.6. V prípade porušenia VOP alebo odstúpenia či zrušenia zmluvy a zrušenia účtu má
                                používateľ 7 dní na zálohovanie všetkých dát potrebných pre evidenciu podľa zákona o
                                pohrebníctve 131/2010 Z.z.
                            </Typography>
                            <Typography variant="h4" component="h2">
                                18. SPOLOČNÉ USTANOVENIA
                            </Typography>
                            <Typography variant="body1" component="h2">
                                &emsp;18.1. Ak nie je v zmluve vyslovene uvedené inak, vyhlasuje používateľ, že zmluvu uzatvára
                                v súvislosti so svojou podnikateľskou alebo profesijnou činnosťou či inou činnosťou vyplývajúcou
                                zo zákona.<br/>
                                &emsp;18.2. Poskytovateľ je povinný informovať o zmene týchto VOP, a to telefonicky alebo e-
                                mailom. Vždy aktuálne VOP sa nachádzajú na stránke www.modernepohrebnictvo.sk . Ak
                                používateľ uhradí odmenu aj za ďalšie obdobie, platí, že so zmenou VOP súhlasí.
                            </Typography>
                            <Typography variant="h4" component="h2">
                                19. ZÁVEREČNÉ USTANOVENIA
                            </Typography>
                            <Typography variant="body1" component="h2">
                                &emsp;19.1. Zmluva a VOP sa riadia právnym poriadkom Slovenskej republiky, a to najmä
                                zákonom č. 185/2015 Z. z. Autorský zákon v platnom znení, zákonom č. 513/1991 Zb. Obchodný
                                zákonník v platnom znení a zákonom č. 40/1964 Zb. Občiansky zákonník v platnom znení.<br/>
                                &emsp;19.2. V prípade, že je alebo sa stane niektoré z ustanovení týchto VOP a/alebo zmluvy
                                neplatné, neúčinné alebo nevykonateľné, nebude tým zasiahnutá platnosť, účinnosť a
                                vykonateľnosť ostatných zmluvných dojednaní. Zmluvné strany sú povinné poskytnúť si vzájomnú
                                súčinnosť pre to, aby neplatné, neúčinné alebo nevykonateľné ustanovenie bolo nahradené takým
                                platným, účinným a vykonateľným ustanovením, ktoré v najväčšej možnej miere zachováva
                                ekonomický účel zamýšľaný neplatným, neúčinným alebo nevykonateľným ustanovením. To isté
                                platí aj pre prípad zmluvnej medzery.<br/>
                                &emsp;19.3. O všetkých sporoch vyplývajúcich zo zmluvy bude rozhodovať obecný súd určený
                                podľa obecne záväzných predpisov stanovených podľa miestneho sídla poskytovateľa.<br/>
                                &emsp;19.4. V prípade rozporu medzi VOP a zmluvou majú prednosť dojednania obsiahnuté v
                                zmluve.<br/>
                                &emsp;19.5. Všetky oznámenia týkajúce sa zmluvy, určené pre používateľa môžu byť zaslané v
                                podobe elektronickej správy na e-mailovú adresu používateľa, tak isto môže používateľ využiť
                                elektronickú komunikáciu s poskytovateľom vrátane prípadného vypovedania alebo odstúpenia od
                                zmluvy.<br/>
                                &emsp;19.6. Používateľ je povinný písomne informovať poskytovateľa o každej zmene kontaktnej
                                adresy alebo iných identifikačných údajov používateľa uvedených v zmluve.<br/>
                                &emsp;19.7. Akákoľvek zmena zmluvných podmienok musí byť vykonaná výhradne na základe
                                písomného dodatku k zmluve.
                            </Typography>
                            <div className={classes.marginTopLittle}/>


                        </Grid>

                        <Grid item xs={1} sm={2} md={2} className={classes.marginTopLittleMore}>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        </Grid>
    );
}