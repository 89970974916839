import React, {useEffect, useRef, useState} from "react";
import {Redirect, useParams} from "react-router-dom";
import UserService from "../../services/user.service";
import '../../Print.css';
import { logOut} from "../../helpers/logout";
import {Button, Divider, Grid, Toolbar} from "@material-ui/core";
import ActionButton from "../form/input/ActionButton";
import Tooltip from "@material-ui/core/Tooltip";
import Paper from "@material-ui/core/Paper";
import Container from "@material-ui/core/Container";
import {makeStyles} from "@material-ui/core/styles";
import clsx from "clsx";
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import Popup from "../presentation/Popup";
import TextInput from "../form/input/TextInput";
import DateInput from "../form/input/DateInput";
import altPhoto from "../../assets/trans.png";
import {PDFExport} from '@progress/kendo-react-pdf';
import Time from "react-time-format";
import {NotificationManager} from "react-notifications";
import withWidth from '@material-ui/core/withWidth'
import {ReactSketchCanvas} from "react-sketch-canvas";
import {convertSize} from "../../helpers/helper";
import application1 from '../../assets/application1.jpg';
import application2 from '../../assets/application2.jpg';
import application3 from '../../assets/application3.jpg';
import urnapplication from '../../assets/urnapplication.jpg';
import funus from '../../assets/funus.png';
import mp from '../../assets/mp.png';
import { red } from "@material-ui/core/colors";

const useStyles = makeStyles((theme) => ({
    appBarSpacer: theme.mixins.toolbar,
    root: {
        flexGrow: 1,
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
    seeMore: {
        marginTop: theme.spacing(8),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    toolbarButtons: {
        '& .MuiFormControl-root': {
            margin: theme.spacing(1)
        }
    },
    marginElement: {
        margin: theme.spacing(2)
    },
    marginTop: {
        marginTop: theme.spacing(2)
    },
    icon: {
        marginRight: theme.spacing(2),
    },
    heroContent: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(8, 0, 6),
    },
    heroButtons: {
        marginTop: theme.spacing(4),
    },
    cardGrid: {
        paddingTop: theme.spacing(8),
        paddingBottom: theme.spacing(8),
    },
    card: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    cardMedia: {
        paddingTop: '56.25%', // 16:9
    },
    cardContent: {
        flexGrow: 1,
    },
}));


const UrnApplication = (props) => {
    const classes = useStyles();
    const fixedHeightPaper = clsx(classes.paper);
    let canvas = useRef(null);

    const pdfRef = useRef(null);
    const [funeral, setFuneral] = useState({
        contracting_authority: '',
        ca_details: '',
        ca_name: '',
        ca_last_name: '',
        ca_address: '',
        ca_street: '',
        ca_street_number: '',
        ca_zip: '',
        ca_city: '',
        ca_state: '',
        ca_nationality: '',
        ca_email: '',
        ca_phone: '',
        ca_id: '',
        ca_birth: '',
        ca_relationship: '',
        ca_sr_1: '',
        ca_sr_2: '',
        deceased: '',
        d_name: '',
        d_name_born: '',
        d_last_name: '',
        d_address: '',
        d_street: '',
        d_street_number: '',
        d_zip: '',
        d_city: '',
        d_state: '',
        d_place_birth: '',
        d_birth: '',
        d_gender: '',
        d_marital_status: '',
        d_death: '',
        d_place_death: '',
        d_id: '',
        d_nationality: '',
        d_education: '',
        d_last_job: '',
        d_wife_husband: '',
        d_wife_husband_id: '',
        d_sr_1: '',
        d_sr_2: '',
        date_of_signature_delegation: new Date(),
        planned_pickup_place: '',
        planned_pickup: '',
        town_funeral: '',
        place_funeral: '',
        date_funeral: '',
        coffin_arrival: '',
        coffin_arrival_place: '',
        signatures: {},
        application_date: '',
        employee: '',
        user_name: '',
        user_address: '',
        cemetery: '',
        grave_number: '',
        urn_date: '',
        signiture1_place: 'Signiture 1 place',
        signiture1_date: 'Signiture 1 date',
        signiture2_place: 'Signiture 2 place',
        signiture2_date: 'Signiture 2 date'
    });
    const [show, setShow] = useState(false);
    const [date, setDate] = useState(new Date());
    const [location, setLocation] = useState('');
    const [success, setSuccess] = useState(false);
    const [fullScreen, setFullScreen] = useState(false);

    const handleShow = () => setShow(true);

    const handleDate = (e) => {
        setDate(e.target.value)
    }

    const handleLocation = (e) => {
        setLocation(e.target.value)
    }

    const [user, setUser] = useState(null);

    const funeral_id = useParams();
    const user_local = JSON.parse(localStorage.getItem('user')).id;

    useEffect(() => {
        UserService.getUser(user_local).then(
            (response) => {
                setUser(response.data);
                if (response.data.defined_texts) {
                    setLocation(response.data.defined_texts.default_signature_place);
                }
            },
            (e) => {
                if (e.response.status === 401) {
                    logOut(props);
                    NotificationManager.warning('Potrebné sa znova prihlásiť!', 'Upozornenie', 5000);
                }
            }
        );
        UserService.getFuneral(funeral_id.funeral_id).then(
            (response) => {
                setFuneral({...response.data,
                    ca_name: response.data.contracting_authority ? response.data.contracting_authority.split(" ")[0] : '',
                    ca_last_name: response.data.contracting_authority ? response.data.contracting_authority.split(" ").slice(1,response.data.contracting_authority.length - 1).join(' ') : '',
                    d_name: response.data.deceased ? response.data.deceased.split(" ")[0] : '',
                    d_last_name: response.data.deceased ? response.data.deceased.split(" ").slice(1,response.data.deceased.length - 1).join(' ') : '',
                    ca_street: response.data.ca_address ? response.data.ca_address.split(" ").slice(0, -1).join(' ') : '',
                    ca_street_number: response.data.ca_address ? response.data.ca_address.split(" ").slice(-1)[0] : '',
                    d_street: response.data.d_address ? response.data.d_address.split(" ").slice(0, -1).join(' ') : '',
                    d_street_number: response.data.d_address ? response.data.d_address.split(" ").slice(-1)[0] : '',
                    ca_birth: new Date(response.data.ca_birth).toLocaleDateString(),
                    d_birth: new Date(response.data.d_birth).toLocaleDateString(),
                    d_death: new Date(response.data.d_death).toLocaleDateString(),
                    date_funeral: new Date(response.data.date_funeral).toLocaleDateString(),
                    ca_sr_1: 'X',
                    ca_sr_2: '',
                    d_sr_1: 'X',
                    d_sr_2: '',
                    application_date: new Date(response.data.date_funeral).toLocaleDateString(),
                    employee: response.data.evidence ? response.data.evidence.employee : '',
                    ca_details: response.data.contracting_authority + " " + response.data.ca_address + " " +  response.data.ca_city + " " +  response.data.ca_zip + " " +  response.data.ca_state,
                    cemetery: response.data.place ? response.data.place.cemetery : '',
                    grave_number: response.data.place ? response.data.place.place_number : ''
                });
            },
            (e) => {
                if (e.response.status === 401) {
                    logOut(props);
                    NotificationManager.warning('Potrebné sa znova prihlásiť!', 'Upozornenie', 5000);
                }
            }
        );
    }, [funeral_id.funeral_id, user_local, props]);

    const singleFileUploadHandler = (file) => {
        const data = new FormData();
        data.append('someimage', file, 'delegation_signature.png');
        data.append('signatures_id', funeral.signatures._id);
        data.append('tag', 'delegation');
        data.append('date_of_delegation', new Date(date).toISOString());
        data.append('location_of_delegation', location);
        UserService.signDocument(data)
            .then((response) => {
                if (200 === response.status) {
                    // If file size is larger than expected.
                    if (response.data.error) {
                    } else {
                        let fileName = response.data;
                        setFuneral({
                            ...funeral,
                            'signatures': {...funeral.signatures, 'delegation_signature': fileName.location}
                        });
                        setShow(false);
                    }
                }
            }).catch((e) => {
            if (e.response.status === 401) {
                logOut(props);
                NotificationManager.warning('Potrebné sa znova prihlásiť!', 'Upozornenie', 5000);
            }
            NotificationManager.error('Nepodarilo sa podpísať dokument!', 'Chyba', 5000);
            setShow(false);
        });
    };

    const handleSignature = async () => {
        const exportImage = canvas.current?.exportImage;

        if (exportImage) {
            const exportedDataURI = await exportImage('png');
            fetch(exportedDataURI)
                .then((response) => {
                    return response.blob();
                })
                .then((blob) => {
                    singleFileUploadHandler(blob);
                })
                .catch(() => {
                    NotificationManager.error('Nepodarilo sa podpísať dokument!', 'Chyba', 5000);
                    setShow(false);
                });
        }
    }

    const handleExport = () => {
        pdfRef.current.save();
    }

    const handleClear = () => {
        const resetCanvas = canvas.current?.resetCanvas;

        if (resetCanvas) {
            resetCanvas();
        }
    }

    const handleChange = (event) => {
        const {name, value} = event.target;
        setFuneral({...funeral, [name]: value});
    }

    const showDelegation = () => {
        if (user) {
            return <div style={{border: '1px solid'}}>
                <PDFExport ref={pdfRef} paperSize="A4" scale={0.75}
                           fileName={`ziadost-o-prispevok-${funeral.deceased.replace(' ', '')}`}>
                    <div className="a4-paper" style={{backgroundSize: 'cover', backgroundImage: `url(${urnapplication})`}}>
                        <div className="wrapper" style={{position: 'relative', height: '100%'}}>
                            <input name="ca_details" onChange={handleChange} type="text" style={{marginTop: '35px', marginLeft: '100px', width: '600px', position: 'absolute', border: 'none', background: 'none'}} value={funeral.ca_details} />

                            <input name="user_name" onChange={handleChange} type="text" style={{marginTop: '130px', marginLeft: '500px', width: '200px', position: 'absolute', border: 'none',background: 'none'}} value={user.name} />
                            <input name="user_address" onChange={handleChange} type="text" style={{marginTop: '150px', marginLeft: '500px', width: '250px', position: 'absolute', border: 'none',background: 'none'}} value={user.address} />

                            <input name="contracting_authority" onChange={handleChange} type="text" style={{marginTop: '312px', marginLeft: '215px', width: '240px', position: 'absolute', border: 'none',background: 'none'}} value={funeral.contracting_authority} />
                            <input name="ca_address" onChange={handleChange} type="text" style={{marginTop: '312px', marginLeft: '510px', width: '210px', position: 'absolute', border: 'none', background: 'none'}} value={funeral.ca_address} />

                            <input name="deceased" onChange={handleChange} type="text" style={{marginTop: '340px', marginLeft: '485px', width: '230px', position: 'absolute', border: 'none', background: 'none'}} value={funeral.deceased} />
                            
                            <input name="d_name_born" onChange={handleChange} type="text" style={{marginTop: '368px', marginLeft: '135px', width: '130px', position: 'absolute', border: 'none', background: 'none'}} value={funeral.d_name_born} />
                            <input name="d_birth" onChange={handleChange} type="text" style={{marginTop: '368px', marginLeft: '418px', width: '130px', position: 'absolute', border: 'none', background: 'none'}} value={funeral.d_birth} />

                            <input name="d_death" onChange={handleChange} type="text" style={{marginTop: '392px', marginLeft: '95px', width: '150px', position: 'absolute', border: 'none', background: 'none'}} value={funeral.d_death} />
                            <input name="cemetery" onChange={handleChange} type="text" style={{marginTop: '395px', marginLeft: '345px', width: '350px', position: 'absolute', border: 'none', background: 'none'}} value={funeral.cemetery} />

                            <input name="signiture1_place" onChange={handleChange} type="text" style={{marginTop: '505px', marginLeft: '115px', width: '120px', position: 'absolute', border: 'none', background: 'none'}} value={funeral.signiture1_place} />
                            <input name="signiture1_date" onChange={handleChange} type="text" style={{marginTop: '505px', marginLeft: '260px', width: '120px', position: 'absolute', border: 'none', background: 'none'}} value={funeral.signiture1_date} />

                            <input name="grave_number" onChange={handleChange} type="text" style={{marginTop: '423px', marginLeft: '345px', width: '120px', position: 'absolute', border: 'none', background: 'none'}} value={funeral.grave_number} />

                            <input name="urn_date" onChange={handleChange} type="text" style={{marginTop: '450px', marginLeft: '125px', width: '135px', position: 'absolute', border: 'none', background: 'none'}} value={funeral.urn_date} />

                            <input name="grave_number" onChange={handleChange} type="text" style={{marginTop: '695px', marginLeft: '310px', width: '95px', position: 'absolute', border: 'none', background: 'none'}} value={funeral.grave_number} />

                            <input name="contracting_authority" onChange={handleChange} type="text" style={{marginTop: '723px', marginLeft: '220px', width: '250px', position: 'absolute', border: 'none', background: 'none'}} value={funeral.contracting_authority} />

                            <input name="d_birth" onChange={handleChange} type="text" style={{marginTop: '750px', marginLeft: '95px', width: '120px', position: 'absolute', border: 'none', background: 'none'}} value={funeral.d_birth} />
                            <input name="d_death" onChange={handleChange} type="text" style={{marginTop: '752px', marginLeft: '380px', width: '160px', position: 'absolute', border: 'none', background: 'none'}} value={funeral.d_death} />

                            <input name="cemetery" onChange={handleChange} type="text" style={{marginTop: '780px', marginLeft: '175px', width: '250px', position: 'absolute', border: 'none', background: 'none'}} value={funeral.cemetery} />
                            <input name="grave_number" onChange={handleChange} type="text" style={{marginTop: '780px', marginLeft: '555px', width: '160px', position: 'absolute', border: 'none', background: 'none'}} value={funeral.grave_number} />

                            <input name="signiture2_place" onChange={handleChange} type="text" style={{marginTop: '834px', marginLeft: '115px', width: '120px', position: 'absolute', border: 'none', background: 'none'}} value={funeral.signiture2_place} />
                            <input name="signiture2_date" onChange={handleChange} type="text" style={{marginTop: '834px', marginLeft: '270px', width: '120px', position: 'absolute', border: 'none', background: 'none'}} value={funeral.signiture2_date} />

                        </div>
                    </div>
                </PDFExport>
            </div>
        }
        return <></>
    }

    return (
        <Container maxWidth="lg" className={classes.seeMore}>
            <div className={classes.root}>
                <Paper className={fixedHeightPaper}>
                    <Grid container>
                        <Grid xs={12} sm={4} md={3} lg={3} item container>
                            <Toolbar>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    hidden={true}
                                    className={classes.button}
                                    onClick={() => {
                                        setSuccess(true)
                                    }}
                                >
                                    Späť na detaily
                                </Button>
                            </Toolbar>
                        </Grid>
                        <Grid xs={12} sm={8} md={9} lg={9} item container justifyContent='flex-end'>
                            <Toolbar className={classes.toolbarButtons}>
                                {/* <TextInput
                                    label="Miesto podpisu"
                                    name={'location'}
                                    value={location}
                                    onChange={handleLocation}
                                />
                                <DateInput
                                    label="Dátum podpisu"
                                    name={'date'}
                                    value={date}
                                    onChange={handleDate}
                                />
                                <ActionButton
                                    color="primary">
                                    <Tooltip title="Podpísať splnomocnenie">
                                        <BorderColorIcon
                                            fontSize="small"
                                            onClick={() => {
                                                handleShow();
                                            }}
                                        />
                                    </Tooltip>
                                </ActionButton> */}
                                <ActionButton
                                    color="primary">
                                    <Tooltip title="Pdf export">
                                        <PictureAsPdfIcon
                                            fontSize="small"
                                            onClick={handleExport}
                                        />
                                    </Tooltip>
                                </ActionButton>
                            </Toolbar>
                        </Grid>
                    </Grid>

                    <Divider/>
                    <Grid className={classes.marginTop}
                          container
                          spacing={0}
                          direction="column"
                          alignItems="center"
                          justifyContent="center"
                    >
                        {showDelegation()}
                    </Grid>
                </Paper>
            </div>
            <Popup
                title={'Podpis'}
                openPopup={show}
                setOpenPopup={setShow}
                maxW = 'xl'
                actions={<>
                    <Button color="primary" variant="outlined" onClick={() => {
                        setFullScreen(!fullScreen);
                    }
                    }>
                        {fullScreen ? 'Zmenšiť' : 'Zväčšiť'}
                    </Button>
                    <Button color="primary" variant="outlined" onClick={handleClear}>
                        Vyčistiť
                    </Button>
                    <Button color="primary" variant="outlined" onClick={handleSignature}>
                        Uložiť
                    </Button>
                </>}
            >
                <ReactSketchCanvas
                    ref={canvas}
                    strokeWidth={3}
                    strokeColor="blue"
                    style={{
                        border: "0.0625rem solid #9c9c9c",
                        borderRadius: "0.25rem",
                        width: fullScreen ? convertSize(props.width) : props.width === 'xs' ? "300px" : "500px",
                        height: fullScreen ? '600px' : props.width === 'xs' ? "125px" : "300px"
                    }}
                />
            </Popup>
            {success ? <Redirect to={{
                pathname: `/pohreb/${funeral_id.funeral_id}/info`,
                state: {from: props.location, number: 5}
            }}/> : <></>}
        </Container>
    )
}
export default withWidth()(UrnApplication);

