import React, {useState, useEffect} from "react";
import {makeStyles} from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import clsx from "clsx";
import Title from "../dashboard/Title";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import SelectInput from "../form/input/SelectInput";
import UserService from "../../services/user.service";
import { logOut} from "../../helpers/logout";
import {NotificationManager} from 'react-notifications';
import Paper from "@material-ui/core/Paper";
import TextInput from "../form/input/TextInput";
import AuthService from "../../services/auth.service";
import {
    Card,
    CardActions, CardContent, Chip,
    Divider,
    Toolbar
} from "@material-ui/core";
import Popup from "../presentation/Popup";
import ActionButton from "../form/input/ActionButton";
import Tooltip from "@material-ui/core/Tooltip";
import EditIcon from "@material-ui/icons/Edit";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import ConfirmDialog from "../presentation/ConfirmDialog";
import CheckBoxInput from "../form/input/CheckBoxInput";
import useTable from "../presentation/useTable";
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Time from "react-time-format";
import { Redirect} from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        '& .MuiFormControl-root': {
            margin: theme.spacing(1),
            width: '100%'
        }
    },
    cardHeight: {
        height: '30vh',
        overflow: 'auto',
    },
    appBarSpacer: theme.mixins.toolbar,
    seeMore: {
        marginTop: theme.spacing(8),
    },
    marginTop: {
        marginTop: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    button: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    toolbarButtons: {
        marginLeft: 'auto',
    },
    border: {
        borderBottom: '1 px'
    },
    box: {
        overflow: 'auto',
        height: '12vh',
    },
    marginBox: {
        margin: theme.spacing(1)
    }
}));

export default function TasksDashboard(props) {
    const classes = useStyles();

    const [tasks, setTasks] = useState([]);
    const [filterFn, setFilterFn] = useState({
        fn: items => {
            return items;
        }
    });
    const [limit, setLimit] = useState(100);
    const [funeralDetail, setFuneralDetail] = useState(null);

    const fixedHeightPaper = clsx(classes.paper, classes.root);
    const seeMoreContainer = clsx(classes.seeMore, classes.root);

    const [values, setValues] = useState({
        _id: null,
        name: '',
        status: 1
    });

    const headCells = [
        {id: 'name', label: 'Názov'},
        {id: 'status', label: 'Stav'},
        {id: 'actions', label: 'Akcie', disableSorting: true, align: 'right'}
    ]

    const {
        TblContainer,
        TblHead,
        TblPagination,
        recordsAfterPagingAndSorting
    } = useTable(tasks, headCells, filterFn);

    const [show, setShow] = useState(false);
    const [confirmDialog, setConfirmDialog] = useState({isOpen: false, title: '', subTitle: ''})
 
    const handleChange = (event) => {
        const {name, value} = event.target;
        setValues({...values, [name]: value});
    }

    useEffect(() => {
        UserService.getTaskTemplates().then(
            (response) => {
                setTasks(response.data);
            },
            (e) => {
                if (e.response.status === 401) {
                    logOut(props);
                }
            }
        );
    }, [limit, props]);

    const resetToDefault = () => {
        setValues({
            _id: null,
            name: '',
            status: 0,
        });
        setShow(false);
    }

    const errNotification = (e) => {
        if (e.response.status === 401) {
            logOut(props);
            NotificationManager.error('Musíte sa nanovo prihlásiť.', 'Chyba', 5000);
        } else {
            NotificationManager.error('Došlo k neočakávanej chybe. Skúste neskôr alebo kontaktujte podporu.', 'Chyba', 5000);
        }
    }

    const handleCreate = (e) => {
        e.preventDefault();

        if (validate()) {
            let data = {
                _id: values._id,
                status: values.status,
                name: values.name
            };

            if (values._id) {
                UserService.updateTaskTemplate(data).then(
                    () => {
                        UserService.getTaskTemplates().then(
                            (response) => {
                                setTasks(response.data);
                                NotificationManager.success('Úloha upravená!', 'Upravené', 5000);
                            },
                            (e) => {
                                if (e.response.status === 401) {
                                    logOut(props);
                                }
                            }
                        );
                        resetToDefault();
                    },
                    (e) => {
                        errNotification(e);
                    }
                );
            } else {
                data.created = new Date();
                UserService.addTaskTemplate(data).then(
                    () => {
                        UserService.getTaskTemplates().then(
                            (response) => {
                                setTasks(response.data);
                                NotificationManager.success('Úloha vytvorená!', 'Vytvorené', 5000);
                            },
                            (e) => {
                                if (e.response.status === 401) {
                                    logOut(props);
                                }
                            }
                        );
                        resetToDefault();
                    },
                    (e) => {
                        errNotification(e);
                    }
                );
            }
        } else {
            NotificationManager.error('Skontrolujte vyplnený formulár!', 'Chyba', 5000);
        }
    };

    const [errors, setErrors] = useState({});

    const validate = () => {
        let temp = {};
        temp.name = values.name !== '' ? "" : "Vyplne názov."
        if (values.type === 1 && values.options.length === 0) {
            temp.type = 'Musíte mať aspon jendu možnosť!'
        }
        if (values.type === 4 && values.options.length === 0) {
            temp.type = 'Musíte mať aspon jendu možnosť!'
        }
        if(!values.status){
            temp.status = "Musíte vybrať jednú z možností"
        }
        setErrors({...temp})
        // eslint-disable-next-line eqeqeq
        return Object.values(temp).every(x => x == "")
    }

    const handleDelete = (id) => {
        setConfirmDialog({
            ...confirmDialog,
            isOpen: false
        })
        UserService.deleteTaskTemplate(id).then(
            () => {
                NotificationManager.success('Úloha vymazaná!', 'Vymazané', 5000);
                UserService.getTaskTemplates().then(
                    (response) => {
                        setTasks(response.data);
                    },
                    (e) => {
                        if (e.response.status === 401) {
                            logOut(props);
                            NotificationManager.warning('Potrebné sa znova prihlásiť!', 'Upozornenie', 5000);
                        }
                    }
                );
            },
            (e) => {
                if (e.response.status === 401) {
                    logOut(props);
                    NotificationManager.warning('Potrebné sa znova prihlásiť!', 'Upozornenie', 5000);
                }
                NotificationManager.error('Úlohu sa nepodarilo odstrániť!', 'Chyba', 5000);
            }
        );
    }

    const handleEdit = (vals) => () => {
        setValues(vals);
        setShow(true);
    }

    const handleRemove = (index) => {
        let newOpt = [...values.options];
        newOpt.splice(index, 1);
        setValues({...values, options: newOpt});
    }

    const getStatus = (val) => {
        switch (val) {
            case 2:
                return "Pre pohreb";
            default:
                return "Všeobecná";
        } 
    }

    const handleNoLimit = () => {
        setLimit(0);
    }

    return (

        <Container maxWidth="lg">
            <div className={seeMoreContainer}>
                <div>
                    <div>
                        <Typography className={classes.instructions} component={'span'}>
                            <Paper className={fixedHeightPaper}>
                                <Title>Zoznam šablón pre úlohy</Title>
                                <Toolbar className={classes.toolbarButtons}>
                                    <Button
                                        className={classes.buttonPosition}
                                        variant="outlined"
                                        startIcon={<AddIcon/>}
                                        onClick={() => {
                                            setShow(true);
                                        }}
                                    >
                                        Pridať šablónu pre úlohy
                                    </Button>
                                </Toolbar>
                            <TblContainer>
                            <TblHead/>
                            <TableBody>
                                {
                                    recordsAfterPagingAndSorting().map((item, index) =>
                                        (<TableRow key={index}>
                                            <TableCell>{item.name}</TableCell>
                                            <TableCell>{getStatus(item.status)}</TableCell>
                                            <TableCell align="right">
                                                <ActionButton
                                                    color="primary">
                                                    <Tooltip title="Upraviť šablónu">
                                                        <EditIcon
                                                            onClick={handleEdit(item)}
                                                            fontSize="small"
                                                        />
                                                    </Tooltip>
                                                </ActionButton>
                                                <ActionButton
                                                    color="secondary">
                                                    <Tooltip title="Vymazať šablónu">
                                                        <DeleteIcon
                                                            fontSize="small"
                                                            onClick={() => {
                                                                setConfirmDialog({
                                                                    isOpen: true,
                                                                    title: 'Naozaj chcete vymazať túto šablónu?',
                                                                    subTitle: "Túto operáciu nevieš vrátiť.",
                                                                    onConfirm: () => {
                                                                        handleDelete(item._id)
                                                                    }
                                                                })
                                                            }}
                                                        />
                                                    </Tooltip>
                                                </ActionButton>
                                            </TableCell>
                                        </TableRow>)
                                    )
                                }
                            </TableBody>
                        </TblContainer>

                                <Popup
                                    title={values._id ? 'Úprava šablóny' : 'Pridanie šablóny'}
                                    openPopup={show}
                                    setOpenPopup={setShow}
                                    additionalWork={resetToDefault}
                                >
                                    <div className={classes.root}>
                                        <form autoComplete='off' onSubmit={handleCreate}>
                                            <TextInput
                                                label="Názov"
                                                error={errors.name}
                                                name={'name'}
                                                value={values.name}
                                                onChange={handleChange}
                                            />
                                                <SelectInput
                                                    label="Typ"
                                                    name={'status'}
                                                    error={errors.status}
                                                    value={values.status}
                                                    options={[
                                                        {value: 1, title: 'Všeobecná'},
                                                        {value: 2, title: 'Pre pohreby'},
                                                    ]}
                                                    onChange={handleChange}
                                                />
                             
                                            <div className={classes.marginTop}>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    type='submit'
                                                    hidden={true}
                                                    className={classes.button}
                                                >
                                                    {values._id ? "Upraviť šablónu" : "Pridať šablónu"}
                                                </Button>
                                            </div>
                                        </form>
                                    </div>
                                </Popup>
                                <TblPagination/>
                            </Paper>
                        </Typography>
                    </div>

                </div>
            </div>
            <ConfirmDialog
                confirmDialog={confirmDialog}
                setConfirmDialog={setConfirmDialog}
                icon={<DeleteIcon/>}
            />
        </Container>

    )
}