import React from 'react'
import {MuiPickersUtilsProvider, DatePicker} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import skLocale from "date-fns/locale/sk";
import DateRangeIcon from '@material-ui/icons/DateRange';

export default function DateInput(props) {

    const {name, label, value, onChange, error = null} = props


    const convertToDefEventPara = (name, value) => ({
        target: {
            name, value
        }
    })

    return (
        <>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={skLocale}>
                <DatePicker variant="inline" inputVariant="outlined"
                            label={label}
                            format="dd.MM.yyyy"
                            name={name}
                            {...(error && {
                                error: true,
                                helperText: error
                            })}
                            value={value}
                            onChange={date => onChange(convertToDefEventPara(name, date))}
                            InputProps={{
                                endAdornment: (
                                    <DateRangeIcon/>
                                ),
                            }}

                />
            </MuiPickersUtilsProvider>
        </>
    )
}