import React from 'react'
import {Dialog, DialogTitle, DialogContent, makeStyles, Typography, DialogActions} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import ActionButton from "../form/input/ActionButton";

const useStyles = makeStyles(theme => ({
    dialogWrapper: {
        padding: theme.spacing(2),
        position: 'absolute',
        top: theme.spacing(5)
    },
    dialogTitle: {
        paddingRight: '0px'
    }
}))

export default function Popup(props) {

    const { title, children, openPopup, setOpenPopup, actions, additionalWork = null, maxW = 'md'} = props;
    const classes = useStyles();

    return (
        <Dialog open={openPopup} onClose={()=>{
            if(additionalWork){
                additionalWork()
            }
            setOpenPopup(false);
        }} maxWidth={maxW} classes={{ paper: classes.dialogWrapper }}>
            <DialogTitle className={classes.dialogTitle}>
                <div style={{ display: 'flex' }}>
                    <Typography variant="h6" component="div" style={{ flexGrow: 1 }}>
                        {title}
                    </Typography>
                    <ActionButton
                        color="secondary"
                        onClick={()=>{
                            if(additionalWork){
                                additionalWork()
                            }
                            setOpenPopup(false)}}>
                        <CloseIcon />
                    </ActionButton>
                </div>
            </DialogTitle>
            <DialogContent dividers>
                {children}
            </DialogContent>
            <DialogActions>
                {actions}
            </DialogActions>
        </Dialog>
    )
}