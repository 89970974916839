import React, {useState, useEffect} from "react";
import {makeStyles} from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import clsx from "clsx";
import Title from "../dashboard/Title";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import SelectInput from "../form/input/SelectInput";
import UserService from "../../services/user.service";
import { logOut} from "../../helpers/logout";
import {NotificationManager} from 'react-notifications';
import Paper from "@material-ui/core/Paper";
import TextInput from "../form/input/TextInput";
import AuthService from "../../services/auth.service";
import {
    Card,
    CardActions, CardContent, Chip,
    Divider,
    Toolbar
} from "@material-ui/core";
import Popup from "../presentation/Popup";
import ActionButton from "../form/input/ActionButton";
import Tooltip from "@material-ui/core/Tooltip";
import EditIcon from "@material-ui/icons/Edit";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import ConfirmDialog from "../presentation/ConfirmDialog";
import CheckBoxInput from "../form/input/CheckBoxInput";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        '& .MuiFormControl-root': {
            margin: theme.spacing(1),
            width: '100%'
        }
    },
    cardHeight: {
        height: '30vh',
        overflow: 'auto',
    },
    appBarSpacer: theme.mixins.toolbar,
    seeMore: {
        marginTop: theme.spacing(8),
    },
    marginTop: {
        marginTop: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    button: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    toolbarButtons: {
        marginLeft: 'auto',
    },
    border: {
        borderBottom: '1 px'
    },
    box: {
        overflow: 'auto',
        height: '12vh',
    },
    marginBox: {
        margin: theme.spacing(1)
    }
}));

export default function ProductsDashboard(props) {
    const classes = useStyles();
    const fixedHeightPaper = clsx(classes.paper, classes.root);
    const seeMoreContainer = clsx(classes.seeMore, classes.root);

    const [values, setValues] = useState({
        _id: null,
        name: '',
        type: 0,
        options: [],
        show_list: false,
        count_cost: false
    });

    const currentUser = AuthService.getCurrentUser();
    const [products, setProducts] = useState([]);
    const [show, setShow] = useState(false);
    const [confirmDialog, setConfirmDialog] = useState({isOpen: false, title: '', subTitle: ''})
    const [optionTextCostInput, setOptionTextCostInput] = useState({
        text: '',
        cost: 0
    });
    const [optionTextInput, setOptionTextInput] = useState('');

    const handleTextCostOptionInputChange = (event) => {
        let {name, value} = event.target;
        setOptionTextCostInput({...optionTextCostInput, [name]: value});
    }

    const handleTextOptionInputChange = (event) => {
        let {value} = event.target;
        setOptionTextInput(value);
    }

    const addTextCostOption = () => {
        if (optionTextCostInput.text.length !== 0) {
            optionTextCostInput.cost = parseFloat(optionTextCostInput.cost);
            let newOpt = [...values.options];
            newOpt.push(optionTextCostInput);
            setValues({...values, options: newOpt});
        }
        setOptionTextCostInput({
            text: '',
            cost: 0
        });
    }

    const addTextOption = () => {
        if (optionTextInput.length !== 0) {
            let newOpt = [...values.options];
            newOpt.push(optionTextInput);
            setValues({...values, options: newOpt});
        }
        setOptionTextInput('');
    }

    const handleChange = (event) => {
        const {name, value} = event.target;
        setValues({...values, [name]: value});
    }

    useEffect(() => {
        UserService.getUser(currentUser.id).then(
            (response) => {
                setProducts(response.data.product_templates);
            },
            (e) => {
                if (e.response.status === 401) {
                    logOut(props);
                }
            }
        );
    }, [currentUser.id, props]);

    const resetToDefault = () => {
        setValues({
            _id: null,
            name: '',
            type: 0,
            options: [],
            show_list: false,
            count_cost: false
        });
        setOptionTextCostInput({
            text: '',
            cost: 0
        });
        setOptionTextInput('');
        setShow(false);
    }

    const errNotification = (e) => {
        if (e.response.status === 401) {
            logOut(props);
            NotificationManager.error('Musíte sa nanovo prihlásiť.', 'Chyba', 5000);
        } else {
            NotificationManager.error('Došlo k neočakávanej chybe. Skúste neskôr alebo kontaktujte podporu.', 'Chyba', 5000);
        }
    }

    const handleCreate = (e) => {
        e.preventDefault();

        if (validate()) {
            let data = {
                _id: values._id,
                name: values.name,
                type: values.type,
                show_list: values.show_list,
                count_cost: values.count_cost
            };
            // eslint-disable-next-line default-case
            switch (values.type) {
                case 1:
                    data.text_option = values.options
                    break;
                case 4:
                    data.text_cost_option = values.options
                    break;
            }
            if (values._id) {
                UserService.updateProductTemplate(data).then(
                    () => {
                        UserService.getUser(currentUser.id).then(
                            (response) => {
                                setProducts(response.data.product_templates);
                                NotificationManager.success('Šablóna upravená!', 'Upravené', 5000);
                            },
                            (e) => {
                                if (e.response.status === 401) {
                                    logOut(props);
                                }
                            }
                        );
                        resetToDefault();
                    },
                    (e) => {
                        errNotification(e);
                    }
                );
            } else {
                UserService.createProductTemplate(data).then(
                    () => {
                        UserService.getUser(currentUser.id).then(
                            (response) => {
                                setProducts(response.data.product_templates);
                                NotificationManager.success('Šablóna vytvorená!', 'Vytvorené', 5000);
                            },
                            (e) => {
                                if (e.response.status === 401) {
                                    logOut(props);
                                }
                            }
                        );
                        resetToDefault();
                    },
                    (e) => {
                        errNotification(e);
                    }
                );
            }
        } else {
            NotificationManager.error('Skontrolujte vyplnený formulár!', 'Chyba', 5000);
        }
    };

    const [errors, setErrors] = useState({});

    const validate = () => {
        let temp = {};
        temp.name = values.name !== '' ? "" : "Vyplne názov."
        if (values.type === 1 && values.options.length === 0) {
            temp.type = 'Musíte mať aspon jendu možnosť!'
        }
        if (values.type === 4 && values.options.length === 0) {
            temp.type = 'Musíte mať aspon jendu možnosť!'
        }
        setErrors({...temp})
        // eslint-disable-next-line eqeqeq
        return Object.values(temp).every(x => x == "")
    }

    const handleDelete = (id) => {
        setConfirmDialog({
            ...confirmDialog,
            isOpen: false
        })
        UserService.deleteProductTemplate(id).then(
            () => {
                NotificationManager.success('Šablóna vymazaná!', 'Vymazané', 5000);
                UserService.getUser(currentUser.id).then(
                    (response) => {
                        setProducts(response.data.product_templates);
                    },
                    (e) => {
                        if (e.response.status === 401) {
                            logOut(props);
                            NotificationManager.warning('Potrebné sa znova prihlásiť!', 'Upozornenie', 5000);
                        }
                    }
                );
            },
            (e) => {
                if (e.response.status === 401) {
                    logOut(props);
                    NotificationManager.warning('Potrebné sa znova prihlásiť!', 'Upozornenie', 5000);
                }
                NotificationManager.error('Šablónu sa nepodarilo odstrániť!', 'Chyba', 5000);
            }
        );
    }

    const handleEdit = (vals) => () => {
        if (vals.type === 4) {
            vals.options = vals.text_cost_option;
        }
        if (vals.type === 1) {
            vals.options = vals.text_option;
        }
        setValues(vals);
        setShow(true);
    }

    const handleRemove = (index) => {
        let newOpt = [...values.options];
        newOpt.splice(index, 1);
        setValues({...values, options: newOpt});
    }

    const showType = (type) => {
        switch (type) {
            case 0:
                return 'Textová hodnota';
            case 1:
                return 'Výber z textových možností';
            case 2:
                return 'Výber z áno/nie možností';
            case 3:
                return 'Číselná hodnota';
            default:
                return 'Výber z text + cena možností';
        }
    }


    return (

        <Container maxWidth="lg">
            <div className={seeMoreContainer}>
                <div>
                    <div>
                        <Typography className={classes.instructions} component={'span'}>
                            <Paper className={fixedHeightPaper}>
                                <Title>Tvorba šablón pre produkty</Title>
                                <Toolbar className={classes.toolbarButtons}>
                                    <Button
                                        className={classes.buttonPosition}
                                        variant="outlined"
                                        startIcon={<AddIcon/>}
                                        onClick={() => {
                                            setShow(true);
                                        }}
                                    >
                                        Vytvoriť produkt
                                    </Button>
                                </Toolbar>
                                <Divider/>
                                <Box className={classes.marginTop}>
                                    {products.length === 0 ? <Typography
                                            component={'span'}
                                            color="textSecondary"
                                            className={clsx(classes.depositContext, classes.mt)}
                                            variant="h6">
                                            Zatiaľ nemáte pridané žiadne šablóny pre produkty.
                                        </Typography> :

                                        <Grid container spacing={2}>
                                            {products.map((item, index) =>
                                                <Grid key={index} item xs={12} sm={12} md={6} lg={3}>
                                                    <Card className={classes.cardHeight}>
                                                        <CardContent>
                                                            <Typography className={item.title} color="textSecondary"
                                                                        gutterBottom>
                                                                <span
                                                                    style={{fontWeight: "bold"}}>Názov:</span> {item.name}
                                                            </Typography>
                                                            <Typography className={classes.pos} color="textSecondary">
                                                                <span
                                                                    style={{fontWeight: "bold"}}>Typ:</span> {showType(item.type)}
                                                            </Typography>
                                                            {item.type === 4 ?
                                                                <div className={classes.box}>
                                                                    {item.text_cost_option.map((item, index) =>
                                                                        <Chip
                                                                            className={classes.marginBox}
                                                                            key={index}
                                                                            label={`${item.text} - ${item.cost}`}
                                                                        />
                                                                    )}
                                                                </div>
                                                                :
                                                                <></>}
                                                            {item.type === 1 ?
                                                                <div className={classes.box}>
                                                                    {item.text_option.map((item, index) =>
                                                                        <Chip
                                                                            className={classes.marginBox}
                                                                            key={index}
                                                                            label={item}
                                                                        />
                                                                    )}
                                                                </div>
                                                                :
                                                                <></>}
                                                            {item.type === 1 || item.type === 4 ? <></> :
                                                                <div className={classes.box}/>}
                                                        </CardContent>
                                                        <CardActions className={classes.toolbarButtons}>
                                                            <ActionButton
                                                                color="primary">
                                                                <Tooltip title="Upraviť šablónu">
                                                                    <EditIcon
                                                                        onClick={handleEdit(item)}
                                                                        fontSize="small"
                                                                    />
                                                                </Tooltip>
                                                            </ActionButton>
                                                            <ActionButton
                                                                color="primary">
                                                                <Tooltip title="Vymazať šablónu">
                                                                    <DeleteIcon
                                                                        onClick={() => {
                                                                            setConfirmDialog({
                                                                                isOpen: true,
                                                                                title: 'Naozaj chceš vymazať túto šablónu?',
                                                                                subTitle: "Túto operáciu nevieš vrátiť.",
                                                                                onConfirm: () => {
                                                                                    handleDelete(item._id)
                                                                                }
                                                                            })
                                                                        }}
                                                                        fontSize="small"
                                                                    />
                                                                </Tooltip>
                                                            </ActionButton>
                                                        </CardActions>
                                                    </Card>
                                                </Grid>
                                            )}
                                        </Grid>
                                    }
                                </Box>

                                <Popup
                                    title={'Pridanie šablóny'}
                                    openPopup={show}
                                    setOpenPopup={setShow}
                                    additionalWork={resetToDefault}
                                >
                                    <div className={classes.root}>
                                        <form autoComplete='off' onSubmit={handleCreate}>
                                            <TextInput
                                                label="Názov produktu"
                                                error={errors.name}
                                                name={'name'}
                                                value={values.name}
                                                onChange={handleChange}
                                            />
                                            {values._id ?
                                                <></>
                                                :
                                                <SelectInput
                                                    label="Typ produktu"
                                                    name={'type'}
                                                    error={errors.type}
                                                    value={values.type}
                                                    options={[
                                                        {value: 0, title: 'Textová hodnota'},
                                                        {value: 1, title: 'Výber z textových možností'},
                                                        {value: 2, title: 'Výber z áno/nie možností'},
                                                        {value: 3, title: 'Číselná hodnota'},
                                                        {value: 4, title: 'Výber z text + cena možností'},
                                                    ]}
                                                    onChange={handleChange}
                                                />
                                            }
                                            {values._id &&
                                            <Typography component="div" variant="body1" color="secondary">
                                                Zmeny v zobrazovaní dát v informačnom liste a orientačnej sumy sa
                                                odzrkadlia až v novopridaných pohreboch.
                                            </Typography>
                                            }
                                            <CheckBoxInput
                                                name={'show_list'}
                                                label={'Zobrazovať v informačnom liste?'}
                                                value={values.show_list}
                                                onChange={handleChange}
                                            />
                                            {values.type > 2 &&
                                                <CheckBoxInput
                                                    name={'count_cost'}
                                                    label={'Počítať hodnotu do orientačnej sumy?'}
                                                    value={values.count_cost}
                                                    onChange={handleChange}
                                                />
                                            }
                                            {values.type === 4 ?
                                                <>
                                                    <Card className={classes.root}>
                                                        Možnosti:
                                                        <CardContent>
                                                            {values.options.map((item, index) =>
                                                                <Chip
                                                                    key={index}
                                                                    label={`${item.text} - ${item.cost}`}
                                                                    onDelete={() => {
                                                                        handleRemove(index);
                                                                    }}
                                                                    deleteIcon={<DeleteIcon/>}
                                                                />
                                                            )}
                                                        </CardContent>
                                                        <CardActions>
                                                            <TextInput
                                                                label="Názov možnosti"
                                                                name={'text'}
                                                                value={optionTextCostInput.text}
                                                                onChange={handleTextCostOptionInputChange}
                                                            />
                                                            <TextInput
                                                                label="Cena"
                                                                name={'cost'}
                                                                type="number"
                                                                value={optionTextCostInput.cost}
                                                                onChange={handleTextCostOptionInputChange}
                                                            />
                                                            <Button size="small"
                                                                    onClick={addTextCostOption}>Pridať</Button>
                                                        </CardActions>
                                                    </Card>
                                                </>
                                                :
                                                <></>
                                            }
                                            {values.type === 1 ?
                                                <>
                                                    <Card className={classes.root}>
                                                        Možnosti:
                                                        <CardContent>
                                                            {values.options.map((item, index) =>
                                                                <Chip
                                                                    key={index}
                                                                    label={item}
                                                                    onDelete={() => {
                                                                        handleRemove(index);
                                                                    }}
                                                                    deleteIcon={<DeleteIcon/>}
                                                                />
                                                            )}
                                                        </CardContent>
                                                        <CardActions>
                                                            <TextInput
                                                                label="Názov možnosti"
                                                                name={'name'}
                                                                value={optionTextInput}
                                                                onChange={handleTextOptionInputChange}
                                                            />
                                                            <Button size="small" onClick={addTextOption}>Pridať</Button>
                                                        </CardActions>
                                                    </Card>
                                                </>
                                                :
                                                <></>
                                            }
                                            <div className={classes.marginTop}>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    type='submit'
                                                    hidden={true}
                                                    className={classes.button}
                                                >
                                                    {values._id ? "Upraviť šablónu" : "Pridať šablónu"}
                                                </Button>
                                            </div>
                                        </form>
                                    </div>
                                </Popup>
                            </Paper>
                        </Typography>
                    </div>

                </div>
            </div>
            <ConfirmDialog
                confirmDialog={confirmDialog}
                setConfirmDialog={setConfirmDialog}
                icon={<DeleteIcon/>}
            />
        </Container>

    )
}