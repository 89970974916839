import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';
import Cloud from '../../assets/cloud.jpg'

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100vh',
    },
    image: {
        backgroundImage: `url(${Cloud})`,
        backgroundRepeat: 'no-repeat',
        backgroundColor:
            theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },
    paper: {
        margin: theme.spacing(8, 4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%',
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    margin: theme.spacing(1),
    marginTopMiddle: {
        marginTop: theme.spacing(15)
    },
    marginTopLittle: {
        marginTop: theme.spacing(3)
    },
    marginTopLittleMore: {
        marginTop: theme.spacing(5)
    }
}));

export default function Information(props) {
    const classes = useStyles();


    return (
        <Grid container component="main" className={classes.root}>
            <CssBaseline/>
            <Grid item xs={12} sm={12} md={12} className={classes.image}>
                <Grid container>
                    <Grid item xs={1} sm={2} md={2} className={classes.marginTopMiddle}>
                    </Grid>
                    <Grid item xs={10} sm={8} md={8} className={classes.marginTopMiddle}>
                        <Typography variant="h4" component="h2">
                            Čo dokáže?
                        </Typography>
                        <Typography className={classes.marginTopLittle} variant="body1" component="h2">
                            Aplikácia Vás správne prevedie jednotlivými krokmi
                            vybavenia pohrebu zosnulého/ej tak, aby ste mali zaznamenané všetko,
                            čo je z hľadiska zákona potrebné a mali k dispozícii všetky dokumenty, ktoré
                            sú uvedené v zákone o pohrebníctve č. 131/2010 Z. z., podľa ktorého je postavený model
                            našej aplikácie.

                            Aplikácia automaticky generuje splnomocnenie, ktoré viete podpísať
                            digitálne, evidenciu zosnulých, spomienkovú stránku a veľa ďalších vymožeností.

                            Najdôležitejšie dokumenty (List o obhliadke mŕtveho, Úmrtný list, Pas
                            pre mŕtvolu, Povolenie na pochovanie) si viete jednoducho odfotiť
                            alebo zoskenovať a digitálne uloźiť v aplikácií. Samozrejmosťou je aj fotka
                            zosnulého a fotka výkopu hrobu.

                            Okrem uchovávania a spravovania dát o zosnulom ponúka aplikácia aj užitočné upozornenia
                            ohľadom nastavajúcich udalostí. Každé ráno vám príde na
                            váš e-mail upozornenie čo vás čaká najbližie dni. Napríklad
                            vyzdvihnutie nebohého, dátum a čas príchodu rakvy, dátum a čas pohrebu.

                            V neposlednom rade aplikácia ponúka možnosť prispôsobenia, konfigurovania a spravovania
                            vnútorných procesov pohrebníctva (služby, produkty a ich ceny).
                        </Typography>
                    </Grid>
                    <Grid item xs={1} sm={2} md={2} className={classes.marginTopMiddle}>
                    </Grid>
                    <Grid item xs={1} sm={2} md={2} className={classes.marginTopLittleMore}>
                    </Grid>
                    <Grid item xs={10} sm={8} md={8} className={classes.marginTopLittleMore}>
                        <Typography variant="h4" component="h2">
                            Ako je aplikáciu možné používať?
                        </Typography>
                        <Typography className={classes.marginTopLittle} variant="body1" component="h2">
                            Aplikácia je prístupná na všetkých mobilných a stolových zariadeniach s prístupom na internet pomocou vami obľubeného webového prehliadača.
                        </Typography>
                    </Grid>
                    <Grid item xs={1} sm={2} md={2} className={classes.marginTopLittleMore}>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}