import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Title from '../dashboard/Title';
import UserService from "../../services/user.service";
import { logOut} from "../../helpers/logout";
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import CancelIcon from '@material-ui/icons/Cancel';
import Tooltip from "@material-ui/core/Tooltip";
import useTable from "./useTable";
import ActionButton from "../form/input/ActionButton";
import InfoIcon from "@material-ui/icons/Info";
import TextInput from "../form/input/TextInput";
import Button from "@material-ui/core/Button";
import Popup from "./Popup";
import {NotificationManager} from "react-notifications";
import DeleteIcon from "@material-ui/icons/Delete";
import ConfirmDialog from "./ConfirmDialog";

const useStyles = makeStyles((theme) => ({
    seeMore: {
        marginTop: theme.spacing(3),
    },
    depositContext: {
        flex: 1,
    },
}));

const headCells = [
    {id: 'username', label: 'Username'},
    {id: 'name', label: 'Nazov sluzby'},
    {id: 'weekly', label: 'Kolko pohrebov'},
    {id: 'reset', label: 'Resetovať', disableSorting: true},
    {id: 'funerio', label: 'Funerio id', disableSorting: true},
    {id: 'premium', label: 'Premium/Zaklad', disableSorting: true, align: 'right'},
    {id: 'actions', label: 'Aktivovat/Deaktivovat', disableSorting: true, align: 'right'}
]

export default function AdminLogs(props) {
    const classes = useStyles();

    const [content, setContent] = useState([]);
    const [showPassword, setShowPassword] = useState(false);
    const [password, setPassword] = useState({
        user_id: '',
        password: '',
        repeat: ''
    });
    const [showFunerio, setShowFunerio] = useState(false);
    const [funerio, setFunerio] = useState({
        user_id: '',
        funerio_service_id: '',
        funerio_token: ''
    });
    const handlePasswordChange = (event) => {
        const {name, value} = event.target;
        setPassword({...password, [name]: value});
    }
    const handleFunerioChange = (event) => {
        const {name, value} = event.target;
        setFunerio({...funerio, [name]: value});
    }

    const [confirmDialog, setConfirmDialog] = useState({isOpen: false, title: '', subTitle: ''})

    const handleChangePassword = (e) => {
        e.preventDefault();
        if (validatePass()) {
            const data = {
                user_id: password.user_id,
                password: password.password
            }
            UserService.resetPassword(data).then(
                () => {
                    setPassword({
                        user_id: '',
                        password: '',
                        repeat: '',
                    });
                    setShowPassword(false);
                    NotificationManager.success('Heslo bolo zmenené.', 'Upravené', 5000);
                },
                (e) => {
                    if (e.response.status === 401) {
                        logOut(props);
                        NotificationManager.error('Musíte sa nanovo prihlásiť.', 'Chyba', 5000);
                    } else {
                        NotificationManager.error('Došlo k neočakávanej chybe. Skúste neskôr alebo kontaktujte podporu.', 'Chyba', 5000);
                    }
                }
            );
        } else {
            NotificationManager.error('Skontrolujte vyplnený formulár!', 'Chyba', 5000);
        }
    };

    const handleFunerioId = (e) => {
        e.preventDefault();

        UserService.setFunerio(funerio).then(
            () => {
                setFunerio({
                    user_id: '',
                    funerio_service_id: '',
                    funerio_token: '',
                });
                setShowFunerio(false);
                NotificationManager.success('Id zmenene.', 'Upravené', 5000);
            },
            (e) => {
                if (e.response.status === 401) {
                    logOut(props);
                    NotificationManager.error('Musíte sa nanovo prihlásiť.', 'Chyba', 5000);
                } else {
                    NotificationManager.error('Došlo k neočakávanej chybe. Skúste neskôr alebo kontaktujte podporu.', 'Chyba', 5000);
                }
            }
        );

    };

    const validatePass = () => {
        let temp = {};
        temp.password = (/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]{8,}$/).test(password.password) ? "" : "minimálne 8 znakov, aspoň 1 veľké písmeno, aspoň 1 malé písmeno, aspoň 1 číslo";
        temp.repeat = password.password === password.repeat ? "" : "Heslá sa nezhodujú!"
        setErrors({...temp})
        // eslint-disable-next-line eqeqeq
        return Object.values(temp).every(x => x == "")
    }

    const [errors, setErrors] = useState({});

    const {
        TblContainer,
        TblHead,
    } = useTable(content, headCells);


    useEffect(() => {
        UserService.getAdminLogs().then(
            (response) => {
                setContent(response.data);
            },
            (e) => {
                if (e.response.status === 401) {
                    logOut(props);
                }
            }
        );
    }, [props]);

    const showWeeks = (date) => {
        if (!date) {
            return false
        }
        let today = new Date();
        let formatDate = new Date(date);
        return (today.getTime() - formatDate.getTime()) / 1000 / 60 / 60 / 24 / 7
    }

    const handleBlock = (role, id) => {
        setConfirmDialog({
            ...confirmDialog,
            isOpen: false
        });
        UserService.blockUnblockUser({user_id: id, moderator: role}).then(
            () => {
                UserService.getAdminLogs().then(
                    (response) => {
                        setContent(response.data);
                    },
                    (e) => {
                        if (e.response.status === 401) {
                            logOut(props);
                        }
                    }
                );
                NotificationManager.success('Používateľová rola zmenená.', 'Upravené', 5000);
            },
            (e) => {
                if (e.response.status === 401) {
                    logOut(props);
                    NotificationManager.error('Musíte sa nanovo prihlásiť.', 'Chyba', 5000);
                } else {
                    NotificationManager.error('Došlo k neočakávanej chybe. Skúste neskôr alebo kontaktujte podporu.', 'Chyba', 5000);
                }
            }
        );
    }

    const handlePremium = (premium, id) => {
        setConfirmDialog({
            ...confirmDialog,
            isOpen: false
        });
        UserService.setPremium({user_id: id, premium: premium}).then(
            () => {
                UserService.getAdminLogs().then(
                    (response) => {
                        setContent(response.data);
                    },
                    (e) => {
                        if (e.response.status === 401) {
                            logOut(props);
                        }
                    }
                );
                NotificationManager.success('Používateľové premium zmenené.', 'Upravené', 5000);
            },
            (e) => {
                if (e.response.status === 401) {
                    logOut(props);
                    NotificationManager.error('Musíte sa nanovo prihlásiť.', 'Chyba', 5000);
                } else {
                    NotificationManager.error('Došlo k neočakávanej chybe. Skúste neskôr alebo kontaktujte podporu.', 'Chyba', 5000);
                }
            }
        );
    }

    return (
        <React.Fragment>
            <Title>Logy</Title>
            <TblContainer>
                <TblHead/>
                <TableBody>
                    {
                        content.map((item, index) =>
                            (<TableRow key={index}>
                                <TableCell>{item.username}</TableCell>
                                <TableCell>{item.name}</TableCell>
                                <TableCell>{item.createdAt &&
                                Math.round((item.count / showWeeks(item.createdAt) + Number.EPSILON) * 100) / 100
                                }</TableCell>
                                <TableCell>
                                    <ActionButton
                                        color="primary">
                                        <Tooltip title="Reset hesla">
                                            <InfoIcon
                                                onClick={() => {
                                                    setShowPassword(true);
                                                    setPassword({...password, user_id: item._id})
                                                }}
                                                fontSize="small"
                                            />
                                        </Tooltip>
                                    </ActionButton>
                                </TableCell>
                                <TableCell>
                                    <ActionButton
                                        color="primary">
                                        <Tooltip title="Funerio id">
                                            <InfoIcon
                                                onClick={() => {
                                                    setShowFunerio(true);
                                                    setFunerio({
                                                        funerio_service_id: item.funerio_service_id,
                                                        funerio_token: item.funerio_token,
                                                        user_id: item._id
                                                    })
                                                }}
                                                fontSize="small"
                                            />
                                        </Tooltip>
                                    </ActionButton>
                                </TableCell>
                                <TableCell align={'right'}>
                                    Premium:
                                    {item.premium ? <ActionButton
                                            color="primary">
                                            <Tooltip title="Degraduj">
                                                <CheckCircleOutlineIcon
                                                    onClick={() => {
                                                        setConfirmDialog({
                                                            isOpen: true,
                                                            title: 'Naozaj chceš degradovať tohto užívateľa?',
                                                            onConfirm: () => {
                                                                handlePremium(false, item._id)
                                                            }
                                                        })
                                                    }}
                                                    fontSize="small"
                                                />
                                            </Tooltip>
                                        </ActionButton> :
                                        <ActionButton
                                            color="primary">
                                            <Tooltip title="Upgraduj">
                                                <CancelIcon
                                                    onClick={() => {
                                                        setConfirmDialog({
                                                            isOpen: true,
                                                            title: 'Naozaj chceš upgradovať tohto užívateľa??',
                                                            onConfirm: () => {
                                                                handlePremium(true, item._id)
                                                            }
                                                        })
                                                    }}
                                                    fontSize="small"
                                                />
                                            </Tooltip>
                                        </ActionButton>}
                                </TableCell>

                                <TableCell align={'right'}>
                                    {item.isPaying ? <ActionButton
                                            color="primary">
                                            <Tooltip title="Inactivate">
                                                <CheckCircleOutlineIcon
                                                    onClick={() => {
                                                        setConfirmDialog({
                                                            isOpen: true,
                                                            title: 'Naozaj chceš zablokovať tohto užívateľa?',
                                                            onConfirm: () => {
                                                                handleBlock('user', item._id)
                                                            }
                                                        })
                                                    }}
                                                    fontSize="small"
                                                />
                                            </Tooltip>
                                        </ActionButton> :
                                        <ActionButton
                                            color="primary">
                                            <Tooltip title="Activate">
                                                <CancelIcon
                                                    onClick={() => {
                                                        setConfirmDialog({
                                                            isOpen: true,
                                                            title: 'Naozaj chceš odblokovať tohto užívateľa??',
                                                            onConfirm: () => {
                                                                handleBlock('moderator', item._id)
                                                            }
                                                        })
                                                    }}
                                                    fontSize="small"
                                                />
                                            </Tooltip>
                                        </ActionButton>}
                                </TableCell>
                            </TableRow>)
                        )
                    }
                </TableBody>
            </TblContainer>
            <Popup
                title={'Zmena hesla'}
                openPopup={showPassword}
                setOpenPopup={setShowPassword}
                additionalWork={() => {
                    setPassword({
                        user_id: '',
                        password: '',
                        repeat: '',
                    });
                    setErrors({})
                }}
            >
                <div className={classes.root}>
                    <form autoComplete='off' onSubmit={handleChangePassword}>
                        <TextInput
                            label="Heslo"
                            name={'password'}
                            value={password.password}
                            error={errors.password}
                            onChange={handlePasswordChange}
                        />
                        <TextInput
                            label="Zopakovať heslo"
                            name={'repeat'}
                            value={password.repeat}
                            error={errors.repeat}
                            onChange={handlePasswordChange}
                        />
                        <div className={classes.marginTop}>
                            <Button
                                variant="contained"
                                color="primary"
                                type='submit'
                                hidden={true}
                                className={classes.button}
                            >
                                Zmeniť heslo
                            </Button>
                        </div>
                    </form>
                </div>
            </Popup>
            <Popup
                title={'Funerio service id'}
                openPopup={showFunerio}
                setOpenPopup={setShowFunerio}
                additionalWork={() => {
                    setFunerio({
                        user_id: '',
                        funerio_service_id: '',
                        funerio_token: ''
                    });
                }}
            >
                <div className={classes.root}>
                    <form autoComplete='off' onSubmit={handleFunerioId}>
                        <TextInput
                            label="Funerio service id"
                            name={'funerio_service_id'}
                            value={funerio.funerio_service_id}
                            onChange={handleFunerioChange}
                        />
                        <TextInput
                            label="funerio token"
                            name={'funerio_token'}
                            value={funerio.funerio_token}
                            onChange={handleFunerioChange}
                        />
                        <div className={classes.marginTop}>
                            <Button
                                variant="contained"
                                color="primary"
                                type='submit'
                                hidden={true}
                                className={classes.button}
                            >
                                Zmeniť id
                            </Button>
                        </div>
                    </form>
                </div>
            </Popup>
            <ConfirmDialog
                confirmDialog={confirmDialog}
                setConfirmDialog={setConfirmDialog}
                icon={<DeleteIcon/>}
            />
        </React.Fragment>
    );
}