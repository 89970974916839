import React from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Cloud from "../../assets/cloud.jpg";
import { Paper, Box } from "@material-ui/core";
import navod1 from "../../assets/navod1.PNG";
import navod2 from "../../assets/navod2.PNG";

const useStyles = makeStyles((theme) => ({
    root: {
        height: "100vh",
    },
    image: {
        backgroundImage: `url(${Cloud})`,
        backgroundRepeat: "no-repeat",
        backgroundColor:
            theme.palette.type === "light"
                ? theme.palette.grey[50]
                : theme.palette.grey[900],
        backgroundSize: "cover",
        backgroundPosition: "center",
    },
    paper: {
        margin: theme.spacing(8, 4),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: "100%",
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    margin: theme.spacing(1),
    marginTopMiddle: {
        marginTop: theme.spacing(15),
    },
    marginTopLittle: {
        marginTop: theme.spacing(3),
    },
    marginTopLittleMore: {
        marginTop: theme.spacing(5),
    },
    padding: {
        padding: theme.spacing(3),
    },
    imageThat: {
        width: '70%',
        margin: 'auto'
    },
    imageOther: {
        width: '90%',
        margin: 'auto',
    }
}));

export default function GuideExcel() {
    const classes = useStyles();

    return (
        <Grid container component="main" className={classes.root}>
            <CssBaseline />
            <Grid item xs={12} sm={12} md={12} className={classes.image}>
                <Grid container>
                    <Grid
                        item
                        xs={1}
                        sm={2}
                        md={2}
                        className={classes.marginTopMiddle}
                    ></Grid>
                    <Grid
                        item
                        xs={10}
                        sm={8}
                        md={8}
                        className={classes.marginTopMiddle}
                    >
                        <Typography variant="h4" component="h2">
                            Návod
                        </Typography>

                        <Paper className={classes.padding}>
                            <Typography
                                component="div"
                                variant="body1"
                                color="black"
                            >
                                Prejdite na stránku google sheets:
                                <a href="https://sheets.new">
                                    https://sheets.new
                                </a>
                                (pre nahranie dokumentu musíte mať aktívny
                                google účet)
                            </Typography>
                            <img key={123} className={classes.imageThat} src={navod1} alt={""} loading="lazy" />
                            <img key={74864856} className={classes.imageOther} src={navod2} alt={""} loading="lazy" />
                        </Paper>
                    </Grid>
                    <Grid
                        item
                        xs={1}
                        sm={2}
                        md={2}
                        className={classes.marginTopMiddle}
                    ></Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}
