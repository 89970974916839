import React, {useEffect, useRef, useState} from "react";
import {Redirect, useParams} from "react-router-dom";
import UserService from "../../services/user.service";
import '../../Print.css';
import { logOut} from "../../helpers/logout";
import {Button, Divider, Grid, Toolbar} from "@material-ui/core";
import ActionButton from "../form/input/ActionButton";
import Tooltip from "@material-ui/core/Tooltip";
import Paper from "@material-ui/core/Paper";
import Container from "@material-ui/core/Container";
import {makeStyles} from "@material-ui/core/styles";
import clsx from "clsx";
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import altPhoto from "../../assets/candle.jpg";
import {PDFExport} from '@progress/kendo-react-pdf';
import {NotificationManager} from "react-notifications";


const useStyles = makeStyles((theme) => ({
    appBarSpacer: theme.mixins.toolbar,
    root: {
        flexGrow: 1,
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
    seeMore: {
        marginTop: theme.spacing(8),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    toolbarButtons: {
        '& .MuiFormControl-root': {
            margin: theme.spacing(1)
        }
    },
    marginElement: {
        margin: theme.spacing(2)
    },
    marginTop: {
        marginTop: theme.spacing(2)
    },
    icon: {
        marginRight: theme.spacing(2),
    },
    heroContent: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(8, 0, 6),
    },
    heroButtons: {
        marginTop: theme.spacing(4),
    },
    cardGrid: {
        paddingTop: theme.spacing(8),
        paddingBottom: theme.spacing(8),
    },
    cardMedia: {
        height: 200,
        width: 150,
        borderRadius: 10
    },
    cardContent: {
        flexGrow: 1,
    },
}));


const PricesGenerator = (props) => {
    const classes = useStyles();
    const fixedHeightPaper = clsx(classes.paper);

    const pdfRef = useRef(null);
    const [funeral, setFuneral] = useState({
        contracting_authority: '',
        ca_email: '',
        ca_phone: '',
        ca_birth: '',
        ca_relationship: '',
        deceased: '',
        d_death: '',
        product_entities: [],
        service_entities: []
    });
    const [success, setSuccess] = useState(false);

    const [user, setUser] = useState(null);

    const funeral_id = useParams();
    const user_local = JSON.parse(localStorage.getItem('user')).id;

    useEffect(() => {
        UserService.getUser(user_local).then(
            (response) => {
                setUser(response.data);
            },
            (e) => {
                if (e.response.status === 401) {
                    logOut(props);
                    NotificationManager.warning('Potrebné sa znova prihlásiť!', 'Upozornenie', 5000);
                }
            }
        );
        UserService.getFuneral(funeral_id.funeral_id).then(
            (response) => {
                setFuneral(response.data);
            },
            (e) => {
                if (e.response.status === 401) {
                    logOut(props);
                    NotificationManager.warning('Potrebné sa znova prihlásiť!', 'Upozornenie', 5000);
                }
            }
        );
    }, [funeral_id.funeral_id, user_local, props]);

    const handleExport = () => {
        pdfRef.current.save();
    }

    const listItems = (entities) => {
        return funeral[entities].map((item, index) => {
            if (item.text_cost) {
                return <span key={index}> <span
                    style={{fontWeight: 'bold'}}>{item.name}</span> - {item.text_cost.text} {item.text_cost.cost}€,</span>
            }
            return ''
        })
    }

    const countPrice = (entities) => {
        let sum = 0;
        funeral[entities].forEach((item) => {
            if (item.count_cost) {
                if (item.number) {
                    sum += item.number;
                }
                if(item.text_cost) {
                    sum += item.text_cost.cost;
                }
            }
        })
        return sum;
    }

    const showDelegation = () => {
        if (user) {
            return <div style={{border: '1px solid'}}>
                <PDFExport ref={pdfRef} paperSize="A4" scale={0.75}
                           fileName={`informacnylist-${funeral.deceased.replace(' ', '')}`}>
                    <div className="a4-paper">
                        <div className="wrapper">
                            <div className="column-3 center">
                            </div>
                            <div className="column-3 center">
                                <div>
                                    <h1>
                                        Informačné ceny k pohrebu
                                    </h1>
                                </div>
                            </div>
                            <div className="column-3 center">
                            </div>
                        </div>
                        <div className="wrapper-tbl">
                            <div className="column-2-1">
                                <div className="tables">
                                    <span style={{fontWeight: 'bold'}}>Zosnulý/á:</span> {funeral.deceased}
                                    <br/>
                                    <span
                                        style={{fontWeight: 'bold'}}>Dátum úmrtia:</span> {funeral.d_death && new Date(funeral.d_death).toShortFormat()}
                                        <br/>
                                    <span style={{fontWeight: 'bold'}}>Vybavoval:</span> {funeral.contracting_authority} - {funeral.ca_relationship} - {funeral.ca_phone}
                                </div>
                            </div>
                        </div>

                        <div className="wrapper-content">
                            <span style={{textDecoration: 'underline'}}>Produkty:</span> <span
                                style={{fontWeight: 'normal'}}>
                                {listItems('product_entities')}
                            </span>
                            <br/>
                            <span style={{textDecoration: 'underline'}}>Produkty, celková cena:</span> {countPrice("product_entities")}€
                            <br/>
                            <span style={{textDecoration: 'underline'}}>Služby:</span>
                            <span
                                style={{fontWeight: 'normal'}}>
                                {listItems('service_entities')}
                            </span>
                            <br/>
                            <span style={{textDecoration: 'underline'}}>Služby, celková cena:</span> {countPrice("service_entities")}€
                        </div>
                    </div>
                </PDFExport>
            </div>
        }
        return <></>
    }

    return (
        <Container maxWidth="lg" className={classes.seeMore}>
            <div className={classes.root}>
                <Paper className={fixedHeightPaper}>
                    <Grid container>
                        <Grid xs={12} sm={4} md={3} lg={3} item container>
                            <Toolbar>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    hidden={true}
                                    className={classes.button}
                                    onClick={() => {
                                        setSuccess(true)
                                    }}
                                >
                                    Späť na detaily
                                </Button>
                            </Toolbar>
                        </Grid>
                        <Grid xs={12} sm={8} md={9} lg={9} item container justifyContent='flex-end'>
                            <Toolbar className={classes.toolbarButtons}>
                                <ActionButton
                                    color="primary">
                                    <Tooltip title="Pdf export">
                                        <PictureAsPdfIcon
                                            fontSize="small"
                                            onClick={handleExport}
                                        />
                                    </Tooltip>
                                </ActionButton>
                            </Toolbar>
                        </Grid>
                    </Grid>

                    <Divider/>
                    <Grid className={classes.marginTop}
                          container
                          spacing={0}
                          direction="column"
                          alignItems="center"
                          justifyContent="center"
                    >
                        {showDelegation()}
                    </Grid>
                </Paper>
            </div>
            {success ? <Redirect to={{
                pathname: `/pohreb/${funeral_id.funeral_id}/info`,
                state: {from: props.location, number: 5}
            }}/> : <></>}
        </Container>
    )
}
export default PricesGenerator;

